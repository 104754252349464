import React from 'react';
import PropTypes from 'prop-types';
import { Card, Button } from 'react-bootstrap';
import corner1 from 'assets/img/illustrations/corner-1.png';
import corner2 from 'assets/img/illustrations/corner-4.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Background from 'components/common/Background';
import IconAlert from 'components/common/IconAlert';

const SpaceWarning = ({ dCuentaPorCobrar }) => (
    <Card className={`overflow-hidden`}>
        {dCuentaPorCobrar > 0 ? <Background image={corner1} className="p-x1 bg-card" />
            : <Background image={corner2} className="p-x1 bg-card" />}
        <Card.Body className="position-relative">

            {dCuentaPorCobrar > 0 && <>
                <h5 className="text-warning">Cuentas por cobrar</h5>

                <h4 className="text-danger pt-3">${parseFloat(dCuentaPorCobrar ).toLocaleString("es-MX", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                })} MXN</h4>

                <Button
                    as={Link}
                    variant="link"
                    className="fs--1 text-warning ps-0"
                    to="/ordenes/"
                >
                    Ir a mis &oacute;rdenes
                    <FontAwesomeIcon icon="chevron-right" className="ms-1 fs--2" />
                </Button>
            </>
            }

            {dCuentaPorCobrar == 0 && <>
                <IconAlert className="mb-0" variant={'success'} >
                    <p className="mb-0">No tienes cuentas por cobrar!</p>
                </IconAlert>
            </>
            }
        </Card.Body>
    </Card>
);

SpaceWarning.propTypes = {
    dCuentaPorCobrar: PropTypes.number
};

export default SpaceWarning;
