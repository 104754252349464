
import PropTypes from 'prop-types';
import React, { useEffect, useState} from 'react';
import { CloseButton, Form, Modal, Button, InputGroup } from 'react-bootstrap';
import AdvanceTableFooter from '../../components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from '../../components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from '../../components/common/advance-table/AdvanceTableWrapper';
import { Card, Row, Col } from 'react-bootstrap';
import useToken from 'hooks/useToken';
import { Link } from 'react-router-dom';


const ModalClienteGanado = ({
    showEtapaClienteGanadoModal,
    setShowEtapaClienteGanadoModal,
    setMensajeError,
    setShowMensajeError,
    idLead,
    vNombreEtapa,
    detallesLead,
    contactos,
    setContactos,
    setEditarEtapa,
    setEditarEtapaModal,
    setvNombreEtapa,
    listaJsonLeadsVinculados,
    GuardarComentario,
    setMensajeInformativo,
    setShowMensajeInformativo,
    guardarNotificacion,
    crearObjAction,
    vendedor,
    obtenerComentarios
}) => {

    const columnsClientes = [
        {
            accessor: 'name',
            Header: 'Nombre',
            cellProps: {
                className: 'white-space-nowrap pe-5 ps-2 py-2'
            },
            Cell: rowData => {
                const { vNombreClienteAkasia, idClienteAkasia } = rowData.row.original;

                return (
                    <>
                        <Row>
                            <Col className="col-12 fs--3">
                                <Link to={"#"}
                                    onClick={() => {

                                        if (vNombreEtapa == null) {
                                            vincularLeadCliente(idLead, idClienteAkasia);
                                        } else {
                                            etapaClienteGanado(idLead, idClienteAkasia, vNombreEtapa);
                                        }

                                        setShowEtapaClienteGanadoModal(false);
                                        setListaClientesAkasia([]);
                                        setVLikeNombreIdCliente('');
                                    }}>
                                    {"Cliente #" + idClienteAkasia}
                                </Link>
                            </Col>
                        </Row>

                        <h6 className="mb-0">
                            <Link to={"#"}
                                onClick={() => {


                                    if (vNombreEtapa == null) {
                                        vincularLeadCliente(idLead, idClienteAkasia);
                                    } else {
                                        etapaClienteGanado(idLead, idClienteAkasia, vNombreEtapa);
                                    }

                                    setShowEtapaClienteGanadoModal(false);
                                    setListaClientesAkasia([]);
                                    setVLikeNombreIdCliente('');
                                }}>
                                <img src="/img/ver20.png"></img > {vNombreClienteAkasia}
                            </Link>

                        </h6>
                    </>
                );
            }
        }
    ];

    const { token, setToken, idUsuario, nombreCompleto } = useToken();
    const [vLikeNombreIdCliente, setVLikeNombreIdCliente] = useState('');
    const [listaClientesAkasia, setListaClientesAkasia] = useState([]);
    

    /**
    * Obtiene los clientes que coincidan con la busqueda
    */
    const obtenerClientesAkasia = async () => {

        var objDataSend = {};
        objDataSend.vLikeNombreId = vLikeNombreIdCliente;

        console.log(objDataSend);
        var jDataSend = JSON.stringify(objDataSend);

        const response = await fetch("/api/leads/obtenerClientesAkasia/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: jDataSend
            }).catch((error) => {
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {

            console.log("==== OBTENER LOS CLIENTES ====");
            console.log("response: ", response);
            const data = await response.json();
            console.log("data: ", data);

            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);
                console.log("Detalles de los clientes: ", detalles['table2']);
                setListaClientesAkasia(detalles['table2']);
                //console.log("lista de clientes: ", listaClientesAkasia);

            }
            else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
    };

    /**
     * Maneja la etapa de cliente ganado
     * @param {any} idLead
     */
    const etapaClienteGanado = async (idLead, idClienteAkasia, vNombreEtapa) => {

        var objDataSend = {};
        objDataSend.idLead = idLead;
        objDataSend.idClienteAkasia = idClienteAkasia;

        console.log("vNombreEtapa: ", vNombreEtapa);
        console.log(objDataSend);

        var jDataSend = JSON.stringify(objDataSend)

        const response = await fetch("/api/leads/etapaClienteGanado/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: jDataSend
            }).catch((error) => {
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {

            console.log("==== DATOS DEL CLIENTE GANADO ====");
            console.log("response: ", response);
            const data = await response.json();
            console.log("data: ", data);

            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);
                console.log("Detalles: ", detalles[0]);

                GuardarComentario("Modificó etapa de <b>" + vNombreEtapa + "</b> a <b>" + detalles[0].vNombreEtapa + "</b>", "2", idLead);

                //este objeto nos dice la accion que se realizara cuando se le de click a la notifiacion
                var objToActionString = "";

                if (detallesLead != null) {

                    detallesLead.vNombreEtapa = detalles[0].vNombreEtapa;
                    detallesLead.idClienteAkasia = detalles[0].idClienteAkasia;
                    detallesLead.iDaysDiff = 0;
                    detallesLead.idEtapa = detalles[0].idEtapa;
                    detallesLead.vNombreClienteAkasia = detalles[0].vNombreClienteAkasia;

                    //Guarda una notificación si alguien que no es el vendedor de este lead cambia la etapa del lead a no interesado.
                    if (idUsuario != detallesLead.idUsuario) {
                        objToActionString = crearObjAction(1, '', 1, detallesLead.idLead);

                        guardarNotificacion("Etapa de lead cambiada",
                            "<b> " + nombreCompleto + "</b> modificó la etapa y cliente del lead <b>#" + detallesLead.idLead + "</b> a: <i> 'Cliente...'</i>",
                            detallesLead.idUsuario,
                            objToActionString,
                            2
                        );

                        await guardarNotificacion("Cliente vinculado",
                            "<b> " + nombreCompleto + "</b> vinculó el lead <b>#" + detallesLead.idLead + "</b> con el cliente <i>'" + detallesLead.vNombreClienteAkasia.substring(0, 4) + "...'</i>",
                            detallesLead.idUsuario,
                            objToActionString,
                            2
                        );
                    }

                    setvNombreEtapa(null);
                    setEditarEtapaModal(false);

                } else {
                    var currentLeadIndex = contactos.findIndex((lead) => lead.idLead === idLead);

                    if (currentLeadIndex != -1) {
                        contactos[currentLeadIndex].idEtapa = detalles[0].idEtapa;
                        contactos[currentLeadIndex].vNombreEtapa = detalles[0].vNombreEtapa;
                        contactos[currentLeadIndex].iDaysDiff = 0;
                        contactos[currentLeadIndex].idClienteAkasia = detalles[0].idClienteAkasia;
                        contactos[currentLeadIndex].vNombreClienteAkasia = detalles[0].vNombreClienteAkasia;

                        //Guarda una notificación si alguien que no es el vendedor de este lead cambia la etapa del lead a cliente ganado.
                        if (idUsuario != contactos[currentLeadIndex].idUsuario) {

                            objToActionString = crearObjAction(1, '', 1, contactos[currentLeadIndex].idLead);
                            guardarNotificacion("Etapa de lead cambiada",
                                "<b> " + nombreCompleto + "</b> modificó la etapa y cliente del lead <b>#" + contactos[currentLeadIndex].idLead + "</b> a: <i> 'Cliente...'</i> y vinculo el lead con el cliente <i>'" + contactos[currentLeadIndex].vNombreClienteAkasia.substring(0, 4) + "...'</i>",
                                vendedor,
                                objToActionString,
                                2
                            );
                            await guardarNotificacion("Cliente vinculado",
                                "<b> " + nombreCompleto + "</b> vinculó el lead <b>#" + contactos[currentLeadIndex].idLead + "</b> con el cliente <i>'" + contactos[currentLeadIndex].vNombreClienteAkasia.substring(0, 4) + "...'</i>",
                                vendedor,
                                objToActionString,
                                2);
                        }
                    }
                    //setvNombreEtapa(null);
                    //setEditarEtapaModal(false);
                    //setEditarEtapa("");
                }

                setEditarEtapa("");
                
            }
            else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);

                setvNombreEtapa(null);
            }

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);

            setvNombreEtapa(null);
        }

    }

    useEffect(() => {

        //obtenerClientesAkasia();

    }, []);

    if (listaClientesAkasia.length == 0 && showEtapaClienteGanadoModal){
        obtenerClientesAkasia();
    }

    /**
     * Asigna un lead a un cliente
     */
    const vincularLeadCliente = async (idLead, idClienteAkasia) => {

        var objDataSend = {};
        objDataSend.idLead = idLead;
        objDataSend.idClienteAkasia = idClienteAkasia;
       
        console.log(objDataSend);
        var jDataSend = JSON.stringify(objDataSend);

        const response = await fetch("/api/leads/vincularLeadCliente/",
        {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: jDataSend
        }).catch((error) => {

            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            setShowMensajeError(true);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {

            console.log("==== ASIGNAR CLIENTE A UN LEAD ====");
            console.log("response: ", response);

            const data = await response.json();
            console.log("data: ", data);

            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);

                console.log("detalles: ", detalles);
                console.log("idClienteAkasia: ", detalles.table2[0].idClienteAkasia);

                var currentLeadIndex = contactos.findIndex((lead) => lead.idLead === idLead);

                if (currentLeadIndex == -1) {
                    var detallesLeadCopy = detallesLead;
                    detallesLeadCopy.iDaysDiff = 0;
                    detallesLeadCopy.idClienteAkasia = detalles.table2[0].idClienteAkasia;
                    detallesLeadCopy.vNombreClienteAkasia = detalles.table2[0].vNombreClienteAkasia;

                    setContactos([detallesLeadCopy, ...contactos]);
                } else {
                    contactos[currentLeadIndex].iDaysDiff = 0;
                    contactos[currentLeadIndex].idClienteAkasia = detalles.table2[0].idClienteAkasia;
                    contactos[currentLeadIndex].vNombreClienteAkasia = detalles.table2[0].vNombreClienteAkasia;
                }

                await GuardarComentario("<b class='badge badge-soft-success' >Se vinculo al cliente</b> <b>" + detalles.table2[0].vNombreClienteAkasia + "</b>", 2, idLead);

                if (listaJsonLeadsVinculados.length > 0) {
                    listaJsonLeadsVinculados.map((lead) => {

                        GuardarComentario("<b class='badge badge-soft-success' >Se vinculo al cliente</b> <b>" + detalles.table2[0].vNombreClienteAkasia + "</b>", 2, lead.idLead);

                        currentLeadIndex = contactos.findIndex((l) => l.idLead === lead.idLead);

                        console.log(currentLeadIndex);
                        if (currentLeadIndex > -1) {//si el lead vinculado esta en la lista de contactos
                            contactos[currentLeadIndex].iDaysDiff = 0;
                        }

                    })
                }
                detallesLead.vNombreClienteAkasia = detalles.table2[0].vNombreClienteAkasia;
                detallesLead.idClienteAkasia = detalles.table2[0].idClienteAkasia;

                //Guarda una notificación si alguien que no es el vendedor de este lead lo vincula con un cliente.
                if (idUsuario != detallesLead.idUsuario) {

                    //este objeto nos dice la accion que se realizara cuando se le de click a la notifiacion
                    var objToActionString = crearObjAction(1, '', 1, detallesLead.idLead);
                    guardarNotificacion("Cliente vinculado",
                        "<b> " + nombreCompleto + "</b> vinculó el lead <b>#" + detallesLead.idLead + "</b> con el cliente <i>'" + detalles.table2[0].vNombreClienteAkasia.substring(0, 4) + "...'</i>",
                        detallesLead.idUsuario,
                        objToActionString,
                        2
                    );
                }

                obtenerComentarios(idLead);

                //setEditarEtapa("");
                setMensajeInformativo("Asignación de cliente exitosa!");
                setShowMensajeInformativo(true);

            } else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }


    };

    return (

        <>
            <Modal
                show={showEtapaClienteGanadoModal}
                onHide={() => { setShowEtapaClienteGanadoModal(false); setListaClientesAkasia([]); setVLikeNombreIdCliente('');}}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Elige un cliente</Modal.Title>
                    <CloseButton
                        className="btn btn-circle btn-sm transition-base p-0"
                        onClick={() => { setShowEtapaClienteGanadoModal(false); setListaClientesAkasia([]); setVLikeNombreIdCliente(''); }}
                    />
                </Modal.Header>
                <Modal.Body>

                    <InputGroup className="fs--1 align-items-center">
                        
                        <Form.Control
                            type="text"
                            size="sm"
                            value={vLikeNombreIdCliente}
                            onChange={
                                (e) => setVLikeNombreIdCliente(e.target.value)
                            }
                            onKeyDown={
                                (e) => {
                                    if (e.key == 'Enter') {
                                        obtenerClientesAkasia()
                                    }
                                }
                            }
                        />

                        <Button
                            className="btn btn-primary"
                            size="sm"
                            type="submit"
                            onClick={() => obtenerClientesAkasia()}
                        >
                            Buscar
                        </Button>
                    </InputGroup>

                        <Row className="gx-3">
                            <Col>
                                <AdvanceTableWrapper
                                    columns={columnsClientes}
                                    data={listaClientesAkasia}

                                    selectionColumnWidth={52}
                                    sortable
                                    pagination
                                    rowCount={listaClientesAkasia.length}
                                >
                                    <Card>
                                        <Card.Header className="px-0">

                                        </Card.Header>
                                        <Card.Body className="p-0">
                                            <AdvanceTable
                                                table
                                                headerClassName="bg-light text-800 align-middle"
                                                rowClassName="btn-reveal-trigger align-middle"
                                                tableProps={{
                                                    size: 'sm',
                                                    className: 'fs--2 mb-0 overflow-hidden'
                                                }}
                                            />
                                        </Card.Body>
                                        <Card.Footer>
                                            <AdvanceTableFooter
                                                rowCount={listaClientesAkasia.length}
                                                table
                                                rowInfo
                                                rowsPerPageSelection
                                                navButtons
                                            />
                                        </Card.Footer>
                                    </Card>
                                </AdvanceTableWrapper>
                            </Col>
                        </Row>

                 
                </Modal.Body>
                
            </Modal>
        </>
    );
};

ModalClienteGanado.propTypes = {
    showEtapaClienteGanadoModal: PropTypes.bool,
    setShowEtapaClienteGanadoModal: PropTypes.func,
    setMensajeError: PropTypes.func,
    setShowMensajeError: PropTypes.func,
    idLead: PropTypes.number,
    vNombreEtapa: PropTypes.string,
    idClienteAkasia: PropTypes.number,
    detallesLead: PropTypes.oneOfType([
        PropTypes.array, // Acepta un array
        PropTypes.object // Acepta un objeto
    ]),
    contactos: PropTypes.array,
    setContactos: PropTypes.func,
    setEditarEtapa: PropTypes.func,
    setEditarEtapaModal: PropTypes.func,
    setvNombreEtapa: PropTypes.func,
    listaJsonLeadsVinculados: PropTypes.array,
    GuardarComentario: PropTypes.func,
    setMensajeInformativo: PropTypes.func,
    setShowMensajeInformativo: PropTypes.func,
    guardarNotificacion: PropTypes.func,
    crearObjAction: PropTypes.func,
    vendedor: PropTypes.number,
    obtenerComentarios: PropTypes.func
};

export default ModalClienteGanado;
