import OrdenesRecientes from 'akasia-components/dashboards/general/ordenes-por-acreditar/OrdenesPorAcreditar';
import classNames from 'classnames';
import MensajeErrror from 'components/common/MensajeError';
import { ToastContainer } from 'react-toastify';
import useToken from 'hooks/useToken';
import React, { useEffect, useState } from 'react';
import { Badge, Card, Col, Modal, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SpaceWarning from '../default/SpaceWarning';
import AvanceVentaItemsTrimestre from './AvanceMetas/AvanceVentaItemsTrimestre';
import AvanceVentasItemsMes from './AvanceMetas/AvanceVentasItemsMes';
import Notification from './saludo-inicial/NotificacionPocoSoporte';
import TarjetaSaludo from './saludo-inicial/TarjetaSaludo';

import FalconCloseButton from 'components/common/FalconCloseButton';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';

import corner1 from 'assets/img/illustrations/corner-1.png';
import Background from 'components/common/Background';

import VentasPorVendedor from 'akasia-components/administradores/VentasPorVendedor';
import AvanceGraficoAnual from './AvanceMetas/AvanceGraficoAnual';
import AvanceGraficoTrimestral from './AvanceMetas/AvanceGraficoTrimestral';
import ModalSeleccionaNivel from './modal-seleccionar-nivel/opciones';
import NotificacionCumple from './saludo-inicial/NotificacionCumple';


const Ecommerce = () => {

    //Columnas
    const columnsOrdenesDeudas = [
        {
            accessor: 'iNumPagosNoValidados',
            Header: '',
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { iNumPagosNoValidados, idOrden, bComentario, dFechaOrden, dFechaPrimerPago } = rowData.row.original;
                return (
                    <>

                        {iNumPagosNoValidados > 0 &&
                            <Link to={"/ordenes/detalle/" + idOrden}>
                                <img title="Hay pagos sin validar..." src="/img/advertencia20.png"></img>
                            </Link>
                        }

                        {bComentario == 1 &&
                            <img title="Hay comentarios" src="/img/comentario20.png"></img>
                        }
                        {dFechaPrimerPago < dFechaOrden &&
                            <Link to={"/ordenes/detalle/" + idOrden}>
                                <img title="Hay un error en este pago" src="/img/error20.png"></img>
                            </Link>

                        }
                    </>
                );
            }
        },
        {
            accessor: 'idOrden',
            Header: 'Orden',

            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { idOrden, vNombreCliente, vEmail, bIsActive, Estatus } = rowData.row.original;
                return (
                    <>
                        <Link to={"/ordenes/detalle/" + idOrden}>
                            <strong className={classNames({
                                "text-danger": bIsActive == false,
                            })}># {idOrden}</strong>
                        </Link>{'   '}&nbsp;&nbsp;&nbsp;
                        <Link to={"/ordenes/detalle/" + idOrden}>
                            <strong className={classNames({
                                "text-danger": bIsActive == false,
                            })}>{vNombreCliente}</strong> <br />
                        </Link>
                        <span className={classNames({
                            "text-danger": bIsActive == false,
                            "text-lowercase": true
                        })} >{vEmail}</span>
                        <div><Badge bg={Estatus == "Vencida" ? "danger" : "warning"} >{Estatus}</Badge></div>
                    </>
                );
            }
        },
        {
            accessor: '',
            Header: 'Fechas',
            Cell: rowData => {
                const { dFechaVenta, dFechaVencimiento } = rowData.row.original;
                return (
                    <>
                        <div className="text-700">
                            Fecha Venta
                        </div>
                        <div className="text-800 fw-bold">
                            {new Date(dFechaVenta).toLocaleDateString('es-mx', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' })}
                        </div>

                        <div className="text-700 mt-2">
                            Fecha Vencimiento
                        </div>
                        <div className="text-800 fw-bold">
                            {new Date(dFechaVencimiento).toLocaleDateString('es-mx', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' })}
                        </div>


                    </>
                );
            }
        },
        {
            accessor: 'vVendedor',
            Header: 'Vendedor',
            Cell: rowData => {
                const { vVendedor } = rowData.row.original;
                return (
                    <>
                        {vVendedor}

                    </>
                );
            }
        },

        {
            accessor: 'dTotal',
            Header: 'Total',
            Cell: rowData => {
                const { dTotal } = rowData.row.original;
                return (
                    <>
                        <span className="text-end fs--1 fw-medium py-2 fw-bold">${parseFloat(dTotal).toLocaleString("es-MX", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                        })}</span>
                    </>
                );
            }
        },

        {
            accessor: 'dImportePagado',
            Header: 'Pagado',
            Cell: rowData => {
                const { dImportePagado } = rowData.row.original;
                return (
                    <>
                        <span className="text-end fs--1 fw-medium py-2 text-black fw-bold">${parseFloat(dImportePagado).toLocaleString("es-MX", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                        })}</span>
                    </>
                );
            }
        },
        {
            accessor: 'dDeuda',
            Header: 'Insoluto',
            Cell: rowData => {
                const { dDeuda } = rowData.row.original;
                return (
                    <>
                        <span className="text-end fs--1 fw-medium py-2 text-danger fw-bold">${parseFloat(dDeuda).toLocaleString("es-MX", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                        })}</span>
                    </>
                );
            }
        },
        {
            accessor: 'vMoneda',
            Header: 'Moneda',
            Cell: rowData => {
                const { vMoneda, dTipoCambio } = rowData.row.original;
                return (
                    <>
                        <span>

                            <span>{vMoneda}</span>
                            <span className="fw-bold">{(vMoneda == 'USD' ? " T.C. " + dTipoCambio : "")}</span>

                        </span>

                    </>
                );
            }
        },

    ];
    const { token, setToken, name, idCatTipoUsuario } = useToken();
    const [mensajeError, setMensajeError] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);
    const [aviso, setAviso] = useState({});

    const [ventasTrim, setVentasTrim] = useState({});
    const [ventasMes, setVentasMes] = useState({});
    const [variablesMetasControl, setVariablesMetasControl] = useState({});
    const [ordenes, setOrdenes] = useState([]);

    const [cargando, setCargando] = useState(true);
    const [vendedores, setVendedores] = useState([]);
    const [cumpleanios, setCumpleanios] = useState([]);

    //const chartRef = useRef(null);

    /** Ventas Totales por Vendedor */
    //const [ventasPorVendedor, setVentasPorVendedor] = useState([]);

    const [ShowModalOrdenesVencidas, setShowModalOrdenesVencidas] = useState(false);
    const [showModalSeleccionarNivel, setShowModalSeleccionarNivel] = useState(true);

    const [ordenesDeudas, setOrdenesDeudas] = useState([]);

    const [dateQuarter, setDateQuarter] = useState(new Date())

    const ObtenerHomeStatitics = async () => {
        setCargando(true);
        const response = await fetch("/api/home/ObtenerHomeStatitics", {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch: ' + error);
                console.log('Hubo un problema con la peticion Fetch: ' + error.message);

            });

        if (response.ok) {
            console.log("==== OBTENER HOME STATISTICS ====");
            console.log(response);
            const data = await response.json();
            console.log("OBTENER HOME STATISTICS", data);

            //Avisos
            setAviso(data['table1'][0]);

            //Items del Mes
            setVentasMes(data['table2'][0]);

            //Items del trimestre
            setVentasTrim(data['table3'][0]);
            /*
            setAviso({
                ...aviso,
                dTotalVentaMensual: data['table4'][0].dTotalVentaMensual,
                dTotalIngresosMensual: data['table4'][0].dTotalIngresosMensual,
                vHorasSoporteTecnico: data['table4'][0].vHorasSoporteTecnico
            });*/

            //Tabla de configuracion
            setVariablesMetasControl(data['table4'][0]);

            //Tabla de ordenes pendientes de acreditar
            setOrdenes(data['table5']);

            //Tabla con lista de vendedores
            setVendedores(data['table6']);

            //Tabla con lista de cumpleañeros
            setCumpleanios(data['table7']);

            //Tabla con Modal de las ordenes con deuda
            ObtenerOrdenesConDeudas(data['table4'][0].idUsuario);

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setCargando(false);

    };

    const ObtenerHomeStatiticsAvanceVentas = async (date_, idUsuario) => {
        console.log("date_: ",date_);
        setCargando(true);
        const response = await fetch("/api/home/ObtenerHomeStatiticsAvanceVentas?idUsuario=" + idUsuario + "&date=" + date_, {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch: ' + error);
                console.log('Hubo un problema con la peticion Fetch: ' + error.message);

            });

        if (response.ok) {
            console.log(response);
            const data = await response.json();
            console.log(data);

            //Items del Mes
            setVentasMes(data['table1'][0]);

            //Items del trimestre
            setVentasTrim(data['table2'][0]);

           /* setVariablesMetasControl({
                ...variablesMetasControl,
                dTotalVentaMensual: data['table3'][0].dTotalVentaMensual,
                dTotalIngresosMensual: data['table3'][0].dTotalIngresosMensual,
                vHorasSoporteTecnico: data['table3'][0].vHorasSoporteTecnico
            });*/

            //Tabla de configuracion
            setVariablesMetasControl(data['table3'][0]);

            //Tabla con Modal de las ordenes con deuda
            ObtenerOrdenesConDeudas(idUsuario);

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setCargando(false);

    };

    /**
    * Obtiene las ventas por vendedor
    * 
    */
    


    useEffect(() => {
        ObtenerHomeStatitics();
        //obtenerVentasTotalesByVendedor();
    }, []);

    const ObtenerOrdenesConDeudas = async (idUsuario) => {

        setShowModalOrdenesVencidas(false);

        const response = await fetch("/api/home/ObtenerOrdenesConDeuda?idUsuario=" + idUsuario, {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch: ' + error);
                setShowMensajeError(true);

                console.log('Hubo un problema con la peticion Fetch: ' + error.message);

            });

        if (response.ok) {
            console.log("**********************ORDENES CON DEUDA**********************");
            console.log(response);
            const objResponse = await response.json();
            console.log(objResponse);

            if (objResponse.iResponseType == 1) {

                const data = JSON.parse(objResponse.detalles);

                console.log(data);

                setOrdenesDeudas(data);

                if (data.length > 0)
                    setShowModalOrdenesVencidas(true);

            }


            console.log("**********************ORDENES CON DEUDA**********************");
        }
        else {

            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            console.log(response);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }


    };

    /**
     * obtiene el total de ventas y soporte de un usuario
     * @param {any} idUsuario
     */
    /*const obtenerTotalVentasSoporte = async (idUsuario, date) => {

        var jDataSend = {};
        jDataSend.idUsuario = idUsuario;
        jDataSend.date = date;

        const response = await fetch("/api/home/obtenerTotalVentasSoporte",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jDataSend)
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);

                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {

            console.log("============= VENTAS POR VENDEDORES =================");
            console.log("response: ", response);

            const data = await response.json();
            console.log("data: ", data);

            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);
                console.log("detalles: ", detalles);

                setAviso({
                    ...aviso,
                    dTotalVentaMensual: detalles['table2'][0].dTotalVentaMensual,
                    dTotalIngresosMensual: detalles['table2'][0].dTotalIngresosMensual,
                    vHorasSoporteTecnico: detalles['table2'][0].vHorasSoporteTecnico
                });
              
                

            } else {
                setMensajeError(data.vMessage);
                setShowMensajeError(true);
            }

        }
        else {
            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
    };
    ELIMINADO POR OPTIMIZACION
    */ 

    return (
        <>
            <Row className="g-3 mb-3">
                <Col xxl={6} xl={6} md={12}>
                    <Row className="g-3 mb-3">
                        <Col xs={12}>
                            {aviso &&
                                <TarjetaSaludo

                                idUsuario={variablesMetasControl.idUsuario}
                                setMensajeError={setMensajeError}
                                setShowMensajeError={setShowMensajeError}
                                dateQuarter={dateQuarter }

                                dTotalVentaMensual={variablesMetasControl.dTotalVentaMensual}
                                dTotalIngresosMensual={variablesMetasControl.dTotalIngresosMensual}
                                vEmailIncorrecto={aviso.vEmailIncorrecto}
                                vDireccionIncompleta={aviso.vDireccionIncompleta}
                                vUltimos48Horas={aviso.vUltimos48Horas}
                                vVendedorNoAsignado={aviso.vVendedorNoAsignado}
                                vTelefonoIncorrecto={aviso.vTelefonoIncorrecto}
                                vLatLonIncorrecto={aviso.vLatLonIncorrecto}
                                vHorasSoporteTecnico={variablesMetasControl.vHorasSoporteTecnico}
                                vClientesDuplicados={aviso.vClientesDuplicados} />
                            }
                        </Col>
                        <Col lg={12}>
                            {cumpleanios.length >  0 &&
                                <NotificacionCumple
                                    cumpleanios={cumpleanios}
                                    profileLink="/user/profile"
                                    className="mb-3"
                                />
                            }
                            {aviso.iNumTicketsSoporte < 20 &&
                                <Notification
                                    emoji={'\u2618'}
                                    time={aviso.vAgenteConMayorSoporte ? aviso.vAgenteConMayorSoporte.includes(name) ? "Suerte en tus ventas!" : aviso.vAgenteConMayorSoporte + " necesita ayuda" : "Al parecer nadie tiene soporte, vamos a incrementar las ventas!"}
                                    mensaje={'<strong>Felicidades ' + name + '!</strong> he notado que no has tenido soporte t&eacute;cnico en los &uacute;ltimos d&iacute;as. <strong>Aprovecha el tiempo libre ayudando a tus compa&ntilde;eros con sus clientes para agilizar el trabajo de la empresa.</strong>'}
                                    className="border-x-0 border-bottom-0 border-300 rounded-top-0"
                                />
                            }
                        </Col>
                    </Row>
                    <Row className="g-3 mb-3">
                        <Col md={12}>
                            {variablesMetasControl.dPagosPendientes >= 0 &&
                                <SpaceWarning dCuentaPorCobrar={variablesMetasControl.dPagosPendientes} />
                            }
                        </Col>

                    </Row>
                </Col>
                <Col xxl={6} xl={6} md={12}>
                    <Row className="g-3 mb-3">
                        <Col>

                            {variablesMetasControl.idUsuario >= 0 &&
                                <AvanceVentasItemsMes
                                    vendedores={vendedores}
                                    variablesMetasControl={variablesMetasControl}
                                    ObtenerHomeStatiticsAvanceVentas={ObtenerHomeStatiticsAvanceVentas}
                                    cargando={cargando}
                                    idCatTipoUsuario={idCatTipoUsuario}
                                    itemsVentasMes={ventasMes}
                                    dateQuarter={dateQuarter}
                                    setDateQuarter={setDateQuarter }

                                />
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <AvanceVentaItemsTrimestre
                                items={ventasTrim}
                                cargando={cargando}
                                trimestre={variablesMetasControl.trimestre}
                            />
                        </Col>
                    </Row>
                    <Row className="g-3 mb-3">
                        <Col md={6}>
                            <AvanceGraficoTrimestral
                                variablesMetasControl={variablesMetasControl}
                                cargando={cargando}
                                fontSize="fs-2" />
                        </Col>
                        <Col md={6}>
                            <AvanceGraficoAnual
                                cargando={cargando}
                                variablesMetasControl={variablesMetasControl}
                                fontSize="fs-2" />
                        </Col>
                    </Row>

                </Col>
            </Row>
            <Row className="g-3 mb-3">
                <Col>
                    <Card className="h-100">
                        <VentasPorVendedor
                            setMensajeError={setMensajeError}
                            setShowMensajeError={setShowMensajeError}
                            items_Money={"ITEMS"}
                        />
                        {/*<Card.Header className="justify-content-between border-bottom border-200 py-3 py-md-2">
                            <h6 className="mb-2 mb-md-0 py-md-2">Gráfico comparativo de número de ITEMS</h6>
                        </Card.Header>
                        <Card.Body className="scrollbar overflow-y-hidden">
                            {/*<GraficoBarras*/}
                            {/*    ref={chartRef}*/}
                            {/*    ventasPorVendedor={ventasPorVendedor}*/}
                            {/*    vendedores={vendedores} />*/}
                                                   {/* 
                        </Card.Body>
                        */}
                    </Card>

                </Col>
            </Row>
            <Row className="g-3 mb-3">

                <Col md={12} xxl={12}>
                    {ordenes.length > 0 &&
                        <OrdenesRecientes ordenes={ordenes} />
                    }
                </Col>

            </Row>

            <MensajeErrror
                setShowMensajeError={setShowMensajeError}
                showMensajeError={showMensajeError}
                mensajeError={mensajeError}>
            </MensajeErrror>
            <ToastContainer/>
            {ShowModalOrdenesVencidas &&
                <Modal
                    show={ShowModalOrdenesVencidas}
                    onHide={() => { setShowModalOrdenesVencidas(false); }}
                    size="xl"

                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Background image={corner1} className="p-x1 bg-card" style={{ zIndex: 0 }} />

                    <Modal.Header >
                        <Modal.Title>

                            Órdenes con deudas

                        </Modal.Title>
                        <FalconCloseButton style={{ zIndex: 1 }} className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setShowModalOrdenesVencidas(false); }} />
                    </Modal.Header>
                    <Modal.Body>

                        <Row>

                            <Col className="col-12">

                                <Row>

                                    <Col>
                                        <AdvanceTableWrapper
                                            columns={columnsOrdenesDeudas}
                                            data={ordenesDeudas}
                                            sortable
                                            pagination
                                            perPage={6}
                                        >

                                            <AdvanceTable
                                                table
                                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                                rowClassName="align-middle white-space-nowrap"
                                                tableProps={{
                                                    size: 'sm',
                                                    striped: false,
                                                    className: 'fs--2 mb-0 overflow-hidden'
                                                }}
                                            />

                                            <AdvanceTableFooter
                                                rowCount={ordenesDeudas.length}
                                                table
                                                rowInfo
                                                navButtons
                                                rowsPerPageSelection
                                            />

                                        </AdvanceTableWrapper>
                                    </Col>

                                </Row>
                            </Col>

                        </Row>

                    </Modal.Body>
                    <Modal.Footer>



                    </Modal.Footer>
                </Modal>
            }

            {false && //variablesMetasControl.iNivelVendedor == -1 &&
                <ModalSeleccionaNivel
                    setShowModalSeleccionarNivel={setShowModalSeleccionarNivel}
                    showModalSeleccionarNivel={showModalSeleccionarNivel}
                    iNivelVendedor={variablesMetasControl.iNivelVendedor}
                    vMesString={variablesMetasControl.vMesString}
                ></ModalSeleccionaNivel>
            }
        </>
    );
};

export default Ecommerce;
