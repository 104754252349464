import Flex from 'components/common/Flex';
import React from 'react';
import LoginForm from './LoginForm';

const Login = () => (
  <>
    <Flex justifyContent="between" alignItems="center" className="mb-2">
      <h5>Inicio de sesi&oacute;n</h5>
      <p className="fs--1 text-600 mb-0">
        
      </p>
    </Flex>
        <LoginForm />
  </>
);

export default Login;
