import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';

const NotificacionCumple = ({ cumpleanios, profileLink, className }) => {
  return (
    <Card className={className}>
      <Card.Body className="fs--1">
        <Flex>
          <FontAwesomeIcon icon="gift" className="fs-5 text-danger" />
                  <div className="ms-2 flex-1">
                      En este mes cumplen a&ntilde;os: {cumpleanios.map((d, index) => {
                          return <span key={index}>el {cumpleanios[index].dia} festeja <Link className="fw-semi-bold" to={profileLink}>{cumpleanios[index].vNombre}</Link>{index < cumpleanios.length - 1 && <b>,</b>} </span> 
                      })}. Todo el equipo de Akasia Software Development les deseamos un feliz cumplea&ntilde;os!!!
          </div>
        </Flex>
      </Card.Body>
    </Card>
  );
};

NotificacionCumple.propTypes = {
    cumpleanios: PropTypes.array,
    profileLink: PropTypes.string.isRequired,
    className: PropTypes.string
};

export default NotificacionCumple;
