import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';
import MensajeErrror from 'components/common/MensajeError';
import { ToastContainer } from 'react-toastify';
import useToken from 'hooks/useToken';
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row, Spinner, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const NuevoCliente = () => {

    const navigate = useNavigate();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const { token, setToken } = useToken();

    const [tablaRegimenFiscal, settablaRegimenFiscal] = useState([]);
    const [tablaUsoCFDI, settablaUsoCFDI] = useState([]);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [mensajeError, setMensajeError] = useState('');

    const [cargando, setCargando] = useState(true);
    const [cargandoEstado, setCargandoEstado] = useState(false);
    const [showMensajeError, setShowMensajeError] = useState(false);
    const [editar, setEditar] = useState(true)


    const obtenerComboBoxes = async () => {
        setCargando(true);
        setCountries([]);
        const response = await fetch("/api/cliente/getcomboboxes", {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch: ' + error);
                console.log('Hubo un problema con la peticion Fetch: ' + error.message);

            });

        if (response.ok) {
            console.log(response);
            const data = await response.json();

            console.log(data['table1']);
            settablaRegimenFiscal(data['table1']);

            console.log(data['table2']);
            settablaUsoCFDI(data['table2']);

            console.log(data['table3']);
            setCountries(data['table3']);


        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setCargando(false);

    };
    const mostrarEstados = async (e) => {
        setCargandoEstado(true);
        setStates([]);
        setValue("vEstadoDepProv", "");
        if (e.includes('XICO')) {
            setValue("vRfc", "XAXX010101000");
            setValue("idCatRegimenFiscal", 12);
            setValue("idCatUsoCFDI", 22);
        }
        else {
            setValue("vRfc", "XEXX010101000");
            setValue("idCatRegimenFiscal", 12);
            setValue("idCatUsoCFDI", 22);
        }
        setValue('vCp', '61770');


        console.log(e);
        const response = await fetch("/api/cliente/GetStatesByCountry/?vCountryName=" + encodeURIComponent(e), {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error.message);
                console.log('Hubo un problema con la peticion Fetch:' + error.message);
            });

        if (response.ok) {
            console.log(response);
            const data = await response.json();

            console.log(data);
            setStates(data);
            setCargandoEstado(false);


        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            setStates([]);
            console.log(response);
            setCargandoEstado(false);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }

    };

    const enviarInfoToServer = async (data) => {
        setCargando(true);
        console.log(data);
        console.log(JSON.stringify({ values: data }));
        const response = await fetch("/api/cliente/GuardarCliente?values=" + encodeURIComponent(data),
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: data
            })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);
                setCargando(false);
            });

        if (response.ok) {
            console.log("response: ", response);

            const data = await response.json();
            console.log("data: ", data);

            if (data.iResponseType == 1) {

                //const detalles = data.detalles;
                var detalles = JSON.parse(data.detalles);

                console.log("detalles: ", detalles);

                setCargando(false);
                setEditar(false);
                navigate("/clientes/detalle/" + detalles["table2"][0].LastIdentityValue);
            }
            

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            setStates([]);
            console.log(response);
            setCargando(false);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }

    };

    const onSubmit = data => {
        enviarInfoToServer(JSON.stringify(data, null, 2));
    };

    useEffect(() => {
        obtenerComboBoxes();
    }, []);

    return (
        <>
            <Card className="mb-1 p-2">
                <Row className="g-2 align-items-sm-center">
                    <Col xs="auto">
                        <IconButton
                            onClick={() => navigate(-1)}
                            variant="falcon-default"
                            size="sm"
                            icon="arrow-left"
                        />
                    </Col>
                    <Col>
                        <h5>
                            Nuevo cliente
                        </h5>
                    </Col>
                </Row>
            </Card>
            {cargando &&
                (
                    <h6>
                        <Spinner className="text-inline" animation="border" variant="primary" /> Cargando...
                    </h6>
                )}
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Row className="mt-1">
                    <Col lg={6}>
                        <Card>
                            <Card.Header className="p-2">
                                <h6 className="fw-semi-bold text-uppercase">
                                    <FontAwesomeIcon icon="phone" className="mx-2" /> INFORMACI&Oacute;N DE CONTACTO
                                </h6>
                            </Card.Header>
                            <Card.Body className="border-top">

                                <Table responsive striped size="sm" className="fs--1" >
                                    <tbody>
                                        <tr>
                                            <td > <strong>Nombre </strong></td>
                                            <td>
                                                <Form.Control
                                                    type="hidden"
                                                    defaultValue={0}
                                                    {...register("idClienteAkasia")}
                                                />
                                                <Form.Control
                                                    type="text"
                                                    size="sm"
                                                    onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}

                                                    autoComplete="off"
                                                    id="vNombre"
                                                    isInvalid={!!errors.vNombre}
                                                    {...register("vNombre",
                                                        {
                                                            required: 'El nombre del cliente es requerido',
                                                            maxLength: 100,
                                                            minLength: 1
                                                        })
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.vNombre && errors.vNombre.message}
                                                </Form.Control.Feedback>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Apellidos</strong>
                                            </td>
                                            <td>
                                                <Form.Control
                                                    type="text"
                                                    size="sm"
                                                    onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                    id="vApellidos"
                                                    autoComplete="off"
                                                    isInvalid={!!errors.vApellidos}
                                                    {...register("vApellidos",
                                                        {
                                                            required: 'Los apellidos son requeridos',
                                                            maxLength: 100,
                                                            minLength: 1
                                                        })
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.vApellidos && errors.vApellidos.message}
                                                </Form.Control.Feedback>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <strong>Email </strong></td>
                                            <td>
                                                <Form.Control
                                                    type="text"
                                                    size="sm"
                                                    autoComplete="off"
                                                    isInvalid={!!errors.vEmail}
                                                    {...register("vEmail",
                                                        {
                                                            required: 'El email es requerido',
                                                            maxLength: 120,
                                                            minLength: 5,
                                                            pattern: {
                                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                                message: "Email incorrecto."
                                                            }
                                                        })
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.vEmail && errors.vEmail.message}
                                                </Form.Control.Feedback>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="col-lg-4 col-sm-4 col-md-4">
                                                <strong>Tipo Cliente</strong>
                                            </td>
                                            <td>
                                                {editar ?
                                                    (
                                                        <Form.Select
                                                            defaultValue={"1"}
                                                            size="sm"
                                                            aria-label="Default select"
                                                            {...register("idCatTipoCliente",
                                                                {
                                                                    required: true
                                                                })
                                                            }
                                                        >
                                                            <option value="1">CONSUMIDOR FINAL</option>
                                                            <option value="2">DISTRIBUIDOR</option>
                                                        </Form.Select>
                                                    ) : (<></>)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="col-lg-4 col-sm-4 col-md-4">
                                                <strong>Tel&eacute;fono Personal</strong>
                                            </td>
                                            <td>
                                                <Form.Control
                                                    type="text"
                                                    size="sm"
                                                    autoComplete="off"
                                                    isInvalid={!!errors.vTelefonoPersonal}
                                                    {...register("vTelefonoPersonal",
                                                        {
                                                            required: 'Este campo es requerido, incluya el c\u00f3digo de pais: ejemplo: +52',
                                                            maxLength: 20,
                                                            minLength: 5,
                                                            pattern: {
                                                                value: /^[+][0-9]+$/,
                                                                message: "Tel\u00E9fono incorrecto. Debe iniciar con el prefijo: +"
                                                            }
                                                        })
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.vTelefonoPersonal && errors.vTelefonoPersonal.message}
                                                </Form.Control.Feedback>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="col-lg-4 col-sm-4 col-md-4">
                                                <strong>Tel&eacute;fono Trabajo</strong>
                                            </td>
                                            <td>
                                                <Form.Control
                                                    type="text"
                                                    size="sm"
                                                    autoComplete="off"
                                                    isInvalid={!!errors.vTelefonoTrabajo}
                                                    {...register("vTelefonoTrabajo",
                                                        {
                                                            required: false,
                                                            maxLength: 20,
                                                            minLength: 5,
                                                            pattern: {
                                                                value: /^[+][0-9]+$/,
                                                                message: "Tel\u00E9fono incorrecto. Debe iniciar con el prefijo: +"
                                                            }
                                                        })
                                                    }
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="col-lg-4 col-sm-4 col-md-4"><strong>Pa&iacute;s</strong></td>
                                            <td>
                                                <Form.Select
                                                    size="sm"
                                                    aria-label="Default select"
                                                    isInvalid={!!errors.vPais}
                                                    {...register("vPais",
                                                        {
                                                            required: true
                                                        })
                                                    }
                                                    onChange={(event) => { mostrarEstados(event.target.value) }}
                                                >
                                                    <option value="">Selecciona un pa&iacute;s</option>
                                                    {countries.map((country, index) => {
                                                        return (<option key={index} value={country.vName}>{country.vName}</option>)
                                                    })}
                                                </Form.Select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="col-lg-4 col-sm-4 col-md-4"><strong>Estado/Provincia</strong></td>
                                            <td>
                                                {cargandoEstado ?
                                                    (
                                                        <h6>
                                                            <Spinner className="text-inline" size="sm" animation="border" variant="primary" /> Cargando...
                                                        </h6>
                                                    ) :
                                                    <Form.Select
                                                        size="sm"
                                                        aria-label="Default select"
                                                        defaultValue=""
                                                        isInvalid={!!errors.vEstadoDepProv}
                                                        {...register("vEstadoDepProv",
                                                            {
                                                                required: true
                                                            })
                                                        }
                                                    >
                                                        <option value="">Selecciona estado/provincia</option>
                                                        {states.map((st, index) => {
                                                            return (<option key={index} value={st.vName}>{st.vName}</option>)
                                                        })}
                                                    </Form.Select>}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>

                            </Card.Body>
                            <Card.Footer className="border-top text-end">

                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Header className="p-2">
                                <h6 className="fw-semi-bold ls text-uppercase"> <FontAwesomeIcon icon="file" className="ms-2" />  INFORMACI&Oacute;N FISCAL</h6>
                            </Card.Header>
                            <Card.Body className="border-top">
                                <Table responsive striped size="sm" className="fs--1" >
                                    <tbody>
                                        <tr>
                                            <td className="col-lg-4 col-sm-4 col-md-4"><strong>RFC</strong></td>
                                            <td>
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    autoComplete="off"
                                                    onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                    isInvalid={!!errors.vRfc}
                                                    {...register('vRfc',
                                                        {
                                                            required: 'El RFC es obligatorio',
                                                            maxLength: 13,
                                                            minLength: 12,
                                                            pattern: /^[A-Z�&]{3,4}\d{6}(?:[A-Z\d]{3})?$/
                                                        })
                                                    }
                                                    onChange={(event) => {
                                                        let rfc = (event.target.value).trim().toUpperCase();
                                                        if (rfc === "XEXX010101000" || rfc === "XAXX010101000") {
                                                            setValue('vCp', '61770');
                                                        }
                                                    }}

                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.vRfc && errors.vRfc.message}
                                                </Form.Control.Feedback>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="col-lg-4 col-sm-4 col-md-4"><strong>Raz&oacute;n Social</strong>
                                            </td>
                                            <td>
                                                <Form.Control
                                                    size="sm"
                                                    autoComplete="off"
                                                    type="text"
                                                    onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                    isInvalid={!!errors.vRazonSocial}
                                                    {...register('vRazonSocial',
                                                        {
                                                            required: 'La Raz\u00f3n Social es  obligatoria',
                                                            maxLength: 300,
                                                            minLength: 1,
                                                            pattern: /[^|]{1,300}/
                                                        })
                                                    }
                                                />
                                                <a href="#" onClick={() => { setValue("vRazonSocial", document.getElementById('vNombre').value + " " + document.getElementById('vApellidos').value) }}>Usar nombre y apellidos del cliente.</a>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.vRazonSocial && errors.vRazonSocial.message}
                                                </Form.Control.Feedback>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="col-lg-4 col-sm-4 col-md-4"><strong>R&eacute;gimen Fiscal</strong></td>
                                            <td>
                                                <Form.Select
                                                    size="sm"
                                                    aria-label="Default select"
                                                    isInvalid={!!errors.idCatRegimenFiscal}
                                                    {...register("idCatRegimenFiscal",
                                                        {
                                                            required: true
                                                        })
                                                    }
                                                >
                                                    <option value="">Selecciona un r&eacute;gimen</option>
                                                    {tablaRegimenFiscal.map((regimen, index) => {
                                                        return (
                                                            <option
                                                                key={index}
                                                                value={regimen.idCatRegimenFiscal}>
                                                                {regimen.vCodigoRegimen} - {regimen.vDescripcionRegimenFiscal}
                                                            </option>)
                                                    })}
                                                </Form.Select>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="col-lg-4 col-sm-4 col-md-4">
                                                <strong>Num. Reg. Trib.</strong>
                                            </td>
                                            <td>
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    autoComplete="off"
                                                    onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                    isInvalid={!!errors.vNumRegTrib}
                                                    {...register('vNumRegTrib',
                                                        {
                                                            maxLength: 40,
                                                            minLength: 1
                                                        })
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.vNumRegTrib && errors.vNumRegTrib.message}
                                                </Form.Control.Feedback>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="col-lg-4 col-sm-4 col-md-4">
                                                <strong>Direcci&oacute;n Fiscal</strong>
                                            </td>
                                            <td>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={3}
                                                    size="sm"
                                                    autoComplete="off"
                                                    onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                    isInvalid={!!errors.vDireccionFiscal}
                                                    {...register('vDireccionFiscal',
                                                        {
                                                            required: 'La direccion fiscal es obligatorio',
                                                            maxLength: 300,
                                                            minLength: 10
                                                        })
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.vDireccionFiscal && errors.vDireccionFiscal.message}
                                                </Form.Control.Feedback>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="col-lg-4 col-sm-4 col-md-4">C&oacute;digo postal</td>
                                            <td>
                                                <Form.Control
                                                    size="sm"
                                                    type="number"
                                                    autoComplete="off"
                                                    isInvalid={!!errors.vCp}
                                                    {...register('vCp',
                                                        {
                                                            required: 'El Codigo postal es obligatorio',
                                                            maxLength: 6,
                                                            minLength: 4
                                                        })
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.vCp && errors.vCp.message}
                                                </Form.Control.Feedback>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="col-lg-4 col-sm-4 col-md-4">
                                                <strong>Uso CFDI</strong>
                                            </td>
                                            <td>
                                                <Form.Select
                                                    size="sm"
                                                    aria-label="Default select"
                                                    isInvalid={!!errors.idCatUsoCFDI}
                                                    {...register("idCatUsoCFDI",
                                                        {
                                                            required: true
                                                        })
                                                    }
                                                >
                                                    <option value="">Selecciona un uso de CFDI</option>
                                                    {tablaUsoCFDI.map((uso, index) => {
                                                        return (
                                                            <option
                                                                key={index}
                                                                value={uso.idCatUsoCFDI}>
                                                                {uso.vCodigoUso} - {uso.vDescripcionUsoCFDI}
                                                            </option>)
                                                    })}
                                                </Form.Select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <strong>Correo de Facturaci&oacute;n </strong></td>
                                            <td>
                                                <Form.Control
                                                    type="text"
                                                    size="sm"
                                                    autoComplete="off"
                                                    isInvalid={!!errors.vEmailFacturacion}
                                                    {...register("vEmailFacturacion",
                                                        {
                                                            maxLength: 120,
                                                            minLength: 5,
                                                            pattern: {
                                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                                message: "Email incorrecto."
                                                            }
                                                        })
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.vEmailFacturacion && errors.vEmailFacturacion.message}
                                                </Form.Control.Feedback>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="col-lg-4 col-sm-4 col-md-4">
                                                <strong>Tel&eacute;fono de Facturaci&oacute;n</strong>
                                            </td>
                                            <td>
                                                <Form.Control
                                                    type="text"
                                                    size="sm"
                                                    autoComplete="off"
                                                    isInvalid={!!errors.vTelefonoFacturacion}
                                                    {...register("vTelefonoFacturacion",
                                                        {
                                                            required: false,
                                                            maxLength: 20,
                                                            minLength: 5,
                                                            pattern: {
                                                                value: /^[+][0-9]+$/,
                                                                message: "Tel\u00E9fono incorrecto. Incluya el c\u00f3digo de pais: ejemplo: +52 "
                                                            }
                                                        })
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.vTelefonoFacturacion && errors.vTelefonoFacturacion.message}
                                                </Form.Control.Feedback>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Card.Body>
                            <Card.Footer className="border-top text-end">
                                {editar &&
                                    <IconButton
                                        className="ms-2"
                                        iconClassName="fs--2 me-1"
                                        variant="falcon-default"
                                        size="sm"
                                        icon="check"
                                        type="submit"
                                    >
                                        Guardar Cambios
                                    </IconButton>
                                }
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Form>
            <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>
            <ToastContainer/>
        </>
    );
};

export default NuevoCliente;
