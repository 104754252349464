import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useToken from '../../hooks/useToken';
import Avatar from './Avatar';

const ImagenPerfil = ({ idUsuario }) => {

    const { token, setToken } = useToken();
    const [imagenPerfil, setImagenPerfil] = useState();
    const ObtenerImagenPerfil = async () => {
        const response = await fetch("/api/perfil/obtenerImagenPerfil/?idUsuario=" + idUsuario,
            {
                method: 'GET',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'text/plain'
                }
            }).catch((error) => {
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            console.log("==== OBTENER IMAGEN DEL PERFIL ====");
            console.log(response);
            const data = await response.text();
            console.log(data);
            setImagenPerfil(data)
            // Guardar la imagen en LocalStorage
            localStorage.setItem(`perfil_${idUsuario}`, data);
        }
        else {
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }


    }

    const CargarImagen = async () => {
        var img_perfil = localStorage.getItem(`perfil_${idUsuario}`); 

        //Revisa si existe la variable de sesion. Si no existe, Obtiene los datos para guardarlos.
        if (img_perfil)
            setImagenPerfil(img_perfil);
        else {
            ObtenerImagenPerfil()
            
        }
    }

    useEffect(() => {
        CargarImagen()
    }, [idUsuario])

    return (
        <Link className="d-flex" to={"/perfil/" + idUsuario} >
            <Avatar src={imagenPerfil} size="xl" />

        </Link>
    );
};

ImagenPerfil.propTypes = {
    idUsuario: PropTypes.integer
};

export default ImagenPerfil;
