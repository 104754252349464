import {
    lmsStatistics
} from 'data/dashboard/lms';
import React from 'react';
import LmsStats from './lms-stats/LmsStats';

const MetasVenta = () => {
    return (
        <>
            <LmsStats data={lmsStatistics} />

        </>
    );
};

export default MetasVenta;
