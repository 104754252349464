import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import FalconCloseButton from 'components/common/FalconCloseButton';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import MensajeErrror from 'components/common/MensajeError';
import { ToastContainer } from 'react-toastify';
import DatePicker from "react-datepicker";

import useToken from 'hooks/useToken';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Col, Form, Modal, ProgressBar, Row, Spinner } from 'react-bootstrap';

import MensajeInformativo from 'components/common/MensajeInformativo';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Greetings from './Greetings';
import { FaCalendarAlt } from 'react-icons/fa';


const CatalogoTicketSoporte = () => {

    const [tickets, setTickets] = useState([]);
    const [soporteAgentes, setSoporteAgentes] = useState([]);
    const [totalTickets, setTotalTickets] = useState([]);
    const [mensajeError, setMensajeError] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);
    const [showMensajeInformativo, setShowMensajeInformativo] = useState(false);
    const [mensajeInformativo, setMensajeInformativo] = useState('');

    const [cargando, setCargando] = useState(true);
    const [nivelSoporte, setNivelSoporte] = useState([]);
    const [herramientas, setHerramientas] = useState([]);
    const [statusSolucion, setStatusSolucion] = useState([]);

    const [clientes, setClientes] = useState([]);


    const [fechaInicio, setFechaInicio] = useState(new Date());
    const [fechaFin, setFechaFin] = useState(new Date());

    const [idUsuario, setIdUsuario] = useState("0");

    const [showModalNuevoSoporte, setShowModalNuevoSoporte] = useState(false);
    const [cargandoComboBoxSoporte, setCargandoComboBoxSoporte] = useState(false);

    //se obtiene las funciones del hook useForm para usar formularios
    const { register, reset, handleSubmit, setValue, formState: { errors } } = useForm();

    //obtiene los datos de la session
    const { token, setToken } = useToken();

    //las columnas de la tabla
    const columns = [
        {
            accessor: 'dFechaSoporte',
            Header: 'Fecha',
            headerProps: { className: 'pe-5 white-space-nowrap' },
            cellProps: { className: 'font-sans-serif white-space-nowrap' },
            Cell: rowData => {
                const { dCreatedDate } = rowData.row.original;
                return (
                    <span>{dCreatedDate}</span>
                );
            }
        },
        {
            accessor: 'vNombre',
            Header: 'Nombre del cliente',
            headerProps: { className: 'ps-2', style: { height: '46px' } },
            cellProps: {
                className: 'white-space-nowrap pe-5 ps-2'
            },
            Cell: rowData => {
                const { vNombre, idClienteAkasia } = rowData.row.original;
                return (
                    <Flex alignItems="center" className="position-relative">
                        <Link
                            to={"/soporte-tecnico/detalles-soporte/" + idClienteAkasia}
                            className="stretched-link text-900"
                        >
                            <span className="mb-0 d-inline-block text-truncate" style={{ maxWidth: '250px' }}>
                                {vNombre}
                            </span>
                        </Link>
                    </Flex>
                );
            }
        },
        {
            accessor: 'vUsuario',
            Header: 'Agente',
            headerProps: { className: 'pe-5 white-space-nowrap' },
            cellProps: { className: 'font-sans-serif white-space-nowrap' },
            Cell: rowData => {
                const { vUsuario } = rowData.row.original;
                return (
                    <span>{vUsuario}</span>
                );
            }
        },
        {
            accessor: 'iNivel',
            Header: 'Nivel',
            headerProps: { className: 'text-end white-space-nowrap' },
            cellProps: {
                className: 'text-end'
            },
            Cell: rowData => {
                const { iNivel } = rowData.row.original;
                return <span>{iNivel == 1 && <img title="Incidencias b&aacute;sicas" src="img/nivel1.png"></img>}
                    {iNivel == 2 && <img title="Nivel t&eacute;cnico m&aacute;s especializado" src="img/nivel2.png"></img>}
                    {iNivel == 3 && <img title="Problemas t&eacute;cnicos de resoluci&oacute;n m&aacute;s avanzada" src="img/nivel3.png"></img>}
                    {iNivel == 4 && <img title="Especializado en el &aacute;rea de sistemas y servidores" src="img/nivel4.png"></img>}
                    {iNivel == 5 && <img title="Programaci&oacute;n en diferentes lenguajes de programaci&oacute;n y contacto con agentes extranjeros" src="img/nivel5.png"></img>}
                </span>
            }
        },
        {
            accessor: 'vTitulo',
            Header: 'Nombre del reporte',
            cellProps: {
                className: 'white-space-nowrap pe-5'
            },
            Cell: rowData => {
                const { vTitulo, idClienteAkasia } = rowData.row.original;
                return <Link to={"/soporte-tecnico/detalles-soporte/" + idClienteAkasia} className="d-inline-block text-truncate" style={{ maxWidth: '250px' }}>{vTitulo}</Link>;
            }
        },
        {
            accessor: 'iMinutos',
            Header: 'Minutos',
            headerProps: { className: 'text-end white-space-nowrap' },
            cellProps: {
                className: 'text-end fs-1'
            },
            Cell: rowData => {
                const { iMinutos } = rowData.row.original;
                return <span className="fs--1"> {iMinutos < 30 ? <Badge
                    bg='success'
                    className='me-2'>
                    {iMinutos}
                </Badge> : iMinutos < 90 ? <Badge
                    bg='warning'
                    className='me-2'>
                    {iMinutos}
                </Badge> : iMinutos > 90 && <Badge
                    bg='danger'
                    className='me-2'>
                    {iMinutos}
                </Badge>}
                </span>

            }
        },
        {
            accessor: 'vTipoContacto',
            Header: 'Tipo Contacto',
            headerProps: { className: 'text-end' },
            cellProps: { className: 'ps-4 text-end' },
            Cell: rowData => {
                const { vTipoContacto } = rowData.row.original;
                return <span>{vTipoContacto}</span>
            }
        }
    ];

    //llena los combos del registro de formulario
    const obtenerComboBoxSoporteTecnico = async () => {
        setShowModalNuevoSoporte(true);
        setCargandoComboBoxSoporte(true)
        const response = await fetch("/api/soportetecnico/ObtenerComboBoxesRegistroSoporte/", {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch: ' + error);
                console.log('Hubo un problema con la peticion Fetch: ' + error.message);
            });

        if (response.ok) {
            console.log(response);
            const data = await response.json();

            console.log(data['table1']);
            setNivelSoporte(data['table1']);

            console.log(data['table2']);
            setHerramientas(data['table2']);

            console.log(data['table3']);
            setStatusSolucion(data['table3']);

            console.log(data['table4']);
            setClientes(data['table4']);

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setCargandoComboBoxSoporte(false)
    };

    //envia los datos del formulario l servidor
    const registrarSoporteTecnico = async (values) => {
        setShowModalNuevoSoporte(false);
        console.log(values);
        const response = await fetch("/api/soportetecnico/RegistrarSoporteTecnico/?values=" + encodeURIComponent(values), {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            console.log(response);
            const data = await response.json();
            console.log(data);
            setShowMensajeInformativo(true);
            setMensajeInformativo(data["table1"][0].vMensaje);
            obtenerCatalogoTicketSoporte();
            reset();
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }

    };

    //recoge los datos del formulario del soporte
    const onSubmitNuevoSoporte = data => {
        registrarSoporteTecnico(JSON.stringify(data));
    };

    //obtiene la tabla de soporte tecnico
    const obtenerCatalogoTicketSoporte = async () => {
        setCargando(true);
        const response = await fetch("/api/soportetecnico/ObtenerCatalogoTicketSoporte/?idUsuario=" + idUsuario + "&fechaInicio=" + fechaInicio.toISOString().split('T')[0] + "&fechaFin=" + fechaFin.toISOString().split('T')[0],
            {
                method: 'GET',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            });


        if (response.ok) {
            const data = await response.json();
            setTickets(data['table1']);
            setSoporteAgentes(data['table2']);
            setTotalTickets(data['table3'][0]);
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
        setCargando(false);
    };

    useEffect(() => {
        if (idUsuario) {
            obtenerCatalogoTicketSoporte();
        }
    }, [idUsuario]);


    return (
        <>
            <Row className="g-3 ">
                <Col xxl={12}>
                    <Row className="g-0 h-100">
                        <Col xs={12} className="mb-1">
                            <Greetings />
                        </Col>
                    </Row>
                </Col>
            </Row>

            {cargando ? (<h3><Spinner className="text-inline" animation="border" variant="primary" /> Cargando...</h3>) :
                <Row className="gx-3">
                    <Col xxl={12} xl={12}>
                        <AdvanceTableWrapper
                            columns={columns}
                            data={tickets}
                            selectionColumnWidth={52}
                            sortable
                            pagination
                            perPage={11}
                            rowCount={tickets.length}
                        >
                            <Card>
                                <Card.Header className="px-0">
                                    <Row className="mx-0  ">
                                        <Col
                                            xs={12}
                                            md={6}
                                            sm={6}
                                            lg={6}
                                            className="p-x1 border-md-end border-bottom border-md-bottom-0 border-dashed">
                                            <h6 className="fs--1 mb-3">Tickets de soporte por Agente</h6>
                                            {soporteAgentes.map((sp, index) => {
                                                return (
                                                    <Row key={index} className="mt-2">
                                                        <Col xs={4} sm={3} md={3} lg={2}>
                                                            <p className="mb-0 fs--1 fw-semi-bold text-600 text-nowrap">
                                                                {sp.vUsuario}
                                                            </p>
                                                        </Col>
                                                        <Col xs={8} sm={9} md={9} lg={10} className="d-flex align-items-center">
                                                            <ProgressBar
                                                                now={sp.iNum / totalTickets.iTotal * 100}
                                                                className="w-100 animated-progress-bar"
                                                                style={{
                                                                    height: '6px',
                                                                    '--falcon-progressbar-width': `${sp.iNum / totalTickets.iTotal * 100}%`
                                                                }}
                                                            />
                                                            <p className="mb-0 fs--1 ps-3 fw-semi-bold text-600">
                                                                {sp.iNum}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                )
                                            })}
                                        </Col>
                                        <Col xs={12}
                                            md={4}
                                            sm={4}
                                            lg={4} className="p-x1">
                                            <Row className="g-3">
                                                <Col xs="auto">
                                                    <h6 className="mb-0">Agente</h6>
                                                    <Form.Select
                                                        size="sm"
                                                        aria-label="Default select"
                                                        className="mb-1"
                                                        value={idUsuario}
                                                        onChange={(e) => {
                                                            setIdUsuario(e.target.value);
                                                        }}
                                                    >
                                                        <option value="0">Todos los vendedores</option>
                                                        {soporteAgentes.map((sp, index) => {
                                                            return (<option key={index} value={sp.idUsuario}>{sp.vNombre}</option>)
                                                        })}
                                                    </Form.Select>
                                                </Col>


                                                <Col xs="auto">
                                                    <h6 className="mb-0">Fecha Inicio</h6>
                                                    <DatePicker
                                                        selected={fechaInicio}
                                                        onChange={(date) => {
                                                            console.log(date);
                                                            //sessionStorage.setItem("selectMes", date);
                                                            setFechaInicio(date);

                                                        }}
                                                        onCalendarClose={() => obtenerCatalogoTicketSoporte()}
                                                        dateFormat="dd MMM yyyy"
                                                        className="form-control"
                                                        showDayYearPicker
                                                        customInput={
                                                            <button>
                                                                <FaCalendarAlt />&nbsp;
                                                                {fechaInicio ? new Date(fechaInicio).toLocaleDateString('es-ES', { day: '2-digit', month: 'short', year: 'numeric' }) : ""}
                                                            </button>
                                                        }
                                                    />
                                                </Col>

                                                <Col xs="auto">
                                                    <h6 className="mb-0">Fecha Fin</h6>
                                                    <DatePicker
                                                        selected={fechaFin}
                                                        onChange={(date) => {
                                                            console.log(date);
                                                            //sessionStorage.setItem("selectMes", date);
                                                            setFechaFin(date);
                                                        }}
                                                        onCalendarClose={() => obtenerCatalogoTicketSoporte()}
                                                        dateFormat="dd MMM yyyy"
                                                        className="form-control"
                                                        showDayYearPicker
                                                        customInput={
                                                            <button>
                                                                <FaCalendarAlt />&nbsp;
                                                                {fechaFin ? new Date(fechaFin).toLocaleDateString('es-ES', { day: '2-digit', month: 'short', year: 'numeric' }) : ""}
                                                            </button>
                                                        }
                                                    />

                                                </Col>

                                            </Row>
                                        </Col>
                                    </Row>
                                    <div className="d-lg-flex justify-content-between">

                                        <Row className="flex-between-center gy-2 px-x1">
                                            <Col xs="auto" className="pe-0">
                                                <h6 className="mb-0">Todos los tickets</h6>
                                            </Col>

                                            <Col xs="auto">
                                                {!cargando &&
                                                    <AdvanceTableSearchBox
                                                        className="input-search-width"
                                                        table
                                                    />
                                                }
                                            </Col>
                                        </Row>
                                        <div className="border-bottom border-200 my-3"></div>
                                        <div className="d-flex align-items-center justify-content-between justify-content-lg-end px-x1">
                                            <IconButton
                                                variant="falcon-default"
                                                size="sm"
                                                icon="filter"
                                                transform="shrink-4"
                                                iconAlign="middle"

                                                className="d-xl-none"
                                            >
                                                <span className="d-none d-sm-inline-block ms-1">Filtrar</span>
                                            </IconButton>
                                            <div
                                                className="bg-300 mx-3 d-none d-lg-block d-xl-none"
                                                style={{ width: '1px', height: '29px' }}
                                            ></div>
                                            <div id="orders-actions">
                                                <IconButton
                                                    variant="falcon-default"
                                                    size="sm"
                                                    icon="plus"
                                                    transform="shrink-3"
                                                    iconAlign="middle"
                                                    onClick={() => obtenerComboBoxSoporteTecnico()}
                                                >
                                                    <span className="d-none d-sm-inline-block d-xl-none d-xxl-inline-block ms-1">
                                                        Nuevo Ticket de Soporte
                                                    </span>
                                                </IconButton>
                                            </div>

                                        </div>
                                    </div>
                                </Card.Header>
                                <Card.Body className="p-0">
                                    <AdvanceTable
                                        table
                                        headerClassName="bg-light text-800 align-middle"
                                        rowClassName="btn-reveal-trigger align-middle"
                                        tableProps={{
                                            size: 'sm',
                                            className: 'fs--2 mb-0 overflow-hidden'
                                        }}
                                    />
                                </Card.Body>
                                <Card.Footer>
                                    <AdvanceTableFooter
                                        rowCount={tickets.length}
                                        table
                                        rowInfo
                                        navButtons
                                        rowsPerPageSelection
                                    />
                                </Card.Footer>
                            </Card>
                        </AdvanceTableWrapper>

                    </Col>

                </Row>
            }
            <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>

            <MensajeInformativo setShowMensajeInformativo={setShowMensajeInformativo} showMensajeInformativo={showMensajeInformativo} mensajeInformativo={mensajeInformativo}></MensajeInformativo>
            <ToastContainer />
            {showModalNuevoSoporte &&
                <Modal
                    show={showModalNuevoSoporte}
                    onHide={() => setShowModalNuevoSoporte(false)}
                    size="lg"
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    {cargandoComboBoxSoporte ?
                        <h3>
                            <Spinner className="text-inline" animation="border" variant="primary" /> Cargando...
                        </h3> :
                        <Form noValidate onSubmit={handleSubmit(onSubmitNuevoSoporte)}>
                            <Modal.Header>
                                <Modal.Title>Registrar Ticket de Soporte T&eacute;cnico</Modal.Title>
                                <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => setShowModalNuevoSoporte(false)} />

                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col xs={2} md={2}>
                                        <img src="/img/soporte48.png"></img>
                                    </Col>
                                    <Col xs={10} md={10}>
                                        <p>Debes registrar los detalles del Soporte T&eacute;nico que brindaste al cliente. Indica la fecha, hora y tiempo requerido.</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6} md={6}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Nombre del cliente</Form.Label>
                                            <Select
                                                {...register("idClienteAkasia",
                                                    {
                                                        required: true
                                                    })
                                                }
                                                closeMenuOnSelect={true}
                                                options={clientes}
                                                placeholder='Selecciona...'
                                                classNamePrefix="react-select"
                                                onChange={target => { setValue("idClienteAkasia", target.value); }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.idClienteAkasia && errors.idClienteAkasia.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Label>Fecha del Soporte</Form.Label>
                                        <Form.Group className="mb-3">
                                            <Form.Control
                                                type="date"
                                                {...register("dFechaSoporte",
                                                    {
                                                        required: true
                                                    })
                                                }
                                                isInvalid={!!errors.dFechaSoporte} />
                                            {/* 
                                            <DatePicker
                                                isInvalid={!!errors.dFechaSoporte}
                                                {...register("dFechaSoporte",
                                                    {
                                                        required: true
                                                    })
                                                }
                                                selected={date}
                                                onChange={(date) => { setValue("dFechaSoporte", date); setDate(date) }}
                                                formatWeekDay={day => day.slice(0, 3)}
                                                className='form-control'
                                                placeholderText="Selecciona Fecha y Hora"
                                                timeIntervals={5}
                                                dateFormat="dd/MMM/yyyy h:mm aa"
                                                showTimeSelect
                                                minDate={addDays(new Date(), -2)}
                                                maxDate={new Date()}
                                            />
                                            */}
                                            <Form.Control.Feedback type="invalid">
                                                {errors.dFechaSoporte && errors.dFechaSoporte.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Nivel de Soporte</Form.Label>
                                            <Form.Select
                                                aria-label="Default select"
                                                isInvalid={!!errors.idCatNivelSoporte}
                                                {...register("idCatNivelSoporte",
                                                    {
                                                        required: true
                                                    })
                                                }
                                            >
                                                <option value="">Selecciona un nivel</option>
                                                {nivelSoporte.map((nivel, index) => {
                                                    return (
                                                        <option
                                                            key={index}
                                                            value={nivel.idCatNivelSoporte}>
                                                            {nivel.iNivel} - {nivel.vNombreNivel}
                                                        </option>)
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>T&iacute;tulo</Form.Label>
                                            <Form.Control
                                                autoComplete="off"
                                                type="text"
                                                onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                isInvalid={!!errors.vTitulo}
                                                {...register("vTitulo",
                                                    {
                                                        required: 'Es necesario el titulo',
                                                        maxLength: 100,
                                                        minLength: 1
                                                    })
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.vTitulo && errors.vTitulo.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Descripci&oacute;n (m&iacute;nimo 50 caracteres)</Form.Label>
                                            <Form.Control
                                                type="text"
                                                autoComplete="off"
                                                onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                isInvalid={!!errors.vDescripcion}
                                                {...register("vDescripcion",
                                                    {
                                                        required: 'La descripci�n es requerida',
                                                        maxLength: 200,
                                                        minLength: 50
                                                    })
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.vDescripcion && errors.vDescripcion.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={4} md={4}>
                                        <Form.Label>Duraci&oacute;n (minutos)</Form.Label>
                                        <Form.Group className="mb-3">
                                            <Form.Select
                                                aria-label="Default select"
                                                isInvalid={!!errors.iMinutos}
                                                {...register("iMinutos",
                                                    {
                                                        required: true
                                                    })
                                                }
                                            >
                                                <option value="">Selecciona...</option>
                                                <option value="10">10 minutos</option>
                                                <option value="20">20 minutos</option>
                                                <option value="30">30 minutos</option>
                                                <option value="45">45 minutos</option>
                                                <option value="60">1 hora</option>
                                                <option value="90">1 hora y media</option>
                                                <option value="120">2 horas</option>
                                                <option value="150">2 horas y media</option>
                                                <option value="180">3 horas</option>
                                                <option value="240">4 horas</option>
                                                <option value="300">5 horas</option>
                                                <option value="360">6 horas</option>
                                                <option value="420">7 horas</option>
                                            </Form.Select>

                                            <Form.Control.Feedback type="invalid">
                                                {errors.iMinutos && errors.iMinutos.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={4} md={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Herramienta Utilizada</Form.Label>
                                            <Form.Select
                                                aria-label="Default select"
                                                isInvalid={!!errors.idCatHerramienta}
                                                {...register("idCatHerramienta",
                                                    {
                                                        required: true
                                                    })
                                                }
                                            >
                                                <option value="">Selecciona...</option>
                                                {herramientas.map((herr, index) => {
                                                    return (
                                                        <option
                                                            key={index}
                                                            value={herr.idCatHerramienta}>
                                                            {herr.idCatHerramienta} - {herr.vNombreHerramienta}
                                                        </option>)
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={4} md={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Tipo de contacto</Form.Label>
                                            <Form.Select
                                                aria-label="Default select"
                                                isInvalid={!!errors.vTipoContacto}
                                                {...register("vTipoContacto",
                                                    {
                                                        required: true
                                                    })
                                                }
                                            >
                                                <option value="">Selecciona...</option>
                                                <option value="WhatsApp">WhatsApp</option>
                                                <option value="Llamada por tel�fono">Llamada por tel&eacute;fono</option>
                                                <option value="Messenger">Messenger</option>
                                                <option value="Comentario">Comentario</option>
                                                <option value="En persona">En persona</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Estatus de la soluci&oacute;n</Form.Label>
                                            <Form.Select
                                                aria-label="Default select"
                                                isInvalid={!!errors.idCatStatusSolucion}
                                                {...register("idCatStatusSolucion",
                                                    {
                                                        required: true
                                                    })
                                                }
                                            >
                                                <option value="">Selecciona...</option>
                                                {statusSolucion.map((st, index) => {
                                                    return (
                                                        <option
                                                            key={index}
                                                            value={st.idCatStatusSolucion}>
                                                            {st.idCatStatusSolucion} - {st.vDescripcionStatus}
                                                        </option>)
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={() => { setShowModalNuevoSoporte(false); reset() }} variant='primary' >Cerrar</Button>
                                <Button type="submit" variant='success' >Registrar</Button>
                            </Modal.Footer>
                        </Form>
                    }
                </Modal>
            }
        </>
    );
};

export default CatalogoTicketSoporte;
