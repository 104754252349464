import PropTypes from 'prop-types';
import React from 'react';

const ImagenTipoArchivo = ({ vNombreArchivo }) => {
    return (
        <>
            {
                vNombreArchivo.includes('.pdf') ? <img title={vNombreArchivo } src="/img/pdf20.png"></img> :
                    vNombreArchivo.includes('.PDF') ? <img title={vNombreArchivo} src="/img/pdf20.png"></img> :
                        vNombreArchivo.includes('.xml') ? <img title={vNombreArchivo} src="/img/xml20.png"></img> :
                            vNombreArchivo.includes('.zip') ? <img title={vNombreArchivo} src="/img/winrar20.png"></img> :
                                vNombreArchivo.includes('.rar') ? <img title={vNombreArchivo} src="/img/winrar20.png"></img> :
                                    vNombreArchivo.includes('.jpg') ? <img title={vNombreArchivo} src="/img/imagen20.png"></img> :
                                        vNombreArchivo.includes('.png') ? <img title={vNombreArchivo} src="/img/imagen20.png"></img> :
                                            vNombreArchivo.includes('.jpeg') ? <img title={vNombreArchivo} src="/img/imagen20.png"></img> :
                                                <img title={vNombreArchivo} src="/img/archivo20.png"></img>
        }
                
        </>
    );
};

ImagenTipoArchivo.propTypes = {
    vNombreArchivo: PropTypes.string
};

export default ImagenTipoArchivo;
