import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

const MensajeInformativo = ({ setShowMensajeInformativo, showMensajeInformativo, mensajeInformativo }) => {
    if (showMensajeInformativo) {
        toast.success(mensajeInformativo, {
            theme: 'colored',
            position: "bottom-right",
            closeOnClick: true,
        });
        setShowMensajeInformativo(false);
    }
    return (
        //<ToastContainer />
        <></>
    );
};

MensajeInformativo.propTypes = {

    setShowMensajeInformativo: PropTypes.func,
    showMensajeInformativo: PropTypes.bool,
    mensajeInformativo: PropTypes.string
};

export default MensajeInformativo;