import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import IconButton from 'components/common/IconButton';
import PageHeader from 'components/common/PageHeader';
import useToken from 'hooks/useToken';
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import MensajeErrror from 'components/common/MensajeError';
import { ToastContainer } from 'react-toastify';
import { useParams } from 'react-router-dom';


const columns = [
    {
        Header: ' ',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { idClienteAkasia } = rowData.row.original;
            return <>
                <Link to={"/clientes/detalle/" + idClienteAkasia} >
                    <img title="Ver informaci&oacute;n" src="/img/ver20.png"></img>
                </Link>
            </>
        }
    },
    {
        accessor: 'idClienteAkasia',
        Header: 'ID',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { idClienteAkasia } = rowData.row.original;
            return <Link to={"/clientes/detalle/" + idClienteAkasia}><span>{idClienteAkasia}</span></Link>
        }
    },
    {
        accessor: 'vNombreVendedor',
        Header: 'Vendedor',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { vNombreVendedor } = rowData.row.original;
            return <span> {vNombreVendedor}</span>;
        }
    },
    {
        accessor: 'vRfc',
        Header: 'RFC',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { vRfc } = rowData.row.original;
            return <span> {vRfc}</span>;
        }
    },
    {
        accessor: 'vNombre',
        Header: 'Nombre',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const { vNombre, idClienteAkasia } = rowData.row.original;
            return (
                <>
                    <h5 className="mb-0 fs--2">
                        <Link to={"/clientes/detalle/" + idClienteAkasia}>
                            <span className="d-inline-block text-truncate" style={{ maxWidth: '250px' }}>
                                {vNombre}
                            </span>
                        </Link>
                    </h5>
                </>
            );
        }
    },
    {
        accessor: 'vEmail',
        Header: 'Email',
        Cell: rowData => {
            const { vEmail } = rowData.row.original;
            return <a href={`mailto:${vEmail}`}><span className="d-inline-block text-truncate" style={{ maxWidth: '250px' }}>{vEmail}</span></a>;
        }
    },
    {
        accessor: 'vDireccionFiscal',
        Header: 'Direccion',
        Cell: rowData => {
            const { vDireccionFiscal } = rowData.row.original;
            return <span className="d-inline-block text-truncate" style={{ maxWidth: '250px' }}>{vDireccionFiscal}</span>
        }
    },
    {
        accessor: 'vTelefonoPersonal',
        Header: 'Tel\u00e9fono',
        Cell: rowData => {
            const { vTelefonoPersonal } = rowData.row.original;
            return <>
                <a href={"https://wa.me/" + vTelefonoPersonal} target="_blank" rel="noreferrer">
                    <img title="Enviar WhatsApp" src="/img/whatsapp20.png"></img >
                </a>

                <a href={"https://wa.me/" + vTelefonoPersonal} target="_blank" rel="noreferrer">{vTelefonoPersonal}</a>
            </>
        }
    },
    {
        accessor: 'vPais',
        Header: 'Pa\u00EDs',
        Cell: rowData => {
            const { vPais } = rowData.row.original;
            return <span className="fs--2">{vPais}</span>
        }
    }
];

const ClientesInfoIncompleta = () => {
    const [customers, setCustomers] = useState([]);
    const [cargando, setCargando] = useState(true);
    const navigate = useNavigate();
    const { token, name, usuario, setToken } = useToken();
    const [mensajeError, setMensajeError] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);
    const { vFiltro } = useParams();
    const [vendedores, setVendedores] = useState([]);


    const mostrarClientes = async (vUsuario) => {
        setCargando(true);
        const response = await fetch("/api/cliente/ObtenerClientesInfoIncompleta/?vFiltro=" + vFiltro + "&vUsuario=" + vUsuario,
            {
                method: 'GET',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            });
        if (response.ok) {
            const data = await response.json();
            console.log(data['table1']);
            setCustomers(data['table1']);

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
        setCargando(false);
    };

    const ObtenerDatosVendedores = async () => {
        setCargando(true);
        const response = await fetch("/api/usuario/ObtenerDatosVendedores",
            {
                method: 'GET',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }).catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            const data = await response.json();
            setVendedores(data);
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
        setCargando(false);
        mostrarClientes(usuario)
    };

    useEffect(() => {
        ObtenerDatosVendedores();
    }, []);

    return (
        <>
            <PageHeader
                title={"Errores en " + vFiltro + " del cliente"}
                className="mb-1"
            >
            </PageHeader>
            <AdvanceTableWrapper
                columns={columns}
                data={customers}
                sortable
                pagination
                perPage={15}
            >
                <Card className="mb-3">
                    <Card.Header className="d-flex flex-between-center">
                        <IconButton
                            onClick={() => navigate(-1)}
                            variant="falcon-default"
                            size="sm"
                            icon="arrow-left"
                        />

                    </Card.Header>
                </Card>
                <Card >
                    <Card.Header>
                        <h3>{name},</h3><h5>En esta tabla puedes visualizar los clientes que han comprado alg&uacute;n servicio de Akasia Software Development, pero los datos que se han registrado tienen errores o est&aacute;n incompletos. Por favor rev&iacute;salos detenidamente y completa la informaci&oacute;n de cada uno.</h5>

                    </Card.Header>
                    <Card.Body>
                        <Row className="flex-between-center">
                            <Col xs={12} sm={6} md={6} lg={3} className="d-flex align-items-center pe-0 mb-2">
                                <Form.Select
                                    size="sm"
                                    aria-label="Default select"
                                    onChange={(event) => mostrarClientes(event.target.value)}
                                    defaultValue={usuario}
                                >
                                    <option value="">Todos los vendedores</option>
                                    <option value="NoAsignados">No asignados</option>
                                    {vendedores.map((vendedor, index) => {
                                        return (<option key={index} value={vendedor.vUsuario}>{vendedor.vUsuario}</option>)
                                    })}

                                </Form.Select>
                            </Col>
                        </Row>
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--2 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableFooter
                            rowCount={customers.length}
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            {cargando && (<h5><Spinner className="text-inline" animation="border" variant="primary" /> Cargando...</h5>)}
            <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>
            <ToastContainer/>
        </>
    );
};

export default ClientesInfoIncompleta;
