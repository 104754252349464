import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

//Firebase Config values imported from .env file
const firebaseConfig = {
    apiKey: 'AIzaSyAnKA1HhwfTC1HsYQk0hjmuJkoTySTCpnE',
    authDomain: 'akasia-admin-panel.firebaseapp.com',
    projectId: 'akasia-admin-panel',
    storageBucket: 'akasia-admin-panel.appspot.com',
    messagingSenderId: '663791212073',
    appId: '1:663791212073:web:14ee9dd308bfc574e46e38',
    measurementId: 'G-19B2695EB8',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging service
export const messaging = getMessaging(app);