import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import React from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Timeline = ({ timeline }) => {

    return (
        timeline != null ?
        <div className="timeline-vertical py-0">
            {timeline.map((item, index) => {

                return (
                    <div
                        key={index}
                        className={classNames('timeline-item', {
                            'timeline-item-start': index % 2 === 0,
                            'timeline-item-end': index % 2 !== 0
                        })}
                    >
                        <div className="timeline-icon icon-item icon-item-lg text-primary border-300">
                            <FontAwesomeIcon icon='envelope' className="fs-1" />
                        </div>
                        <Row
                            className={` ${index % 2 == 0 ? 'timeline-item-start' : 'timeline-item-end'
                                }`}
                        >
                            <Col lg={6} className="timeline-item-time">
                                <div>
                                    <h6 className="mb-0 text-700"> {item.vAnio}</h6>
                                    <p className="fs--2 text-500 font-sans-serif">{item.vDia}</p>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="timeline-item-content arrow-bg-white">
                                    <div className="timeline-item-card bg-white dark__bg-1100">
                                        <Link to="#">
                                            <h6 className="mb-2 hover-primary">{item.vTitulo}</h6>
                                        </Link>
                                        <p className="mb-3 text-600 fs--1">
                                            {item.vDescripcion}
                                        </p>
                                        <span className="fs--1"><strong>Nivel:</strong> {item.iNivel} - {item.vNombreNivel}</span><br></br>
                                        <span className="fs--1"><strong>Estatus:</strong> {item.vDescripcionStatus}</span>
                                        <Flex wrap="wrap" className="pt-2">
                                            <h6 className="mb-0 text-600 lh-base">
                                                <FontAwesomeIcon
                                                    icon={['far', 'clock']}
                                                    className="me-1"
                                                />
                                                {item.vHora}
                                            </h6>
                                            <Flex
                                                alignItems="center"
                                                className="ms-auto me-2 me-sm-x1 me-xl-2 me-xxl-x1"
                                            >
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip style={{ position: 'fixed' }}>
                                                            {item.vTipoContacto}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <div
                                                        className={`dot me-0 me-sm-2 me-xl-0 me-xxl-2 bg-success`}
                                                    ></div>
                                                </OverlayTrigger>
                                                <h6 className="mb-0 text-700 d-none d-sm-block d-xl-none d-xxl-block">
                                                    {item.vUsuario}
                                                </h6>
                                            </Flex>
                                            <Flex
                                                alignItems="center"
                                                className="ms-auto me-2 me-sm-x1 me-xl-2 me-xxl-x1"
                                            >
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip style={{ position: 'fixed' }}>
                                                            {item.vTipoContacto}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <div
                                                        className={`dot me-0 me-sm-2 me-xl-0 me-xxl-2 bg-success`}
                                                    ></div>
                                                </OverlayTrigger>
                                                <h6 className="mb-0 text-700 d-none d-sm-block d-xl-none d-xxl-block">
                                                    {item.vNombreHerramienta}
                                                </h6>
                                            </Flex>
                                            <SoftBadge bg='success'>{item.iMinutos} minutos</SoftBadge>
                                        </Flex>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                );
            })}
        </div>
            :
        <div></div>
        );
};

Timeline.propTypes = {
    timeline: PropTypes.array
};

export default Timeline;
