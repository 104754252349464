import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProfileDropdown from 'akasia-components/navbar/top/ProfileDropdown';
import AppContext from 'context/Context';
import React, { useContext } from 'react';
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import NotificationDropdown from './NotificationDropdown';
import PropTypes from 'prop-types';

const TopNavRightSideNavItem = ({
    notificaciones,
    setIdLead,
    setShowModalSeguimientoLead,
    isAllRead,
    setIsAllRead,
    obtenerNotificaciones,
    hayNotificacionesNoLeidas,
    lastNotf
}) => {
    const {
        config: { isDark, isRTL },
        setConfig
    } = useContext(AppContext);
    return (
        <Nav
            navbar
            className="navbar-nav-icons ms-auto flex-row align-items-center"
            as="ul"
        >
            <Nav.Item as={'li'}>
                <Nav.Link
                    className="px-2 theme-control-toggle"
                    onClick={() => setConfig('isDark', !isDark)}
                >
                    <OverlayTrigger
                        key="left"
                        placement={isRTL ? 'bottom' : 'left'}
                        overlay={
                            <Tooltip style={{ position: 'fixed' }} id="ThemeColor">
                                {isDark ? 'Switch to light theme' : 'Switch to dark theme'}
                            </Tooltip>
                        }
                    >
                        <div className="theme-control-toggle-label">
                            <FontAwesomeIcon
                                icon={isDark ? 'sun' : 'moon'}
                                className="fs-0"
                            />
                        </div>
                    </OverlayTrigger>
                </Nav.Link>
            </Nav.Item>

            {/*<CartNotification />*/}
            {<NotificationDropdown
                notificaciones={notificaciones}
                setIdLead={setIdLead}
                setShowModalSeguimientoLead={setShowModalSeguimientoLead}
                isAllRead={isAllRead}
                setIsAllRead={setIsAllRead}
                hayNotificacionesNoLeidas={hayNotificacionesNoLeidas}
                obtenerNotificaciones={obtenerNotificaciones}
                lastNotf={lastNotf}
            />}
            {/*<NineDotMenu />*/}
            <ProfileDropdown />
        </Nav>
    );
};

TopNavRightSideNavItem.propTypes = {
    notificaciones: PropTypes.array,
    setIdLead: PropTypes.func,
    setShowModalSeguimientoLead: PropTypes.func,
    isAllRead: PropTypes.number,
    setIsAllRead: PropTypes.func,
    hayNotificacionesNoLeidas: PropTypes.func,
    obtenerNotificaciones: PropTypes.func,
    lastNotf: PropTypes.number
};

export default TopNavRightSideNavItem;
