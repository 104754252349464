
import PropTypes from 'prop-types';

import React from 'react';
import { Card, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

import useToken from 'hooks/useToken';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const AvanceVentaItemsTrimestre = ({ items, cargando, trimestre }) => {
    const { idCatTipoUsuario } = useToken();
    return (
        <Card className="mb-3">
            <Card.Header>
                <Col>
                    <Row>

                        <Col className="col-12">
                            <h5><img src="/img/items48.png"></img> Tus items trimestre
                                {
                                    (cargando || trimestre == null) ?
                                        <Skeleton />
                                        :
                                        <strong>
                                            {" " + (trimestre.split('-'))[0] + " " + (trimestre.split('-'))[1] + " " + (trimestre.split('-'))[2] + " " + (trimestre.split('-'))[3]}
                                        </strong>
                                }

                            </h5>

                        </Col>
                    </Row>

                </Col>
            </Card.Header>
            <Card.Body>
                <Row className="g-0">
                    <Col xs={6} sm={4} md={3} xl={3} xxl={2} className="align-items-center text-center border-300 border-end" >
                        <h6 className="pb-0 text-700">
                            LIC-SERV
                        </h6>
                        <span className={
                            'font-sans-serif fs-2 ' + (items.iLicServ > 0 ? 'text-success' : 'text-danger')
                        }>
                            {cargando ? <Skeleton className="w-75" /> : items.iLicServ}
                        </span>
                        <div>
                            <h6 className="fs--1 text-500 mb-0 ">

                                {cargando ? <Skeleton className="w-75" /> :
                                    idCatTipoUsuario == 1 ?
                                        <span>
                                            ${parseFloat(items.dLicServSubtotal).toLocaleString("es-MX", {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2
                                            })}
                                        </span>
                                        : <span></span>
                                }

                            </h6>
                        </div>
                    </Col>
                    <Col xs={6} sm={4} md={3} xl={3} xxl={2} className="align-items-center text-center border-300 border-end" >
                        <h6 className="pb-0 text-700">
                            LIC-CAJ
                        </h6>
                        <span className={
                            'font-sans-serif fs-2 ' + (items.iLicCaj > 0 ? 'text-success' : 'text-danger')
                        }>
                            {cargando ? <Skeleton className="w-75" /> : items.iLicCaj}
                        </span>
                        <div>
                            <h6 className="fs--1 text-500 mb-0 ">
                                {cargando ? <Skeleton className="w-75" /> :
                                    idCatTipoUsuario == 1 ?
                                        <span>
                                            ${parseFloat(items.dLicCajSubtotal).toLocaleString("es-MX", {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2
                                            })}
                                        </span>
                                        : <span></span>
                                }
                            </h6>
                        </div>
                    </Col>
                    <Col xs={6} sm={4} md={3} xl={3} xxl={2} className="align-items-center text-center border-300 border-end" >
                        <h6 className="pb-0 text-700">
                            P&Oacute;LIZAS
                        </h6>
                        <span className={
                            'font-sans-serif fs-2 ' + (items.iPoliza > 0 ? 'text-success' : 'text-danger')
                        }>
                            {cargando ? <Skeleton className="w-75" /> : items.iPoliza}
                        </span>
                        <div>
                            <h6 className="fs--1 text-500 mb-0 ">
                                {cargando ? <Skeleton className="w-75" /> :
                                    idCatTipoUsuario == 1 ?
                                        <span>
                                            ${parseFloat(items.dPolizaSubtotal).toLocaleString("es-MX", {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2
                                            })}
                                        </span> :
                                        <span></span>
                                }
                            </h6>
                        </div>
                    </Col>

                    <Col xs={6} sm={4} md={3} xl={3} xxl={2} className="align-items-center text-center border-300 border-end" >
                        <h6 className="pb-0 text-700">
                            LIC-NUB
                        </h6>
                        <span className={
                            'font-sans-serif fs-2 ' + (items.iLicNub > 0 ? 'text-success' : 'text-danger')
                        }>
                            {cargando ? <Skeleton className="w-75" /> : items.iLicNub}
                        </span>
                        <div>
                            <h6 className="fs--1 text-500 mb-0 ">
                                {cargando ? <Skeleton className="w-75" /> :
                                    idCatTipoUsuario == 1 ?
                                        <span>
                                            ${parseFloat(items.dLicNubSubtotal).toLocaleString("es-MX", {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2
                                            })}
                                        </span>
                                        :
                                        <span></span>
                                }
                            </h6>
                        </div>
                    </Col>
                    <Col xs={6} sm={4} md={3} xl={3} xxl={2} className="align-items-center text-center border-300 border-end" >
                        <h6 className="pb-0 text-700">
                            LIC-NUB-R
                        </h6>
                        <span className={
                            'font-sans-serif fs-2 ' + (items.iLicNubRenov > 0 ? 'text-success' : 'text-danger')
                        }>
                            {cargando ? <Skeleton className="w-75" /> : items.iLicNubRenov}
                        </span>
                        <div>
                            <h6 className="fs--1 text-500 mb-0 ">
                                {cargando ? <Skeleton className="w-75" /> :
                                    idCatTipoUsuario == 1 ?
                                        <span>
                                            ${parseFloat(items.dLicNubRenovSubtotal).toLocaleString("es-MX", {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2
                                            })}
                                        </span>
                                        : <span></span>
                                }
                            </h6>
                        </div>
                    </Col>
                    <Col xs={6} sm={4} md={3} xl={3} xxl={2} className="align-items-center text-center " >
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip style={{ position: 'fixed' }} >
                                    <p>Se contempla M&oacute;dulos, Timbres MX, Hardware, Usuarios, Servicios.</p>
                                </Tooltip>
                            }
                        >
                            <h6 className="pb-0 text-700">
                                MOD-S
                            </h6>
                        </OverlayTrigger>


                        <span className={
                            'font-sans-serif fs-2 ' + (items.iMODs > 0 ? 'text-success' : 'text-danger')
                        }>
                            {cargando ? <Skeleton className="w-75" /> : items.iMODs}
                        </span>
                        <div>
                            <h6 className="fs--1 text-500 mb-0 ">
                                {cargando ? <Skeleton className="w-75" /> :
                                    <span>
                                        ${parseFloat(items.dMODsSubtotal).toLocaleString("es-MX", {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2
                                        })}
                                    </span>
                                }
                            </h6>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

AvanceVentaItemsTrimestre.propTypes = {
    items: PropTypes.object,
    cargando: PropTypes.bool,
    trimestre: PropTypes.string
};

export default AvanceVentaItemsTrimestre;
