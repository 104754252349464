import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

//import FalconCardHeader from 'components/common/FalconCardHeader';
//import CardDropdown from 'components/common/CardDropdown';
import { Card, Spinner, Col, Row, Form } from 'react-bootstrap';
//import DatePicker from 'react-datepicker';
import TotalAnualByMonthChart from './TotalAnualByMonthChart';

//import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import useToken from 'hooks/useToken';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import holdTicket from 'assets/img/support-desk/hold-tickets.png';


const TotalAnualByMonth = ({
    setMensajeError,
    setShowMensajeError,

}) => {

    const { token, setToken } = useToken();
    const [cargando, setCargando] = useState(true);

    const [chartData, setChartData] = useState([]);

    //const date = new Date();
    //const [startDate, setStartDate] = useState(date.setDate(date.getDate() - 30));
    //const [endDate, setEndDate] = useState(new Date());

    //const chartRef = useRef(null);

    useEffect(
        () => {
            //setCargando(true)
            //getDailyleads(startDate, endDate);
            //console.log("12_pastDates", getPastDates(12));
            obtenerVentasTotalesAnuales();
        }
        , []);

    //const onChange = dates => {
    //    const [start, end] = dates;


    //    setStartDate(start);
    //    setEndDate(end);

    //    if (start != null && end != null) {
    //        //getDailyleads(start, end);
    //    }

    //    console.log(dates);
    //};

    /**
     * Obtiene las ventas totales anuales por mes
     */
    const obtenerVentasTotalesAnuales = async () => {

        setCargando(true);

        const response = await fetch("/api/Administradores/ventasTotalesAnuales",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }).catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            const responseType = await response.json();
            console.log("===== CHART DATA ANUAL SALES =====");
            console.log(responseType);

            if (responseType.iResponseType == 1) {

                const data = JSON.parse(responseType.detalles);
                console.log(data);

                var salesByMonth = data[0];
                var totalAnualSales = data[1][0];

                //console.log(totalAnualSales);

                var monthNames = [];
                var monthSales = [];
                var i = 0;
                salesByMonth.forEach((month) => {
                    monthNames[i] = month.TheMonthNameYear;
                    monthSales[i] = month.totalMXN;
                    i++;
                });

                var chartData_ = [];
                chartData_.monthNames = monthNames;
                chartData_.monthSales = monthSales;
                chartData_.totalAnualSales = totalAnualSales.totalMXN;

                setChartData(chartData_);


                //console.log(monthNames);
                //setMonthNameYear(monthNames);
                //setSalesByMonth(monthSales);
                //setAnualSales(totalAnualSales.totalMXN);

                setCargando(false);


            } else {
                setMensajeError(responseType.vMessage);
                setShowMensajeError(true);
            }

        }
        else {
            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }

        setCargando(false);
    }

    const GreetingsDateInput = forwardRef(({ value, onClick }, ref) => (
        <div className="position-relative">
            <Form.Control
                size="sm"
                ref={ref}
                onClick={onClick}
                value={value}
                className="ps-4"
                onChange={e => {
                    console.log({ e });
                }}
            />
            <FontAwesomeIcon
                icon="calendar-alt"
                className="text-primary position-absolute top-50 translate-middle-y ms-2"
            />
        </div>
    ));
    GreetingsDateInput.propTypes = {
        value: PropTypes.string,
        onClick: PropTypes.func
    };

    return (
        <>
            {
                cargando ?
                    <>
                        <Card >
                            <Card.Header>
                                <Row>
                                    <Col>
                                        <h5><Spinner className='text-inline mt-3 ms-2' animation='border' variant='primary' /> Cargando... Ventas Anuales por mes </h5>
                                    </Col>
                                    {/*<Col md="auto" className="mb-3 mb-md-0">*/}
                                    {/*    <Row className="g-3 gy-md-0 h-100 align-items-center">*/}
                                    {/*        <Col xs="auto">*/}
                                    {/*            <h6 className="text-700 mb-0 text-nowrap">Mostrando datos entre:</h6>*/}
                                    {/*        </Col>*/}
                                    {/*        <Col md="auto">*/}
                                    {/*            <DatePicker*/}
                                    {/*                selected={startDate}*/}
                                    {/*                onChange={onChange}*/}
                                    {/*                startDate={startDate}*/}
                                    {/*                formatWeekDay={day => day.slice(0, 3)}*/}
                                    {/*                endDate={endDate}*/}
                                    {/*                selectsRange*/}
                                    {/*                dateFormat="MMM dd"*/}
                                    {/*                customInput={<GreetingsDateInput />}*/}
                                    {/*            />*/}
                                    {/*        </Col>*/}
                                    {/*    </Row>*/}
                                    {/*</Col>*/}
                                </Row>


                            </Card.Header>

                        </Card>
                        <Skeleton height={400} style={{ "zIndex": "-1" }} />
                    </>
                    :
                    <>
                        <Row>

                            <Col className="col-12">

                                <Card>
                                    <Card.Header>
                                        <Row className="flex-between-center g-0">
                                            <Col xs="auto">
                                                <h6 className="mb-0">Ventas Anuales por mes</h6>
                                            </Col>


                                            {/*<Col md="auto" className="mb-3 mb-md-0">*/}
                                            {/*    <Row className="g-3 gy-md-0 h-100 align-items-center">*/}
                                            {/*        <Col xs="auto">*/}
                                            {/*            <h6 className="text-700 mb-0 text-nowrap">Mostrando datos entre:</h6>*/}
                                            {/*        </Col>*/}
                                            {/*        <Col md="auto">*/}
                                            {/*            <DatePicker*/}
                                            {/*                selected={startDate}*/}
                                            {/*                onChange={onChange}*/}
                                            {/*                startDate={startDate}*/}
                                            {/*                formatWeekDay={day => day.slice(0, 3)}*/}
                                            {/*                endDate={endDate}*/}
                                            {/*                selectsRange*/}
                                            {/*                dateFormat="MMM dd"*/}
                                            {/*                customInput={<GreetingsDateInput />}*/}
                                            {/*            />*/}
                                            {/*        </Col>*/}
                                            {/*    </Row>*/}
                                            {/*</Col>*/}


                                        </Row>

                                    </Card.Header>
                                    <Card.Body className="pe-xxl-0">
                                        <Row className="p-1">

                                            <Col className="col-xxl-2 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 m-0">
                                                <img src={holdTicket} alt="" width="39" className="mt-1 mb-2" />
                                                <div className="text-700 fs--2"><strong>TOTAL </strong>(IVA incluido)</div>
                                                <h5 className="mt-0 mb-0 text-black fw-normal">
                                                    {chartData.totalAnualSales.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}
                                                </h5>
                                                <div className="text-700 fs--2">IVA <strong>{(chartData.totalAnualSales - (chartData.totalAnualSales / 1.16)).toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}</strong></div>
                                            </Col>
                                            <Col className="col-xxl-10 col-xl-9 col-lg-9 col-md-9 col-sm-6 col-6 ">
                                                <TotalAnualByMonthChart
                                                    data_={chartData}
                                                />
                                            </Col>

                                        </Row>
                                        
                                    </Card.Body>
                                </Card>

                            </Col>
                        </Row>

                    </>
            }
        </>

    );
};

TotalAnualByMonth.propTypes = {
    setMensajeError: PropTypes.func,
    setShowMensajeError: PropTypes.func,
};

export default TotalAnualByMonth;
