import React, { useEffect, useState,  forwardRef } from 'react';
import PropTypes from 'prop-types';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { Card, Spinner, Col, Row, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import useToken from 'hooks/useToken';

import LeadsActualizadosChart from './LeadsActualizadosChart';

const LeadsActualizados = ({
    setMensajeError,
    setShowMensajeError,

}) => {

    const { token, setToken} = useToken();
    const [cargando, setCargando] = useState(true);
 
    const [vendedoresDDL, setVendedoresDDL] = useState([]);
    const [vendedor, setVendedor] = useState(0);


    const [leadsActualizados, setLeadsActualizados] = useState([]);


    const date = new Date();

    const [startDate, setStartDate] = useState(date.setDate(date.getDate() - 15));
    const [endDate, setEndDate] = useState(new Date());

    useEffect(
        () => {
            getVendedores();
            
        }
        , []);

    /**Obtiene los vendedores*/
    const getVendedores = async () => {
        setCargando(true);
        const response = await fetch("/api/Administradores/getVendedores/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        console.log("response: ", response);
        if (response.ok) {
            //console.log("==== OBTIENE VENDEDORES ====");
            const data = await response.json();
            console.log("data-> ", data);

            if (data.iResponseType == 1) {
                var ObjDetalles = JSON.parse(data.detalles);
                console.log("Detalles: ", ObjDetalles);
                setVendedoresDDL(ObjDetalles.table2);

                getLeadsActualizados(0, startDate, endDate);
                
                //setCargando(false);

            } else {

                setMensajeError(data.vMessage);
                setShowMensajeError(true);
                setCargando(false);
            }

        }
        else {


            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
            setCargando(false);
        }

    }

    /**
     * Obtiene los leads actualizados
     * @param {any} vendedor_
     * @param {any} start
     * @param {any} end
     */
    const getLeadsActualizados = async (vendedor_, start, end) => {
        setCargando(true);
        
        var _startDate_ = new Date(start).toLocaleDateString('es-MX', {
            year: 'numeric', month: '2-digit', day: '2-digit', hour12: false
        });

        var _endDate_ = new Date(end).toLocaleDateString('es-MX', {
            year: 'numeric', month: '2-digit', day: '2-digit', hour12: false
        });

        console.log("StratDate", _startDate_);
        console.log("end", _endDate_);

        var jDataSend = {};
        jDataSend.startDate = _startDate_;
        jDataSend.endDate = _endDate_;
        jDataSend.vendedor = vendedor_;

        const response = await fetch("/api/Administradores/getLeadsActualizados/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jDataSend)
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        console.log("response Leads modificados: ", response);
        if (response.ok) {
            console.log("==== LEADS ACTUALIZADOS ====");

           
            const data = await response.json();
            console.log("data LEADS ACTUALIZADOS: ", data);

            if (data.iResponseType == 1) {

                var ObjDetalles = JSON.parse(data.detalles);
                console.log("Detalles leads ACTUALIZADOS -> ", ObjDetalles);


                var value = [];
                var dates = [];
                ObjDetalles.table2.forEach((item) => {
                    value.push(item.leadsActualizados)
                    //console.log("Date", new Date(item.TheDate));
                    dates.push(new Date(item.TheDate));
                });

                var chartData = [];
                chartData.Value = value;
                chartData.Dates = dates;

                console.log("NuevosLeadsGraphData", chartData);

                setLeadsActualizados(chartData);

                setCargando(false);

            } else {

                setMensajeError(data.vMessage);
                setShowMensajeError(true);
            }

        }
        else {
           

            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }

    }

    const onChange = dates => {
        const [start, end] = dates;

        
        setStartDate(start);
        setEndDate(end);

        if (start != null && end != null) {
            getLeadsActualizados(vendedor, start, end);
        }

        console.log(dates);
    };

    const GreetingsDateInput = forwardRef(({ value, onClick }, ref) => (
        <div className="position-relative">
            <Form.Control
                size="sm"
                ref={ref}
                onClick={onClick}
                value={value}
                className="ps-4"
                onChange={e => {
                    console.log({ e });
                }}
            />
            <FontAwesomeIcon
                icon="calendar-alt"
                className="text-primary position-absolute top-50 translate-middle-y ms-2"
            />
        </div>
    ));
    GreetingsDateInput.propTypes = {
        value: PropTypes.string,
        onClick: PropTypes.func
    };

    return (
        <>
            {
                cargando ?
                <>
                    <Card >
                            <Card.Header>
                                <Row>
                                    <Col>
                                        <h5><Spinner className='text-inline mt-3 ms-2' animation='border' variant='primary' /> Cargando... Seguimiento a leads (Leads actualizados) </h5>
                                    </Col>
                                    <Col md="auto" className="mb-3 mb-md-0">
                                        <Row className="g-3 gy-md-0 h-100 align-items-center">
                                            <Col xs="auto">
                                                <Form.Select
                                                    size="sm"
                                                    aria-label="Default select"
                                                    value={vendedor}
                                                    onChange={(event) => { setVendedor(event.target.value); getLeadsActualizados(event.target.value, startDate, endDate); }}
                                                >
                                                    <option value="">Todos los vendedores</option>
                                                    {vendedoresDDL.map((vendedor, index) => {
                                                        return (<option key={index} value={vendedor.idUsuario}>{vendedor.vNombre}</option>)
                                                    })}

                                                </Form.Select>
                                            </Col>
                                            <Col md="auto">
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={onChange}
                                                    startDate={startDate}
                                                    formatWeekDay={day => day.slice(0, 3)}
                                                    endDate={endDate}
                                                    selectsRange
                                                    dateFormat="MMM dd"
                                                    customInput={<GreetingsDateInput />}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                
                                
                        </Card.Header>
                        
                    </Card>
                    <Skeleton height={400} style={{"zIndex":"-1"}} />
                </>
                :
                <>
                        <Row>

                            <Col className="col-12">

                                <Card>
                                    <Card.Header>
                                        <Row className="flex-between-center g-0">
                                            <Col xs="auto">
                                                <h6 className="mb-0">Seguimiento a leads (Leads actualizados)</h6>
                                            </Col>

                                            
                                           
                                            <Col md="auto" className="mb-3 mb-md-0">
                                                <Row className="g-3 gy-md-0 h-100 align-items-center">

                                                    <Col xs="auto">
                                                        <Form.Select
                                                            size="sm"
                                                            aria-label="Default select"
                                                            value={vendedor}
                                                            onChange={(event) => { setVendedor(event.target.value); getLeadsActualizados(event.target.value, startDate, endDate); }}
                                                        >
                                                            <option value="">Todos los vendedores</option>
                                                            {vendedoresDDL.map((vendedor, index) => {
                                                                return (<option key={index} value={vendedor.idUsuario}>{vendedor.vNombre}</option>)
                                                            })}

                                                        </Form.Select>
                                                    </Col>
                                                    <Col md="auto">
                                                        <DatePicker
                                                            selected={startDate}
                                                            onChange={onChange}
                                                            startDate={startDate}
                                                            formatWeekDay={day => day.slice(0, 3)}
                                                            endDate={endDate}
                                                            selectsRange
                                                            dateFormat="MMM dd"
                                                            customInput={<GreetingsDateInput />}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>

                                           
                                        </Row>

                                    </Card.Header>
                                    <Card.Body className="pe-xxl-0">
                                        <LeadsActualizadosChart
                                            chartData_={leadsActualizados}
                                            style={{ height: '18.625rem' }}
                                        />
                                    </Card.Body>
                                </Card>

                            </Col>
                        </Row>
                    
                </>
            }
        </>
    
  );
};

LeadsActualizados.propTypes = {
    /**Mensajes de errores & informativos*/
    setMensajeError: PropTypes.func,
    setShowMensajeError: PropTypes.func,
    /** */
};

export default LeadsActualizados;
