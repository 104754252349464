import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import IconButton from 'components/common/IconButton';
import MensajeErrror from 'components/common/MensajeError';
import { ToastContainer } from 'react-toastify';
import PageHeader from 'components/common/PageHeader';
import useToken from 'hooks/useToken';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AdvanceTableSearchBox from '../../components/common/advance-table/AdvanceTableSearchBox';


const columns = [
    {
        Header: ' ',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { idClienteAkasia } = rowData.row.original;
            return <>
                <Link to={"/clientes/detalle/" + idClienteAkasia} >
                    <img title="Ver informaci&oacute;n" src="/img/ver20.png"></img>
                </Link>
            </>
        }
    },
    {
        accessor: 'idClienteAkasia',
        Header: 'ID',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { idClienteAkasia } = rowData.row.original;
            return <Link to={"/clientes/detalle/" + idClienteAkasia}><span>{idClienteAkasia}</span></Link>
        }
    },
    {
        accessor: 'iNumlicAct',
        Header: 'AC',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { iNumlicAct, iNumlicPrueba } = rowData.row.original;
            return <span>{iNumlicAct} {iNumlicAct > 0 && <img src="/img/activo.png"></img>} {!iNumlicAct && !iNumlicPrueba && <img src="/img/advertencia20.png"></img>}</span>
        }
    },
    {
        accessor: 'vRfc',
        Header: 'RFC',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { vRfc } = rowData.row.original;
            return <span> {vRfc}</span>;
        }
    },
    {
        accessor: 'vNombre',
        Header: 'Nombre',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const { vNombre, idClienteAkasia } = rowData.row.original;
            return (
                <>
                    <h5 className="mb-0 fs--2">
                        <Link to={"/clientes/detalle/" + idClienteAkasia}>
                            <span className="d-inline-block text-truncate" style={{ maxWidth: '250px' }}>
                                {vNombre}
                            </span>
                        </Link>
                    </h5>
                </>
            );
        }
    },
    {
        accessor: 'vEmail',
        Header: 'Email',
        Cell: rowData => {
            const { vEmail } = rowData.row.original;
            return <a href={`mailto:${vEmail}`}><span className="d-inline-block text-truncate" style={{ maxWidth: '250px' }}>{vEmail}</span></a>;
        }
    },
    {
        accessor: 'vDireccionFiscal',
        Header: 'Direccion',
        Cell: rowData => {
            const { vDireccionFiscal } = rowData.row.original;
            return <span className="d-inline-block text-truncate" style={{ maxWidth: '250px' }}>{vDireccionFiscal}</span>
        }
    },
    {
        accessor: 'vTelefonoPersonal',
        Header: 'Tel\u00e9fono',
        Cell: rowData => {
            const { vTelefonoPersonal } = rowData.row.original;
            return <>
                <a href={"https://wa.me/" + vTelefonoPersonal} target="_blank" rel="noreferrer">
                    <img title="Enviar WhatsApp" src="/img/whatsapp20.png"></img >
                </a>

                <a href={"https://wa.me/" + vTelefonoPersonal} target="_blank" rel="noreferrer">{vTelefonoPersonal}</a>
            </>
        }
    },
    {
        accessor: 'vPais',
        Header: 'Pa\u00EDs',
        Cell: rowData => {
            const { vPais } = rowData.row.original;
            return <span className="fs--2">{vPais}</span>
        }
    }
];

const ConctactosDuplicados = () => {
    const [customers, setCustomers] = useState([]);
    const [cargando, setCargando] = useState(true);
    const navigate = useNavigate();
    const { token, name, usuario, setToken } = useToken();
    const [mensajeError, setMensajeError] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);
    const { vFiltro } = useParams();
    

    const mostrarClientes = async (vUsuario) => {
        setCargando(true);
        const response = await fetch("/api/cliente/ObtenerClientesInfoIncompleta/?vFiltro=" + vFiltro + "&vUsuario=" + vUsuario,
            {
                method: 'GET',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            });
        if (response.ok) {
            const data = await response.json();
            console.log(data['table1']);
            setCustomers(data['table1']);

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
        setCargando(false);
    };


    useEffect(() => {
        mostrarClientes(usuario)
    }, []);

    return (
        <>
            <PageHeader
                title={"Contactos duplicados"}
                className="mb-1"
            >
            </PageHeader>
            <AdvanceTableWrapper
                columns={columns}
                data={customers}
                sortable
                pagination
                perPage={15}
            >
                <Card className="mb-3">
                    <Card.Header className="d-flex flex-between-center">
                        <IconButton
                            onClick={() => navigate(-1)}
                            variant="falcon-default"
                            size="sm"
                            icon="arrow-left"
                        />

                    </Card.Header>
                </Card>
                <Card >
                    <Card.Header>
                        <h3>{name},</h3>
                        <h5>He detectado que existen algunos contactos (clientes y no clientes) que tienen el n&uacute;mero de tel&eacute;fono repetido. Por favor ay&uacute;dame a revisar los contactos y eliminar los duplicados. Para ayudarte a identificar los clientes, he marcado con una palomita ( <img src="/img/activo.png"></img> ) los contactos que tienen almenos 1 licencia activa. Tambi&eacute;n he se&ntilde;alado con un signo de advertencia ( <img src="/img/advertencia20.png"></img> ) los contactos que no tienen asignada ninguna licencia (ni de prueba)</h5>
                    </Card.Header>
                    <Card.Body>
                        <Row className="flex-end-center mb-3 px-3">
                            <Col xs={12} md={6} lg={3}>

                            </Col>
                            <Col xs={12} md={6} lg={3}>
                                <AdvanceTableSearchBox table />

                            </Col>
                        </Row>
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--2 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableFooter
                            rowCount={customers.length}
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            {cargando && (<h5><Spinner className="text-inline" animation="border" variant="primary" /> Cargando...</h5>)}
            <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>
            <ToastContainer/>
        </>
    );
};

export default ConctactosDuplicados;
