import IconButton from 'components/common/IconButton';
import MensajeErrror from 'components/common/MensajeError';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import useFiltro from 'hooks/useFiltro';
import useToken from 'hooks/useToken';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Banderas from '../cliente/Banderas';
import ImagenTipoArchivo from '../orden/ImagenTipoArchivo';

const columns = [
    {
        accessor: 'idCliente',
        Header: 'ID',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { idClienteAkasia } = rowData.row.original;
            return <Link to={"/clientes/detalle/" + idClienteAkasia}><span>{idClienteAkasia}</span></Link>
        }
    },
    {
        accessor: 'NL',
        Header: 'NL',  //numero de licencias tipo servidor/ nubes / cajas  compradas
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { iNL } = rowData.row.original;
            return <span> {iNL}</span>;
        }
    }, {
        accessor: 'dMontoVendido',
        Header: 'Compra',  //Monto vendido
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { dMontoVendido } = rowData.row.original;
            return <span> ${parseFloat(dMontoVendido).toLocaleString("es-MX", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
            })}</span>;
        }
    },
    {
        accessor: 'Agente',
        Header: 'Agente',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { vAgente } = rowData.row.original;
            return <span className="d-inline-block text-truncate" style={{ maxWidth: '100px' }}> {vAgente} </span>
        }
    },
    {
        accessor: 'RFC',
        Header: 'RFC',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { vRfc } = rowData.row.original;
            return <span> {vRfc}</span>;
        }
    },
    {
        accessor: 'vNumRegTrib',
        Header: 'Num. Reg. Trib.',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { vNumRegTrib } = rowData.row.original;
            return <span> {vNumRegTrib}</span>;
        }
    },
    {
        accessor: 'vNombreDistribuidor',
        Header: 'Nombre Distribuidor',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const { vNombreDistribuidor, idClienteAkasia, vRazonSocial } = rowData.row.original;
            return (
                <>
                    <h5 className="mb-0 fs--2">
                        <Link to={"/clientes/detalle/" + idClienteAkasia}>
                            {vNombreDistribuidor}
                            <br></br>
                            <span className="d-inline-block text-truncate" style={{ maxWidth: '250px' }}>
                                {vRazonSocial}
                            </span>
                        </Link>
                    </h5>
                </>
            );
        }
    },
    {
        accessor: 'vIdFotoDoc',
        Header: 'I',  //numero de licencias tipo servidor/ nubes / cajas  compradas
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { vIdFotoDoc, idClienteAkasia } = rowData.row.original;
            return vIdFotoDoc && <span><Link to={"/api/distribuidor/OnGetFile/?idClienteAkasia=" + idClienteAkasia + "&vNombreArchivo=" + vIdFotoDoc} target="_blank">
                <ImagenTipoArchivo vNombreArchivo={vIdFotoDoc}></ImagenTipoArchivo> </Link></span>;
        }
    },
    {
        accessor: 'vCedulaFiscalDoc',
        Header: 'C',  //numero de licencias tipo servidor/ nubes / cajas  compradas
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { vCedulaFiscalDoc, idClienteAkasia } = rowData.row.original;
            return vCedulaFiscalDoc && <span><Link to={"/api/distribuidor/OnGetFile/?idClienteAkasia=" + idClienteAkasia + "&vNombreArchivo=" + vCedulaFiscalDoc} target="_blank">
                <ImagenTipoArchivo vNombreArchivo={vCedulaFiscalDoc}></ImagenTipoArchivo> </Link></span>;
        }
    },
    {
        accessor: 'vComprobanteDomicilioDoc',
        Header: 'D',  //numero de licencias tipo servidor/ nubes / cajas  compradas
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { vComprobanteDomicilioDoc, idClienteAkasia } = rowData.row.original;
            return vComprobanteDomicilioDoc && <span><Link to={"/api/distribuidor/OnGetFile/?idClienteAkasia=" + idClienteAkasia + "&vNombreArchivo=" + vComprobanteDomicilioDoc} target="_blank">
                <ImagenTipoArchivo vNombreArchivo={vComprobanteDomicilioDoc}></ImagenTipoArchivo> </Link></span>;
        }
    },
    {
        accessor: 'vMotivosDoc',
        Header: 'M',  //numero de licencias tipo servidor/ nubes / cajas  compradas
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { vMotivosDoc, idClienteAkasia } = rowData.row.original;
            return vMotivosDoc && <span><Link to={"/api/distribuidor/OnGetFile/?idClienteAkasia=" + idClienteAkasia + "&vNombreArchivo=" + vMotivosDoc} target="_blank">
                <ImagenTipoArchivo vNombreArchivo={vMotivosDoc}></ImagenTipoArchivo> </Link></span>;
        }
    },
    {
        accessor: 'vTelefonoPersonal',
        Header: 'Tel\u00e9fono',
        Cell: rowData => {
            const { vTelefonoPersonal } = rowData.row.original;
            return <>
                <a href={"https://wa.me/" + vTelefonoPersonal} target="_blank" rel="noreferrer">
                    <img title="Enviar WhatsApp" src="/img/whatsapp20.png"></img >
                </a>
                &nbsp;
                <a href={"https://wa.me/" + vTelefonoPersonal} target="_blank" rel="noreferrer">{vTelefonoPersonal}</a>
            </>
        }
    },
    {
        accessor: 'vPais',
        Header: 'Pa\u00EDs',
        Cell: rowData => {
            const { vPais } = rowData.row.original;
            return <span className="fs--2"><Banderas Height={20} vPais={vPais}></Banderas></span>
        }
    },
    {
        accessor: 'vEstadoDepProv',
        Header: 'vEstadoDepProv',
        Cell: rowData => {
            const { vEstadoDepProv } = rowData.row.original;
            return <span className="fs--2">{vEstadoDepProv}</span>
        }
    },
    {
        accessor: 'joined',
        Header: 'Creado',
        Cell: rowData => {
            const { joinDate } = rowData.row.original;
            return <span>{joinDate}</span>;
        }
    }
];

const Distribuidores = () => {
    const [distribuidores, setDistribuidores] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [countries, setCountries] = useState([]);
    const { token, setToken } = useToken();
    const [mensajeError, setMensajeError] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);
    const { FiltroDistribuidor, setFiltroDistribuidor, resetFiltroDistribuidor } = useFiltro();

    const mostrarDistribuidores = async () => {
        setCargando(true);
        const response = await fetch("/api/distribuidor/GetDistribuidoresAkasia/?vFiltro=" + encodeURIComponent(JSON.stringify(FiltroDistribuidor)),
            {
                method: 'GET',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            });
        if (response.ok) {
            const data = await response.json();
            console.log("=============== MOSTRAR DISTRIBUIDORES ===============");
            setDistribuidores(data['table1']);
            setCountries(data['table2']);

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
        setCargando(false);
    };

    useEffect(() => {
        mostrarDistribuidores();
    }, []);

    return (
        <>
            <Card className="mb-1 p-2">
                <Row className="g-2 align-items-sm-center">
                    <Col xs="auto">
                        <img src="/img/cliente96.png" alt="connectCircle" height={30} />
                    </Col>
                    <Col>
                        <h5>
                            Distribuidores autorizados
                        </h5>
                    </Col>
                    <Col sm="auto" className="ms-auto">
                        <Link to="/clientes/nuevo">
                            <Button size="sm"
                                variant="falcon-primary"
                            >Nuevo Distribuidor</Button>
                        </Link>
                    </Col>
                </Row>
            </Card>

            <AdvanceTableWrapper
                columns={columns}
                data={distribuidores}

                sortable
                pagination
                perPage={15}
            >
                <Card className="mb-3">
                    <Card.Header >
                        <Form >
                            <Row className="flex-between-center">
                                <Col xs={12} sm={6} md={6} lg={3} className="d-flex align-items-center pe-0 mb-2">
                                    <Form.Select
                                        size="sm"
                                        aria-label="Default select"
                                        value={FiltroDistribuidor.vPais}
                                        onChange={(event) => { setFiltroDistribuidor({ ...FiltroDistribuidor, vPais: event.target.value }) }}
                                    >
                                        <option value="">Todos los pa&iacute;ses</option>
                                        {countries.map((pais, index) => {
                                            return (<option key={index} value={pais.vName}>{pais.vName}</option>)
                                        })}
                                    </Form.Select>

                                </Col>

                                <Col xs={12} sm={6} md={6} lg={3} className="d-flex align-items-center pe-0 mb-2">
                                    <Form.Group className="col-12">
                                        <Form.Control
                                            onChange={(event) => { setFiltroDistribuidor({ ...FiltroDistribuidor, vNombreDistribuidor: event.target.value.toUpperCase() }) }}
                                            size="sm"
                                            type="text"
                                            placeholder="Nombre del distribuidor"
                                            value={FiltroDistribuidor.vNombreDistribuidor}
                                        />

                                    </Form.Group>

                                </Col>


                                <Col xs={12} sm={6} md={6} lg={6} className="d-flex align-items-center pe-0 mb-2">

                                    {cargando ? <Button
                                        variant="falcon-default"
                                        size="sm"
                                        transform="shrink-3"
                                        disabled
                                    >
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />&nbsp;
                                        Buscando...
                                    </Button>
                                        : <><IconButton
                                            variant="falcon-default"
                                            type="submit"
                                            size="sm"
                                            icon="search"
                                            transform="shrink-3"

                                            onClick={() => mostrarDistribuidores()}
                                        >
                                            <span className="d-none d-sm-inline-block ms-1">Buscar</span>
                                        </IconButton> <IconButton
                                            variant="falcon-default"
                                            size="sm"
                                            icon="redo-alt"
                                            transform="shrink-3"
                                            className="mx-2"
                                            onClick={() => { resetFiltroDistribuidor(); }}
                                        >
                                                <span className="d-none d-sm-inline-block ms-1">Reset</span>
                                            </IconButton></>}
                                </Col>
                            </Row>
                        </Form>
                        <Row>
                            <Col className="ms-auto" sm={8} lg={4} xs={12} >
                                {!cargando &&
                                    <AdvanceTableSearchBox table />
                                }
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="p-0">

                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--2 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableFooter
                            rowCount={distribuidores.length}
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            {cargando && (<h5><Spinner className="text-inline" animation="border" variant="primary" /> Cargando...</h5>)}
            <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>
            <ToastContainer />
        </>
    );
};

export default Distribuidores;
