import cornerBg from 'assets/img/illustrations/corner-2.png';
import classNames from 'classnames';
import Background from 'components/common/Background';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const InfoItem = ({ title, content, className }) => {
    return (
        <div className={classNames('mb-4', className)}>
            <h6
                className={classNames({
                    'mb-1': title === 'Email' || title === 'Phone Number'
                })}
            >
                {title}
            </h6>
            {title === 'Email' && (
                <a className="fs--1" href={`mailto: ${content}`}>
                    {content}
                </a>
            )}
            {title === 'Phone Number' && (
                <a className="fs--1" href={`tel: ${content.replace(/\s+/g, '')}`}>
                    {content}
                </a>
            )}
            {title !== 'Email' && title !== 'Phone Number' && (
                <p className="mb-0 text-700 fs--1">{content}</p>
            )}
        </div>
    );
};

InfoItem.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    className: PropTypes.string
};

const ContactProfile = ({ cliente}) => {
    return (
        <Card className="mb-xl-3">
            <FalconCardHeader
                title="Informaci&oacute;n de contacto"
                titleTag="h6"
                className="py-2"
            />
            <Card.Body className="bg-light">
                <Card className="border rounded-3 bg-white dark__bg-1000 shadow-none">
                    <Background
                        image={cornerBg}
                        className="bg-card d-none d-sm-block d-xl-none"
                    />
                    <Card.Body className="row g-0 flex-column flex-sm-row flex-xl-column z-index-1 align-items-center">
                        <Col xs="auto" className="text-center me-sm-x1 me-xl-0">
                            <img className="ticket-preview-avatar" src="/img/cliente96.png" alt="" />
                        </Col>
                        <Col sm={8} md={6} lg={4} xl={12} className="ps-sm-1 ps-xl-0">
                            <p className="fw-semi-bold mb-0 text-800 text-center text-sm-start text-xl-center mb-3 mt-3 mt-sm-0 mt-xl-3">
                                {cliente[0].vNombre} {cliente[0].vApellidos}
                            </p>
                            <Flex justifyContent="center" className="gap-2">
                                <IconButton
                                    variant="falcon-default"
                                    icon="fab fa-whatsapp"
                                    transform="shrink-4"
                                    size="sm"
                                    className="w-80" >
                                    <span className="fs--2">Enviar WhatsApp</span>
                                </IconButton>
                            </Flex>
                        </Col>
                    </Card.Body>
                </Card>
                <Row className="border rounded-3 p-x1 mt-3 bg-white dark__bg-1000 mx-0 g-0">
                    <Col md={6} xl={12} className="pe-md-4 pe-xl-0">
                        <InfoItem title="Email" content={cliente[0].vEmail} />
                        <InfoItem title="Tel&eacute;fono Personal" content={cliente[0].vTelefonoPersonal} />
                        {cliente[0].vTelefonoTrabajo && <InfoItem title="Tel&eacute;fono Trabajo" content={cliente[0].vTelefonoTrabajo} />}
                        <InfoItem
                            title="Direcci&oacute;n"
                            content={cliente[0].vDireccionFiscal}
                        />
                        <InfoItem title="Pa&iacute;s" content={cliente[0].vPais } />
                        <InfoItem
                            title="Tiempo de Soporte"
                            content="13 horas"
                            className="mb-md-0 mb-xl-4"
                        />
                    </Col>

                </Row>
            </Card.Body>
        </Card>
    );
};


ContactProfile.propTypes = {
    cliente: PropTypes.Object
};
export default ContactProfile;
