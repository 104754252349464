import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import React, { useState } from 'react';
import { Button, Card, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';

import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import bg3 from 'assets/img/icons/spot-illustrations/corner-3.png';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import FalconCloseButton from 'components/common/FalconCloseButton';
import MensajeErrror from 'components/common/MensajeError';
import PageHeader from 'components/common/PageHeader';
import useToken from 'hooks/useToken';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Greetings from './Greetings';
import StatisticsCard from './StatisticsCard';
import MensajeInformativo from 'components/common/MensajeInformativo';
import { ToastContainer } from 'react-toastify';

const RecargasTaecel = () => {

    const columns = [
        {
            accessor: 'none',
            Header: '',
            Cell: () => {

                return (
                    <>
                        <Row >
                            <Col xs="auto" >

                                <Link to={""}>
                                    <img title="Agregar Saldo" src="../img/mas20.png"></img >
                                </Link>
                            </Col>
                        </Row>

                    </>
                );
            }
        },
        {
            accessor: 'CuentaID',
            Header: 'CuentaID',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { CuentaID } = rowData.row.original;
                return (<span>{CuentaID}</span>)
            }
        },

        {
            accessor: 'CreatedDate',
            Header: 'Fecha registro',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { CreatedDate } = rowData.row.original;
                return (<span >{CreatedDate}</span>);
            }
        },
        {
            accessor: 'NumeroCuenta',
            Header: 'NumeroCuenta',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { NumeroCuenta } = rowData.row.original;
                return <span>{NumeroCuenta}</span>
            }
        },
        {
            accessor: 'ReferenciaPagos',
            Header: 'ReferenciaPagos',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { ReferenciaPagos } = rowData.row.original;
                return <span>{ReferenciaPagos}</span>
            }
        },
        {
            accessor: 'ReferenciaServicios',
            Header: 'ReferenciaServicios',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { ReferenciaServicios } = rowData.row.original;
                return <span>{ReferenciaServicios}</span>
            }
        },
        {
            accessor: 'Usuario',
            Header: 'Usuario',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { Usuario } = rowData.row.original;
                return (<span> {Usuario}</span>)
            }
        },
        {
            accessor: 'Nombre',
            Header: 'Nombre',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { Nombre } = rowData.row.original;
                return (<span> {Nombre}</span>)
            }
        },
        {
            accessor: 'Key',
            Header: 'Key',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { Key } = rowData.row.original;
                return (<span> {Key}</span>)
            }
        },
        {
            accessor: 'Nip',
            Header: 'Nip',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { Nip } = rowData.row.original;
                return (<span> {Nip}</span>)
            }
        }

    ];

    const { token, setToken } = useToken();
    const { register, reset, handleSubmit, formState: { errors } } = useForm();
    const [procesandoModal, setProcesandoModal] = useState(false);
    const [socios, setSocios] = useState([]);
    const [mensajeError, setMensajeError] = useState('');
    const [mensajeInformativo, setMensajeInformativo] = useState('');
    const [saldo, setSaldo] = useState(0);
    const [cargando, setCargando] = useState(false);
    const [showMensajeError, setShowMensajeError] = useState(false);
    const [showModalRegistrarCuenta, setShowModalRegistrarCuenta] = useState(false);

    const [showMensajeInformativo, setShowMensajeInformativo] = useState(false);

    //muestra todos los socios
    const mostrarSocios = async () => {
        setCargando(true);
        const response = await fetch("/api/taecel/getSocios",
            {
                method: 'GET',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }).catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            setSocios(data);

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
        setCargando(false);
    };

    //Obtener saldo de los servicios de Akasia
    const getBalance = async () => {
        setCargando(true);
        const response = await fetch("/api/taecel/getBalance", {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error.message);
                console.log('Hubo un problema con la peticion Fetch:' + error.message);
            });

        if (response.ok) {
            console.log(response);
            const data = await response.json();

            console.log(data);
            if (data.success) {
                setSaldo(data.data[0].Saldo.replace(',', ''))
            }
            else {
                setMensajeError(data.message);
                setShowMensajeError(true);
            }

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setCargando(false);
    };

    //Registrar una cuenta socio
    const RegistroCuenta = async (data) => {
        setCargando(true);
        setProcesandoModal(true);
        const response = await fetch("/api/taecel/RegistroCuenta/?telefono=" + data.telefono + "&correo=" + data.correo + "&nombre=" + data.nombre + "&apellidos=" + data.apellidos, {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error.message);
                console.log('Hubo un problema con la peticion Fetch:' + error.message);
            });

        if (response.ok) {
            console.log(response);
            const data = await response.json();

            console.log(data);
            if (data.success) {
                mostrarSocios();
                setShowMensajeInformativo(true);
                setMensajeInformativo(data.message);
                mostrarSocios();
            }
            else {
                setMensajeError(data.message);
                setShowMensajeError(true);
            }

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setCargando(false);
        setProcesandoModal(false)
        setShowModalRegistrarCuenta(false);
        reset();
    };

    useEffect(() => {
        getBalance();
        mostrarSocios();
    }, []);

    //Submit
    const onSubmitRegistroCuenta = data => {
        RegistroCuenta(data);
    };

    return (
        <>
            <PageHeader
                title="Recargas / Servicios TAECEL"
                className="mb-1"
            >
            </PageHeader>
            <Row className="g-3 mb-3">
                <Col xxl={6} lg={12}>
                    <Greetings setShowModalRegistrarCuenta={setShowModalRegistrarCuenta} />
                </Col>
                <Col xxl={6} md={12}>
                    <Row className="g-3 mb-3">

                        <Col sm={6}>
                            <StatisticsCard stat={{
                                title: 'Saldo TAE',
                                value: saldo,
                                decimal: true,
                                suffix: '',
                                prefix: '$',
                                valueClassName: 'text-success',
                                badgeBg: 'success',
                                badgeText: '+20%',
                                link: '/servicios/movimientos-taecel',
                                linkText: 'Ver movimientos',
                                image: bg3
                            }} style={{ minWidth: '12rem' }} />

                        </Col>
                        <Col sm={6}>
                            <StatisticsCard stat={{
                                title: 'Saldo Servicios',
                                value: 0.00,
                                decimal: true,
                                suffix: '',
                                prefix: '$',
                                valueClassName: 'text-primary',
                                badgeBg: 'primary',
                                badgeText: '0%',
                                link: '/servicios/movimientos-taecel',
                                linkText: 'Ver movimientos',
                                image: bg1
                            }} style={{ minWidth: '12rem' }} />

                        </Col>

                    </Row>
                </Col>

            </Row>
            <AdvanceTableWrapper
                columns={columns}
                data={socios}
                sortable
                pagination
                perPage={15}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <Row className="flex-between-center">
                            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                                <div id="orders-actions">

                                </div>
                            </Col>
                            <Col xs={6} sm="auto" className="ms-auto ps-0">
                                <AdvanceTableSearchBox table />
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                hover: true,
                                className: 'fs--2 mb-0 overflow-hidden'
                            }}
                        />

                    </Card.Body>
                    <Card.Footer>

                        <AdvanceTableFooter
                            rowCount={socios.length}
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                        />
                    </Card.Footer>
                </Card>

            </AdvanceTableWrapper>

            {cargando && (<h3><Spinner className="text-inline" animation="border" variant="primary" /> Cargando...</h3>)}
            <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>

            <MensajeInformativo setShowMensajeInformativo={setShowMensajeInformativo} showMensajeInformativo={showMensajeInformativo} mensajeInformativo={mensajeInformativo}></MensajeInformativo>
            <ToastContainer/>
            {showModalRegistrarCuenta &&
                <Modal
                    show={showModalRegistrarCuenta}
                    onHide={() => setShowModalRegistrarCuenta(false)}
                    size="lg"
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Form noValidate onSubmit={handleSubmit(onSubmitRegistroCuenta)}>
                        <Modal.Header>
                            <Modal.Title>Registrar nuevo socio</Modal.Title>
                            <FalconCloseButton className="btn btn-circle btn-sm transition-base" onClick={() => { setShowModalRegistrarCuenta(false); reset(); }} />
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col xs={12} sm={6} md={6} lg={4} className="d-flex align-items-center mb-3">
                                    <Form.Group className="col-12">
                                        <Form.Label>
                                            N&uacute;mero de tel&eacute;fono
                                        </Form.Label>
                                        <Form.Control
                                            onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                            size="sm"
                                            type="text"
                                            isInvalid={!!errors.telefono}
                                            {...register("telefono",
                                                {
                                                    required: 'El numero de telefono celular a 10 digitos es obligatorio',
                                                    maxLength: 10,
                                                    minLength: 10,
                                                    pattern: /[0-9]$/
                                                })
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.telefono && errors.telefono.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={8} className="d-flex align-items-center  mb-3">
                                    <Form.Group className="col-12">
                                        <Form.Label>
                                            Correo electr&oacute;nico
                                        </Form.Label>
                                        <Form.Control
                                            size="sm"
                                            type="text"
                                            isInvalid={!!errors.correo}
                                            {...register("correo",
                                                {
                                                    required: 'El correo es requerido',
                                                    maxLength: 99,
                                                    minLength: 5,
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                        message: "Email incorrecto."
                                                    }
                                                })
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.correo && errors.correo.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row>
                                <Col xs={12} sm={6} md={6} lg={4} className="d-flex align-items-center mb-3">
                                    <Form.Group className="col-12">
                                        <Form.Label>
                                            Nombre
                                        </Form.Label>
                                        <Form.Control
                                            onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                            size="sm"
                                            type="text"
                                            isInvalid={!!errors.nombre}
                                            {...register("nombre",
                                                {
                                                    required: 'El nombre es requerido',
                                                    maxLength: 99,
                                                    minLength: 5
                                                })
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.nombre && errors.nombre.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={8} className="d-flex align-items-center mb-3">
                                    <Form.Group className="col-12">
                                        <Form.Label>
                                            Apellidos
                                        </Form.Label>
                                        <Form.Control
                                            onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                            size="sm"
                                            type="text"
                                            isInvalid={!!errors.apellidos}
                                            {...register("apellidos",
                                                {
                                                    required: 'El apellidos es requerido',
                                                    maxLength: 99,
                                                    minLength: 5
                                                })
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.apellidos && errors.apellidos.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => { setShowModalRegistrarCuenta(false); reset(); }} variant='primary'>Cerrar</Button>
                            {procesandoModal ? <Button variant="primary" disabled>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                Procesando...
                            </Button> : <Button type="submit" variant='success' >Registrar</Button>}
                        </Modal.Footer>
                    </Form>
                </Modal>
            }

        </>
    );
};

export default RecargasTaecel;
