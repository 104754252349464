import React from 'react';
import Flex from 'components/common/Flex';
import { Card, Col, Row } from 'react-bootstrap';
import barChart from 'assets/img/illustrations/crm-bar-chart.png';
import lineChart from 'assets/img/illustrations/crm-line-chart.png';

const Greetings = () => {
    return (
        <Card className="bg-100 shadow-none border mb-3">
            <Card.Body className="py-0">
                <Row className="g-0 justify-content-between">
                    <Col sm="auto">
                        <Flex alignItems="center">
                            <img src={barChart} width={90} alt="..." className="ms-n4" />
                            <div>
                                <h6 className="text-primary fs--1 mb-0">Bienvenidos a </h6>
                                <h4 className="text-primary fw-bold mb-0">
                                    Akasia
                                    <span className="text-info fw-medium"> estad&iacute;sticas</span>
                                </h4>
                            </div>
                            <img
                                src={lineChart}
                                width={140}
                                alt="..."
                                className="ms-n4 d-md-none d-lg-block"
                            />
                        </Flex>
                    </Col>

                </Row>
            </Card.Body>
        </Card>
    );
};

export default Greetings;
