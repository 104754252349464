import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import corner1 from 'assets/img/icons/spot-illustrations/corner-3.png';
import Background from 'components/common/Background';
import Flex from 'components/common/Flex';
import useToken from 'hooks/useToken';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Greetings = ({ setShowModalRegistrarCuenta }) => {
    const { name } = useToken();

    return (
        <Card className="h-100">
            <Background image={corner1} className="rounded-soft bg-card" />
            <Card.Header className="z-index-1">
                <h5 className="text-primary">Hola {name}!</h5>
                <h6 className="text-600">Bienvenido al apartado de TAECEL, podr&aacute;s registrar nuevos socios y hacer recargas a diferentes tel&eacute;fonos.</h6>
            </Card.Header>
            <Card.Body className="z-index-1">
                <Row className="g-2 h-100 align-items-end">

                    <Col sm={6} md={5} >
                        <Flex className="position-relative" onClick={() => setShowModalRegistrarCuenta(true)}>
                            <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                                <FontAwesomeIcon icon="plus" className={`text-primary`} />
                            </div>
                            <div className="flex-1">
                                <Link to="#!" className="stretched-link text-800">
                                    <h6 className="mb-0">Agrega Nuevo socio</h6>
                                </Link>
                                <p className="mb-0 fs--2 text-500 ">Clientes Akasia o App taecel</p>
                            </div>
                        </Flex>
                    </Col>
                    <Col sm={6} md={5} >
                        <Flex className="position-relative">
                            <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                                <FontAwesomeIcon icon="phone" className={`text-warning`} />
                            </div>
                            <div className="flex-1">
                                <Link to="#!" className="stretched-link text-800">
                                    <h6 className="mb-0">Haz una recarga</h6>
                                </Link>
                                <p className="mb-0 fs--2 text-500 ">Solo tel&eacute;fonos M&eacute;xico</p>
                            </div>
                        </Flex>
                    </Col>

                </Row>
            </Card.Body>
        </Card>
    );
};

Greetings.propTypes = {

    setShowModalRegistrarCuenta: PropTypes.func
};


export default Greetings;
