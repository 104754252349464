import React, { useEffect, useState, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

//import FalconCardHeader from 'components/common/FalconCardHeader';
//import CardDropdown from 'components/common/CardDropdown';
import { Card, Spinner, Col, Row, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

//import Flex from 'components/common/Flex';

//import LeadConversationChart from './LeadConversationChart';
import LeadsNuevosChart from './LeadsNuevosChart';

//import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import useToken from 'hooks/useToken';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//import { getPastDates} from 'helpers/utils';

const DailyLead = ({
    setMensajeError,
    setShowMensajeError,

}) => {

    const { token, setToken} = useToken();
    const [cargando, setCargando] = useState(true);

    const [leadsRegisteredByDay, setleadsRegisteredByDay] = useState([]);


    const date = new Date();

    const [startDate, setStartDate] = useState(date.setDate(date.getDate() - 30));
    const [endDate, setEndDate] = useState(new Date());

    useEffect(
        () => {
            //setCargando(true)
            getDailyleads(startDate, endDate);
            //console.log("12_pastDates", getPastDates(12));
        }
        , []);


    //const totalSale = {
    //    lastMonth: [50, 80, 60, 80, 65, 90, 130, 90, 30, 40, 30, 70],
    //    previousYear: [110, 30, 40, 50, 80, 70, 50, 40, 110, 90, 60, 60]
    //};

    const chartRef = useRef(null);

    const getDailyleads = async (start, end) => {
        setCargando(true);

        //var today = new Date();
        //today.setDate(new Date() - 30);
        var _startDate_ = new Date(start).toLocaleDateString('es-MX', {
            year: 'numeric', month: '2-digit', day: '2-digit', hour12: false
        });

        var _endDate_ = new Date(end).toLocaleDateString('es-MX', {
            year: 'numeric', month: '2-digit', day: '2-digit', hour12: false
        });

        console.log("StratDate", _startDate_);
        console.log("end", _endDate_);

        var jDataSend = {};
        jDataSend.startDate = _startDate_;
        jDataSend.endDate = _endDate_;

        const response = await fetch("/api/Administradores/getDailyleads/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jDataSend)
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        console.log("response: ", response);
        if (response.ok) {
            console.log("==== DAILY LEADS ====");

           
            const data = await response.json();
            console.log("data: ", data);

            if (data.iResponseType == 1) {

                var ObjDetalles = JSON.parse(data.detalles);
                console.log("Detalles: ", ObjDetalles);

                var leadsRegistradosByDia = [];
                var leadsRegistradosByDia_Dates = [];
                ObjDetalles.table2.forEach((item) => {
                    leadsRegistradosByDia.push(item.RegisteredLeads)
                    //console.log("Date", new Date(item.TheDate));
                    leadsRegistradosByDia_Dates.push(new Date(item.TheDate));
                });

                var LeadsNuevosGraph = [];
                LeadsNuevosGraph.Value = leadsRegistradosByDia;
                LeadsNuevosGraph.Dates = leadsRegistradosByDia_Dates;

                console.log("NuevosLeadsGraphData", LeadsNuevosGraph);

                setleadsRegisteredByDay(LeadsNuevosGraph);

                setCargando(false);

            } else {

                setMensajeError(data.vMessage);
                setShowMensajeError(true);
            }

        }
        else {
           

            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }

    }

    const onChange = dates => {
        const [start, end] = dates;

        
        setStartDate(start);
        setEndDate(end);

        if (start != null && end != null) {
            getDailyleads(start, end);
        }

        console.log(dates);
    };

    const GreetingsDateInput = forwardRef(({ value, onClick }, ref) => (
        <div className="position-relative">
            <Form.Control
                size="sm"
                ref={ref}
                onClick={onClick}
                value={value}
                className="ps-4"
                onChange={e => {
                    console.log({ e });
                }}
            />
            <FontAwesomeIcon
                icon="calendar-alt"
                className="text-primary position-absolute top-50 translate-middle-y ms-2"
            />
        </div>
    ));
    GreetingsDateInput.propTypes = {
        value: PropTypes.string,
        onClick: PropTypes.func
    };

    return (
        <>
            {
                cargando ?
                <>
                    <Card >
                            <Card.Header>
                                <Row>
                                    <Col>
                                        <h5><Spinner className='text-inline mt-3 ms-2' animation='border' variant='primary' /> Cargando... Daily Leads </h5>
                                    </Col>
                                    <Col md="auto" className="mb-3 mb-md-0">
                                        <Row className="g-3 gy-md-0 h-100 align-items-center">
                                            <Col xs="auto">
                                                <h6 className="text-700 mb-0 text-nowrap">Mostrando datos entre:</h6>
                                            </Col>
                                            <Col md="auto">
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={onChange}
                                                    startDate={startDate}
                                                    formatWeekDay={day => day.slice(0, 3)}
                                                    endDate={endDate}
                                                    selectsRange
                                                    dateFormat="MMM dd"
                                                    customInput={<GreetingsDateInput />}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                
                                
                        </Card.Header>
                        
                    </Card>
                    <Skeleton height={400} style={{"zIndex":"-1"}} />
                </>
                :
                <>
                        <Row>

                            <Col className="col-12">

                                <Card>
                                    <Card.Header>
                                        <Row className="flex-between-center g-0">
                                            <Col xs="auto">
                                                <h6 className="mb-0">Daily Leads</h6>
                                            </Col>
                                            
                                           
                                            <Col md="auto" className="mb-3 mb-md-0">
                                                <Row className="g-3 gy-md-0 h-100 align-items-center">
                                                    <Col xs="auto">
                                                        <h6 className="text-700 mb-0 text-nowrap">Mostrando datos entre:</h6>
                                                    </Col>
                                                    <Col md="auto">
                                                        <DatePicker
                                                            selected={startDate}
                                                            onChange={onChange}
                                                            startDate={startDate}
                                                            formatWeekDay={day => day.slice(0, 3)}
                                                            endDate={endDate}
                                                            selectsRange
                                                            dateFormat="MMM dd"
                                                            customInput={<GreetingsDateInput />}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>

                                           
                                        </Row>

                                    </Card.Header>
                                    <Card.Body className="pe-xxl-0">
                                        <LeadsNuevosChart
                                            data_={leadsRegisteredByDay}
                                            ref={chartRef}
                                            style={{ height: '18.625rem' }}
                                        />
                                    </Card.Body>
                                </Card>

                            </Col>
                        </Row>
                    
                </>
            }
        </>
    
  );
};

DailyLead.propTypes = {
    /**Mensajes de errores & informativos*/
    setMensajeError: PropTypes.func,
    setShowMensajeError: PropTypes.func,
    /** */
};

export default DailyLead;
