import React, { useEffect, useState, useRef, forwardRef } from 'react';
import useToken from 'hooks/useToken';
import PropTypes from 'prop-types';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import CardDropdown from 'components/common/CardDropdown';
import FalconCardHeader from 'components/common/FalconCardHeader';

import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

import LeadsByCountryChart from './LeadsByCountryChart';
import WorldMap from './WorldMap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';

const LeadsByCountry = ({
    //chartData,
    //mapData,
    setMensajeError,
    setShowMensajeError
}) => {

    const { token, setToken } = useToken();
    const [cargando, setCargando] = useState(true);


    const [mapData, setMapData] = useState([]);
    const [chartData, setchartData] = useState([]);



    const date = new Date();
    const [startDate, setStartDate] = useState(date.setDate(date.getDate() - 30));
    const [endDate, setEndDate] = useState(new Date());

    useEffect(
        () => {
            //setCargando(true)
            getLeadsByCountry(startDate, endDate);
            //console.log("12_pastDates", getPastDates(12));
        }
        , []);

    const getLeadsByCountry = async (start, end) => {
        setCargando(true);

        //var today = new Date();
        //today.setDate(new Date() - 30);
        var _startDate_ = new Date(start).toLocaleDateString('es-MX', {
            year: 'numeric', month: '2-digit', day: '2-digit', hour12: false
        });

        var _endDate_ = new Date(end).toLocaleDateString('es-MX', {
            year: 'numeric', month: '2-digit', day: '2-digit', hour12: false
        });

        console.log("StratDate", _startDate_);
        console.log("end", _endDate_);

        var jDataSend = {};
        jDataSend.startDate = _startDate_;
        jDataSend.endDate = _endDate_;

        const response = await fetch("/api/Administradores/getLeadsByCountry/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jDataSend)
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            console.log("==== LEADS BY COUNTRY ====");

            console.log("response: ", response);
            const data = await response.json();
            console.log("data: ", data);

            if (data.iResponseType == 1) {

                var ObjDetalles = JSON.parse(data.detalles);
                console.log("Detalles: ", ObjDetalles);


                setMapData(ObjDetalles.table2);

                var country = [];
                var value = [];
                ObjDetalles.table2.forEach((item) => {
                    console.log(item);
                    country.push(item.name);
                    value.push(item.value);
                });

                var GraphData = [];
                GraphData.country = country;
                GraphData.value = value;

                setchartData(GraphData);


                setCargando(false);

            } else {

                setMensajeError(data.vMessage);
                setShowMensajeError(true);
            }

        }
        else {


            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }

    }

    const chartRef = useRef(null);

    const handleMapReset = () => {
        chartRef.current.getEchartsInstance().dispatchAction({
            type: 'restore'
        });
    };

    const onChange = dates => {
        const [start, end] = dates;


        setStartDate(start);
        setEndDate(end);

        if (start != null && end != null) {
            getLeadsByCountry(start, end);
        }

        console.log(dates);
    };

    const GreetingsDateInput = forwardRef(({ value, onClick }, ref) => (
        <div className="position-relative">
            <Form.Control
                size="sm"
                ref={ref}
                onClick={onClick}
                value={value}
                className="ps-4"
                onChange={e => {
                    console.log({ e });
                }}
            />
            <FontAwesomeIcon
                icon="calendar-alt"
                className="text-primary position-absolute top-50 translate-middle-y ms-2"
            />
        </div>
    ));
    GreetingsDateInput.propTypes = {
        value: PropTypes.string,
        onClick: PropTypes.func
    };

    return (

        <>
            {
                cargando ?
                    <>
                        <Card >
                            <Card.Header>
                                <Row>
                                    <Col>
                                        <h6><Spinner className='text-inline mt-3 ms-2' size='sm' animation='border' variant='primary' /> Cargando... Leads by Country </h6>
                                    </Col>
                                    <Col md="auto" className="mb-3 mb-md-0">
                                        <Row className="g-3 gy-md-0 h-100 align-items-center">
                                           
                                            <Col md="auto">
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={onChange}
                                                    startDate={startDate}
                                                    formatWeekDay={day => day.slice(0, 3)}
                                                    endDate={endDate}
                                                    selectsRange
                                                    dateFormat="MMM dd"
                                                    customInput={<GreetingsDateInput />}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>


                            </Card.Header>

                        </Card>
                        <Skeleton height={300} style={{ "zIndex": "-1" }} />
                    </>
                    :
                    <>
                        <Card className="h-100">
                            <FalconCardHeader
                                title="Leads By Country"
                                titleTag="h6"
                                className="py-2"
                                light
                                endEl={
                                    <Flex>
                                        <Row className="g-3 gy-md-0 h-100 align-items-center mt-1">
                                            
                                            <Col md="auto">
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={onChange}
                                                    startDate={startDate}
                                                    formatWeekDay={day => day.slice(0, 3)}
                                                    endDate={endDate}
                                                    selectsRange
                                                    dateFormat="MMM dd"
                                                    customInput={<GreetingsDateInput />}
                                                />
                                            </Col>
                                        </Row>

                                        <div className="btn-reveal-trigger">
                                            <Button
                                                variant="link"
                                                size="sm"
                                                className="btn-reveal"
                                                type="button"
                                                onClick={handleMapReset}
                                            >
                                                <FontAwesomeIcon icon="sync-alt" />
                                            </Button>
                                        </div>
                                        <CardDropdown />
                                    </Flex>
                                }
                            />
                            <Card.Body>
                                <WorldMap data={mapData} ref={chartRef} style={{ height: '20rem' }} />
                                <LeadsByCountryChart data={chartData} />
                            </Card.Body>


                        </Card>
                    </>
        }
        </>
    
  );
};

LeadsByCountry.propTypes = {
    //chartData: PropTypes.arrayOf(PropTypes.array).isRequired,
    //mapData: WorldMap.propTypes.data.isRequired,

    setMensajeError: PropTypes.func,
    setShowMensajeError: PropTypes.func,
};

export default LeadsByCountry;
