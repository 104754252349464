import SettingsPanel from 'akasia-components/settings-panel/SettingsPanel';
import SettingsToggle from 'akasia-components/settings-panel/SettingsToggle';
import AppContext from 'context/Context';
import is from 'is_js';
import React, { useContext, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import FalconRoutes from 'routes';



const App = () => {
      
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  

    useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
      }
     
  }, [HTMLClassList]);
  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
      }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);
    return (
        
      <Router basename={process.env.PUBLIC_URL}>
      <FalconRoutes />
      <SettingsToggle />
      <SettingsPanel />
      
    </Router>
  );
};
export default App;