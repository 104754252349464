import React from 'react';
import PropTypes from 'prop-types';




const Calendar = ({ month, day }) => {

    //const [showModalEditarEvento, setShowModalEditarEvento] = useState(false);

    return (

        <>
            <div className="calendar">
                <span className="calendar-month">
                    {
                        month == 0 ? "ENE" :
                            month == 1 ? "FEB" :
                                month == 2 ? "MAR" :
                                    month == 3 ? "ABR" :
                                        month == 4 ? "MAY" :
                                            month == 5 ? "JUN" :
                                                month == 6 ? "JUL" :
                                                    month == 7 ? "AGO" :
                                                        month == 8 ? "SEP" :
                                                            month == 9 ? "OCT" :
                                                                month == 10 ? "NOV" :
                                                                    month == 11 ? "DIC" : ''}
                </span>

                <span className="calendar-day">{day}</span>
                
            </div>



        </>

    );
};

Calendar.propTypes = {
    month: PropTypes.number.isRequired,
    day: PropTypes.number.isRequired
};

export default Calendar;
