import Flex from 'components/common/Flex';
import React from 'react';
import { Card } from 'react-bootstrap';
import HalfDoughnutChart from '../HalfDoughnutChart';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';


const AvanceGraficoAnual = ({ variablesMetasControl, cargando }) => {
    return (
        <Card className="h-md-100">
            <Card.Header className="pb-0">
                <h5 className="mb-0 mt-2">Avance Anual</h5>
            </Card.Header>
            <Card.Body as={Flex} justifyContent="between">
                <div>
                    {/* <h2 className="mb-1 mt-3 text-700 fw-normal lh-1 fs-2">
                        {parseFloat((variablesMetasControl.nItemsVendidosTrim / 1293103.44) * 100).toLocaleString("es-MX", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                        })} %</h2>*/}
                    {cargando ?
                        <Skeleton width={100} /> :
                        <h2 className="mb-1 text-success fw-normal lh-1 fs-2 ">
                            {variablesMetasControl.nItemsVendidosAnio} Items

                        </h2>}
                    {cargando ?
                        <Skeleton width={100} /> :
                        <h6 className="mb-1 mt-3 text-700 fw-normal lh-1 fs--1">
                            {parseFloat((variablesMetasControl.nItemsVendidosAnio / variablesMetasControl.dMetaAnualItems) * 100).toLocaleString("es-MX", {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                            })} %</h6>}
                </div>
                <div>
                    <div className="my-n5">
                        {cargando ? <div>

                            <Skeleton circle width={100} height={100} className="mb-1" />
                            

                        </div> :
                            <HalfDoughnutChart
                                color="primary"
                                target={variablesMetasControl.dMetaAnualItems}
                                reached={variablesMetasControl.nItemsVendidosAnio}
                            />}
                    </div>
                    <p className="mb-0 mt-4 text-center fs--2 text-500">
                        Objetivo:
                        {cargando ?
                            <Skeleton /> : <span className="text-800"> {variablesMetasControl.dMetaAnualItems} </span>}
                    </p>
                </div>
            </Card.Body>
        </Card>
    );
};

AvanceGraficoAnual.propTypes = {
    variablesMetasControl: PropTypes.object,
    cargando: PropTypes.bool
};

export default AvanceGraficoAnual;
