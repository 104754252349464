
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { isIterableArray } from 'helpers/utils';
import PropTypes from 'prop-types';
import React from 'react';
import Flex from './Flex';

const Avatar = ({
    size,
    rounded,
    src,
    name,
    emoji,
    className,
    mediaClass,
    isExact,
    icon
}) => {
    const classNames = ['avatar', `avatar-${size}`, className].join(' ');
    const mediaClasses = [
        rounded ? `rounded-${rounded}` : 'rounded',
        mediaClass
    ].join(' ');

    const getAvatar = () => {
        if (src) {
            if (isIterableArray(src)) {
                return (
                    <div className={`${mediaClasses} overflow-hidden h-100 d-flex`}>
                        <div className="w-50 border-right">
                        </div>
                        <div className="w-50 d-flex flex-column">
                        </div>
                    </div>
                );
            } else {
                return src.length > 100 ? <img className={mediaClasses} loading="lazy" src={src} alt="" /> : <img className={mediaClasses} loading="lazy" src="/img/team/nofoto50.png" alt="" />
            }
        }

        if (name) {
            return (
                <div className={`avatar-name ${mediaClasses}`}>
                    <span>{isExact ? name : name.match(/\b\w/g).join('')}</span>
                </div>
            );
        } 

        if (icon) {
            return (
                <Flex className={`avatar-name ${mediaClasses}`}>
                    <FontAwesomeIcon icon={icon} />
                </Flex>
            );
        }

        return (
            <div className={`avatar-emoji ${mediaClasses}`}>
                <span role="img" aria-label="Emoji">
                    {emoji}
                </span>
            </div>
        );
    };

    return <div className={classNames}>{getAvatar()}</div>;
};

export const AvatarGroup = ({ children, dense, className }) => {
    return (
        <div
            className={classNames(className, 'avatar-group', {
                'avatar-group-dense': dense
            })}
        >
            {children}
        </div>
    );
};

Avatar.propTypes = {
    size: PropTypes.oneOf(['s', 'm', 'l', 'xl', '2xl', '3xl', '4xl', '5xl']),
    rounded: PropTypes.string,
    src: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    name: PropTypes.string,
    emoji: PropTypes.string,
    className: PropTypes.string,
    mediaClass: PropTypes.string,
    isExact: PropTypes.bool,
    icon: PropTypes.string
};

Avatar.defaultProps = {
    size: 'xl',
    rounded: 'circle',
    emoji: '😊',
    isExact: false
};

AvatarGroup.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    dense: PropTypes.bool
};

export default Avatar;
