import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HistorialMovimientosCliente from 'akasia-components/cliente/HistorialMovimientosCliente';
import Avatar from 'components/common/Avatar';
import FalconCloseButton from 'components/common/FalconCloseButton';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import MensajeErrror from 'components/common/MensajeError';
import MensajeInformativo from 'components/common/MensajeInformativo';
import PageHeader from 'components/common/PageHeader';
import SoftBadge from 'components/common/SoftBadge';
import useToken from 'hooks/useToken';
import React, { useEffect, useState } from 'react';
import { Accordion, Alert, Badge, Button, ButtonGroup, Card, CloseButton, Col, Form, ListGroup, Modal, OverlayTrigger, Row, Spinner, Table, ToggleButton, Tooltip } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { ToastContainer } from 'react-toastify';
import ModalSeguimientoLead from '../leads/ModalSeguimientoLead';
import ComentariosSoporte from './ComentariosSoporte';
import ModalDatosFiscalesIncompletos from './ModalDatosFiscalesIncompletos';
import Banderas from './Banderas';

const DetallesCliente = () => {
    const { id } = useParams();
    const { token, setToken, profileImage } = useToken();
    const navigate = useNavigate();
    const { register, setValue, reset, handleSubmit, formState: { errors } } = useForm();

    const [customerdetails, setCustomers] = useState([]);
    const [agrupacionLicencias, setAgrupacionLicencias] = useState([]);
    const [tablaRegimenFiscal, settablaRegimenFiscal] = useState([]);
    const [tablaUsoCFDI, settablaUsoCFDI] = useState([]);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [clienteActivityLogs, setClienteActivityLogs] = useState([]);
    const [nivelSoporte, setNivelSoporte] = useState([]);
    const [herramientas, setHerramientas] = useState([]);
    const [isDistribucion, setIsDistribucion] = useState(false);
    const [distribuidores, setDistribuidores] = useState([]);

    const [statusSolucion, setStatusSolucion] = useState([]);
    const [soporte, setSoporte] = useState([]);
    const [orden, setOrden] = useState([]);
    const [documentosCargados, setDocumentosCargados] = useState({
        docIDFoto: true,
        docCedFiscal: true,
        docCompDomicilio: true,
        docCartaMotivos: true

    });
    const [documentos, setDocumentos] = useState([]);

    const [cargando, setCargando] = useState(true);
    const [cargandoEstado, setCargandoEstado] = useState(false);
    const [cargandoFile, setCargandoFile] = useState(false);
    const [mensajeError, setMensajeError] = useState('');
    const [mensajeInformativo, setMensajeInformativo] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);
    const [showMensajeInformativo, setShowMensajeInformativo] = useState(false);
    const [editar, setEditar] = useState(false)
    const [showModalNuevoSoporte, setShowModalNuevoSoporte] = useState(false);
    const [cargandoComboBoxSoporte, setCargandoComboBoxSoporte] = useState(false);
    const [showModalEliminar, setShowModalEliminar] = useState(false);
    const [showModalCombinarClientes, setShowModalCombinarClientes] = useState(false);

    const [licencias, setLicencias] = useState([]);
    const [polizas, setPolizas] = useState([]);
    const [dataFiles, setDataFiles] = useState({ DocFile: null });

    const [leadsVinculados, setLeadsVinculados] = useState([]);

    const [showModalSeguimientoLead, setShowModalSeguimientoLead] = useState(false);

    const [idLead, setIdLead] = useState(0);

    const [habilitarBtnCombinar, setHabilitarBtnCombinar] = useState(true);

    const [showModalDatosFiscalesIncompletos, setShowModalDatosFiscalesIncompletos] = useState(false);

    const [clientesRepetidos, setClientesRepetidos] = useState([]);

    const [idClienteAkasiaACombinar, setIdClienteAkasiaACombinar] = useState(0);

    const [comentariosProblemasSoporte, setComentariosProblemasSoporte] = useState([]);
    const [problemaSoporte, setProblemaSoporte] = useState('');

    const iconName = icon({ name: 'code-merge', style: "solid" });

    /**
     * obtiene informacion de un cliente
     */
    const getinformaciondetalladaclientebyid = async () => {

        setCargando(true);
        console.log(token);

        var objDataSend = {};

        objDataSend.idClienteAkasia = id;

        console.log(objDataSend);
        var jDataSend = JSON.stringify(objDataSend);

        const response = await fetch("/api/cliente/getinformaciondetalladaclientebyid/", {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: jDataSend
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch: ' + error);
                console.log('Hubo un problema con la peticion Fetch: ' + error.message);
            });

        if (response.ok) {

            console.log("===================== getinformaciondetalladaclientebyid =========================");

            console.log(response);
            const data = await response.json();

            console.log("data: ", data);

            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);
                console.log("detalles: ", detalles);

                console.log("===================== table 2 =========================");

                console.log("tabla 2 (Info Client) ", detalles['table2']);
                setCustomers(detalles['table2'][0]);

                console.log("===================== FIN table 2 =========================");

                console.log("Tabla 3 (Agrupacion Lic) ", detalles['table3']);
                setAgrupacionLicencias(detalles['table3']);

                console.log("Tabla 4 (Licencias) ", detalles['table4']);
                setLicencias(detalles['table4']);

                console.log("Tabla 5 (Polizas) ", detalles['table5']);
                setPolizas(detalles['table5']);

                console.log("tabla 7 (Regimenes Fiscales)", detalles['table7']);
                settablaRegimenFiscal(detalles['table7']);

                console.log("tabla 8 (Uso CFDI)", detalles['table8']);
                settablaUsoCFDI(detalles['table8']);

                console.log("tabla 9 (Paises)", detalles['table9']);
                setCountries(detalles['table9']);

                console.log("tabla 10 (Estados)", detalles['table10']);
                setStates(detalles['table10']);

                console.log("tabla 11 (Logs)", detalles['table11']);
                setClienteActivityLogs(detalles['table11']);

                console.log("tabla 12 (TimeLine)", detalles['table12']);

                console.log("tabla 13 (Soporte)", detalles['table13']);
                setSoporte(detalles['table13']);

                console.log("tabla 14 (Orden)", detalles['table14']);
                setOrden(detalles['table14']);

                console.log("tabla 15 (Leads Vinculados)", detalles['table15']);
                setLeadsVinculados(detalles['table15']);

                console.log("tabla 16 (Clientes repetidos)", detalles['table16']);

                if (detalles['table16'].length > 0) {
                    var clientesRepetidos = JSON.parse(detalles["table16"][0].vJsonClientesAkasiaRepetidos);
                    const newArray = clientesRepetidos.filter(e => e.idClienteAkasia !== detalles["table2"][0].idClienteAkasia);//quitamos del arreglo el cliente del cual vemos los detalles
                    console.log("Clientes repetidos: ", newArray);
                    setClientesRepetidos(newArray);
                }

                console.log("tabla 17 (comentarios de problemas de soporte)");
                console.table(detalles['table17']);
                setComentariosProblemasSoporte(detalles['table17']);

                console.log("tabla 18 (comentarios de soluciones de soporte)", detalles['table18']);
                //setComentariosSolucionesSoporte(detalles['table18']);

                console.log("tabla 19 (Documentos cargados)", detalles['table19']);
                setDocumentos(detalles['table19']);
                for (let i = 0; i < detalles["table19"].length; i++) {
                    if (detalles["table19"][i].idTipoDoc == 1)
                        setDocumentosCargados(documentosCargados => { return { ...documentosCargados, docIDFoto: false } })
                    if (detalles["table19"][i].idTipoDoc == 2)
                        setDocumentosCargados(documentosCargados => { return { ...documentosCargados, docCedFiscal: false } })
                    if (detalles["table19"][i].idTipoDoc == 3)
                        setDocumentosCargados(documentosCargados => { return { ...documentosCargados, docCompDomicilio: false } })
                    if (detalles["table19"][i].idTipoDoc == 4)
                        setDocumentosCargados(documentosCargados => { return { ...documentosCargados, docCartaMotivos: false } })
                }

                console.log("tabla 20 (Distribuidores)", detalles['table20']);
                setDistribuidores(detalles['table20']);



            } else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }



        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setCargando(false);
    };

    /**
     * llena el combobox para el soporte tecnico
     */
    const obtenerComboBoxSoporteTecnico = async () => {
        setShowModalNuevoSoporte(true);
        setCargandoComboBoxSoporte(true)
        const response = await fetch("/api/soportetecnico/ObtenerComboBoxesRegistroSoporte/", {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch: ' + error);
                console.log('Hubo un problema con la peticion Fetch: ' + error.message);
            });

        if (response.ok) {
            console.log(response);
            const data = await response.json();

            console.log(data['table1']);
            setNivelSoporte(data['table1']);

            console.log(data['table2']);
            setHerramientas(data['table2']);

            console.log(data['table3']);
            setStatusSolucion(data['table3']);


        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setCargandoComboBoxSoporte(false)
    };

    /**
      * muestra los estados
     * @param {any} e
     */
    const mostrarEstados = async (e) => {
        setCargandoEstado(true);
        console.log(e);
        const response = await fetch("/api/cliente/GetStatesByCountry/?vCountryName=" + encodeURIComponent(e), {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error.message);
                console.log('Hubo un problema con la peticion Fetch:' + error.message);
            });

        if (response.ok) {
            console.log(response);
            const data = await response.json();

            console.log(data);
            setStates(data);
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            setStates([]);
            console.log(response);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setCargandoEstado(false);
    };

    /**
     * edita la info del cliente
     * @param {any} key
     * @param {any} values
     */
    const editarInfoCliente = async (key, values) => {
        setCargando(true);
        console.log("========== EDITAR INFO CLIENTE ==========");
        console.log("values: ", values);
        const response = await fetch("/api/cliente/updatecliente/?key=" + key + "&values=" + encodeURIComponent(values), {
            method: 'PUT',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {

            console.log("Response: ", response);
            const data = await response.json();
            console.log("data: ", data);

            if (data.iResponseType == 1) {

                setEditar(false);
                setShowMensajeInformativo(true);
                setMensajeInformativo('Se han guardado los datos correctamente');
                getinformaciondetalladaclientebyid();

            } else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            setStates([]);
            console.log(response);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setCargando(false);
    };

    /**
     * elimina un cliente
     * */
    const eliminarCliente = async () => {
        const response = await fetch("/api/cliente/EliminarCliente/?key=" + id, {
            method: 'DELETE',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {

            const data = await response.json();
            console.log("data: ", data);

            if (data.iResponseType == 1) {

                getinformaciondetalladaclientebyid();
                setShowMensajeInformativo(true);
                setMensajeInformativo(data.vMensaje);
                setShowModalEliminar(false);

            } else {
                setShowMensajeError(true);
                setMensajeError(data.vMensaje);
                setShowModalEliminar(false);
            }


        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            setStates([]);
            console.log(response);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
    };

    /**
     * registra el soporte tecnico
     * @param {any} values
     */
    const registrarSoporteTecnico = async (values) => {
        setShowModalNuevoSoporte(false);
        console.log(values);
        const response = await fetch("/api/soportetecnico/RegistrarSoporteTecnico/?values=" + encodeURIComponent(values), {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            console.log(response);
            setEditar(false);
            setShowMensajeInformativo(true);
            setMensajeInformativo('Se han guardado los datos correctamente');
            getinformaciondetalladaclientebyid();
            reset();
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            setStates([]);
            console.log(response);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setCargando(false);

    };

    /**
     * valida los datos fiscales del cliente para evitar que cree una orden con datos incompletos
     * @param {any} idClienteAkasia
     */
    const validarDatosFiscales = (idClienteAkasia) => {

        var datosCompletos = true;

        if (customerdetails.vRfc != null) {
            if (customerdetails.vRfc == "")
                datosCompletos = false
        } else {
            datosCompletos = false
        }

        if (customerdetails.vRazonSocial != null) {
            if (customerdetails.vRazonSocial == "")
                datosCompletos = false
        } else {
            datosCompletos = false
        }

        if (customerdetails.vCodigoRegimen != null) {
            if (customerdetails.vCodigoRegimen == "")
                datosCompletos = false
        } else {
            datosCompletos = false
        }

        if (customerdetails.vDireccionFiscal != null) {
            if (customerdetails.vDireccionFiscal == "")
                datosCompletos = false
        } else {
            datosCompletos = false
        }

        if (customerdetails.vCodigoUso != null) {
            if (customerdetails.vCodigoUso == "")
                datosCompletos = false
        } else {
            datosCompletos = false
        }

        //if (!customerdetails.vTelefonoPersonal.includes("+52")) { //si el cliente es de otro pais el vNumRegTrib no puede estar vacio

        //    if (customerdetails.vNumRegTrib != null) {
        //        if (customerdetails.vNumRegTrib == "")
        //            datosCompletos = false
        //    } else {
        //        datosCompletos = false
        //    }
        //}


        if (datosCompletos) {
            navigate('/ordenes/nueva/' + idClienteAkasia);
        } else {
            setShowModalDatosFiscalesIncompletos(true);
        }

    }

    /**
     * combina la informaci�n de 2 clientes en uno solo para evitar tener clientes repetidos
     */
    const combinarClientes = async () => {

        setHabilitarBtnCombinar(false);
        var jDataSend = {};
        jDataSend.idClienteAkasia = customerdetails.idClienteAkasia
        jDataSend.idClienteAkasiaACombinar = idClienteAkasiaACombinar

        const response = await fetch("/api/cliente/CombinarClientes/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jDataSend)
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            console.log("==== CLIENTES COMBINADOS ====");

            console.log("response: ", response);
            const data = await response.json();
            console.log("data: ", data);

            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);
                console.log("detalles: ", detalles);

                getinformaciondetalladaclientebyid();

                setHabilitarBtnCombinar(false);
                setShowModalCombinarClientes(false);
            } else {

                setMensajeError(data.vMessage);
                setShowMensajeError(true);
                setHabilitarBtnCombinar(false);
                setShowModalCombinarClientes(false);

            }

        }
        else {

            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);
            setHabilitarBtnCombinar(false);
            setShowModalCombinarClientes(false);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
    }

    const crearComentarioSoporte = async () => {

        var jDataSend = {};
        jDataSend.vProblema = problemaSoporte;
        jDataSend.idClienteAkasia = customerdetails.idClienteAkasia;

        const response = await fetch("/api/cliente/RegistrarProblemaSoporte/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jDataSend)
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            console.log("==== REGISTRO DE COMENTARIO DE SOPORTE ====");

            console.log("response: ", response);
            const data = await response.json();
            console.log("data: ", data);

            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);
                console.log("detalles: ", detalles);

                setComentariosProblemasSoporte([detalles['table2'][0], ...comentariosProblemasSoporte]);
                setProblemaSoporte('');

            } else {

                setMensajeError(data.vMessage);
                setShowMensajeError(true);
                setHabilitarBtnCombinar(false);
                setShowModalCombinarClientes(false);

            }

        }
        else {

            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);
            setHabilitarBtnCombinar(false);
            setShowModalCombinarClientes(false);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
    };

    //Enviar Archivo DocFile
    const onSubmitDocFile = async (idTipoDoc) => {
        if (dataFiles.DocFile == null) {
            setMensajeError('Es obligatorio subir un documento para enviar');
            setShowMensajeError(true);
            return;
        }

        if (dataFiles.DocFile.length == 0) {
            setMensajeError('Es obligatorio subir un documento para enviar');
            setShowMensajeError(true);
            return;
        }

        setCargandoFile(true);

        var data = new FormData()
        data.append('DocFile', dataFiles.DocFile);
        data.append('idClienteAkasia', id);
        data.append('idTipoDoc', idTipoDoc)
        const response = await fetch("/api/distribuidor/SubirDocFile", {
            method: 'POST',
            body: data,
            headers:
            {
                'Authorization': 'Bearer ' + token
            }
        }).catch((error) => {
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            setShowMensajeError(true);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            setDocumentos(data);

            setDocumentosCargados(
                documentosCargados => {
                    return {
                        ...documentosCargados,
                        docCompDomicilio: true,
                        docCartaMotivos: true,
                        docCedFiscal: true,
                        docIDFoto: true
                    }
                });

            for (let i = 0; i < data.length; i++) {
                if (data[i].idTipoDoc == 1)
                    setDocumentosCargados(documentosCargados => { return { ...documentosCargados, docIDFoto: false } })
                if (data[i].idTipoDoc == 2)
                    setDocumentosCargados(documentosCargados => { return { ...documentosCargados, docCedFiscal: false } })
                if (data[i].idTipoDoc == 3)
                    setDocumentosCargados(documentosCargados => { return { ...documentosCargados, docCompDomicilio: false } })
                if (data[i].idTipoDoc == 4)
                    setDocumentosCargados(documentosCargados => { return { ...documentosCargados, docCartaMotivos: false } })
            }
        }
        else {
            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }


        setDataFiles(dataFiles => {
            return {
                ...dataFiles,
                DocFile: null
            }
        })
        setCargandoFile(false);
    };

    useEffect(() => {
        getinformaciondetalladaclientebyid();
    }, [id]);

    const onSubmit = data => {
        editarInfoCliente(id, JSON.stringify(data, null, 2))
    };

    const onSubmitNuevoSoporte = data => {
        registrarSoporteTecnico(JSON.stringify(data));
    };

    const onSubmitEliminar = data => {
        eliminarCliente(JSON.stringify(data));
    };

    return (
        <>
            <PageHeader
                title={"Cliente " + id + " | Informaci\u00f3n detallada"}
                className="mb-1"
            ></PageHeader>
            {cargando ?
                <h3>
                    <Spinner className="text-inline" animation="border" variant="primary" /> Cargando...
                </h3>
                :
                <>
                    <Card className="mb-3">
                        <Card.Header className="d-flex flex-between-center">
                            <IconButton
                                onClick={() => navigate(-1)}
                                variant="falcon-default"
                                size="sm"
                                icon="arrow-left"
                            />
                            {customerdetails.bIsActive == 0 && <h1><Badge bg='danger' className='me-2'>CLIENTE ELIMINADO</Badge></h1>}

                            <Flex>
                                {customerdetails.bIsActive &&
                                    (<>
                                        {!editar &&
                                            <>
                                                <Link to={"/licencias/nueva/" + customerdetails.idClienteAkasia}>
                                                    <IconButton
                                                        variant="falcon-default"
                                                        size="sm"
                                                        icon="plus"
                                                        transform="shrink-2"
                                                        iconAlign="middle"
                                                        className="d-sm-block me-2"
                                                    >
                                                        <span className="d-none d-xl-inline-block ms-1">Nueva licencia</span>
                                                    </IconButton>
                                                </Link>

                                                <IconButton
                                                    onClick={() => { validarDatosFiscales(customerdetails.idClienteAkasia); }}

                                                    variant="falcon-default"
                                                    size="sm"
                                                    icon="shopping-cart"
                                                    transform="shrink-2"
                                                    iconAlign="middle"
                                                    className="d-sm-block me-2"
                                                >
                                                    <span className="d-none d-xl-inline-block ms-1">Nueva orden</span>
                                                </IconButton>


                                                <IconButton
                                                    onClick={() => { setEditar(true); }}

                                                    variant="falcon-default"
                                                    size="sm"
                                                    icon="pencil-alt"
                                                    transform="shrink-2"
                                                    className="d-sm-block me-2"
                                                >
                                                    <span className="d-none d-xl-inline-block ms-1">Editar detalles</span>
                                                </IconButton>
                                            </>
                                        }
                                        {editar &&
                                            <>
                                                <IconButton
                                                    onClick={() => { setEditar(false); reset(); }}
                                                    className="d-sm-block me-2"
                                                    variant="falcon-default"
                                                    size="sm"
                                                    icon={faWindowClose}
                                                    transform="shrink-2"
                                                >
                                                    <span className="d-none d-xl-inline-block ms-1">Cancelar edici&oacute;n</span>

                                                </IconButton>

                                                <IconButton
                                                    className="d-sm-block me-2"
                                                    variant="falcon-default"
                                                    transform="shrink-2"
                                                    size="sm"
                                                    icon="check"
                                                    type="submit"
                                                    onClick={handleSubmit(onSubmit)}
                                                >
                                                    <span className="d-none d-xl-inline-block ms-1">Guardar Cambios</span>

                                                </IconButton>
                                            </>
                                        }
                                        {!editar &&
                                            <>
                                                <IconButton
                                                    variant="falcon-default"
                                                    size="sm"
                                                    icon="edit"
                                                    transform="shrink-2"
                                                    iconAlign="middle"
                                                    className="d-sm-block me-2"
                                                    onClick={() => obtenerComboBoxSoporteTecnico()}
                                                >
                                                    <span className="d-none d-xl-inline-block ms-1">Registrar Soporte T&eacute;cnico</span>
                                                </IconButton>

                                                <IconButton
                                                    variant="falcon-danger"
                                                    size="sm"
                                                    icon="trash"
                                                    transform="shrink-2"
                                                    iconAlign="middle"
                                                    className="d-sm-block me-2"
                                                    onClick={() => { setShowModalEliminar(true) }}
                                                >

                                                </IconButton>
                                            </>
                                        }
                                    </>)}
                                <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    icon="print"
                                    transform="shrink-2"
                                    iconAlign="middle"
                                    className="d-sm-block me-2"
                                    onClick={() => { window.print() }}
                                >

                                </IconButton>

                            </Flex>

                        </Card.Header>
                    </Card>
                    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3 mb-3">
                            <Col lg={12}>{/*Informaci�n general*/}
                                <Card>
                                    <Card.Header>
                                        <Row>
                                            <Col xs="auto" className="mt-2">
                                                <h6 className="text-uppercase text-600">
                                                    {/* CLIENTE
                                            <FontAwesomeIcon icon="user" className="ms-2" />*/}
                                                    <img src="/img/cliente70.png"></img>
                                                </h6>
                                            </Col>
                                            <Col>
                                                <h5 className="mb-2">
                                                    {editar ?
                                                        (
                                                            <>
                                                                <Form.Control
                                                                    type="hidden"
                                                                    defaultValue={customerdetails.idClienteAkasia}
                                                                    {...register("idClienteAkasia")}
                                                                />
                                                                <Form.Label>Nombre</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    size="sm"
                                                                    autoComplete="off"
                                                                    onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                                    defaultValue={customerdetails.vNombre}
                                                                    isInvalid={!!errors.vNombre}
                                                                    {...register("vNombre",
                                                                        {
                                                                            required: 'El nombre del cliente es requerido',
                                                                            maxLength: 100,
                                                                            minLength: 1
                                                                        })
                                                                    }
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.vNombre && errors.vNombre.message}
                                                                </Form.Control.Feedback>
                                                            </>
                                                        ) : (
                                                            customerdetails.vNombre
                                                        )}
                                                </h5>
                                                <h5 className="mb-2">
                                                    {editar ?
                                                        (
                                                            <>
                                                                <Form.Label>Apellidos</Form.Label>
                                                                <Form.Control
                                                                    size="sm"
                                                                    autoComplete="off"
                                                                    type="text"
                                                                    onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                                    defaultValue={customerdetails.vApellidos}
                                                                    isInvalid={!!errors.vApellidos}
                                                                    {...register("vApellidos",
                                                                        {
                                                                            required: 'Los apellidos son requeridos',
                                                                            maxLength: 100,
                                                                            minLength: 1
                                                                        })
                                                                    }
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.vApellidos && errors.vApellidos.message}
                                                                </Form.Control.Feedback>
                                                            </>
                                                        ) : (
                                                            customerdetails.vApellidos
                                                        )}

                                                </h5>
                                                <h6>
                                                    {editar ?
                                                        (
                                                            <>
                                                                <Form.Label>Email</Form.Label>
                                                                <Form.Control
                                                                    size="sm"
                                                                    autoComplete="off"
                                                                    type="text"
                                                                    defaultValue={customerdetails.vEmail}
                                                                    isInvalid={!!errors.vEmail}
                                                                    {...register("vEmail",
                                                                        {
                                                                            required: 'El email es requerido',
                                                                            maxLength: 120,
                                                                            minLength: 5,
                                                                            pattern: {
                                                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                                                message: "Email incorrecto."
                                                                            }
                                                                        })
                                                                    }
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.vEmail && errors.vEmail.message}
                                                                </Form.Control.Feedback>
                                                            </>
                                                        ) : (
                                                            <a href={"mailto:" + customerdetails.vEmail}>{customerdetails.vEmail}</a>
                                                        )}

                                                </h6>
                                                {!editar &&
                                                    (
                                                        <>
                                                            {/* <IconButton
                                                        iconClassName="fs--2 me-1"
                                                        variant="falcon-default"
                                                        size="sm"
                                                        icon="plus"
                                                    >
                                                        Registrar licencia
                                                    </IconButton>
                                                    <Dropdown className="d-inline-block ms-2">
                                                        <Dropdown.Toggle
                                                            as={Button}
                                                            variant="falcon-default"
                                                            size="sm"
                                                            className="dropdown-caret-none"
                                                        >
                                                            <FontAwesomeIcon icon="ellipsis-h" />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu align={isRTL ? 'end' : 'start'}>
                                                            <Dropdown.Item onClick={() => { setEditar(true) }}>
                                                                Editar
                                                            </Dropdown.Item>
                                                            <Dropdown.Divider as="div" />
                                                            <Dropdown.Item as={Link} to="#!" className="text-danger">
                                                                Eliminar cliente
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>*/}
                                                        </>
                                                    )}
                                            </Col>
                                            <Col lg={6} sm={12} xs={12} md={12} className="g-3">
                                                <Row className="g-0">
                                                    <Col xs={2} md={2}>
                                                        <img src="/img/ventas48.png"></img>
                                                    </Col>
                                                    <Col xs={4} md={4}>
                                                        <h6 className="pb-1 text-700">Ventas</h6>
                                                        <p className="font-sans-serif lh-1 mb-1 fs-2">${parseFloat(customerdetails.dTotalVenta).toLocaleString("es-MX", {
                                                            maximumFractionDigits: 2,
                                                            minimumFractionDigits: 2
                                                        })} MXN</p>
                                                        <div className="d-flex align-items-center">
                                                            <h6 className="fs--1 text-500 mb-0">{orden.length} compras</h6>
                                                        </div>
                                                    </Col>
                                                    <Col xs={2} md={2}>
                                                        <img src="/img/soporte48.png"></img>
                                                    </Col>
                                                    <Col xs={4} md={4}>
                                                        <h6 className="pb-1 text-700">Soporte</h6>
                                                        <p className="font-sans-serif lh-1 mb-1 fs-2">{soporte.length > 0 ? soporte[0].iMinutos : "0"} min</p>
                                                        <div className="d-flex align-items-center">
                                                            <h6 className="fs--1 text-500 mb-0">{soporte.length > 0 ? soporte[0].iNumTickets : "0"} tickets</h6>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body className="border-top fs--1">
                                        {!editar &&
                                            (
                                                <>
                                                    <Row >
                                                        <Col lg={6}>

                                                            <Flex>
                                                                <FontAwesomeIcon
                                                                    icon="user"
                                                                    className="text-success me-2"
                                                                    transform="down-5" />
                                                                <div className="flex-1">
                                                                    El cliente fue registrado el &nbsp;
                                                                    <SoftBadge
                                                                        bg='primary'
                                                                        className='me-2'>
                                                                        {customerdetails.reg_desde}
                                                                    </SoftBadge> a las &nbsp;
                                                                    <SoftBadge
                                                                        bg='primary'
                                                                        className='me-2'>
                                                                        {customerdetails.reg_desde_hora}
                                                                    </SoftBadge> por  &nbsp;
                                                                    <SoftBadge
                                                                        bg='success'
                                                                        className='me-2'>
                                                                        {customerdetails.vCreatedUser}
                                                                    </SoftBadge>.
                                                                </div>
                                                            </Flex>
                                                            <Flex>
                                                                <FontAwesomeIcon
                                                                    icon="clock"
                                                                    className="text-primary me-2"
                                                                    transform="down-5"
                                                                />
                                                                <div className="flex-1">

                                                                    Antiguedad: {customerdetails.antiguedad} d&iacute;as.

                                                                </div>
                                                            </Flex>

                                                            {customerdetails.vUpdatedUser &&
                                                                (
                                                                    <Flex>
                                                                        <FontAwesomeIcon
                                                                            icon="user"
                                                                            className="text-primary me-2"
                                                                            transform="down-5"
                                                                        />
                                                                        <div className="flex-1">
                                                                            La &uacute;ltima modificaci&oacute;n la realiz&oacute;:&nbsp;
                                                                            <SoftBadge bg='success' className='me-2'>{customerdetails.vUpdatedUser}</SoftBadge>

                                                                        </div>
                                                                    </Flex>
                                                                )}

                                                            <p>{customerdetails.vDescripcion}</p>
                                                        </Col>
                                                        <Col className="end">

                                                        </Col>
                                                    </Row>
                                                    <Row className="p-2">

                                                        {
                                                            clientesRepetidos.map((cliente, index) => {
                                                                return (

                                                                    <Col className="p-0 col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-2" key={index} >
                                                                        <Row>
                                                                            <Col className="col-1">
                                                                                <FontAwesomeIcon className="me-1 " icon={icon({ name: 'circle-exclamation', style: "solid" })} style={{ color: "#fab005", fontSize: "2.5em" }} />
                                                                            </Col>
                                                                            <Col className=" col-6">

                                                                                <Row>

                                                                                    <Col className="col-12">
                                                                                        <OverlayTrigger
                                                                                            placement="top"
                                                                                            overlay={
                                                                                                <Tooltip style={{ position: 'fixed' }} >
                                                                                                    Ver info de este cliente
                                                                                                </Tooltip>
                                                                                            }
                                                                                        >
                                                                                            <Link className="align-content-center" to={"/clientes/detalle/" + cliente.idClienteAkasia}>
                                                                                                <span className="ms-1 text-center ">
                                                                                                    {"Cliente repetido: " + cliente.idClienteAkasia}
                                                                                                </span>
                                                                                            </Link>

                                                                                        </OverlayTrigger>
                                                                                    </Col>

                                                                                    <Col className="col-12">
                                                                                        {
                                                                                            cliente.bNombre == 1 &&
                                                                                            (
                                                                                                <OverlayTrigger
                                                                                                    placement="top"
                                                                                                    overlay={
                                                                                                        <Tooltip style={{ position: 'fixed' }} >
                                                                                                            Cliente con mismo nombre
                                                                                                        </Tooltip>
                                                                                                    }
                                                                                                >
                                                                                                    <FontAwesomeIcon icon="user" className="ms-2" />
                                                                                                </OverlayTrigger>
                                                                                            )
                                                                                        }
                                                                                        {
                                                                                            cliente.bTelefono == 1 &&
                                                                                            (
                                                                                                <OverlayTrigger
                                                                                                    placement="top"
                                                                                                    overlay={
                                                                                                        <Tooltip style={{ position: 'fixed' }} >
                                                                                                            Cliente con mismo telefono
                                                                                                        </Tooltip>
                                                                                                    }
                                                                                                >
                                                                                                    <FontAwesomeIcon icon="phone" className="ms-2" />
                                                                                                </OverlayTrigger>
                                                                                            )
                                                                                        }
                                                                                        {
                                                                                            cliente.bCorreo == 1 &&
                                                                                            (
                                                                                                <OverlayTrigger
                                                                                                    placement="top"
                                                                                                    overlay={
                                                                                                        <Tooltip style={{ position: 'fixed' }} >
                                                                                                            Cliente con mismo correo
                                                                                                        </Tooltip>
                                                                                                    }
                                                                                                >
                                                                                                    <FontAwesomeIcon icon="envelope" className="ms-2" />
                                                                                                </OverlayTrigger>
                                                                                            )
                                                                                        }
                                                                                    </Col>

                                                                                </Row>

                                                                            </Col>
                                                                            <Col className="col-2">

                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={
                                                                                        <Tooltip style={{ position: 'fixed' }} >
                                                                                            Combinar cliente
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    {/*<IconButton*/}
                                                                                    {/*    variant="falcon-default"*/}
                                                                                    {/*    size="md"*/}
                                                                                    {/*    icon={iconName}*/}
                                                                                    {/*    transform="shrink-2"*/}
                                                                                    {/*    iconAlign="middle"*/}
                                                                                    {/*    className=""*/}
                                                                                    {/*    onClick={() => { setIdClienteAkasiaACombinar(cliente.idClienteAkasia); setShowModalCombinarClientes(true); }}*/}
                                                                                    {/*/>*/}

                                                                                    <Button
                                                                                        className="btn btn-falcon-default btn-md"
                                                                                        onClick={() => { setIdClienteAkasiaACombinar(cliente.idClienteAkasia); setShowModalCombinarClientes(true); }}>
                                                                                        <FontAwesomeIcon
                                                                                            icon={iconName}

                                                                                        />
                                                                                    </Button>
                                                                                </OverlayTrigger>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                )
                                                            })
                                                        }

                                                    </Row>


                                                </>
                                            )
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col lg={12}>{/*Detalles*/}
                                <Card>

                                    <Card.Header>
                                        <Row className="align-items-center">
                                            <Col>
                                                <h5 className="mb-0">Detalles</h5>
                                            </Col>
                                            <Col xs="auto">

                                            </Col>
                                        </Row>
                                    </Card.Header>

                                    <Card.Body className="border-top fs--1">
                                        <Row>
                                            <Col className="col-lg-6 col-12">
                                                <h5 className="fw-semi-bold ls mb-3 text-uppercase">
                                                    <FontAwesomeIcon icon="phone" className="ms-2" /> INFORMACI&Oacute;N DE CONTACTO
                                                </h5>
                                                <Table responsive striped size="sm" >
                                                    <tbody>
                                                        <tr>
                                                            <td className="col-lg-4 col-sm-4 col-md-4">
                                                                <strong>Tipo Cliente</strong>
                                                            </td>
                                                            <td>
                                                                {editar ?
                                                                    (
                                                                        <Form.Select
                                                                            size="sm"
                                                                            defaultValue={customerdetails.idCatTipoCliente}
                                                                            aria-label="Default select"
                                                                            {...register("idCatTipoCliente",
                                                                                {
                                                                                    required: true
                                                                                })
                                                                            }
                                                                        >
                                                                            <option value="1">CONSUMIDOR FINAL</option>
                                                                            <option value="2">DISTRIBUIDOR</option>
                                                                        </Form.Select>
                                                                    ) : (
                                                                        customerdetails.vDescripcionTipo
                                                                    )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="col-lg-4 col-sm-4 col-md-4">
                                                                <strong>Tel&eacute;fono Personal</strong>
                                                            </td>
                                                            <td>
                                                                {editar ?
                                                                    (
                                                                        <>
                                                                            <Form.Control
                                                                                size="sm"
                                                                                autoComplete="off"
                                                                                type="text"
                                                                                defaultValue={customerdetails.vTelefonoPersonal}
                                                                                isInvalid={!!errors.vTelefonoPersonal}
                                                                                {...register("vTelefonoPersonal",
                                                                                    {
                                                                                        required: 'Este campo es requerido, incluya el c\u00f3digo de pais: ejemplo: +52',
                                                                                        maxLength: 20,
                                                                                        minLength: 6,
                                                                                        pattern: {
                                                                                            value: /^[+][0-9]+$/,
                                                                                            message: "Tel\u00E9fono incorrecto. Debe iniciar con el prefijo: +"
                                                                                        }
                                                                                    })
                                                                                }
                                                                            />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {errors.vTelefonoPersonal && errors.vTelefonoPersonal.message}
                                                                            </Form.Control.Feedback>
                                                                        </>
                                                                    ) : <>
                                                                        <Row>
                                                                            <Col>
                                                                                <span>{customerdetails.vTelefonoPersonal} </span>
                                                                            </Col>
                                                                            <Col xs="auto">
                                                                                <a href={"https://wa.me/" + customerdetails.vTelefonoPersonal} target="_blank" rel="noreferrer">
                                                                                    <img width="25" src="/img/whatsapp40.png"></img> Enviar mensaje</a>
                                                                            </Col>
                                                                        </Row>
                                                                    </>}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="col-lg-4 col-sm-4 col-md-4">
                                                                <strong>Tel&eacute;fono Trabajo</strong>
                                                            </td>
                                                            <td>
                                                                {editar ?
                                                                    (
                                                                        <Form.Control
                                                                            size="sm"
                                                                            autoComplete="off"
                                                                            type="text"
                                                                            defaultValue={customerdetails.vTelefonoTrabajo}
                                                                            isInvalid={!!errors.vTelefonoTrabajo}
                                                                            {...register("vTelefonoTrabajo",
                                                                                {
                                                                                    required: false,
                                                                                    maxLength: 20,
                                                                                    minLength: 5,
                                                                                    pattern: {
                                                                                        value: /^[+][0-9]+$/,
                                                                                        message: "Tel\u00E9fono incorrecto. Debe iniciar con el prefijo: +"
                                                                                    }
                                                                                })
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        customerdetails.vTelefonoTrabajo
                                                                    )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="col-lg-4 col-sm-4 col-md-4"><strong>Pa&iacute;s</strong></td>
                                                            <td>
                                                                {editar ?
                                                                    (
                                                                        <Form.Select
                                                                            aria-label="Default select"
                                                                            size="sm"
                                                                            defaultValue={customerdetails.vPais}
                                                                            isInvalid={!!errors.vPais}
                                                                            {...register("vPais",
                                                                                {
                                                                                    required: true
                                                                                })
                                                                            }

                                                                            onChange={(event) => { mostrarEstados(event.target.value) }}

                                                                        >
                                                                            {countries.map((country, index) => {
                                                                                return (<option key={index} value={country.vName}>{country.vName}</option>)
                                                                            })}
                                                                        </Form.Select>
                                                                    ) : <><Banderas Height={20} vPais={customerdetails.vPais}></Banderas> {customerdetails.vPais} </>}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="col-lg-4 col-sm-4 col-md-4"><strong>Estado/Provincia</strong></td>
                                                            <td>
                                                                {cargandoEstado ?
                                                                    (
                                                                        <h5>
                                                                            <Spinner className="text-inline" size="sm" animation="border" variant="primary" /> Cargando...
                                                                        </h5>
                                                                    ) : editar ?
                                                                        (
                                                                            <Form.Select
                                                                                size="sm"
                                                                                aria-label="Default select"
                                                                                defaultValue={customerdetails.vEstadoDepProv}
                                                                                isInvalid={!!errors.vEstadoDepProv}
                                                                                {...register("vEstadoDepProv",
                                                                                    {
                                                                                        required: true
                                                                                    })
                                                                                }
                                                                            >
                                                                                <option value="">Selecciona estado/provincia</option>
                                                                                {states.map((st, index) => {
                                                                                    return (<option key={index} value={st.vName}>{st.vName}</option>)
                                                                                })}
                                                                            </Form.Select>
                                                                        ) : (
                                                                            <span>{customerdetails.vEstadoDepProv}</span>
                                                                        )}
                                                            </td>
                                                        </tr>


                                                    </tbody>
                                                </Table>
                                                <hr />
                                                {customerdetails.idCatTipoCliente == 2 &&
                                                    <>
                                                        <Alert variant="primary">

                                                            <h5>CLIENTE DISTRIBUIDOR</h5>
                                                        </Alert>
                                                        <Table responsive striped size="sm" >
                                                            <tbody>
                                                                {documentos.map((doc, i) => {

                                                                    return (
                                                                        <tr key={i}>
                                                                            <td>
                                                                                <Link to={"/api/distribuidor/OnGetFile/?idClienteAkasia=" + customerdetails.idClienteAkasia + "&vNombreArchivo=" + doc.DocName} target="_blank">
                                                                                    <Flex
                                                                                        alignItems="center"
                                                                                        className="border px-2 rounded-3 bg-white dark__bg-1000 my-1 fs--1"

                                                                                    >
                                                                                        <FontAwesomeIcon icon={'file'} className="fs-1" />
                                                                                        <span className="mx-2 flex-1">
                                                                                            {doc.DocName} ({(2000 / 1024).toFixed(2)}kb)
                                                                                        </span>
                                                                                        <span
                                                                                            className="text-300 p-1 ml-auto cursor-pointer"
                                                                                            id={`attachmentTooltip${id}`}
                                                                                        >
                                                                                            <FontAwesomeIcon icon="download" />
                                                                                        </span>
                                                                                    </Flex>
                                                                                </Link>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}

                                                                {
                                                                    documentosCargados.docIDFoto &&
                                                                    <>
                                                                        <tr>
                                                                            <td colSpan={2}>
                                                                                <b>Identificaci&oacute;n con Foto</b>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="file"
                                                                                    size="sm"
                                                                                    required
                                                                                    onChange={(e) => {
                                                                                        setDataFiles({
                                                                                            ...dataFiles,
                                                                                            DocFile: e.target.files[0]
                                                                                        });
                                                                                        setDocumentosCargados(documentosCargados => { return { ...documentosCargados, docCompDomicilio: false, docCartaMotivos: false, docCedFiscal: false } })
                                                                                    }}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                {cargandoFile ? <Button size="sm" variant="primary" disabled>
                                                                                    <Spinner
                                                                                        as="span"
                                                                                        animation="grow"
                                                                                        size="sm"
                                                                                        role="status"
                                                                                        aria-hidden="true"
                                                                                    />
                                                                                    ...
                                                                                </Button> : <Button size="sm" type="button" onClick={() => onSubmitDocFile(1)} variant='primary' >Subir</Button>}

                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                }


                                                                {
                                                                    documentosCargados.docCedFiscal &&
                                                                    <>
                                                                        <tr>
                                                                            <td colSpan={2} >
                                                                                &nbsp;
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>
                                                                                <b>C&eacute;dula Fiscal</b>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="file"
                                                                                    size="sm"
                                                                                    required
                                                                                    onChange={(e) => {
                                                                                        setDataFiles({
                                                                                            ...dataFiles,
                                                                                            DocFile: e.target.files[0]
                                                                                        });
                                                                                        setDocumentosCargados(documentosCargados => { return { ...documentosCargados, docCompDomicilio: false, docCartaMotivos: false, docIDFoto: false } })
                                                                                    }}
                                                                                />
                                                                            </td>
                                                                            <td>

                                                                                {cargandoFile ? <Button size="sm" variant="primary" disabled>
                                                                                    <Spinner
                                                                                        as="span"
                                                                                        animation="grow"
                                                                                        size="sm"
                                                                                        role="status"
                                                                                        aria-hidden="true"
                                                                                    />
                                                                                    ...
                                                                                </Button> : <Button size="sm" type="button" onClick={() => onSubmitDocFile(2)} variant='primary' >Subir</Button>}

                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                }
                                                                {
                                                                    documentosCargados.docCompDomicilio &&
                                                                    <>
                                                                        <tr>
                                                                            <td colSpan={2} >
                                                                                &nbsp;
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>
                                                                                <b>Comprobante de Domicilio</b>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="file"
                                                                                    size="sm"
                                                                                    required
                                                                                    onChange={(e) => {
                                                                                        setDataFiles({
                                                                                            ...dataFiles,
                                                                                            DocFile: e.target.files[0]
                                                                                        });
                                                                                        setDocumentosCargados(documentosCargados => { return { ...documentosCargados, docIDFoto: false, docCartaMotivos: false, docCedFiscal: false } })
                                                                                    }}
                                                                                />
                                                                            </td>

                                                                            <td>
                                                                                {cargandoFile ? <Button size="sm" variant="primary" disabled>
                                                                                    <Spinner
                                                                                        as="span"
                                                                                        animation="grow"
                                                                                        size="sm"
                                                                                        role="status"
                                                                                        aria-hidden="true"
                                                                                    />
                                                                                    ...
                                                                                </Button> : <Button size="sm" type="button" onClick={() => onSubmitDocFile(3)} variant='primary' >Subir</Button>}

                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                }
                                                                {
                                                                    documentosCargados.docCartaMotivos &&
                                                                    <>
                                                                        <tr>
                                                                            <td colSpan={2} >
                                                                                &nbsp;
                                                                            </td>
                                                                        </tr>
                                                                        <tr >
                                                                            <td colSpan={2} >
                                                                                <b>Carta Motivos</b>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="file"
                                                                                    size="sm"
                                                                                    required
                                                                                    onChange={(e) => {
                                                                                        setDataFiles({
                                                                                            ...dataFiles,
                                                                                            DocFile: e.target.files[0]
                                                                                        });
                                                                                        setDocumentosCargados(documentosCargados => { return { ...documentosCargados, docCompDomicilio: false, docIDFoto: false, docCedFiscal: false } })
                                                                                    }}
                                                                                />
                                                                            </td>
                                                                            <td>

                                                                                {cargandoFile ? <Button size="sm" variant="primary" disabled>
                                                                                    <Spinner
                                                                                        as="span"
                                                                                        animation="grow"
                                                                                        size="sm"
                                                                                        role="status"
                                                                                        aria-hidden="true"
                                                                                    />
                                                                                    ...
                                                                                </Button> : <Button size="sm" type="button" onClick={() => onSubmitDocFile(4)} variant='primary' >Subir</Button>}

                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                }

                                                            </tbody>
                                                        </Table>
                                                    </>
                                                }
                                            </Col>
                                            <Col className="col-lg-6 col-12">
                                                <h5 className="fw-semi-bold ls mb-3 text-uppercase"> <FontAwesomeIcon icon="file" className="ms-2" />  INFORMACI&Oacute;N FISCAL</h5>
                                                <Table responsive striped size="sm"  >
                                                    <tbody>
                                                        {editar &&
                                                            <>
                                                                <tr>
                                                                    <td colSpan={2}>
                                                                        <div className="d-flex mb-0 align-items-center">
                                                                            <ButtonGroup>
                                                                                <ToggleButton
                                                                                    type="radio"
                                                                                    id="rdD_1"
                                                                                    variant={'outline-success'}
                                                                                    name="radioDist"
                                                                                    value={true}
                                                                                    checked={isDistribucion === true}
                                                                                    size="sm"
                                                                                    onChange={() => {
                                                                                        setIsDistribucion(true); // Establece bIsDistribucion en true
                                                                                    }}
                                                                                >
                                                                                    SI
                                                                                </ToggleButton>

                                                                                <ToggleButton
                                                                                    type="radio"
                                                                                    id="rdD_2"
                                                                                    variant={'outline-danger'}
                                                                                    name="radioDist"
                                                                                    value={false}
                                                                                    checked={isDistribucion === false}
                                                                                    size="sm"
                                                                                    onChange={() => {
                                                                                        setIsDistribucion(false); // Establece bIsDistribucion en false
                                                                                    }}
                                                                                >
                                                                                    NO
                                                                                </ToggleButton>

                                                                            </ButtonGroup>
                                                                            <span className="mb-1 ms-1 fw-bold"> USAR INFORMACI&Oacute;N FISCAL DE DISTRIBUIDOR</span>
                                                                        </div>
                                                                        <hr className="mt-0"></hr>

                                                                    </td>
                                                                </tr>
                                                                {isDistribucion &&
                                                                    <>
                                                                        <tr>
                                                                            <td colSpan={2}>
                                                                                <Select
                                                                                    className="basic-single"
                                                                                    closeMenuOnSelect={true}
                                                                                    options={distribuidores}
                                                                                    isSearchable={true}
                                                                                    placeholder='Selecciona...'
                                                                                    classNamePrefix="react-select"
                                                                            onChange={(e) => {
                                                                                setValue('vCp', e.vCp);
                                                                                setValue('vRfc', e.vRfc);
                                                                                setValue('vRazonSocial', e.vRazonSocial);
                                                                                setValue('idCatRegimenFiscal', e.idCatRegimenFiscal);
                                                                                setValue('vNumRegTrib', e.vNumRegTrib);
                                                                                setValue('vDireccionFiscal', e.vDireccionFiscal);
                                                                                setValue('idCatUsoCFDI', e.idCatUsoCFDI);
                                                                                setValue('vEmailFacturacion', e.vEmailFacturacion);
                                                                                setValue('vTelefonoFacturacion', e.vTelefonoFacturacion);

                                                                                 }}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>
                                                                                &nbsp;
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                        <tr>
                                                            <td className="col-lg-4 col-sm-4 col-md-4"><strong>RFC</strong></td>
                                                            <td>{editar ? (
                                                                <>
                                                                    <Form.Control
                                                                        size="sm"
                                                                        autoComplete="off"
                                                                        type="text"
                                                                        onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                                        defaultValue={customerdetails.vRfc}
                                                                        isInvalid={!!errors.vRfc}
                                                                        {...register('vRfc',
                                                                            {
                                                                                required: 'El RFC es obligatorio',
                                                                                maxLength: 13,
                                                                                minLength: 12,
                                                                                pattern: /^[A-Z�&]{3,4}\d{6}(?:[A-Z\d]{3})?$/
                                                                            })
                                                                        }
                                                                        onChange={(event) => {
                                                                            let rfc = (event.target.value).trim().toUpperCase();
                                                                            if (rfc === "XEXX010101000" || rfc === "XAXX010101000") {
                                                                                setValue('vCp', '61770');
                                                                            }
                                                                        }}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {errors.vRfc && errors.vRfc.message}
                                                                    </Form.Control.Feedback>
                                                                </>
                                                            ) : (
                                                                    <Row>
                                                                        <Col>
                                                                            <span>{customerdetails.vRfc} </span>
                                                                        </Col>
                                                                        <Col xs="auto">
                                                                            {customerdetails.vRfc == 'XEXX010101000' &&
                                                                                <>
                                                                                    <img src="/img/extranjero48.png" Width={20}></img>
                                                                                    <Badge bg="primary">EXTRANJERO</Badge>
                                                                                </>
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                
                                                            )}</td>

                                                        </tr>
                                                        <tr>
                                                            <td className="col-lg-4 col-sm-4 col-md-4"><strong>Raz&oacute;n Social</strong></td>
                                                            <td>
                                                                {editar ? (
                                                                    <>
                                                                        <Form.Control
                                                                            size="sm"
                                                                            autoComplete="off"
                                                                            type="text"
                                                                            onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                                            defaultValue={customerdetails.vRazonSocial}
                                                                            isInvalid={!!errors.vRazonSocial}
                                                                            {...register('vRazonSocial',
                                                                                {
                                                                                    required: 'La Raz\u00f3n Social es  obligatoria',
                                                                                    maxLength: 300,
                                                                                    minLength: 1,
                                                                                    pattern: /[^|]{1,300}/
                                                                                })
                                                                            }
                                                                        />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {errors.vRazonSocial && errors.vRazonSocial.message}
                                                                        </Form.Control.Feedback>
                                                                    </>
                                                                ) : (
                                                                    customerdetails.vRazonSocial
                                                                )}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="col-lg-4 col-sm-4 col-md-4"><strong>R&eacute;gimen Fiscal</strong></td>
                                                            <td>
                                                                {editar ?
                                                                    (
                                                                        <Form.Select
                                                                            size="sm"
                                                                            aria-label="Default select"
                                                                            defaultValue={customerdetails.idCatRegimenFiscal}
                                                                            isInvalid={!!errors.idCatRegimenFiscal}
                                                                            {...register("idCatRegimenFiscal",
                                                                                {
                                                                                    required: true
                                                                                })
                                                                            }
                                                                        >
                                                                            <option value="">Selecciona un r&eacute;gimen</option>
                                                                            {tablaRegimenFiscal.map((regimen, index) => {
                                                                                return (
                                                                                    <option
                                                                                        key={index}
                                                                                        value={regimen.idCatRegimenFiscal}>
                                                                                        {regimen.vCodigoRegimen} - {regimen.vDescripcionRegimenFiscal}
                                                                                    </option>)
                                                                            })}
                                                                        </Form.Select>

                                                                    ) : (
                                                                        <span>
                                                                            {customerdetails.vCodigoRegimen}  -  {customerdetails.vDescripcionRegimenFiscal}
                                                                        </span>
                                                                    )}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="col-lg-4 col-sm-4 col-md-4">
                                                                <strong>Num. Reg. Trib.</strong>
                                                            </td>
                                                            <td>
                                                                {editar ? (
                                                                    <>
                                                                        <Form.Control
                                                                            size="sm"
                                                                            autoComplete="off"
                                                                            type="text"
                                                                            onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                                            defaultValue={customerdetails.vNumRegTrib}
                                                                            isInvalid={!!errors.vNumRegTrib}
                                                                            {...register('vNumRegTrib',
                                                                                {
                                                                                    maxLength: 40,
                                                                                    minLength: 1
                                                                                })
                                                                            }
                                                                        />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {errors.vNumRegTrib && errors.vNumRegTrib.message}
                                                                        </Form.Control.Feedback>
                                                                    </>
                                                                ) : (
                                                                    customerdetails.vNumRegTrib
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="col-lg-4 col-sm-4 col-md-4">
                                                                <strong>Direcci&oacute;n Fiscal</strong>
                                                            </td>
                                                            <td>
                                                                {editar ? (
                                                                    <>
                                                                        <Form.Control
                                                                            as="textarea"
                                                                            rows={3}
                                                                            size="sm"
                                                                            autoComplete="off"
                                                                            onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                                            defaultValue={customerdetails.vDireccionFiscal}
                                                                            isInvalid={!!errors.vDireccionFiscal}
                                                                            {...register('vDireccionFiscal',
                                                                                {
                                                                                    required: 'La direccion fiscal es obligatorio',
                                                                                    maxLength: 300,
                                                                                    minLength: 10
                                                                                })
                                                                            }
                                                                        />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {errors.vDireccionFiscal && errors.vDireccionFiscal.message}
                                                                        </Form.Control.Feedback>
                                                                    </>
                                                                ) : (
                                                                    customerdetails.vDireccionFiscal
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="col-lg-4 col-sm-4 col-md-4">
                                                                <strong>C&oacute;digo Postal</strong>
                                                            </td>
                                                            <td>
                                                                {editar ? (
                                                                    <>
                                                                        <Form.Control
                                                                            size="sm"
                                                                            autoComplete="off"
                                                                            type="number"
                                                                            onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                                            defaultValue={customerdetails.vCp}
                                                                            isInvalid={!!errors.vCp}
                                                                            {...register('vCp',
                                                                                {
                                                                                    required: 'El Codigo postal es obligatorio',
                                                                                    maxLength: 6,
                                                                                    minLength: 4
                                                                                })
                                                                            }
                                                                        />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {errors.vCp && errors.vCp.message}
                                                                        </Form.Control.Feedback>
                                                                    </>
                                                                ) : (
                                                                    customerdetails.vCp
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="col-lg-4 col-sm-4 col-md-4">
                                                                <strong>Uso CFDI</strong>
                                                            </td>
                                                            <td>
                                                                {editar ?
                                                                    (
                                                                        <Form.Select
                                                                            size="sm"
                                                                            aria-label="Default select"
                                                                            defaultValue={customerdetails.idCatUsoCFDI}
                                                                            isInvalid={!!errors.idCatUsoCFDI}
                                                                            {...register("idCatUsoCFDI",
                                                                                {
                                                                                    required: true
                                                                                })
                                                                            }
                                                                        >
                                                                            <option value="">Selecciona un uso de CFDI</option>
                                                                            {tablaUsoCFDI.map((uso, index) => {
                                                                                return (
                                                                                    <option
                                                                                        key={index}
                                                                                        value={uso.idCatUsoCFDI}>
                                                                                        {uso.vCodigoUso} - {uso.vDescripcionUsoCFDI}
                                                                                    </option>)
                                                                            })}
                                                                        </Form.Select>
                                                                    ) : (
                                                                        <span>{customerdetails.vCodigoUso}  -  {customerdetails.vDescripcionUsoCFDI} </span>
                                                                    )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="col-lg-4 col-sm-4 col-md-4">
                                                                <strong>Correo de facturaci&oacute;n</strong>
                                                            </td>
                                                            <td>
                                                                {editar ? (
                                                                    <>
                                                                        <Form.Control
                                                                            size="sm"
                                                                            autoComplete="off"
                                                                            type="text"
                                                                            defaultValue={customerdetails.vEmailFacturacion}
                                                                            isInvalid={!!errors.vEmailFacturacion}
                                                                            {...register("vEmailFacturacion",
                                                                                {
                                                                                    maxLength: 120,
                                                                                    minLength: 5,
                                                                                    pattern: {
                                                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                                                        message: "Email incorrecto."
                                                                                    }
                                                                                })
                                                                            }
                                                                        />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {errors.vEmailFacturacion && errors.vEmailFacturacion.message}
                                                                        </Form.Control.Feedback>
                                                                    </>
                                                                ) : (
                                                                    customerdetails.vEmailFacturacion
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="col-lg-4 col-sm-4 col-md-4">
                                                                <strong>Tel&eacute;fono de facturaci&oacute;n</strong>
                                                            </td>
                                                            <td>
                                                                {editar ?
                                                                    (
                                                                        <>
                                                                            <Form.Control
                                                                                size="sm"
                                                                                autoComplete="off"
                                                                                type="text"
                                                                                defaultValue={customerdetails.vTelefonoFacturacion}
                                                                                isInvalid={!!errors.vTelefonoFacturacion}
                                                                                {...register("vTelefonoFacturacion",
                                                                                    {
                                                                                        required: false,
                                                                                        maxLength: 20,
                                                                                        minLength: 5,
                                                                                        pattern: {
                                                                                            value: /^[+][0-9]+$/,
                                                                                            message: "Tel\u00E9fono incorrecto. Debe iniciar con el prefijo: +"
                                                                                        }
                                                                                    })
                                                                                }
                                                                            />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {errors.vTelefonoFacturacion && errors.vTelefonoFacturacion.message}
                                                                            </Form.Control.Feedback>
                                                                        </>
                                                                    ) : (
                                                                        customerdetails.vTelefonoFacturacion
                                                                    )}
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </Table>
                                            </Col>
                                            <Col className="col-12">

                                            </Col>
                                        </Row>
                                    </Card.Body>

                                </Card>
                            </Col>
                            {leadsVinculados.length > 0 &&
                                <Col lg={12}>{/*Leads*/}
                                    <Card>

                                        <Card.Header>
                                            <Row className="align-items-center">
                                                <Col>
                                                    <h5 className="mb-0">Leads</h5>
                                                </Col>
                                                <Col xs="auto">

                                                </Col>
                                            </Row>
                                        </Card.Header>

                                        <Card.Body className="border-top fs--1">
                                            <Row>
                                                {

                                                    leadsVinculados.map(
                                                        (lead, index) => {
                                                            return (

                                                                <Col key={index} className="col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                                    <Row className={"ms-1"}>
                                                                        <Col className="col-12 m-0 p-0 ">

                                                                            <Link to={"#"} onClick={() => { setIdLead(lead.idLead); setShowModalSeguimientoLead(true); }}>
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={
                                                                                        <Tooltip style={{ position: 'fixed' }} >
                                                                                            Ver informacion de este lead
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <FontAwesomeIcon

                                                                                        icon="user"
                                                                                        transform="shrink-2"
                                                                                        className="ms-1"
                                                                                    />
                                                                                </OverlayTrigger>
                                                                                &nbsp;
                                                                                {"Lead #" + lead.idLead}
                                                                            </Link>

                                                                        </Col>
                                                                    </Row>
                                                                    <Row className={"ms-1"}>
                                                                        <Col className="col-12 m-0 p-0 ">
                                                                            <SoftBadge pill bg={
                                                                                lead.idEtapa == 1 ? "secondary" :
                                                                                    lead.idEtapa == 2 ? "primary" :
                                                                                        lead.idEtapa == 3 ? "warning" :
                                                                                            lead.idEtapa == 4 ? "success" :
                                                                                                lead.idEtapa == 5 ? "danger" :
                                                                                                    lead.idEtapa == 6 ? "warning" :
                                                                                                        lead.idEtapa == 7 ? "success" : "success"
                                                                            }
                                                                            >
                                                                                <span className="mb-0 fs--2 p-0">
                                                                                    {lead.vNombreEtapa}
                                                                                </span>
                                                                            </SoftBadge>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className={"ms-1"}>
                                                                        <Col className="col-12 m-0 p-0 ">
                                                                            <img src="/img/vendedor35.png" width={20} /> {lead.vNombre}
                                                                        </Col>
                                                                    </Row>
                                                                </Col>

                                                            )
                                                        }
                                                    )
                                                }
                                            </Row>
                                        </Card.Body>

                                    </Card>
                                </Col>
                            }

                            {
                                orden.length > 0 &&
                                <Col lg={12}>{/*Ordenes*/}
                                    <Card>

                                        <Card.Header>
                                            <Row className="align-items-center">
                                                <Col>
                                                    <h5 className="mb-0">&Oacute;rdenes</h5>
                                                </Col>
                                                <Col xs="auto">

                                                </Col>
                                            </Row>
                                        </Card.Header>

                                        <Card.Body className="border-top fs--1">
                                            <Row>

                                                {
                                                    orden.map(
                                                        (o, index) => {
                                                            return (
                                                                <Col key={index} className="col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">

                                                                    <Row className={!o.bIsActive ? "ms-1" : "ms-1"} >
                                                                        <Col className="col-4 m-0 p-0 ">
                                                                            {
                                                                                !o.bIsActive &&
                                                                                <>
                                                                                    <span className="text-danger">
                                                                                        <OverlayTrigger
                                                                                            placement="top"
                                                                                            overlay={
                                                                                                <Tooltip style={{ position: 'fixed' }} >
                                                                                                    Cancelada
                                                                                                </Tooltip>
                                                                                            }
                                                                                        >
                                                                                            <FontAwesomeIcon

                                                                                                icon="ban"
                                                                                                transform="shrink-2"
                                                                                                className="ms-1"
                                                                                            />
                                                                                        </OverlayTrigger>


                                                                                    </span>

                                                                                </>
                                                                            }
                                                                            <Link style={{ fontSize: "0.8em" }} className="m-0" to={"/ordenes/detalle/" + o.idOrden} >

                                                                                <img src="/img/ordenes96.png" height={20}></img>

                                                                                ORDEN #{o.idOrden}

                                                                            </Link>



                                                                        </Col>
                                                                        <Col className="col-4 m-0 p-0">
                                                                            <span className="fw-bold">
                                                                                {o.dTotal.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })} {o.vMoneda}
                                                                            </span>
                                                                        </Col>
                                                                        <Col className="col-4 m-0 p-0" style={{ fontSize: "0.8em" }}>
                                                                            <span >
                                                                                {new Date(o.dFechaVenta).toLocaleDateString('es-mx', { year: 'numeric', month: 'short', day: 'numeric' })}


                                                                            </span>
                                                                        </Col>


                                                                    </Row>

                                                                </Col>




                                                            )
                                                        }
                                                    )
                                                }

                                            </Row>
                                        </Card.Body>

                                    </Card>
                                </Col>
                            }


                        </Row>
                        {agrupacionLicencias.length > 0 &&
                            <Row className="g-3 mb-3">{/*Servicios contratados*/}
                                <Col lg={12}>
                                    <Card className="mt-3">
                                        <Card.Header className="border-bottom border-200">
                                            <h5 className="mb-0">Servicios Contratados</h5>
                                        </Card.Header>
                                        <Card.Body>
                                            <Row className="gx-3">
                                                <ListGroup variant="flush" className="fs--3">
                                                    {agrupacionLicencias.map((LicAgrupada, index) => {
                                                        const newArrayLicencias = licencias.filter(lic => lic.idCatTipoLicencia == LicAgrupada.idCatTipoLicencia && lic.bIsPeriodoPrueba == LicAgrupada.bIsPeriodoPrueba && lic.bTieneOrdenVinculada == LicAgrupada.bTieneOrdenVinculada);
                                                        console.log("\n newArrayLicencias " + index + ": ", newArrayLicencias);
                                                        const tieneLicenciaSinOrden = newArrayLicencias.some(detalles => detalles.idOrden == null);
                                                        console.log("Tiene Licencias Sin Orden: ", tieneLicenciaSinOrden);
                                                        return (
                                                            <ListGroup.Item key={index}>
                                                                <Accordion defaultActiveKey={['0']} >
                                                                    <Accordion.Item >
                                                                        <Accordion.Header>
                                                                            <Badge bg="success" className="me-2 dark__bg-dark"> {LicAgrupada.NumLic} </Badge> {LicAgrupada.vDescripcionTipo}&nbsp;
                                                                            {LicAgrupada.bIsPeriodoPrueba && <Badge bg="danger" className="me-2 dark__bg-dark"> {LicAgrupada.bTieneOrdenVinculada ? "No Activas" : "Prueba"} </Badge>}
                                                                            {!LicAgrupada.bIsPeriodoPrueba && !LicAgrupada.bTieneOrdenVinculada && <Badge bg="warning" className="me-2 dark__bg-dark"> Sin Orden </Badge>}

                                                                        </Accordion.Header>
                                                                        <Accordion.Body>
                                                                            <Row>
                                                                                {newArrayLicencias.map((detalle, i) => {
                                                                                    var newArrayPolizas = polizas.filter(pol => pol.idLicencia == detalle.idLicencia);
                                                                                    console.log("newArrayPolizas: ", newArrayPolizas);
                                                                                    return (
                                                                                        <Col key={i} md={6} xxl={4} className="border-200 border-md-bottom-0 border-end pt-1 pb-md-0 ps-md-3">
                                                                                            <div className="hover-bg-100 py-x1 text-center rounded-3 position-relative">
                                                                                                <Row>
                                                                                                    <Col><span className="mt-1 text-600"><img src="/img/vendedor35.png"></img>&nbsp;&nbsp;{detalle.vNombreVendedor}</span></Col>
                                                                                                    <Col>
                                                                                                        {detalle.idCatTipoLicencia == 1 &&
                                                                                                            <img src="/img/servidor48.png"></img>}
                                                                                                        {detalle.idCatTipoLicencia == 2 &&
                                                                                                            <img src="/img/caja48.png"></img>}
                                                                                                        {detalle.idCatTipoLicencia == 3 &&
                                                                                                            <img src="/img/nube48.png"></img>}
                                                                                                    </Col>
                                                                                                    <Col>
                                                                                                        {
                                                                                                            newArrayPolizas == null || newArrayPolizas.length == 0 ?
                                                                                                                <></>
                                                                                                                :
                                                                                                                <>
                                                                                                                    {newArrayPolizas[0].status == 1 &&
                                                                                                                        <>
                                                                                                                            <OverlayTrigger
                                                                                                                                overlay={
                                                                                                                                    <Tooltip style={{ position: 'fixed' }} >
                                                                                                                                        <strong>P&Oacute;LIZA SOPORTE T&Eacute;CNICO</strong>
                                                                                                                                        <br></br>
                                                                                                                                        <span>
                                                                                                                                            {
                                                                                                                                                "Vence" + " " + new Date(newArrayPolizas[0].dFechaVencimiento).toLocaleDateString('es-MX', {
                                                                                                                                                    year: 'numeric', month: 'long', day: '2-digit'
                                                                                                                                                })
                                                                                                                                            }
                                                                                                                                        </span>
                                                                                                                                    </Tooltip>
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <img src="/img/polizaActiva.png"></img >
                                                                                                                            </OverlayTrigger>
                                                                                                                        </>
                                                                                                                    }
                                                                                                                    {newArrayPolizas[0].status == 2 &&
                                                                                                                        <>
                                                                                                                            <OverlayTrigger
                                                                                                                                overlay={
                                                                                                                                    <Tooltip style={{ position: 'fixed' }} >
                                                                                                                                        <strong>P&Oacute;LIZA SOPORTE T&Eacute;CNICO</strong>
                                                                                                                                        <br></br>
                                                                                                                                        <span>
                                                                                                                                            {
                                                                                                                                                "Vence" + " " + new Date(newArrayPolizas[0].dFechaVencimiento).toLocaleDateString('es-MX', {
                                                                                                                                                    year: 'numeric', month: 'long', day: '2-digit'
                                                                                                                                                })
                                                                                                                                            }
                                                                                                                                        </span>
                                                                                                                                    </Tooltip>
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <img src="/img/polizaPorVencer.png"></img >
                                                                                                                            </OverlayTrigger>
                                                                                                                        </>
                                                                                                                    }
                                                                                                                    {newArrayPolizas[0].status == 3 &&
                                                                                                                        <>
                                                                                                                            <OverlayTrigger
                                                                                                                                overlay={
                                                                                                                                    <Tooltip style={{ position: 'fixed' }} >
                                                                                                                                        <strong>P&Oacute;LIZA SOPORTE T&Eacute;CNICO</strong>
                                                                                                                                        <br></br>
                                                                                                                                        <span>Vencida</span>
                                                                                                                                    </Tooltip>
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <img src="/img/polizaVencida.png"></img >
                                                                                                                            </OverlayTrigger>
                                                                                                                        </>
                                                                                                                    }
                                                                                                                    {newArrayPolizas[0].status == 4 &&
                                                                                                                        <>
                                                                                                                            <OverlayTrigger
                                                                                                                                overlay={
                                                                                                                                    <Tooltip style={{ position: 'fixed' }} >
                                                                                                                                        <strong>P&Oacute;LIZA SOPORTE T&Eacute;CNICO</strong>
                                                                                                                                        <br></br>
                                                                                                                                        <span>Inactiva</span>
                                                                                                                                    </Tooltip>
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <img src="./img/polizaVencida.png"></img >
                                                                                                                            </OverlayTrigger>
                                                                                                                        </>
                                                                                                                    }
                                                                                                                </>
                                                                                                        }
                                                                                                    </Col>
                                                                                                </Row>
                                                                                                <h5 className="mt-3 mb-1">
                                                                                                    <Link to={"/licencias/detalle/" + detalle.idLicencia}>
                                                                                                        {LicAgrupada.vDescripcionTipo}
                                                                                                    </Link>
                                                                                                </h5>
                                                                                                <h5 className="w-75 mx-auto text-600">{detalle.idLicencia}</h5>


                                                                                                <Table responsive size="sm"  >
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td className="col-lg-3 col-sm-3 col-md-3"><h6 className="mt-1 text-600">Fecha Inicio:</h6></td>
                                                                                                            <td><h6 className="mt-1 text-600">{detalle.dInicioLicenciaUso} <SoftBadge bg='primary' className='me-2'>hace {detalle.dDiasTranscurridos} d&iacute;as</SoftBadge></h6></td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td className="col-lg-3 col-sm-3 col-md-3"><h6 className="mt-1 text-600">&Uacute;ltima Conex:</h6></td>
                                                                                                            <td><h6 className="mt-1 text-600">{detalle.dUltimaConexion} <SoftBadge bg='warning' className='me-2'>hace {detalle.dDiasTransConexion} d&iacute;as</SoftBadge></h6></td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td colSpan={2}
                                                                                                                className="col-lg-3 col-sm-3 col-md-3"><h6 className="mt-1 text-900">::: UUID LICENCIA :::</h6></td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td colSpan={2}><h6 className="mt-1 text-900">{detalle.vUUIDLicencia}</h6></td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td colSpan={2} className="col-lg-3 col-sm-3 col-md-3"><h6 className="mt-1 text-900">::: UUID EMPRESA :::</h6></td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td colSpan={2}><h6 className="mt-1 text-1000">{detalle.vUUIDEmpresa}</h6></td>
                                                                                                        </tr>
                                                                                                        {!detalle.bCancelado &&
                                                                                                            <>
                                                                                                                <tr>
                                                                                                                    <td className="col-lg-3 col-sm-3 col-md-3"><h6 className="mt-1 text-600">Estado:</h6></td>
                                                                                                                    <td>
                                                                                                                        <h6 className="mt-1 text-600">
                                                                                                                            {!detalle.bIsPeriodoPrueba ?
                                                                                                                                (
                                                                                                                                    <>
                                                                                                                                        <SoftBadge bg='success' className='me-2'>A C T I V A</SoftBadge>
                                                                                                                                        <img src="/img/activo.png"></img>
                                                                                                                                    </>
                                                                                                                                )
                                                                                                                                :
                                                                                                                                (
                                                                                                                                    <>
                                                                                                                                        <SoftBadge bg='danger' className='me-2'> {!detalle.idOrden ? "P R U E B A" : "NO ACTIVADA"}</SoftBadge>
                                                                                                                                        <img src="/img/noactivo.png"></img>
                                                                                                                                    </>
                                                                                                                                )

                                                                                                                            }
                                                                                                                            {detalle.vDatosPCAutorizada ? (
                                                                                                                                <>
                                                                                                                                    &nbsp;<OverlayTrigger
                                                                                                                                        placement="top"
                                                                                                                                        overlay={
                                                                                                                                            <Tooltip style={{ position: 'fixed' }} >
                                                                                                                                                <strong>{detalle.vDatosPCAutorizada}</strong>
                                                                                                                                            </Tooltip>
                                                                                                                                        }
                                                                                                                                    >
                                                                                                                                        <img src="/img/pcautorizada25.png"></img>
                                                                                                                                    </OverlayTrigger>
                                                                                                                                </>
                                                                                                                            ) : (<SoftBadge bg='danger' className='me-2'>NO ASIGNADA A PC</SoftBadge>)}
                                                                                                                        </h6>

                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td className="col-lg-3 col-sm-3 col-md-3"><h6 className="mt-1 text-600">D&iacute;as restantes:</h6></td>
                                                                                                                    <td>
                                                                                                                        <h6 className="mt-1 text-600">
                                                                                                                            {detalle.dDiasRestantes > 0 ?
                                                                                                                                detalle.dDiasRestantes > 1500 ?
                                                                                                                                    (
                                                                                                                                        <>
                                                                                                                                            <SoftBadge bg='success' className='me-2'> P E R M A N E N T E </SoftBadge>
                                                                                                                                        </>
                                                                                                                                    )
                                                                                                                                    :
                                                                                                                                    (
                                                                                                                                        <>
                                                                                                                                            <SoftBadge bg='success' className='me-2'>Restan {detalle.dDiasRestantes} d&iacute;as</SoftBadge>
                                                                                                                                        </>
                                                                                                                                    )
                                                                                                                                :
                                                                                                                                (
                                                                                                                                    <>
                                                                                                                                        <SoftBadge bg='danger' className='me-2'>V E N C I D A </SoftBadge>
                                                                                                                                    </>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        </h6>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td colSpan={2}>
                                                                                                                        {detalle.idOrden &&
                                                                                                                            <Alert variant="primary" className="m-0 pt-2 pb-0">
                                                                                                                                <Alert.Heading className="fs--1">
                                                                                                                                    <Row>
                                                                                                                                    </Row>
                                                                                                                                    <Col>
                                                                                                                                        <Link to={"/ordenes/detalle/" + detalle.idOrden}>ORDEN #{detalle.idOrden} | ${parseFloat(detalle.dPrecioUnitario + (detalle.dPrecioUnitario * 0.16)).toLocaleString("es-MX", {
                                                                                                                                            maximumFractionDigits: 2,
                                                                                                                                            minimumFractionDigits: 2
                                                                                                                                        })}  {detalle.vMoneda} |
                                                                                                                                            <br></br>COMPRADA EL {detalle.dFechaVenta}</Link>
                                                                                                                                    </Col>
                                                                                                                                </Alert.Heading>

                                                                                                                            </Alert>}
                                                                                                                    </td>

                                                                                                                </tr>
                                                                                                            </>
                                                                                                        }
                                                                                                    </tbody>
                                                                                                </Table>
                                                                                                {detalle.bCancelado &&
                                                                                                    <Row className="align-items-center">
                                                                                                        <Col>
                                                                                                            <SoftBadge bg='danger' className='me-2'>C A N C E L A D A </SoftBadge>
                                                                                                            <Alert variant="danger">
                                                                                                                <p className="fs--1"><b>MOTIVO:</b> {detalle.vMotivoCancelacion}</p>
                                                                                                                <p className="fs--1"><b>CANCEL&Oacute;:</b> {detalle.vUsuarioCancelador}</p>
                                                                                                            </Alert>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                }
                                                                                                {!detalle.bCancelado &&
                                                                                                    <>
                                                                                                        <IconButton
                                                                                                            iconClassName="fs--2 me-1"
                                                                                                            variant="falcon-default"
                                                                                                            size="sm"
                                                                                                            icon="file"
                                                                                                            className="m-2"
                                                                                                        >
                                                                                                            Descargar licencia
                                                                                                        </IconButton>
                                                                                                        <Link to={"/licencias/detalle/" + detalle.idLicencia}>
                                                                                                            <IconButton
                                                                                                                iconClassName="fs--2 me-1"
                                                                                                                variant="falcon-default"
                                                                                                                size="sm"
                                                                                                                icon="eye"
                                                                                                                className="m-2"
                                                                                                            >
                                                                                                                Ir a detalles
                                                                                                            </IconButton>
                                                                                                        </Link>
                                                                                                    </>
                                                                                                }

                                                                                            </div>
                                                                                        </Col>
                                                                                    )
                                                                                })}
                                                                            </Row>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                </Accordion>
                                                            </ListGroup.Item>
                                                        )
                                                    })}
                                                </ListGroup>

                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        }

                        {/*<Row className="g-3 mb-3">*/}{/*Registrar soporte tecnico*/}
                        {/*    <Col lg={12}>*/}
                        {/*        <Card className="mb-3">*/}
                        {/*            <Card.Header className="d-flex flex-between-center">*/}

                        {/*                <Flex>*/}
                        {/*                    <IconButton*/}
                        {/*                        variant="falcon-default"*/}
                        {/*                        size="sm"*/}
                        {/*                        icon="tasks"*/}
                        {/*                        transform="shrink-2"*/}
                        {/*                        iconAlign="middle"*/}
                        {/*                        className="d-xl-none"*/}
                        {/*                    >*/}
                        {/*                        <span className="ms-1">To-do</span>*/}
                        {/*                    </IconButton>*/}
                        {/*                    <div*/}
                        {/*                        className="bg-300 mx-3 d-xl-none"*/}
                        {/*                        style={{ width: '1px', height: '29px' }}*/}
                        {/*                    ></div>*/}
                        {/*                    <IconButton*/}
                        {/*                        variant="falcon-default"*/}
                        {/*                        size="sm"*/}
                        {/*                        icon="edit"*/}
                        {/*                        transform="shrink-2"*/}
                        {/*                        iconAlign="middle"*/}
                        {/*                        className="me-2"*/}
                        {/*                        onClick={() => obtenerComboBoxSoporteTecnico()}*/}
                        {/*                    >*/}
                        {/*                        <span className="d-none d-xl-inline-block ms-1">Registrar Soporte T&eacute;cnico</span>*/}
                        {/*                    </IconButton>*/}

                        {/*                </Flex>*/}
                        {/*            </Card.Header>*/}
                        {/*            <Card.Body className="bg-light">*/}
                        {/*                <div className="timeline-vertical py-0">*/}
                        {/*                    {*/}
                        {/*                        timeline != null &&*/}
                        {/*                        <Timeline timeline={timeline} />*/}

                        {/*                    }*/}


                        {/*                </div>*/}
                        {/*            </Card.Body>*/}
                        {/*        </Card>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}

                        {agrupacionLicencias.length > 0 &&
                            <Row className="g-3 mb-3">{/*Comentarios de Soporte*/}
                                <Col className="col-12">
                                    <Card className="mb-3">

                                        <Card.Header className="border-bottom border-200">
                                            <h5 className="mb-0">Soporte T&eacute;cnico</h5>
                                        </Card.Header>

                                        <Card.Body className="bg-light ">

                                            <Form id="comment" onSubmit={(e) => {
                                                e.preventDefault();
                                                crearComentarioSoporte();
                                            }}>
                                                <div className="d-flex align-items-start  mb-2">
                                                    <Avatar
                                                        src={profileImage}
                                                        size="xl" />
                                                    <Form.Control
                                                        type="text"
                                                        className=" ms-2 "
                                                        placeholder="Describe el soporte..."
                                                        rows={3}
                                                        value={problemaSoporte}
                                                        style={{ resize: "none" }}
                                                        maxLength={999}
                                                        minLength={15}
                                                        onChange={e => setProblemaSoporte(e.target.value)}

                                                        onKeyDown={
                                                            (e) => {
                                                                if (e.key === 'Enter') {
                                                                    e.preventDefault();

                                                                    if (e.target.value.length >= 15) {
                                                                        crearComentarioSoporte();
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    />
                                                </div>

                                                {/*<Button className="mt-2 mb-2" variant="primary" onClick={() => crearComentarioSoporte()} >*/}
                                                {/*    Enviar*/}
                                                {/*</Button>*/}
                                            </Form>

                                            {
                                                <ComentariosSoporte
                                                    comentariosProblemasSoporte={comentariosProblemasSoporte}
                                                />
                                            }
                                        </Card.Body>
                                        <Card.Footer className="border-top text-end">

                                        </Card.Footer>
                                    </Card>
                                </Col>
                            </Row>
                        }

                        <Row className="g-3 mb-3">{/*historial de movimientos/logs*/}
                            <Col lg={12}>

                                <HistorialMovimientosCliente clienteActivityLogs={clienteActivityLogs}></HistorialMovimientosCliente>

                            </Col>
                        </Row>
                    </Form>

                    <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>
                    <MensajeInformativo setShowMensajeInformativo={setShowMensajeInformativo} showMensajeInformativo={showMensajeInformativo} mensajeInformativo={mensajeInformativo}></MensajeInformativo>
                    <ToastContainer />

                    {showModalNuevoSoporte &&
                        <Modal
                            show={showModalNuevoSoporte}
                            onHide={() => setShowModalNuevoSoporte(false)}
                            size="lg"
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            {cargandoComboBoxSoporte ?
                                <h3>
                                    <Spinner className="text-inline" animation="border" variant="primary" /> Cargando...
                                </h3> :
                                <Form noValidate onSubmit={handleSubmit(onSubmitNuevoSoporte)}>
                                    <Modal.Header>
                                        <Modal.Title>Registrar Ticket de Soporte T&eacute;cnico</Modal.Title>
                                        <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => setShowModalNuevoSoporte(false)} />

                                    </Modal.Header>
                                    <Modal.Body>
                                        <Row>
                                            <Col xs={2} md={2}>
                                                <img src="/img/soporte48.png"></img>
                                            </Col>
                                            <Col xs={10} md={10}>
                                                <p>Debes registrar los detalles del Soporte T&eacute;nico que brindaste al cliente. Indica la fecha, hora y tiempo requerido.</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={6} md={6}>
                                                <Form.Control
                                                    type="hidden"
                                                    defaultValue={id}
                                                    isInvalid={!!errors.idClienteAkasia}
                                                    {...register("idClienteAkasia")
                                                    }
                                                />
                                                <Form.Label>Fecha del Soporte</Form.Label>
                                                <Form.Group className="mb-3">
                                                    <Form.Control
                                                        type="date"
                                                        {...register("dFechaSoporte",
                                                            {
                                                                required: true
                                                            })
                                                        }
                                                        isInvalid={!!errors.dFechaSoporte} />

                                                    {/*
                                                    <DatePicker
                                                        isInvalid={!!errors.dFechaSoporte}
                                                        {...register("dFechaSoporte",
                                                            {
                                                                required: true
                                                            })
                                                        }
                                                        selected={date}
                                                        onChange={(date) => { setValue("dFechaSoporte", date); setDate(date) }}
                                                        formatWeekDay={day => day.slice(0, 3)}
                                                        className='form-control'
                                                        placeholderText="Selecciona Fecha y Hora"
                                                        timeIntervals={5}
                                                        dateFormat="dd/MMM/yyyy h:mm aa"
                                                        showTimeSelect
                                                        minDate={addDays(new Date(), -2)}
                                                        maxDate={new Date()}
                                                    />*/}

                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.dFechaSoporte && errors.dFechaSoporte.message}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6} md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Nivel de Soporte</Form.Label>
                                                    <Form.Select
                                                        aria-label="Default select"
                                                        isInvalid={!!errors.idCatNivelSoporte}
                                                        {...register("idCatNivelSoporte",
                                                            {
                                                                required: true
                                                            })
                                                        }
                                                    >
                                                        <option value="">Selecciona un nivel</option>
                                                        {nivelSoporte.map((nivel, index) => {
                                                            return (
                                                                <option
                                                                    key={index}
                                                                    value={nivel.idCatNivelSoporte}>
                                                                    {nivel.iNivel} - {nivel.vNombreNivel}
                                                                </option>)
                                                        })}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>T&iacute;tulo</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        autoComplete="off"
                                                        onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                        isInvalid={!!errors.vTitulo}
                                                        {...register("vTitulo",
                                                            {
                                                                required: 'Es necesario el titulo',
                                                                maxLength: 100,
                                                                minLength: 1
                                                            })
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.vTitulo && errors.vTitulo.message}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Descripci&oacute;n (m&iacute;nimo 50 caracteres)</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        autoComplete="off"
                                                        onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                        isInvalid={!!errors.vDescripcion}
                                                        {...register("vDescripcion",
                                                            {
                                                                required: 'La descripci�n es requerida',
                                                                maxLength: 200,
                                                                minLength: 50
                                                            })
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.vDescripcion && errors.vDescripcion.message}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4} md={4}>
                                                <Form.Label>Duraci&oacute;n (minutos)</Form.Label>
                                                <Form.Group className="mb-3">
                                                    <Form.Select
                                                        aria-label="Default select"
                                                        isInvalid={!!errors.iMinutos}
                                                        {...register("iMinutos",
                                                            {
                                                                required: true
                                                            })
                                                        }
                                                    >
                                                        <option value="">Selecciona...</option>
                                                        <option value="10">10 minutos</option>
                                                        <option value="20">20 minutos</option>
                                                        <option value="30">30 minutos</option>
                                                        <option value="45">45 minutos</option>
                                                        <option value="60">1 hora</option>
                                                        <option value="90">1 hora y media</option>
                                                        <option value="120">2 horas</option>
                                                        <option value="150">2 horas y media</option>
                                                        <option value="180">3 horas</option>
                                                        <option value="240">4 horas</option>
                                                        <option value="300">5 horas</option>
                                                        <option value="360">6 horas</option>
                                                        <option value="420">7 horas</option>
                                                    </Form.Select>

                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.iMinutos && errors.iMinutos.message}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={4} md={4}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Herramienta Utilizada</Form.Label>
                                                    <Form.Select
                                                        aria-label="Default select"
                                                        isInvalid={!!errors.idCatHerramienta}
                                                        {...register("idCatHerramienta",
                                                            {
                                                                required: true
                                                            })
                                                        }
                                                    >
                                                        <option value="">Selecciona...</option>
                                                        {herramientas.map((herr, index) => {
                                                            return (
                                                                <option
                                                                    key={index}
                                                                    value={herr.idCatHerramienta}>
                                                                    {herr.idCatHerramienta} - {herr.vNombreHerramienta}
                                                                </option>)
                                                        })}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={4} md={4}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Tipo de contacto</Form.Label>
                                                    <Form.Select
                                                        aria-label="Default select"
                                                        isInvalid={!!errors.vTipoContacto}
                                                        {...register("vTipoContacto",
                                                            {
                                                                required: true
                                                            })
                                                        }
                                                    >
                                                        <option value="">Selecciona...</option>
                                                        <option value="WhatsApp">WhatsApp</option>
                                                        <option value="Llamada por tel�fono">Llamada por tel&eacute;fono</option>
                                                        <option value="Messenger">Messenger</option>
                                                        <option value="Comentario">Comentario</option>
                                                        <option value="En persona">En persona</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Estatus de la soluci&oacute;n</Form.Label>
                                                    <Form.Select
                                                        aria-label="Default select"
                                                        isInvalid={!!errors.idCatStatusSolucion}
                                                        {...register("idCatStatusSolucion",
                                                            {
                                                                required: true
                                                            })
                                                        }
                                                    >
                                                        <option value="">Selecciona...</option>
                                                        {statusSolucion.map((st, index) => {
                                                            return (
                                                                <option
                                                                    key={index}
                                                                    value={st.idCatStatusSolucion}>
                                                                    {st.idCatStatusSolucion} - {st.vDescripcionStatus}
                                                                </option>)
                                                        })}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button onClick={() => { setShowModalNuevoSoporte(false); reset() }} variant='primary' >Cerrar</Button>
                                        <Button type="submit" variant='success' >Registrar</Button>
                                    </Modal.Footer>
                                </Form>
                            }
                        </Modal>
                    }

                    {showModalEliminar &&
                        <Modal
                            show={showModalEliminar}
                            onHide={() => setShowModalEliminar(false)}
                            size="md"
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Form noValidate onSubmit={handleSubmit(onSubmitEliminar)}>
                                <Modal.Header>
                                    <Modal.Title >Eliminar Cliente</Modal.Title>
                                    <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setShowModalEliminar(false); reset() }} />
                                </Modal.Header>
                                <Modal.Body>
                                    <h4>&iquest;Est&aacute; seguro que desea eliminar el cliente?</h4>
                                    <Row>
                                        <Col md={12}>
                                            Es importante que el cliente no tenga vinculado ninguna licencia, orden de venta o servicio para poder eliminarlo.
                                        </Col>
                                    </Row>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={() => { setShowModalEliminar(false); reset() }} variant='primary' >Cerrar</Button>
                                    <Button type="submit" variant='danger' >Eliminar cliente</Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>
                    }
                    {/* Modal para confirmar combinar cliente*/

                        <Modal
                            show={showModalCombinarClientes}
                            onHide={() => setShowModalCombinarClientes(false)}
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header>
                                <Modal.Title id="contained-modal-title-vcenter">Combinar clientes</Modal.Title>
                                <CloseButton
                                    className="btn btn-circle btn-sm transition-base p-0"
                                    onClick={() => setShowModalCombinarClientes(false)}
                                />
                            </Modal.Header>
                            <Modal.Body>

                                <h5>Al combinar el cliente todas sus licencias, ordenes y leads pasaran a ser del cliente <b> {customerdetails.vRazonSocial} </b> </h5>

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant='danger' onClick={() => setShowModalCombinarClientes(false)}>Cancelar</Button>

                                {
                                    habilitarBtnCombinar ?
                                        <Button variant='success' onClick={() => combinarClientes()}>Combinar</Button>
                                        :
                                        <>
                                            <Button variant="primary" disabled>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                Procesando...
                                            </Button>
                                        </>
                                }

                            </Modal.Footer>
                        </Modal>
                    }
                    {/*Modal seguimiento lead */
                        <ModalSeguimientoLead
                            showModal={showModalSeguimientoLead}
                            setShowModal={setShowModalSeguimientoLead}
                            setMensajeError={setMensajeError}
                            setShowMensajeError={setShowMensajeError}
                            setMensajeInformativo={setMensajeInformativo}
                            setShowMensajeInformativo={setShowMensajeInformativo}
                            idLead={idLead}
                            setIdLead={setIdLead}
                            leadsVinculados={leadsVinculados}
                            setLeadsVinculados={setLeadsVinculados}

                        />
                    }

                    {/* Modal para evitar crear ordenes a clientes con datos fiscales faltantes*/

                        <ModalDatosFiscalesIncompletos
                            showModalDatosFiscalesIncompletos={showModalDatosFiscalesIncompletos}
                            setShowModalDatosFiscalesIncompletos={setShowModalDatosFiscalesIncompletos}
                        />

                    }

                </>

            }
        </>
    );
};

export default DetallesCliente;
