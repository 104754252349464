import PropTypes from 'prop-types';
import React from 'react';
import { Card, ListGroup, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import FeedCardFooter from './FeedCardFooter';
import FeedCardHeaderInfiniteScroll from './FeedCardHeaderInfiniteScroll';
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from 'react-loading-skeleton'

const FeedCardInfiniteScroll = ({
    comentarios,
    submitComment,
    comentario,
    setComentario,
    procesandoComentario,
    idUsuario,
    obtenerComentarios,
    hayMasComentarios,
    cargandoComentarios
}) => {
    return (
        <Card >
            <FeedCardFooter
                submitComment={submitComment}
                comentario={comentario}
                setComentario={setComentario}
                procesandoComentario={procesandoComentario}
                idUsuario={idUsuario}
            />
            {
                cargandoComentarios ?
                    <ComentarioSkeleton />
                    :
                    <>
                        <span className="pb-2" >

                            {
                                <ListGroup id={"comentarios"} variant="flush" className="fw-normal fs--1 scrollbar" style={{ maxHeight: '25rem' }}>

                                    <InfiniteScroll
                                        dataLength={comentarios ? comentarios.length : 0}
                                        next={() => obtenerComentarios()}
                                        hasMore={hayMasComentarios == 1 ? true : false}
                                        loader={<ComentarioSkeleton />}
                                        scrollableTarget={"comentarios"}
                                        scrollThreshold={0.8}
                                    >

                                        {comentarios &&
                                            comentarios.map(
                                                (c, i) => (
                                                    <FeedCardHeaderInfiniteScroll
                                                        key={i}
                                                        vNombreUsuario={c.vNombreUsuario}
                                                        vComentario={c.vComentario}
                                                        idTipoComentario={c.idTipoComentario}
                                                        vFecha={c.vFecha}
                                                        vHora={c.vHora}
                                                        vIP={c.vIP}
                                                        idUsuario={Number(c.idUsuario)}
                                                        comentarioExtra={c.vComentarioExtra}
                                                        idTipoComentarioExtra={c.idTipoComentarioExtra}
                                                        idOrden={c.idOrden}
                                                        idLicencia={c.idLicencia}
                                                    />

                                                )
                                            )
                                        }

                                    </InfiniteScroll>
                                </ListGroup>
                            }
                        </span>
                    </>
            }
            

        </Card>
    );
};

const ComentarioSkeleton = () => {
    return (
        <>

            <Row
                style={{ border: 'none' }}
                className={classNames(
                    'p-1 shadow-none  d-flex align-items-center rounded-0',
                    'notification-skeleton'
                )}
            >
                <Col className="col-1 pe-0">
                    <Skeleton circle width={30} height={30} />
                </Col>
                <Col className="col-11 ">
                    <Skeleton height={30} width="100%" />
                </Col>
            </Row>

        </>

    );
};

FeedCardInfiniteScroll.propTypes = {
    comentarios: PropTypes.array,
    submitComment: PropTypes.func,
    comentario: PropTypes.string,
    setComentario: PropTypes.func,
    procesandoComentario: PropTypes.bool,
    idUsuario: PropTypes.number,
    obtenerComentarios: PropTypes.func,
    hayMasComentarios: PropTypes.number,
    cargandoComentarios: PropTypes.bool
};

export default FeedCardInfiniteScroll;
