import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import IconButton from 'components/common/IconButton';
import MensajeErrror from 'components/common/MensajeError';
import { ToastContainer } from 'react-toastify';
import SoftBadge from 'components/common/SoftBadge';
import useFiltro from 'hooks/useFiltro';
import useToken from 'hooks/useToken';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import Banderas from './Banderas';

const columns = [
    {
        accessor: 'none',
        Header: '',
        Cell: rowData => {
            const { idClienteAkasia } = rowData.row.original;
            return <>
                <Link to={"/clientes/detalle/" + idClienteAkasia} >
                    <img title="Ver informaci&oacute;n" src="/img/ver20.png"></img >
                </Link>
            </>
        }
    },
    {
        accessor: 'idClienteAkasia',
        Header: 'ID',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { idClienteAkasia } = rowData.row.original;
            return <Link to={"/clientes/detalle/" + idClienteAkasia}><span>{idClienteAkasia}</span></Link>
        }
    },
    {
        accessor: 'vRfc',
        Header: 'RFC',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { vRfc } = rowData.row.original;
            return <span> {vRfc}</span>;
        }
    },
    {
        //si idLead es diferente de 0 significa que tiene almenos un lead vinculado, por lo que vamos a mostra 1
        //no mostraremos el idLead pues puede tener varios leads vinculados
        //se pueden ver los leads vinculados en los detalles del cliente
        accessor: 'bTieneLeadVinculado',
        Header: 'L',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { bTieneLeadVinculado } = rowData.row.original;
            return <span> {bTieneLeadVinculado} </span> 
        }
    },
    /*{
        accessor: 'dNumLic',
        Header: 'dNumLic',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { dNumLic } = rowData.row.original;
            return <span> {dNumLic}</span>;
        }
    },*/
    {
        accessor: 'dACL',
        Header: 'AC',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { dACL } = rowData.row.original;
            return <span> {dACL}</span>;
        }
    },
    {
        accessor: 'dPPL',
        Header: 'PP',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { dPPL } = rowData.row.original;
            return <span> {dPPL}</span>;
        }
    },
    {
        accessor: 'vNombre',
        Header: 'Nombre',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const { vNombre, idClienteAkasia, dACL, dPPL } = rowData.row.original;
            return (
                <>
                    <h5 className="mb-0 fs--2">
                        {dACL == 0 &&
                            dPPL == 0 &&
                            <><img title="Este cliente no tiene ninguna licencia vinculada..." src="/img/advertencia20.png"></img> &nbsp;</>}
                        <Link to={"/clientes/detalle/" + idClienteAkasia}>

                            {dACL > 0 ?
                                <span className="d-inline-block text-truncate" style={{ maxWidth: '250px' }}>
                                    {vNombre}
                                </span>
                                :
                                <span className="d-inline-block text-truncate text-danger" style={{ maxWidth: '250px' }}>
                                    {vNombre}
                                </span>
                            }

                        </Link>
                    </h5>
                </>
            );
        }
    },
    {
        accessor: 'vEmail',
        Header: 'Email',
        Cell: rowData => {
            const { vEmail } = rowData.row.original;
            return <a href={`mailto:${vEmail}`}><span className="d-inline-block text-truncate" style={{ maxWidth: '250px' }}>{vEmail}</span></a>;
        }
    },
    {
        accessor: 'vTelefonoPersonal',
        Header: 'Tel\u00e9fono',
        Cell: rowData => {
            const { vTelefonoPersonal } = rowData.row.original;
            return <>
                <a href={"https://wa.me/" + vTelefonoPersonal} target="_blank" rel="noreferrer">
                    <img title="Enviar WhatsApp" src="/img/whatsapp20.png"></img >
                </a>
                &nbsp;
                <a href={"https://wa.me/" + vTelefonoPersonal} target="_blank" rel="noreferrer">{vTelefonoPersonal}</a>
            </>
        }
    },
    {
        accessor: 'vPais',
        Header: 'Pa\u00EDs',
        Cell: rowData => {
            const { vPais } = rowData.row.original;
            return <span className="fs--2">{vPais}</span>
        }
    },
    {
        accessor: 'joined',
        Header: 'Creado',
        Cell: rowData => {
            const { joinDate } = rowData.row.original;
            return <span>{joinDate}</span>;
        }
    }
];

const Clientes = () => {
    const [customers, setCustomers] = useState([]);
    const [agrupacionPaises, setAgrupacionPaises] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [countries, setCountries] = useState([]);
    const { token, setToken } = useToken();
    const [mensajeError, setMensajeError] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);
    const { FiltroCliente, setFiltroCliente, resetFiltroCliente } = useFiltro();

    const mostrarClientes = async () => {
        setCargando(true);
        const response = await fetch("/api/cliente/getclientesakasia/?vFiltro=" + encodeURIComponent(JSON.stringify(FiltroCliente)),
            {
                method: 'GET',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            });
        if (response.ok) {
            const data = await response.json();
            console.log("=============== MOSTRAR CLIENTES ===============");
            console.log(data['table1']);
            console.log(data['table2']);
            setCustomers(data['table1']);
            setAgrupacionPaises(data['table2']);
            console.log(data['table3']);
            setCountries(data['table3']);

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
        setCargando(false);
    };

    useEffect(() => {
        mostrarClientes();
    }, []);

    return (
        <>
            <Card className="mb-1 p-2">
                <Row className="g-2 align-items-sm-center">
                    <Col xs="auto">
                        <img src="/img/cliente96.png" alt="connectCircle" height={30} />
                    </Col>
                    <Col>
                        <h5>
                            Cat&aacute;logo de clientes
                        </h5>
                    </Col>
                    <Col sm="auto" className="ms-auto">
                        <Link to="/clientes/nuevo">
                            <Button size="sm"
                                variant="falcon-primary"
                            >Nuevo Cliente</Button>
                        </Link>
                    </Col>
                </Row>
            </Card>
           
            <AdvanceTableWrapper
                columns={columns}
                data={customers}

                sortable
                pagination
                perPage={15}
            >
                <Card className="mb-3">
                    <Card.Header >
                        <Row className="g-3 bg-light">
                            {agrupacionPaises.map((pais, index) => {
                                return (
                                    <Col key={index} xl xs={6} md={4} className="text-center">
                                        <SoftBadge pill bg="success" className="me-2 fs--2 mb-2">
                                            <FontAwesomeIcon icon="caret-up" className="me-1" />
                                            {pais.NumLicAct} Lic
                                        </SoftBadge>
                                        <br></br>
                                        <Banderas Height={38} vPais={pais.vPais}></Banderas>

                                        <h5 className="mb-1 font-sans-serif">
                                            <CountUp
                                                start={0}
                                                end={pais.Num}
                                                duration={2.75}
                                                className="text-700"
                                                separator=","
                                            />
                                        </h5>
                                        <span className="fw-normal fs--2 text-600">{pais.vPais}</span>

                                    </Col>
                                )
                            })}

                        </Row>
                        <hr></hr>
                        <Form >
                            <Row className="flex-between-center">
                                <Col xs={12} sm={6} md={6} lg={3} className="d-flex align-items-center pe-0 mb-2">
                                    <Form.Select
                                        size="sm"
                                        aria-label="Default select"
                                        value={FiltroCliente.vPais}
                                        onChange={(event) => { setFiltroCliente({ ...FiltroCliente, vPais: event.target.value }) }}
                                    >
                                        <option value="">Todos los pa&iacute;ses</option>
                                        {countries.map((pais, index) => {
                                            return (<option key={index} value={pais.vName}>{pais.vName}</option>)
                                        })}
                                    </Form.Select>

                                </Col>
                                <Col xs={12} sm={6} md={6} lg={3} className="d-flex align-items-center pe-0 mb-2">
                                    <Form.Select
                                        size="sm"
                                        aria-label="Default select"
                                        value={FiltroCliente.vEstatusCliente}
                                        onChange={(event) => { setFiltroCliente({ ...FiltroCliente, vEstatusCliente: event.target.value }) }}
                                    >
                                        <option value="">Todos los estatus</option>
                                        <option value="prueba">Clientes Solo Licencias Prueba</option>
                                        <option value="activa">Clientes Solo Licencias Activas</option>
                                        <option value="nolicencia">Clientes Sin Licencias</option>
                                    </Form.Select>
                                </Col>

                                <Col xs={12} sm={6} md={6} lg={3} className="d-flex align-items-center pe-0 mb-2">
                                    <Form.Group className="col-12">
                                        <Form.Control
                                            onChange={(event) => { setFiltroCliente({ ...FiltroCliente, vNombreCliente: event.target.value.toUpperCase() }) }}
                                            size="sm"
                                            type="text"
                                            placeholder="Nombre del cliente"
                                            value={FiltroCliente.vNombreCliente}
                                        />

                                    </Form.Group>

                                </Col>


                                <Col xs={12} sm={6} md={6} lg={3} className="d-flex align-items-center pe-0 mb-2">

                                    {cargando ? <Button
                                        variant="falcon-default"
                                        size="sm"
                                        transform="shrink-3"
                                        disabled
                                    >
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />&nbsp;
                                        Buscando...
                                    </Button>
                                        : <><IconButton
                                            variant="falcon-default"
                                            type="submit"
                                            size="sm"
                                            icon="search"
                                            transform="shrink-3"

                                            onClick={() => mostrarClientes()}
                                        >
                                            <span className="d-none d-sm-inline-block ms-1">Buscar</span>
                                        </IconButton> <IconButton
                                            variant="falcon-default"
                                            size="sm"
                                            icon="redo-alt"
                                            transform="shrink-3"
                                            className="mx-2"
                                            onClick={() => { resetFiltroCliente(); }}
                                        >
                                                <span className="d-none d-sm-inline-block ms-1">Reset</span>
                                            </IconButton></>}
                                </Col>
                            </Row>
                        </Form>
                        <Row>
                            <Col className="ms-auto" sm={8} lg={4} xs={12} >
                                {!cargando &&
                                    <AdvanceTableSearchBox table />
                                }
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="p-0">

                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--2 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableFooter
                            rowCount={customers.length}
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            {cargando && (<h5><Spinner className="text-inline" animation="border" variant="primary" /> Cargando...</h5>)}
            <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>
            <ToastContainer/>
        </>
    );
};

export default Clientes;
