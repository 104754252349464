import IconButton from 'components/common/IconButton';
import MensajeErrror from 'components/common/MensajeError';
import MensajeInformativo from 'components/common/MensajeInformativo';
import { ToastContainer } from 'react-toastify';
import PageHeader from 'components/common/PageHeader';
import useToken from 'hooks/useToken';
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row, Spinner, Table, Badge } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

const EditarUsuarioNubeAkasia = () => {
    const { register, setValue, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const { id } = useParams();

    /*SUCURSALES*/
    const [Sucursales, setSucursales] = useState([]);
    const [ddlSucursales, setDDLSucursales] = useState([]);
    const [ddlRoles, setDDLRoles] = useState([]);
    const [UsuarioNubeAkasia, setUsuarioNubeAkasia] = useState([]);
    //const [Sucursales, setSucursales] = useState([]);

    //MENSAJES Y AVISOS

    const [mensajeError, setMensajeError] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);
    const [cargando, setCargando] = useState(true);
    const [mensajeInformativo, setMensajeInformativo] = useState('');
    const [showMensajeInformativo, setShowMensajeInformativo] = useState(false);

    const [Editar_Rol, setEditar_Rol] = useState('');
    const [estatus, setEstatus] = useState(false);


    //AUTENTICACION
    const { token, setToken } = useToken();

    //Obtener Sucursales De la empresa
    const obtenerSucursalesDeEmpresa = async (idLicencia) => {
        // alert("hola mundo " + idLicencia);
        setDDLSucursales([]);
        const response = await fetch("/api/licencia/ObtenerSucursalesEmpresa?idLicencia=" + idLicencia, {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            setDDLSucursales(data);
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
    };

    //Obtener UUID
    const ObtenerCadenaRandom = async (action) => {
        const response = await fetch("/api/licencia/ObtenerCadenaRandom", {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'text/plain'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.text();
            console.log(data);

            if (action == 'Usuario')
                setValue("vUsuario", data);
            else if (action == 'Contrasena')
                setValue("vContrasena", data);

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
    };

    //Editar Usuario Nube Akasia
    const EditarUsuarioNubeAkasia = async (values) => {
        console.log(values);
        const response = await fetch("/api/usuarionubeakasia/EditarUsuarioNubeAkasia/?key=" + id + "&values=" + encodeURIComponent(values), {
            method: 'PUT',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            setShowMensajeInformativo(true);
            setMensajeInformativo(data["table1"][0].vMensaje);
            navigate("/servicios/usuarios-nube-akasia");
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
    };

    //Obtener datos del usuario a editar
    const GetUsuarioNubeAkasiaByID = async () => {
        const response = await fetch("/api/usuarionubeakasia/GetUsuarioNubeAkasiaByID/?idUsuarioNubeAkasia=" + id, {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();

            console.log(data);

            console.log(data['Table1']);
            console.log(data['Table2']);

            setValue("vUsuario", data['table1'][0].vUsuario);
            setValue("vContrasena", data['table1'][0].vContrasena);
            setValue("vNombre", data['table1'][0].vNombre);

            //obtenerSucursalesDeEmpresa(data[0].idLicencia);
            obtenerSucursalesDeEmpresa(data['table1'][0].idLicencia);

            setUsuarioNubeAkasia(data['table1'][0]);
            setSucursales(data['Table2'][0]);
            setDDLRoles(data['table2']);

            setEditar_Rol(data['table1'][0].idRol);
            setEstatus(data['table1'][0].bIsActive)
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setCargando(false);
    };

    const onSubmit = data => {

        data.idRol = Editar_Rol;

        data.bIsActive = estatus;

        EditarUsuarioNubeAkasia(JSON.stringify(data));
    };

    const handdleChangeSucursalDDL = async (data) => {

        var jsonData = {};

        jsonData.idUsuarioNubeAkasia = UsuarioNubeAkasia.idUsuarioNubeAkasia;
        jsonData.idSucursal = data;
        jsonData.vUUIDEmpresa = Sucursales.vUUIDEmpresa;
        jsonData.idEmpresa = Sucursales.idEmpresa;
        jsonData.bIsActive = true;
        jsonData.vCreatedUser = "";

        console.log(jsonData);

        const response = await fetch("/api/usuarionubeakasia/editarSucursalUsuario/?idLicencia=" + UsuarioNubeAkasia.idLicencia, {
            method: 'POST',
            body: JSON.stringify(jsonData),
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('' + error.message);
                console.log('Hubo un problema con la peticion Fetch:' + error.message);
            });

        if (response.ok) {

            const responseType = await response.json();

            if (responseType.iResponseType == 1) {

                setCargando(true);
                GetUsuarioNubeAkasiaByID();

                setMensajeInformativo('Sucursal asignada');
                setShowMensajeInformativo(true);

            }
            else {

                setShowMensajeError(true);
                setMensajeError('' + responseType.vMessage);
                console.log('Hubo un problema:' + responseType.vMessage);
            }

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
    }

    const onCheckedChange = data => {
        console.log(data);
        console.log(data.target.checked);
        setEstatus(data.target.checked);
    }; 

    //ejecutar solo 1 vez despues del DOM
    useEffect(() => {
        GetUsuarioNubeAkasiaByID();

    }, []);

    return (
        <>
            <PageHeader
                title={"Editar Usuario Nube Akasia"}
                className="mb-1"
            >
            </PageHeader>
            <Card>
                <Card.Header className="d-flex flex-between-center">
                    <IconButton
                        onClick={() => navigate(-1)}
                        variant="falcon-default"
                        size="sm"
                        icon="arrow-left"
                    />

                </Card.Header>
            </Card>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Row className="g-3 mb-3">
                    {cargando ? (<h3><Spinner className="text-inline" animation="border" variant="primary" /> Cargando...</h3>)
                        :
                        (
                            <>
                                <Col lg={6}>
                                    <Card className="mt-3">
                                        <Card.Header className="border-bottom border-200">
                                            <Row>
                                                <Col xs={2}>
                                                    <img src="/img/usuarioNubeAkasia.png"></img>
                                                </Col>
                                                <Col>
                                                    <h4 className="mb-0">Usuario Nube</h4>
                                                    <p>Cada usuario de la Nube Akasia puede entrar de forma independiente a los datos de la empresa.</p>
                                                </Col>
                                            </Row>

                                        </Card.Header>
                                        <Card.Body>
                                            <>
                                                <Form.Group className="mb-5">
                                                    <Form.Label>ESTATUS</Form.Label>
                                                    <Row>
                                                        <Col md={2} style={{ cursor: "pointer" }}>
                                                            <Form.Check // prettier-ignore
                                                                {...register("bIsActive",
                                                                    {

                                                                    })
                                                                }
                                                                type="switch"
                                                                checked={estatus}
                                                                onChange={e => { onCheckedChange(e) }}
                                                            />
                                                        </Col>
                                                        <Col md={8}>
                                                            {estatus ?
                                                                <Col>
                                                                    <img src="/img/activo.png"></img> <Badge bg="success">Activo</Badge>
                                                                </Col>
                                                                :
                                                                <Col>
                                                                    <img src="/img/noactivo.png"></img> <Badge bg="danger">Desactivado</Badge>
                                                                </Col>
                                                            }

                                                        </Col>
                                                    </Row>


                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Usuario</Form.Label>
                                                    <IconButton
                                                        className="ms-2"
                                                        iconClassName="fs--2 me-1"
                                                        variant="falcon-primary"
                                                        size="sm"
                                                        icon="circle"
                                                        onClick={() => { ObtenerCadenaRandom('Usuario') }}
                                                    >
                                                        Generar
                                                    </IconButton>
                                                    <Form.Control
                                                        onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                        isInvalid={!!errors.vUsuario}
                                                        {...register("vUsuario",
                                                            {
                                                                required: 'El vUsuario es requerido',
                                                                maxLength: 40
                                                            })
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.vUsuario && errors.vUsuario.message}
                                                    </Form.Control.Feedback>

                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Contrase&ntilde;a</Form.Label>
                                                    <IconButton
                                                        className="ms-2"
                                                        iconClassName="fs--2 me-1"
                                                        variant="falcon-primary"
                                                        size="sm"
                                                        icon="circle"
                                                        onClick={() => { ObtenerCadenaRandom('Contrasena') }}
                                                    >
                                                        Generar
                                                    </IconButton>
                                                    <Form.Control
                                                        onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                        isInvalid={!!errors.vContrasena}
                                                        {...register("vContrasena",
                                                            {
                                                                required: 'El vContrasena es requerido',
                                                                maxLength: 40
                                                            })
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.vContrasena && errors.vContrasena.message}
                                                    </Form.Control.Feedback>

                                                </Form.Group>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label>Nombre y apellidos del usuario</Form.Label>
                                                    <Form.Control
                                                        onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                        type="text"
                                                        placeholder="vNombre"
                                                        isInvalid={!!errors.vNombre}
                                                        {...register("vNombre",
                                                            {
                                                                required: 'El nombre del cliente es requerido',
                                                                maxLength: 200,
                                                                minLength: 1
                                                            })
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.vNombre && errors.vNombre.message}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Row>
                                                    <Col>
                                                        &nbsp;
                                                    </Col>
                                                    <Col xs="auto">
                                                        <IconButton
                                                            className="ms-2"
                                                            iconClassName="fs--2 me-1"
                                                            variant="falcon-default"
                                                            size="sm"
                                                            icon="check"
                                                            type="submit"
                                                        >
                                                            Guardar Cambios
                                                        </IconButton>
                                                    </Col>
                                                </Row>

                                            </>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card className="mt-3">
                                        <Card.Header className="border-bottom border-200">

                                        </Card.Header>
                                        <Card.Body>
                                            <>

                                                <Table responsive striped hover size="sm" >
                                                    <tbody>
                                                        <tr>
                                                            <td><strong>CLIENTE:</strong></td>
                                                            <td><img src="/img/vendedor35.png"></img>&nbsp;&nbsp;<strong>{UsuarioNubeAkasia.idClienteAkasia}</strong> {" | " + UsuarioNubeAkasia.vNombre + " " + UsuarioNubeAkasia.vApellidos}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>LICENCIA:</strong></td>
                                                            <td><strong>{UsuarioNubeAkasia.idLicencia}</strong>  {" | " + UsuarioNubeAkasia.vUUIDLicencia}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>ROL:</strong></td>
                                                            {/*<td><strong>{UsuarioNubeAkasia.vNombreRol}</strong></td>*/}

                                                            <td>
                                                                <Form.Select
                                                                    aria-label="Default select"
                                                                    onChange={(e) => setEditar_Rol(e.target.value)}
                                                                    value={Editar_Rol}
                                                                >
                                                                    <option value="" disabled selected>Selecciona un Rol</option>
                                                                    {ddlRoles.map((rol, index) => {
                                                                        return (<option key={index} value={rol.idRol}>[{rol.idRol}] - {rol.vNombreRol}</option>)
                                                                    })}

                                                                </Form.Select>
                                                            </td>

                                                        </tr>
                                                        <tr >
                                                            <td><strong>EMPRESA:</strong></td>
                                                            <td><strong>{Sucursales.vNombreEmpresa}</strong></td>
                                                        </tr>
                                                        <tr >
                                                            <td><strong>SUCURSALES ASIGNADAS:</strong></td>
                                                            <td>
                                                                {(Sucursales.vSucursales == "" || Sucursales.vSucursales == null) ?

                                                                    <Form.Select
                                                                        aria-label="Default select"
                                                                        isInvalid={!!errors.idSucursal}
                                                                        onChange={(e) => handdleChangeSucursalDDL(e.target.value)}
                                                                    >
                                                                        <option value="" disabled selected>Selecciona Una Sucursal</option>
                                                                        {ddlSucursales.map((sucursal, index) => {
                                                                            return (<option key={index} value={sucursal.idSucursal}>{sucursal.idSucursal} - {sucursal.vNombreSucursal}</option>)
                                                                        })}

                                                                    </Form.Select>

                                                                    :

                                                                    <strong>{Sucursales.vSucursales}</strong>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>

                                            </>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </>
                        )}
                </Row>
            </Form>
            <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>
            <MensajeInformativo setShowMensajeInformativo={setShowMensajeInformativo} showMensajeInformativo={showMensajeInformativo} mensajeInformativo={mensajeInformativo}></MensajeInformativo>
            <ToastContainer/>
        </>
    );
};

export default EditarUsuarioNubeAkasia;