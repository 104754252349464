import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const ContactDetailsHeader = ({ handleShow }) => {
  const navigate = useNavigate();
  return (
    <Card className="mb-3">
      <Card.Header className="d-flex flex-between-center">
        <IconButton
          onClick={() => navigate(-1)}
          variant="falcon-default"
          size="sm"
          icon="arrow-left"
        />
        <Flex>
          <IconButton
            onClick={handleShow}
            variant="falcon-default"
            size="sm"
            icon="tasks"
            transform="shrink-2"
            iconAlign="middle"
            className="d-xl-none"
          >
            <span className="ms-1">To-do</span>
          </IconButton>
          <div
            className="bg-300 mx-3 d-xl-none"
            style={{ width: '1px', height: '29px' }}
          ></div>
        
        </Flex>
      </Card.Header>
    </Card>
  );
};

ContactDetailsHeader.propTypes = {
  handleShow: PropTypes.func
};

export default ContactDetailsHeader;
