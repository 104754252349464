import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import React, { useState } from 'react';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import MensajeErrror from 'components/common/MensajeError';
import { ToastContainer } from 'react-toastify';
import useToken from 'hooks/useToken';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';


const columns = [
    {
        accessor: 'none',
        Header: '',
        Cell: rowData => {
            const { idUsuarioNubeAkasia } = rowData.row.original;
            return (
                <>
                    <Row className="flex-center">
                        <Col xs="auto" >
                            <Link to={"/servicios/usuarios-nube-akasia/editar/" + idUsuarioNubeAkasia}>
                                <FontAwesomeIcon icon="edit" />

                            </Link>
                        </Col>
                    </Row>
                </>
            );
        }
    },
    {
        accessor: 'idLicencia',
        Header: 'Lic. Nube',
        Cell: rowData => {
            const { idLicencia } = rowData.row.original;
            return (<Link to={"/licencias/detalle/" + idLicencia}>{idLicencia}</Link>)
        }
    },
    {
        accessor: 'vNombreCliente',
        Header: 'Nombre Cliente Licencia',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const { vNombreCliente } = rowData.row.original;
            return (<span >{vNombreCliente}</span>);
        }
    },
    {
        accessor: 'vUsuario',
        Header: 'Usuario',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { vUsuario } = rowData.row.original;
            return (<span> {vUsuario}</span>)
        }
    },
    {
        accessor: 'vContrasena',
        Header: 'Contrasena',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { vContrasena } = rowData.row.original;
            return (<span> {vContrasena}</span>)
        }
    },
    {
        accessor: 'vNombre',
        Header: 'Nombre',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { vNombre, vApellidos } = rowData.row.original;
            return <span> {vNombre} {vApellidos}</span>
        }
    }
    
];

const UsuariosNubeAkasia = () => {

    const [usuariosNube, setUsuariosNube] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [mensajeError, setMensajeError] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);
    const { token, setToken } = useToken();
    const mostrarUsuariosNube = async () => {
        setCargando(true);
        const response = await fetch("/api/usuarionubeakasia/GetUsuariosNubeAkasia",
            {
                method: 'GET',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }).catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            setUsuariosNube(data);
            setCargando(false);
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
    };

    useEffect(() => {
        mostrarUsuariosNube();
    }, []);

    return (
        <>
            <Card className="mb-1 p-2">
                <Row className="g-2 align-items-sm-center">
                    <Col xs="auto">
                        <img src="/img/usuarioNubeAkasia.png" alt="connectCircle" height={30} />
                    </Col>
                    <Col>
                        <h5>
                            Usuarios Nube Akasia
                        </h5>
                    </Col>
                    <Col sm="auto" className="ms-auto">
                        <Link to="/servicios/usuarios-nube-akasia/nueva">
                            <Button size="sm" variant="falcon-primary">Nuevo usuario</Button>
                        </Link>
                    </Col>
                </Row>
            </Card>
            {cargando && (<h5><Spinner className="text-inline" animation="border" variant="primary" /> Cargando...</h5>)}
            <AdvanceTableWrapper
                columns={columns}
                data={usuariosNube}

                sortable
                pagination
                perPage={15}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <Row className="flex-between-center">
                            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                                <div id="orders-actions">
                                  
                                </div>
                            </Col>
                            <Col xs={6} sm="auto" className="ms-auto ps-0">
                                {!cargando &&
                                    <AdvanceTableSearchBox table />
                                }
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                hover: true,
                                className: 'fs--2 mb-0 overflow-hidden'
                            }}
                        />

                    </Card.Body>
                    <Card.Footer>

                        <AdvanceTableFooter
                            rowCount={usuariosNube.length}
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                        />
                    </Card.Footer>
                </Card>

            </AdvanceTableWrapper>

            <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>
            <ToastContainer/>

        </>
    );
};

export default UsuariosNubeAkasia;
