import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import SoftBadge from 'components/common/SoftBadge';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const OrdenesPorAcreditar = ({ ordenes }) => {

    //Columnas
    const columns = [
        {
            accessor: 'iNumPagosNoValidados',
            Header: '',
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { iNumPagosNoValidados, idOrden, bComentario, dFechaPrimerPago, dFechaOrden } = rowData.row.original;
                return (
                    <>{iNumPagosNoValidados > 0 &&
                        <Link to={"/ordenes/detalle/" + idOrden}>
                            <img title="Hay pagos sin validar..." src="/img/advertencia20.png"></img>
                        </Link>
                    }
                        
                        {bComentario == 1 &&
                            <img title="Hay comentarios" src="/img/comentario20.png"></img>
                        }
                        {dFechaPrimerPago < dFechaOrden &&
                            <Link to={"/ordenes/detalle/" + idOrden}>
                                <img title="Hay un error en este pago" src="/img/error20.png"></img>
                            </Link>

                        }
                    </>
                );
            }
        },
        {
            accessor: 'idOrden',
            Header: 'Orden',

            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { idOrden, vNombreCliente, vEmail } = rowData.row.original;
                return (
                    <>
                        <Link to={"/ordenes/detalle/" + idOrden}>
                            <strong># {idOrden}</strong>
                        </Link>{'   '}&nbsp;&nbsp;&nbsp;
                        <Link to={"/ordenes/detalle/" + idOrden}>
                            <strong>{vNombreCliente}</strong> <br />
                        </Link>
                        <span className="text-lowercase">{vEmail}</span>
                    </>
                );
            }
        },
        {
            accessor: 'dFechaVenta',
            Header: 'Fecha venta'
        },
        {
            accessor: 'vUsuario',
            Header: 'Vendedor',
            Cell: rowData => {
                const { vUsuario } = rowData.row.original;
                return (
                    <>
                        {vUsuario}
                    </>
                );
            }
        },
        {
            accessor: 'status',
            Header: 'Status',
            headerProps: {
                className: 'text-center'
            },
            cellProps: {
                className: 'fs--1'
            },
            Cell: rowData => {
                const { bIsPagada } = rowData.row.original;
                return (
                    <SoftBadge
                        pill
                        bg={classNames({
                            success: bIsPagada === true,
                            warning: bIsPagada === false,
                        })}
                        className="d-flex flex-center"
                    >
                        <p className="mb-0">
                            {bIsPagada === true && 'Pagada'}
                            {bIsPagada === false && 'No pagada'}
                        </p>
                        <FontAwesomeIcon
                            icon={classNames({
                                check: bIsPagada === true,
                                redo: bIsPagada === 'processing',
                                stream: bIsPagada === false,
                                ban: bIsPagada === 'onhold'
                            })}
                            transform="shrink-2"
                            className="ms-1"
                        />
                    </SoftBadge>
                );
            }
        },
        {
            accessor: 'dTotal',
            Header: 'Total',
            Cell: rowData => {
                const { dTotal } = rowData.row.original;
                return (
                    <>
                        <span className="text-end fs--1 fw-medium py-2">${parseFloat(dTotal).toLocaleString("es-MX", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                        })}</span>
                    </>
                );
            }
        },
        {
            accessor: 'vMoneda',
            Header: 'Moneda',
            Cell: rowData => {
                const { vMoneda } = rowData.row.original;
                return (
                    <>
                        <span>{vMoneda}</span>
                    </>
                );
            }
        },
        {
            accessor: '',
            Header: 'Forma Pago',
            Cell: rowData => {
                const { vClave, vDescripcionFormaPago } = rowData.row.original;
                return (
                    <>
                        <span>{vClave}  {vDescripcionFormaPago}</span>
                    </>
                );
            }
        }
    ];

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={ordenes}
            sortable
            pagination
            perPage={15}
            rowCount={ordenes.length}
        >
            <Card>
                <Card.Header>
                    <Row className="flex-between-center flex-column flex-sm-row">
                        <Col sm="auto" className="d-flex align-items-center pe-0">
                            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">&Oacute;rdenes de compra pendientes de acreditar</h5>
                        </Col>
                        <Col sm="auto" className="">

                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="btn-reveal-trigger text-nowrap align-middle"
                        tableProps={{
                            size: 'sm',
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter rowCount={ordenes.length} table rowInfo navButtons />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

OrdenesPorAcreditar.propTypes = {
    ordenes: PropTypes.array
};

export default OrdenesPorAcreditar;
