import IconButton from "components/common/IconButton";
import useCopyToClipboard from "hooks/useCopyToClipboard";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

const CopyButton = ({ code }) => {

    const [isCopied, handleCopy] = useCopyToClipboard(5000);

    return (
        <>

            <IconButton
                onClick={
                    () => {
                        handleCopy(code);
                        toast.success(
                            'Copiado en el portapapeles: ' + code,
                            {
                                position: "top-right",
                                autoClose: 1000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: false,
                                progress: undefined,
                                theme: "colored"
                            }
                        );
                    }
                }
                variant="falcon-default"
                size="sm"
                icon="a"

            >
                <OverlayTrigger
                    placement="right"
                    overlay={
                        <Tooltip style={{ position: 'fixed' }} id="overlay-trigger-example">
                            <span>Copiar</span>
                        </Tooltip>
                    }
                >
                    {isCopied ? <img src="/img/check25.png"></img> : <img src="/img/copiar25.png"></img>}
                </OverlayTrigger>

                
            </IconButton>

        </>
    );
}

CopyButton.propTypes = {
    code: PropTypes.string
};

export default CopyButton;