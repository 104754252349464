import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ImagenPerfil from '../../../components/common/ImagenPerfil';

const FeedCardHeaderInfiniteScroll = ({
    vNombreUsuario,
    vFecha,
    vHora,
    vComentario,
    idTipoComentario,
    vIP,
    comentarioExtra = "",
    idTipoComentarioExtra,
    idOrden,
    idLicencia,
    idUsuario
}) => {

    const tipoComentarioBg = {
        1: 'warning',
        2: 'success',
        3: 'info',
        default: 'light'
    };

    const SoftBadgeBgClass = tipoComentarioBg[idTipoComentarioExtra] || tipoComentarioBg.default;
    return (
        <>
            {
                idTipoComentario == 1 ?
                    <>
                        {
                            idTipoComentario == 1 && <hr className="text-200 m-0 mb-0" />
                        }

                        <Row className={"justify-content-between p-2 mb-0"} >
                            <Col className="col-12">
                                <Flex>
                                    <ImagenPerfil idUsuario={idUsuario}></ImagenPerfil>
                                    <div className="flex-1 align-self-center ms-2 mb-0">

                                        <p className="mb-1 lh-1">
                                            <Link className="fw-semi-bold fs--2" to={"/perfil/" + idUsuario}>
                                                {vNombreUsuario}
                                            </Link>  •
                                            <span className="mb-0 fs--1  text-500"> {vIP} • {vFecha} • <img src="/img/reloj48.png" width={15}></img> {vHora} • {' '}</span>
                                            {
                                                comentarioExtra != "" &&
                                                <Col className="col-2" >
                                                    <Link target="_blank" rel="noopener noreferrer" to={idTipoComentarioExtra == 2 ? "/ordenes/detalle/" + idOrden : "/licencias/detalle/" + idLicencia} >
                                                        <SoftBadge bg={SoftBadgeBgClass} className={"fw-bold fs--2"} >
                                                            {comentarioExtra}
                                                        </SoftBadge>
                                                    </Link>
                                                </Col>
                                            }
                                        </p>

                                        <p className="mb-0  fs--1">
                                            &nbsp;<span dangerouslySetInnerHTML={{ __html: vComentario }} />
                                        </p>
                                    </div>

                                </Flex>
                            </Col>

                        </Row>
                    </>
                    :
                    <>
                        <Row className="ps-2 pb-1 ">
                            <Col className="col-12 align-content-center">
                                <Row>
                                    <Col className="col-12 m-0">
                                        <Link className="fw-semi-bold fs--2" to={"/perfil/" + idUsuario}>
                                            🤖 Sistema -- {vNombreUsuario}
                                        </Link>  • {" "}
                                        <span className="mb-0 fs--1  text-500">
                                            {vIP} • {vFecha} • <img src="/img/reloj48.png" width={15}></img> {vHora} • {' '}
                                        </span>
                                    </Col>
                                </Row>
                            </Col>

                            <Col className="col-12 align-self-center">
                                <span className="fs--1">
                                    <span dangerouslySetInnerHTML={{ __html: vComentario }} />
                                </span>
                            </Col>

                        </Row>
                    </>
            }


        </>

    );
};

FeedCardHeaderInfiniteScroll.propTypes = {
    vNombreUsuario: PropTypes.string,
    vFecha: PropTypes.string,
    vHora: PropTypes.string,
    vComentario: PropTypes.string,
    idTipoComentario: PropTypes.number,
    vIP: PropTypes.string,
    idUsuario: PropTypes.number,
    comentarioExtra: PropTypes.string,
    idTipoComentarioExtra: PropTypes.number,
    idOrden: PropTypes.number,
    idLicencia: PropTypes.number,

};

export default FeedCardHeaderInfiniteScroll;
