import classNames from 'classnames';
import createMarkup from 'helpers/createMarkup';
import useToken from 'hooks/useToken';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ImagenPerfil from '../../components/common/ImagenPerfil';

const Notification = ({
    flush,
    notification,
    setIdLead,
    setShowModalSeguimientoLead,
    hayNotificacionesNoLeidas
    //setIsModalOpen
}) => {

    const { token } = useToken();
    const navigate = useNavigate();

    const objAction = JSON.parse(notification.vJsonAction);
    //console.log("notification: ", notification); 
    //console.log("objAction: ", objAction);

    //Maneja el click para abrir el modal de seguimiento de lead
    const clickToModalSeguimientoLead = (idLead) => {

        // setIsModalOpen(true);

        setIdLead(idLead);
        setShowModalSeguimientoLead(true);

        notification.bFueMostrado = true;
        marcarNotificacionComoMostrada(notification.idNotificacion);

    };

    const clickToUrl = () => {

        console.log("objAction: ", objAction);
        notification.bFueMostrado = true;
        marcarNotificacionComoMostrada(notification.idNotificacion);


        navigate(objAction.detalles.vUrl);
    };

    const marcarNotificacionComoMostrada = async (idNotificacion) => {

        var jDataSend = {};
        jDataSend.idNotificacion = idNotificacion;

        const response = await fetch("/api/account/MarcarNotificacionComoMostrada/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jDataSend)
            }).catch((error) => {

                //setMensajeError(error);
                //setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            console.log("==== NOTIFICACI�N MARCADA COMO MOSTRADA ====");

            console.log("response: ", response);

            const data = await response.json();
            console.log("data: ", data);

            if (data.iResponseType == 1) {
                hayNotificacionesNoLeidas();
            } //else {

            //setMensajeError(data.vMessage);
            //setShowMensajeError(true);
            //}

        }
        else {

            //setMensajeError(response.status + ' ' + response.statusText);
            //setShowMensajeError(true);

            console.log(response);
        }
    }

    return (
        <div
            className={classNames(
                'notification p-0 ',
                { 'notification-unread': !notification.bFueMostrado, 'notification-flush': flush },
                /*className*/
            )}
            to=""
            style={{ border: 'none', cursor: 'pointer' }}
            onClick={() => {
                //si va mostrar un modal entra aqui
                if (objAction.TypeAction == 1) {

                    //si es seguimiento lead va entrar aqui
                    if (objAction.detalles.idModalType == 1) {
                        clickToModalSeguimientoLead(objAction.detalles.data.idLead);
                    }
                }

                if (objAction.TypeAction == 2) {
                    //si es URL entra aqui
                    clickToUrl();


                }
            }
            }
        >
            {
                <div className="notification-avatar me-3 justify-items-center">
                    {/*<Avatar {...avatar} className="me-3" />*/}
                    <ImagenPerfil idUsuario={notification.idUsuarioSource}></ImagenPerfil>
                </div>
            }
            <div className="notification-body" >
                <p className="mb-1" dangerouslySetInnerHTML={createMarkup(notification.vCuerpo)} />
                <span className="notification-time">
                    {/*{emoji && (*/}
                    {/*    <span className="me-2" role="img" aria-label="Emoji">*/}
                    {/*        {emoji}*/}
                    {/*    </span>*/}
                    {/*)}*/}
                    {notification.time}
                </span>
            </div>
        </div>
    );
};

Notification.propTypes = {
    flush: PropTypes.bool,
    notification: PropTypes.object,
    setIdLead: PropTypes.func,
    setShowModalSeguimientoLead: PropTypes.func,
    hayNotificacionesNoLeidas: PropTypes.func
    //setIsModalOpen: PropTypes.func,
};


export default Notification;
