import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCloseButton from 'components/common/FalconCloseButton';
import Flex from 'components/common/Flex';
import React, { Fragment, useState } from 'react';
import { Dropdown, Form, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SoftBadge from 'components/common/SoftBadge';
import useToken from 'hooks/useToken';


const SearchBox = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState('');
    const [resultItem, setResultItem] = useState({});

    const { token, setToken } = useToken();
    const [cargando, setCargando] = useState(true);


    const ObtenerResultadosBusqueda = async (data) => {

        setCargando(true);
        const response = await fetch("/api/home/ObtenerResultadosBusqueda/?match=" + data, {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {

                console.log('Hubo un problema con la peticion Fetch: ' + error.message);

            });

        if (response.ok) {
            console.log(response);
            const data = await response.json();
            console.log(data);
            setResultItem(data)

        }
        else {

            console.log(response);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setCargando(false);

    };



    return (
        <Dropdown show={dropdownOpen} className="search-box">
            <Form className="position-relative" >
                <Form.Control
                    type="search"
                    autoComplete="off"
                    placeholder="Buscar..."
                    aria-label="Buscar"
                    className="rounded-pill search-input"
                    value={searchInputValue}
                    onChange={({ target }) => { setSearchInputValue(target.value); ObtenerResultadosBusqueda(target.value) }}
                    onFocus={() => setDropdownOpen(true)}

                />
                <FontAwesomeIcon
                    icon="search"
                    className="position-absolute text-400 search-box-icon"
                />
                {(dropdownOpen || searchInputValue) && (
                    <div className="search-box-close-btn-container">
                        <FalconCloseButton
                            size="sm"
                            noOutline
                            className="fs--2"
                            onClick={() => {
                                setSearchInputValue('');
                                setDropdownOpen(false);
                                setResultItem({});
                            }}
                        />
                    </div>
                )}
            </Form>
            <Dropdown.Menu>
                <div className="scrollbar py-3" style={{ maxHeight: '24rem' }}>
                    {cargando && (<Dropdown.Item><Spinner size="sm" className="text-inline" animation="border" variant="primary" /> Cargando...</Dropdown.Item>)}
                    {!cargando &&
                        <>
                            <Dropdown.Header as="h6" className="px-x1 pt-0 pb-2 fw-medium">
                                Clientes
                            </Dropdown.Header>
                            {Object.keys(resultItem).length > 0 && resultItem['table1'].map(item => (
                                JSON.parse(item.coincidencias) &&
                                JSON.parse(item.coincidencias).map((match, index) => {
                                    return (
                                        <Dropdown.Item
                                            onClick={() => setDropdownOpen(false)}
                                            as={Link}
                                            to={item.url + match.idClienteAkasia}
                                            className="fs--1 px-x1 py-1 hover-primary "
                                            key={index}
                                        >

                                            <Flex alignItems="center">
                                                <img src="/img/cliente48.png" width={18}></img>&nbsp;
                                                <div className="fw-normal">

                                                    <Fragment key={index}>
                                                        {match.idClienteAkasia} - {match.vNombre} {match.vApellidos}
                                                        <FontAwesomeIcon
                                                            icon="chevron-right"
                                                            className="mx-1 text-500 fs--2"
                                                            transform="shrink 2"
                                                        />

                                                    </Fragment>

                                                </div>
                                            </Flex>

                                        </Dropdown.Item>
                                    );
                                })
                            ))
                            }
                            <hr className="text-200 dark__text-900" />
                            <Dropdown.Header as="h6" className="px-x1 pt-0 pb-2 fw-medium">
                                Licencias
                            </Dropdown.Header>
                            {Object.keys(resultItem).length > 0 && resultItem['table2'].map(item => (
                                JSON.parse(item.coincidencias) &&
                                JSON.parse(item.coincidencias).map((match, index) => {
                                    return (
                                        <Dropdown.Item
                                            onClick={() => setDropdownOpen(false)}
                                            as={Link}
                                            to={item.url + match.idLicencia}
                                            className="fs--1 px-x1 py-1 hover-primary "
                                            key={index}
                                        >

                                            <Flex alignItems="center">
                                                <img src="/img/archivo20.png" width={18}></img>&nbsp;
                                                <div className="fw-normal">

                                                    <Fragment key={index}>
                                                        {match.idLicencia} - {match.idCatTipoLicencia == 1 ? 'POS Servidor' :
                                                            match.idCatTipoLicencia == 2 ? 'POS Caja Adicional' :
                                                                match.idCatTipoLicencia == 3 ? 'Nube Akasia' : 'POS App Comandas'}
                                                        <FontAwesomeIcon
                                                            icon="chevron-right"
                                                            className="mx-1 text-500 fs--2"
                                                            transform="shrink 2"
                                                        />



                                                    </Fragment>

                                                </div>
                                            </Flex>

                                        </Dropdown.Item>
                                    );
                                })
                            ))
                            }
                            <hr className="text-200 dark__text-900" />
                            <Dropdown.Header as="h6" className="px-x1 pt-0 pb-2 fw-medium">
                                &Oacute;rdenes
                            </Dropdown.Header>
                            {Object.keys(resultItem).length > 0 && resultItem['table3'].map(item => (
                                JSON.parse(item.coincidencias) &&
                                JSON.parse(item.coincidencias).map((match, index) => {
                                    return (
                                        <Dropdown.Item
                                            onClick={() => setDropdownOpen(false)}
                                            as={Link}
                                            to={item.url + match.idOrden}
                                            className="fs--1 px-x1 py-1 hover-primary "
                                            key={index}
                                        >

                                            <Flex alignItems="center">
                                                <img src="/img/ordenes96.png" width={18}></img>&nbsp;
                                                <div className="fw-normal">

                                                    <Fragment key={index}>
                                                        {match.idOrden} - ${match.dTotal} {match.vMoneda}
                                                        <FontAwesomeIcon
                                                            icon="chevron-right"
                                                            className="mx-1 text-500 fs--2"
                                                            transform="shrink 2"
                                                        />
                                                        <SoftBadge
                                                            pill
                                                            size="sm"
                                                            bg={match.bIsPagada ? 'success' : 'danger'}
                                                            className="fs--1"
                                                        >
                                                            <span className="mb-0">
                                                                {match.bIsPagada && 'Pagada'}
                                                                {!match.bIsPagada && 'No pagada'}
                                                            </span>

                                                        </SoftBadge>
                                                    </Fragment>

                                                </div>
                                            </Flex>

                                        </Dropdown.Item>
                                    );
                                })
                            ))
                            }
                        </>}
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};



export default SearchBox;
