import dayjs from 'dayjs';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { getColor, rgbaColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  LegendComponent
]);



const LeadsActualizadosChart = forwardRef(({ chartData_ }, ref) => {

    const tooltipFormatter = params => {
        //console.log("params", params);
        var x =  params
            .map(
                ({ value, name }, index) =>
                    '<div id="'+index+'" class="" style="background-color: ${borderColor}"></div>'+
                    '<span class="text-600 fw-bold"> Leads </span> <span class="text-dark fw-bold">' + value + '</span>' +
                    '<br/>'+
                    '<span class= "text-600 fw-bold" > Date </span > <span class="text-dark fw-bold">' +
                    new Date(name).toLocaleDateString('es-mx', {weekday: 'long', year: 'numeric', month: 'short', day: 'numeric',}) + '</span>'
                    

            )
            .join('<br/>');
        //console.log("x", x);
        return x;
    };

    const getOptions = () => ({
        color: getColor('gray-100'),
        tooltip: {
            trigger: 'axis',
            padding: [7, 10],
            backgroundColor: getColor('gray-100'),
            borderColor: getColor('gray-100'),
            textStyle: { color: getColor('dark') },
            borderWidth: 1,
            formatter: tooltipFormatter,
            transitionDuration: 0
        },
        legend: {
            data: ['Value'],
            show: false
        },
        xAxis: {
            type: 'category',
            data: chartData_.Dates,
            boundaryGap: false,
            axisPointer: {
                lineStyle: {
                    color: getColor('gray-300'),
                    type: 'dashed'
                }
            },
            splitLine: { show: false },
            axisLine: {
                lineStyle: {
                    color: rgbaColor('#000', 0.01),
                    type: 'dashed'
                }
            },
            axisTick: { show: false },
            axisLabel: {
                color: getColor('gray-400'),
                margin: 15,
                formatter: value => dayjs(value).format('MMM DD')
            }
        },
        yAxis: {
            type: 'value',
            axisPointer: { show: false },
            splitLine: {
                lineStyle: {
                    color: getColor('gray-300'),
                    type: 'dashed'
                }
            },
            boundaryGap: false,
            axisLabel: {
                show: true,
                color: getColor('gray-400'),
                margin: 15
            },
            axisTick: { show: false },
            axisLine: { show: false }
        },
        series: [
            {
                name: 'Leads',
                type: 'line',
                data: chartData_.Value,
                lineStyle: { color: getColor('primary') },
                itemStyle: {
                    borderColor: getColor('primary'),
                    borderWidth: 2,
                    backgroundColor: 'transparent'
                },
                label: {
                    show: true
                },
                symbol: 'circle',
                symbolSize: 10,
                emphasis: {
                    scale: true
                },
                areaStyle: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            {
                                offset: 0,
                                color: rgbaColor(getColor('primary'), 0.2)
                            },
                            {
                                offset: 1,
                                color: rgbaColor(getColor('primary'), 0)
                            }
                        ]
                    }
                }
            },
        ],
        grid: { right: '18px', left: '40px', bottom: '15%', top: '5%' }
    });

  return (
    <ReactEChartsCore ref={ref} echarts={echarts} option={getOptions()} />
  );
});

LeadsActualizadosChart.propTypes = {
    chartData_: PropTypes.func,
};

export default LeadsActualizadosChart;
