import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import React, { useState } from 'react';
import { Button, Card, Col, Form, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';

import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import FalconCloseButton from 'components/common/FalconCloseButton';
import IconButton from 'components/common/IconButton';
import MensajeErrror from 'components/common/MensajeError';
import SoftBadge from 'components/common/SoftBadge';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import useFiltro from 'hooks/useFiltro';
import useToken from 'hooks/useToken';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import StatisticsCard from './StatisticsCard';

import bg3 from 'assets/img/icons/spot-illustrations/corner-3.png';
import ModalSeguimientoLead from '../leads/ModalSeguimientoLead';
import MensajeInformativo from '../../components/common/MensajeInformativo';



const Licencias = () => {

    const [licencias, setLicencias] = useState([]);
    const [vendedores, setVendedores] = useState([]);
    const [tipoLicencias, setTipoLicencias] = useState([]);
    const [idLead, setIdLead] = useState(0);
    const { FiltroLicencia, setFiltroLicencia, resetFiltroLicencia } = useFiltro();

    const [cargando, setCargando] = useState(false);
    const [mensajeError, setMensajeError] = useState('');
    const [mensajeInformativo, setMensajeInformativo] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);
    const [showMensajeInformativo, setShowMensajeInformativo] = useState(false);
    const [showModalFiltro, setShowModalFiltro] = useState(false);
    const [procesandoModal, setProcesandoModal] = useState(false);
    const [showModalSeguimientoLead, setShowModalSeguimientoLead] = useState(false);
    const [numLicAct, setNumLicAct] = useState([])
    const [numLicPrueba, setNumLicPrueba] = useState([])

    const { token, setToken } = useToken();

    const columns = [
        {
            accessor: 'none',
            Header: '',
            Cell: rowData => {
                const { idLicencia } = rowData.row.original;
                return (
                    <>
                        <Row className="flex-end-center">
                            <Col xs="auto" >
                                <Link to={"/licencias/detalle/" + idLicencia}>
                                    <img title="Ver informaci&oacute;n" src="../img/ver20.png"></img >
                                </Link>
                            </Col>
                        </Row>
                    </>
                );
            }
        },
        {
            accessor: 'idLicencia',
            Header: 'ID',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { idLicencia, bIsPeriodoPrueba, vUUIDEmpresa, vUUIDLicencia } = rowData.row.original;
                return (
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip style={{ position: 'fixed' }} >
                                <strong>UUID EMPRESA</strong>
                                <p>{vUUIDEmpresa}</p>
                                <strong>UUID LICENCIA</strong>
                                <p>{vUUIDLicencia}</p>
                            </Tooltip>
                        }
                    >
                        {bIsPeriodoPrueba ?
                            <span className="text-danger">
                                <Link to={"/licencias/detalle/" + idLicencia}> <b>{idLicencia}</b></Link>
                            </span>
                            :
                            <span>
                                <Link to={"/licencias/detalle/" + idLicencia}> <b>{idLicencia}</b></Link>
                            </span>}
                    </OverlayTrigger>
                )
            }
        },
        {
            accessor: 'dUltimaConexion',
            Header: 'Ultima Conexion',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { dUltimaConexion, bIsPeriodoPrueba } = rowData.row.original;
                return bIsPeriodoPrueba ? (<span className="text-danger"><b>{dUltimaConexion}</b></span>) : (<span> {dUltimaConexion}</span>)
            }
        },
        {
            accessor: 'vDescripcionTipo',
            Header: 'Tipo licencia',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { vDescripcionTipo, bIsPeriodoPrueba } = rowData.row.original;
                return bIsPeriodoPrueba ? (<span className="text-danger"><b>{vDescripcionTipo}</b></span>) : (<span> <b>{vDescripcionTipo}</b></span>)
            }
        },
        {
            accessor: 'bIsperiodoPrueba',
            Header: 'Estado',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { bIsPeriodoPrueba, bCancelado, iEstadoPoliza, dFechaVencimientoSoporte, idCatTipoLicencia, dDiasFaltantes, dFinLicenciaUso } = rowData.row.original;

                return (
                    bCancelado ? (
                        <>
                            <SoftBadge pill bg='danger' className='me-2'>CANCELADO</SoftBadge>
                        </>
                    ) : (
                        <>
                            {bIsPeriodoPrueba ? <img src="../img/noactivo.png"></img > : <img src="../img/activo.png"></img>}
                            {idCatTipoLicencia == 3 ?

                                <OverlayTrigger
                                    overlay={
                                        <Tooltip style={{ position: 'fixed' }} >
                                            <strong>NUBE AKASIA</strong><br></br>
                                            <span>Vencimiento: {dFinLicenciaUso}</span>
                                            <br></br>
                                            <span>Restante: {dDiasFaltantes} d&iacute;as</span>
                                        </Tooltip>
                                    }
                                >
                                    {dDiasFaltantes > 0 ? <img src="../img/nubeActiva.png"></img > : <img src="../img/nubeVencida.png"></img >}
                                </OverlayTrigger>


                                : ''}
                            {iEstadoPoliza == 1 &&
                                <>
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip style={{ position: 'fixed' }} >
                                                <strong>P&Oacute;LIZA SOPORTE T&Eacute;CNICO</strong>
                                                <br></br>
                                                <span>Vencimiento: {dFechaVencimientoSoporte}</span>
                                            </Tooltip>
                                        }
                                    >
                                        <img src="../img/polizaActiva.png"></img >
                                    </OverlayTrigger>
                                </>
                            }
                            {iEstadoPoliza == 2 &&
                                <>
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip style={{ position: 'fixed' }} >
                                                <strong>P&Oacute;LIZA SOPORTE T&Eacute;CNICO</strong>
                                                <br></br>
                                                <span>Vencimiento: {dFechaVencimientoSoporte}</span>
                                            </Tooltip>
                                        }
                                    >
                                        <img src="../img/polizaVencida.png"></img >
                                    </OverlayTrigger>
                                </>
                            }
                            {(iEstadoPoliza == 3 || iEstadoPoliza == 4 || iEstadoPoliza == 5) &&
                                <>
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip style={{ position: 'fixed' }} >
                                                <strong>P&Oacute;LIZA SOPORTE T&Eacute;CNICO</strong>
                                                <br></br>
                                                <span>Vencimiento: {dFechaVencimientoSoporte}</span>
                                            </Tooltip>
                                        }
                                    >
                                        <img src="../img/polizaPorVencer.png"></img >
                                    </OverlayTrigger>
                                </>
                            }

                        </>
                    )
                )
            }
        },
        {
            accessor: 'vLead',
            Header: 'Lead',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { idLead } = rowData.row.original;
                return idLead > 0 && <Link to={"#"} onClick={() => { setIdLead(idLead); setShowModalSeguimientoLead(true); }}>
                    {"Lead #" + idLead}
                </Link>
            }
        },
        {
            accessor: 'vNombreVendedor',
            Header: 'Vendedor',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { vNombreVendedor } = rowData.row.original;
                return <span> {vNombreVendedor}</span>
            }
        },
        {
            accessor: 'vDatosPCAutorizada',
            Header: '',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { vDatosPCAutorizada } = rowData.row.original;
                return (vDatosPCAutorizada ? <OverlayTrigger
                    overlay={
                        <Tooltip style={{ position: 'fixed' }} >
                            <strong>PC AUTORIZADA</strong>
                            <br></br>
                            <span>{vDatosPCAutorizada}</span>
                        </Tooltip>
                    }
                >
                    <img src="/img/pcautorizada25.png"></img>
                </OverlayTrigger>
                    : <p> </p>
                )
            }
        },
        /*
    {
            accessor: 'bCrack',
            Header: '',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { bCrack } = rowData.row.original;
                return (bCrack ? <OverlayTrigger
                    overlay={
                        <Tooltip style={{ position: 'fixed' }} >
                            <strong>SOY UNA RATA</strong>
                        </Tooltip>
                    }
                >
                    <img src="/img/rata32.png" width="20"></img>
                </OverlayTrigger>
                  :<span></span>
                )
            }
        },*/
        {
            accessor: 'vNombreEmpresa',
            Header: 'Nombre Empresa',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { vNombreEmpresa, idLicencia, bIsPeriodoPrueba } = rowData.row.original;
                return (<Link to={"/licencias/detalle/" + idLicencia}>


                    {bIsPeriodoPrueba ? <span className="text-danger d-inline-block text-truncate" style={{ maxWidth: '250px' }}>{vNombreEmpresa}</span> : <span className="d-inline-block text-truncate" style={{ maxWidth: '250px' }}>{vNombreEmpresa}</span>}

                </Link>);
            }
        },
        {
            accessor: 'vNombreCliente',
            Header: 'Nombre Cliente',
            cellProps: {
                className: 'ps-1'
            },
            Cell: rowData => {
                const { vNombreCliente, idClienteAkasia, bIsPeriodoPrueba, vTelefonoPersonal, vTelefonoTrabajo, vEmail } = rowData.row.original;
                return (
                    <OverlayTrigger
                        placement="left"
                        overlay={
                            <Tooltip className="fs--2" style={{ position: 'fixed' }} >
                                {vTelefonoPersonal && <><strong>TEL. PERSONAL</strong><br></br>
                                    <span>{vTelefonoPersonal}</span></>}

                                {vTelefonoTrabajo && <><br></br><strong>TEL. TRABAJO</strong><br></br>
                                    <span>{vTelefonoTrabajo}</span></>}
                                {vEmail && <>
                                    <br></br><strong>E-MAIL</strong><br></br>
                                    <span>{vEmail}</span></>}
                            </Tooltip>
                        }
                    >
                        {bIsPeriodoPrueba ?
                            <span className="text-danger">
                                <Link to={"/clientes/detalle/" + idClienteAkasia}> <span className="text-danger d-inline-block text-truncate" style={{ maxWidth: '250px' }}>{vNombreCliente}</span></Link>
                            </span>
                            :
                            <span>
                                <Link to={"/clientes/detalle/" + idClienteAkasia}> <span className="d-inline-block text-truncate" style={{ maxWidth: '250px' }}>{vNombreCliente}</span></Link>
                            </span>}
                    </OverlayTrigger>
                );
            }
        },
        {
            Header: ' ',
            Cell: rowData => {
                const { vTelefonoPersonal } = rowData.row.original;
                return <>
                    <a href={"https://wa.me/" + vTelefonoPersonal} target="_blank" rel="noreferrer">
                        <img title="Enviar WhatsApp" src="../img/whatsapp20.png"></img >
                    </a>

                </>
            }
        },
        {
            accessor: 'vTelefonoPersonal',
            Header: 'Tel\u00e9fono',
            Cell: rowData => {
                const { vTelefonoPersonal } = rowData.row.original;
                return <>

                    {vTelefonoPersonal}
                </>
            }
        },
        {
            accessor: 'vUUIDLicencia',
            Header: 'UUID Licencia',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { vUUIDLicencia, bIsPeriodoPrueba } = rowData.row.original;
                return bIsPeriodoPrueba ? <span className="text-danger">{vUUIDLicencia}</span> : <span> {vUUIDLicencia}</span>
            }
        },
        {
            accessor: 'vUUIDEmpresa',
            Header: 'UUID Empresa',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { vUUIDEmpresa, bIsPeriodoPrueba } = rowData.row.original;
                return bIsPeriodoPrueba ? <span className="text-danger">{vUUIDEmpresa}</span> : <span> {vUUIDEmpresa}</span>
            }
        }

    ];
    const mostrarLicencias = async () => {

        setCargando(true);
        setShowModalFiltro(false);

        const response = await fetch("/api/licencia/getlicencias/?vFiltro=" + encodeURIComponent(JSON.stringify(FiltroLicencia)),
            {
                method: 'GET',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            });
        if (response.ok) {
            const data = await response.json();
            setLicencias(data['table1']);
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
        setCargando(false);
        setProcesandoModal(false);
    };

    const obtenerDatosBusqueda = async () => {

        setCargando(true);
        const response = await fetch("/api/licencia/ObtenerDatosBusqueda",
            {
                method: 'GET',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }).catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            const data = await response.json();

            setVendedores(data['table1']);
            setTipoLicencias(data['table2']);
            setNumLicAct(data['table3']);
            setNumLicPrueba(data['table4']);
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
        setCargando(false);
        mostrarLicencias()
    };

    useEffect(() => {

        obtenerDatosBusqueda();
    }, []);


    return (
        <>
            <Card className="mb-1 p-2">
                <Row className="g-2 align-items-sm-center">
                    <Col xs="auto">
                        <img src="/img/licencia96.png" alt="connectCircle" height={30} />
                    </Col>
                    <Col>
                        <h5>
                            Cat&aacute;logo de Licencias
                        </h5>
                    </Col>
                    <Col sm="auto" className="ms-auto">
                        <Link to="nueva">
                            <Button size="sm" variant="falcon-primary">Nueva Licencia</Button>
                        </Link>
                    </Col>
                </Row>
            </Card>
            <Row className="g-1 mb-1">
                <Col xxl={12} md={12}>
                    <Row className="g-1">
                        <Col sm={12} md={6} lg={3}>
                            <StatisticsCard stat={{
                                title: 'Licencias Servidor',
                                value: numLicAct.length > 0 ? numLicAct[0].iNum : 0,
                                decimal: false,
                                suffix: ' conectadas',
                                prefix: '',
                                valueClassName: 'text-success',
                                badgeBg: 'success',
                                badgeText: 'Hoy',
                                image: bg3,
                                imgLic: "/img/servidor48.png"
                            }} style={{ minWidth: '12rem' }} />

                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <StatisticsCard stat={{
                                title: 'Licencias Caja',
                                value: numLicAct.length > 1 ? numLicAct[1].iNum : 0,
                                decimal: false,
                                suffix: ' conectadas',
                                prefix: '',
                                valueClassName: 'text-success',
                                badgeBg: 'success',
                                badgeText: 'Hoy',
                                image: bg3,
                                imgLic: "/img/caja48.png"
                            }} style={{ minWidth: '12rem' }} />

                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <StatisticsCard stat={{
                                title: 'Licencias Nube',
                                value: numLicAct.length > 2 ? numLicAct[2].iNum : 0,
                                decimal: false,
                                suffix: ' conectadas',
                                prefix: '',
                                valueClassName: 'text-primary',
                                badgeBg: 'success',
                                badgeText: 'Hoy',
                                image: bg3,
                                imgLic: "/img/nube48.png"
                            }} style={{ minWidth: '12rem' }} />

                        </Col>
                        <Col sm={12} md={6} lg={3}>
                            <StatisticsCard stat={{
                                title: 'Licencias Prueba',
                                value: numLicPrueba.length > 0 ? numLicPrueba[0].iNum : 0,
                                decimal: false,
                                suffix: ' conectadas',
                                prefix: '',
                                valueClassName: 'text-danger',
                                badgeBg: 'danger',
                                badgeText: 'Hoy',
                                image: bg1,
                                imgLic: "/img/servidor48.png"
                            }} style={{ minWidth: '12rem' }} />

                        </Col>

                    </Row>
                </Col>

            </Row>
            <AdvanceTableWrapper
                columns={columns}
                data={licencias}
                sortable
                pagination
                perPage={15}
            >

                <Card className="mb-3">
                    <Card.Header>
                        <Form onSubmit={mostrarLicencias}>
                            <Row className="flex-between-center">
                                <Col xs={12} sm={6} md={6} lg={3} className="d-flex align-items-center pe-0 mb-2">
                                    <Form.Select
                                        size="sm"
                                        aria-label="Default select"
                                        value={FiltroLicencia.vNombreVendedor}
                                        onChange={(event) => { setFiltroLicencia({ ...FiltroLicencia, vNombreVendedor: event.target.value }) }}
                                    >
                                        <option value="">Todos los vendedores</option>
                                        <option value="NoAsignados">No asignados</option>
                                        {vendedores.map((vendedor, index) => {
                                            return (<option key={index} value={vendedor.vUsuario}>{vendedor.vNombre} { vendedor.vApellidos}</option>)
                                        })}

                                    </Form.Select>

                                </Col>
                                <Col xs={12} sm={6} md={6} lg={3} className="d-flex align-items-center pe-0 mb-2">
                                    <Form.Select
                                        size="sm"
                                        aria-label="Default select"
                                        onChange={(event) => { setFiltroLicencia({ ...FiltroLicencia, idCatTipoLicencia: event.target.value }) }}
                                        value={FiltroLicencia.idCatTipoLicencia}
                                    >
                                        <option value="">Todos las licencias</option>
                                        {tipoLicencias.map((licencia, index) => {
                                            return (<option key={index} value={licencia.idCatTipoLicencia}>{licencia.vDescripcionTipo}</option>)
                                        })}
                                    </Form.Select>

                                </Col>

                                <Col xs={12} sm={6} md={6} lg={3} className="d-flex align-items-center pe-0 mb-2">
                                    <Form.Select
                                        size="sm"
                                        aria-label="Default select"
                                        value={FiltroLicencia.vEstatusLicencia}
                                        onChange={(event) => { setFiltroLicencia({ ...FiltroLicencia, vEstatusLicencia: event.target.value }) }}
                                    >
                                        <option value="">Todos los estatus</option>
                                        <option value="prueba">Prueba</option>
                                        <option value="activa">Activa</option>
                                        <option value="sinlicencia">Canceladas</option>
                                        <option value="30">Por vencer (30 d&iacute;as)</option>
                                        <option value="60">Por vencer (60 d&iacute;as)</option>
                                        <option value="90">Por vencer (90 d&iacute;as)</option>
                                    </Form.Select>


                                </Col>
                                <Col xs={12} sm={6} md={6} lg={3} className="d-flex align-items-center pe-0 mb-2">
                                    <Form.Select
                                        size="sm"
                                        aria-label="Default select"
                                        value={FiltroLicencia.vEstatusPoliza}
                                        onChange={(event) => { setFiltroLicencia({ ...FiltroLicencia, vEstatusPoliza: event.target.value }) }}
                                    >
                                        <option value="">Todas las p&oacute;lizas</option>
                                        <option value="30">P&oacute;liza por vencer (30 d&iacute;as)</option>
                                        <option value="60">P&oacute;liza por vencer (60 d&iacute;as)</option>
                                        <option value="90">P&oacute;liza por vencer (90 d&iacute;as)</option>
                                        <option value="polizaVencida">P&oacute;liza vencida</option>
                                        <option value="PolizaActiva">P&oacute;liza activa</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row className="flex-between-center">
                                <Col xs={12} sm={6} md={6} lg={3} className="d-flex align-items-center pe-0 mb-2">
                                    <Form.Group className="col-12">
                                        <Form.Control
                                            onChange={(event) => { setFiltroLicencia({ ...FiltroLicencia, vNombreCliente: event.target.value.toUpperCase() }) }}
                                            //onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                            size="sm"
                                            type="text"
                                            placeholder="Nombre del cliente"
                                            value={FiltroLicencia.vNombreCliente}
                                        />

                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={3} className="d-flex align-items-center pe-0 mb-2">
                                    <Form.Group className="col-12">
                                        <Form.Control
                                            onChange={(event) => { setFiltroLicencia({ ...FiltroLicencia, vNombreEmpresa: event.target.value.toUpperCase() }) }}
                                            //onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                            size="sm"
                                            type="text"
                                            placeholder="Nombre de la empresa"
                                            value={FiltroLicencia.vNombreEmpresa}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={3} className="d-flex align-items-center pe-0 mb-2">
                                    {/*<IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    icon="filter"
                                    transform="shrink-3"
                                    className="mx-2"
                                    onClick={() => setShowModalFiltro(true)}
                                >

                                </IconButton>*/}
                                    {cargando ? <Button
                                        variant="falcon-default"
                                        size="sm"
                                        transform="shrink-3"
                                        disabled
                                    >
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />&nbsp;
                                        Buscando...
                                    </Button>
                                        : <><IconButton
                                            variant="falcon-default"
                                            type="submit"
                                            size="sm"
                                            icon="search"
                                            transform="shrink-3"

                                            onClick={() => mostrarLicencias()}
                                        >
                                            <span className="d-none d-sm-inline-block ms-1">Buscar</span>
                                        </IconButton> <IconButton
                                            variant="falcon-default"
                                            size="sm"
                                            icon="redo-alt"
                                            transform="shrink-3"
                                            className="mx-2"
                                            onClick={() => { resetFiltroLicencia(); }}
                                        >
                                                <span className="d-none d-sm-inline-block ms-1">Reset</span>
                                            </IconButton></>}
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={3} className="d-flex align-items-center pe-0 mb-2">

                                </Col>
                            </Row>
                        </Form>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <Row className="flex-end-center mb-3 px-3">
                            <Col xs={12} md={6} lg={3}>

                            </Col>
                            <Col xs={12} md={6} lg={3}>
                                {!cargando &&
                                    <AdvanceTableSearchBox table />
                                }

                            </Col>
                        </Row>

                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                hover: true,
                                className: 'fs--2 mb-0 overflow-hidden'
                            }}
                        />

                    </Card.Body>
                    <Card.Footer>

                        <AdvanceTableFooter
                            rowCount={licencias.length}
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                        />
                    </Card.Footer>
                </Card>

            </AdvanceTableWrapper>
            {cargando && (<h5><Spinner className="text-inline" animation="border" variant="primary" /> Cargando...</h5>)}
            <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>
            <MensajeInformativo setShowMensajeInformativo={setShowMensajeInformativo} showMensajeInformativo={showMensajeInformativo} mensajeInformativo={mensajeInformativo}></MensajeInformativo>
            {/*Modal seguimiento lead */
                <ModalSeguimientoLead
                    showModal={showModalSeguimientoLead}
                    setShowModal={setShowModalSeguimientoLead}
                    setMensajeError={setMensajeError}
                    setShowMensajeError={setShowMensajeError}
                    setMensajeInformativo={setMensajeInformativo}
                    setShowMensajeInformativo={setShowMensajeInformativo}
                    idLead={idLead}
                    setIdLead={setIdLead}

                />
            }

            {
                showModalFiltro &&
                <Modal
                    show={showModalFiltro}
                    onHide={() => setShowModalFiltro(false)}
                    size="lg"
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >

                    <Modal.Header>
                        <Modal.Title>Filtro de b&uacute;squeda. </Modal.Title>
                        <FalconCloseButton className="btn btn-circle btn-sm transition-base" onClick={() => setShowModalFiltro(false)} />
                    </Modal.Header>
                    <Modal.Body>
                        <Row>

                            <Col xs={12} sm={6} md={6} lg={4} className="d-flex align-items-center mb-3">
                                <Form.Group className="col-12">
                                    <Form.Label>
                                        Estatus Nube Akasia
                                    </Form.Label>
                                    <Form.Select
                                        size="sm"
                                        aria-label="Default select"
                                        value={FiltroLicencia.vEstatusNube}
                                        onChange={(event) => { setFiltroLicencia({ ...FiltroLicencia, vEstatusNube: event.target.value }) }}
                                    >
                                        <option value="">Todos los estatus Nube</option>
                                        <option value="nubePorVencer">Nube por vencer (90 d&iacute;as)</option>
                                        <option value="nubeVencida">Nube vencida</option>
                                        <option value="nubeActiva">Nube activa</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6} md={6} lg={4} className="d-flex align-items-center mb-3">

                            </Col>
                            <Col xs={12} sm={6} md={6} lg={4} className="d-flex align-items-center mb-3">

                            </Col>
                            <Col xs={12} sm={6} md={6} lg={4} className="d-flex align-items-center mb-3">

                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => { setShowModalFiltro(false); }} variant='primary'>Cerrar</Button>
                        {procesandoModal ? <Button variant="primary" disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            Procesando...
                        </Button> : <Button type="submit" onClick={mostrarLicencias} variant='success' >Aplicar filtro</Button>}
                    </Modal.Footer>

                </Modal>
            }
        </>
    );
};

export default Licencias;
