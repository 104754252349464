import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart, LineChart } from 'echarts/charts';
import {
    DatasetComponent,
    GridComponent,
    LegendComponent,
    ToolboxComponent,
    TooltipComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';


echarts.use([
    DatasetComponent,
    TooltipComponent,
    ToolboxComponent,
    GridComponent,
    LegendComponent,
    BarChart,
    LineChart,
    CanvasRenderer,
    CanvasRenderer,
    UniversalTransition
]);


const VentasPorVendedorChart = forwardRef(({ chartData }, ref) => {


    /* const { config } = useContext(AppContext);
     const { isDark } = config;*/

    var seriesType = [];
    //var i = 0;
    chartData.Vendedores.forEach(() => {
        var type = {};
        type.type = "bar";
        seriesType.push(type);

    });

    console.log(seriesType);

    const option = {
        legend: {},
        tooltip: {},
        toolbox: {
            feature: {
                magicType: { show: true, type: ['line', 'bar'] },
                saveAsImage: { show: true }
            }
        },
        dataset: {
            dimension: chartData.Vendedores,
            //source: [{ "TheMonthNameYear": "Septiembre 2023", "2 - BRAYAN ALE": 107891.00, "3 - GABRIELA Y": 52830.00, "4 - MARIBEL": 53538.00, "6 - IGNACIO": 23946.00, "7 - MARIA JOSE": 53904.00, "8 - MOISES ALE": 41300.00, "9 - KASSANDRA ": 30920.00, "10 - JORGE": 0.00 }, { "TheMonthNameYear": "Octubre 2023", "2 - BRAYAN ALE": 103862.41, "3 - GABRIELA Y": 53083.16, "4 - MARIBEL": 31366.70, "6 - IGNACIO": 29474.68, "7 - MARIA JOSE": 54012.09, "8 - MOISES ALE": 75904.42, "9 - KASSANDRA ": 26864.54, "10 - JORGE": 0.00 }, { "TheMonthNameYear": "Noviembre 2023", "2 - BRAYAN ALE": 40454.59, "3 - GABRIELA Y": 23400.22, "4 - MARIBEL": 2876.40, "6 - IGNACIO": 8234.35, "7 - MARIA JOSE": 11236.83, "8 - MOISES ALE": 26245.04, "9 - KASSANDRA ": 6356.00, "10 - JORGE": 0.00 }]
            source: chartData.VentasPorVendedor
        },
        xAxis: { type: 'category' },
        yAxis: {},
        // series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }, { type: 'bar' }, { type: 'bar' }, { type: 'bar' }, { type: 'bar' }, { type: 'bar' }, { type: 'bar' }],
        series: seriesType,
        itemStyle: {
            borderRadius: [3, 3, 0, 0]
        },
        barWidth: '10px',
        grid: {
            top: '20%',
            bottom: 0,
            left: 0,
            right: 0,
            containLabel: true
        }
    }

    return (
        <ReactEChartsCore
            echarts={echarts}
            option={option}
            ref={ref}
        />
    );
});

VentasPorVendedorChart.propTypes = {
    chartData: PropTypes.any,
};

export default VentasPorVendedorChart;
