import IconButton from 'components/common/IconButton';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FiFileText } from 'react-icons/fi';
import { Link, useNavigate, useParams } from 'react-router-dom';

const NuevaLicencia = () => {

    const navigate = useNavigate();
    const { idClienteAkasia } = useParams();

    return (
        <>
            <Card className="mb-1 p-2">
                <Row className="g-2 align-items-sm-center">
                    <Col xs="auto">
                        <IconButton
                            onClick={() => navigate(-1)}
                            variant="falcon-default"
                            size="sm"
                            icon="arrow-left"
                        />
                    </Col>
                    <Col>
                        <h5>
                            Registrar Nueva Licencia
                        </h5>
                    </Col>
                </Row>
            </Card>
            <Row className="g-3 mb-1">
                <Col lg={12}>
                    <Card className="mt-1">
                        <Card.Header className="border-bottom border-200">
                            <h6 className="mb-0">Akasia Punto de Venta</h6>
                        </Card.Header>
                        <Card.Body>
                            <Row className="gx-3">
                                <Col xs={12} md={3} xl={3} xxl={2}  >
                                    <div className="hover-bg-100 py-x1 text-center rounded-3 position-relative">
                                        <img src="/img/servidor48.png"></img>
                                        <h6 className="w-75 mx-auto text-600 mb-x1 pt-2">POS Servidor</h6>
                                        <Link to={"/licencias/nueva-servidor/" + (idClienteAkasia ? idClienteAkasia : '')}>
                                            <IconButton
                                                className="ms-2"
                                                iconClassName="fs--2 me-1"
                                                variant="falcon-default"
                                                size="sm"
                                                icon="plus"
                                            >
                                                Registrar
                                            </IconButton>
                                        </Link>
                                    </div>
                                </Col>
                                <Col xs={12} md={3} xl={3} xxl={2}  >
                                    <div className="hover-bg-100 py-x1 text-center rounded-3 position-relative">
                                        <img src="/img/caja48.png"></img>
                                        <h6 className="w-75 mx-auto text-600 mb-x1 pt-2">POS Caja Adicional</h6>
                                        <Link to={"/licencias/nueva-caja/" + (idClienteAkasia ? idClienteAkasia : '')}>
                                            <IconButton
                                                className="ms-2"
                                                iconClassName="fs--2 me-1"
                                                variant="falcon-default"
                                                size="sm"
                                                icon="plus"
                                                type="submit"
                                            >
                                                Registrar
                                            </IconButton>
                                        </Link>
                                    </div>
                                </Col>
                                <Col xs={12} md={3} xl={3} xxl={2}  >
                                    <div className="hover-bg-100 py-x1 text-center rounded-3 position-relative">
                                        <img src="/img/nube48.png"></img>
                                        <h6 className="w-75 mx-auto text-600 mb-x1 pt-2">Nube Akasia</h6>
                                        <Link to={"/licencias/nueva-nube/" + (idClienteAkasia ? idClienteAkasia : '')}>
                                            <IconButton
                                                className="ms-2"
                                                iconClassName="fs--2 me-1"
                                                variant="falcon-default"
                                                size="sm"
                                                icon="plus"
                                                type="submit"
                                            >
                                                Registrar
                                            </IconButton>
                                        </Link>
                                    </div>
                                </Col>
                                <Col xs={12} md={3} xl={3} xxl={2}  >
                                    <div className="hover-bg-100 py-x1 text-center rounded-3 position-relative">
                                        <img src="/img/appcomandas48.png"></img>
                                        <h6 className="w-75 mx-auto text-600 mb-x1 pt-2">POS App Comanda</h6>
                                        <Link to={"/licencias/nueva-app-comandas/" + (idClienteAkasia ? idClienteAkasia : '')}>
                                            <IconButton
                                                className="ms-2"
                                                iconClassName="fs--2 me-1"
                                                variant="falcon-default"
                                                size="sm"
                                                icon="plus"
                                                type="submit"
                                            >
                                                Registrar
                                            </IconButton>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className="g-3 mb-1">
                <Col lg={12}>
                    <Card className="mt-1">
                        <Card.Header className="border-bottom border-200">
                            <h6 className="mb-0">Contabilidad</h6>
                        </Card.Header>
                        <Card.Body>
                            <Row className="gx-3">
                                <Col xs={12} md={3} xl={3} xxl={2}  >
                                    <div className="hover-bg-100 py-x1 text-center rounded-3 position-relative">
                                        <div
                                            className={`icon-item icon-item-xl shadow-none mx-auto mt-x1 bg-soft-success`}
                                        >
                                            <FiFileText className="text-info fs-2" />
                                        </div>
                                        <h6 className="w-75 mx-auto text-600 mb-x1 pt-2">N&oacute;mina</h6>
                                        <IconButton
                                            className="ms-2"
                                            iconClassName="fs--2 me-1"
                                            variant="falcon-default"
                                            size="sm"
                                            icon="plus"
                                            type="submit"
                                        >
                                            Registrar
                                        </IconButton>
                                    </div>
                                </Col>
                                <Col xs={12} md={3} xl={3} xxl={2}  >
                                    <div className="hover-bg-100 py-x1 text-center rounded-3 position-relative">
                                        <div
                                            className={`icon-item icon-item-xl shadow-none mx-auto mt-x1 bg-soft-success`}
                                        >
                                            <FiFileText className="text-info fs-2" />
                                        </div>
                                        <h6 className="w-75 mx-auto text-600 mb-x1 pt-2">Autofactura</h6>
                                        <IconButton
                                            className="ms-2"
                                            iconClassName="fs--2 me-1"
                                            variant="falcon-default"
                                            size="sm"
                                            icon="plus"
                                            type="submit"
                                        >
                                            Registrar
                                        </IconButton>
                                    </div>
                                </Col>
                                <Col xs={12} md={3} xl={3} xxl={2}  >
                                    <div className="hover-bg-100 py-x1 text-center rounded-3 position-relative">
                                        <div
                                            className={`icon-item icon-item-xl shadow-none mx-auto mt-x1 bg-soft-success`}
                                        >
                                            <FiFileText className="text-info fs-2" />
                                        </div>
                                        <h6 className="w-75 mx-auto text-600 mb-x1 pt-2">Facturaci&oacute;n</h6>
                                        <IconButton
                                            className="ms-2"
                                            iconClassName="fs--2 me-1"
                                            variant="falcon-default"
                                            size="sm"
                                            icon="plus"
                                            type="submit"
                                        >
                                            Registrar
                                        </IconButton>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default NuevaLicencia;