import { v4 as uuid } from 'uuid';
import eventImg1 from 'assets/img/generic/13.jpg';
import generic11 from 'assets/img/generic/11.jpg';


export default [
  
  {
    id: uuid(),
    content: {
      feedEvent: {
        title: "Free new year's eve midnight harbor",
        author: 'Boston harbor now',
        calender: {
          day: '31',
          month: 'Dec'
        },
        regFee: '$49.99 – $89.99',
        eventImg: eventImg1
      }
    },
    details: {
      countLCS: {
        like: 130,
        share: 65
      },
      reactions: {
        like: false,
        comment: false,
        share: false
      },
      comments: [
        {
          id: uuid(),
          avatarSrc: generic11,
          name: 'Rowan Atkinson',
          content: `He starred as Jane Porter in The <a href="#!">@Legend of Tarzan (2016)</a>, Tanya Vanderpoel in for which nominated for a Teen Choice Award, and many other awards.`,
          postTime: '1hr'
        }
      ]
    }
  },
 
  
];
