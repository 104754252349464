import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useToken from 'hooks/useToken';
import React, { useEffect, useState } from 'react';
import { Card, Col, Nav, Row, Spinner, Tab, Toast } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import ContactDetailsHeader from './ContactDetailsHeader';
import ContactProfile from './ContactProfile';
import Tickets from './Tickets';
import Timeline from './Timeline';

const ContactDetails = () => {
    const [setShow] = useState(false);

    const handleShow = () => setShow(true);

    const { id } = useParams();
    const { token, setToken } = useToken();
    const [timeline, setTimeline] = useState([]);
    const [cliente, setCliente] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [mensajeError, setMensajeError] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);

    const ObtenerTicketsCliente = async () => {
        setCargando(true);
        console.log(token);
        const response = await fetch("/api/soportetecnico/ObtenerDetallesSoporteByIDCliente/?idClienteAkasia=" + id, {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch: ' + error);
            console.log('Hubo un problema con la peticion Fetch: ' + error.message);
        });

        if (response.ok) {
            console.log(response);
            const data = await response.json();

            console.log(data['table1']);
            setTimeline(data['table1']);

            console.log(data['table2']);
            setCliente(data['table2']);


        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setCargando(false);
    };

    useEffect(() => {
        ObtenerTicketsCliente();
    }, []);

    return (
        <>
            <ContactDetailsHeader handleShow={handleShow} />
            {cargando ? <h3><Spinner className="text-inline" animation="border" variant="primary" /> Cargando...</h3>
                :
                (
                    <>
                        <Row className="g-3">
                            <Col xxl={3} xl={4} className="order-xl-1">
                                <div className="position-xl-sticky top-0">
                                    <ContactProfile cliente={cliente} />
                                </div>
                            </Col>
                            <Col xxl={9} xl={8}>
                                <Card>
                                    <Tab.Container defaultActiveKey="timeline">
                                        <SimpleBar>
                                            <Card.Header className="p-0 border-bottom">
                                                <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            eventKey="timeline"
                                                            className="d-flex align-items-center py-3 px-x1 mb-0"
                                                        >
                                                            <FontAwesomeIcon
                                                                icon="stream"
                                                                className="text-600 tab-icon"
                                                            />
                                                            <h6 className="text-600 mb-0 ms-1">Timeline</h6>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            eventKey="tickets"
                                                            className="d-flex align-items-center py-3 px-x1 mb-0"
                                                        >
                                                            <FontAwesomeIcon
                                                                icon="ticket-alt"
                                                                className="text-600"
                                                            />
                                                            <h6 className="text-600 mb-0 ms-1">Tickets</h6>
                                                        </Nav.Link>
                                                    </Nav.Item>

                                                </Nav>
                                            </Card.Header>
                                        </SimpleBar>
                                        <Card.Body className="bg-light">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="timeline">
                                                    <Timeline timeline={timeline} />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="tickets">
                                                    <Tickets timeline={timeline} />
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Card.Body>
                                    </Tab.Container>
                                </Card>
                            </Col>
                        </Row>

                        <div className="position-fixed bottom-0 end-0 p-3">
                            <Toast onClose={() => setShowMensajeError(false)} show={showMensajeError} delay={10000} autohide>
                                <Toast.Header className='bg-danger text-white'>
                                    <strong className="me-auto">Error</strong>
                                    <small>Hace un momento</small>
                                </Toast.Header>
                                <Toast.Body><p>{mensajeError}</p></Toast.Body>
                            </Toast>
                        </div>
                    </>
                )
            }


        </>
    );
};

export default ContactDetails;
