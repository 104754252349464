import ReactEChartsCore from 'echarts-for-react/lib/core';
import PropTypes from 'prop-types';
import React from 'react';

import * as echarts from 'echarts/core';
import {
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent
} from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

import {  Col, Row} from 'react-bootstrap';


import { getColor } from 'helpers/utils';

echarts.use([
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    BarChart,
    CanvasRenderer
]);



const LeadsPorEtapaChart = (({ chartData }) => {

    //console.log("ChartData Leads Por Etapa -> ", chartData);

    //console.log("etapa lenght", chartData.dates.length);
 

    const labelOption = {
        show: chartData.dates.length > 11 ? false : true,
        position: "insideBottom",
        distance: 15,
        align: "left",
        verticalAlign: "middle",
        rotate: 90,
        formatter: chartData.dates.length > 5 ? '{c}' : '{c}  {name|{a}}',
        fontSize: 12,
        rich: {
            name: {}
        }
    };

    var series_ = [];
    var etapas_ = [];
    var dates_ = [];

    if (chartData) {
       
        chartData.dates.forEach((date) => {
            dates_.push(
                new Date(date.TheDate).toLocaleDateString('en-US', {
                    year: 'numeric', month: 'short', day: '2-digit', hour12: false
                })
            );
        });

        //Se rrecorren las etapas para formar las series & etapas
        
        chartData.etapas.forEach((etapa) => {
            var serie_ = {};
            serie_.name = etapa.vNombreEtapa;
            serie_.type = 'bar';
            serie_.barGap = 0;
            serie_.label = labelOption;
            serie_.emphasis = {};
            serie_.emphasis.focus = 'series';

            serie_.lineStyle = {}

            serie_.itemStyle = {};

            serie_.itemStyle.borderWidth = 2;

            var valueByDate = [];
            chartData.data.forEach(function (data_) {
                if (data_.idEtapa == etapa.idEtapa)
                    valueByDate.push(data_.RegisteredLeads);
            });

            serie_.data = valueByDate;

            series_.push(serie_);

            etapas_.push(etapa.vNombreEtapa);

        });

        console.log("etapa SeriesOBJ -> ", series_);
        console.log("etapa EtapasOBJ -> ", etapas_);
        console.log("etapa DatesOBJ -> ", dates_);
    }
    



    const option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            data: etapas_ //['Forest', 'Steppe', 'Desert', 'Wetland']
        },
        toolbox: {
            show: true,
            orient: 'vertical',
            left: 'right',
            top: 'center',
            feature: {
                mark: { show: true },
                dataView: { show: true, readOnly: false },
                magicType: { show: true, type: ['line', 'bar', 'stack'] },
                restore: { show: true },
                saveAsImage: { show: true }
            }
        },
        xAxis: [
            {
                type: 'category',
                axisTick: { show: false },
                data: dates_, //['2012', '2013', '2014', '2015', '2016']
                axisLabel: {
                    color: getColor('gray-800'),
                    margin: 15,
                    formatter: value => new Date(value).toLocaleDateString('en-US', {
                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                    })
                }
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: series_
    };

    return (
        <>
            <Row>

                <Col className="col-12">
                    <ReactEChartsCore
                        echarts={echarts}
                        option={option}
                    />
                </Col>

            </Row>
           
        </>
       
    );
});

LeadsPorEtapaChart.propTypes = {
    chartData: PropTypes.any,
};

export default LeadsPorEtapaChart;
