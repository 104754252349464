import Calendar from 'components/common/Calendar';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { CloseButton, Form, Modal, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Event = ({ details, TerminarEvento, habilitarBtnTerminarEvento, editarEventos  }) => {


    const {
        vTituloEvento,
        dFechaEvento,
        vDescripcionEvento,
        idLeadEvento
    } = details;

    const [showModal, setShowModal] = useState(false);
    const [resultadoEvento, setResultadoEvento] = useState('');
    //const [habilitarBtnTerminarEvento, setHabilitarBtnTerminarEvento] = useState(true);
    console.log(dFechaEvento);

    return (
        
        <>
            <Flex>
                <Calendar
                    month={new Date(dFechaEvento).getMonth()}
                    day={new Date(dFechaEvento).getDate()}
                />
                
                <div className="flex-1 position-relative ps-3">
                    <h6 className="fs-0 mb-0">
                        <Link to="#">
                            <span className="me-1">{vTituloEvento}</span>

                        </Link>
                        <OverlayTrigger placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    <strong>Editar Evento</strong>
                                </Tooltip>
                            }>
                            <span>
                                &nbsp;<img style={{ cursor: 'pointer' }} width={20} src="/img/editar20.png" onClick={() => editarEventos()}></img>
                            </span>
                        </OverlayTrigger>
                    </h6>
                    {vDescripcionEvento && <p className="text-1000 mb-0">{vDescripcionEvento}</p>}
                    <p className="text-1000 mb-0">
                        <img width={20} src="/img/reloj48.png"></img> &nbsp;
                        {
                            new Date(dFechaEvento).toLocaleTimeString(
                                'es-mx',
                                {
                                    hour12:true
                                }
                            )
                        }
                    </p>
                    <button
                        onClick={ () => setShowModal(true) }
                        className="d-sm-block me-2 btn btn-falcon-default btn-sm mt-2">
                        Marcar como Terminado
                    </button>
                  

                </div>
            </Flex>
            <Modal
                show={showModal}
                onHide={() => { setShowModal(false); setResultadoEvento(''); }}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Terminar evento</Modal.Title>
                    <CloseButton
                        className="btn btn-circle btn-sm transition-base p-0"
                        onClick={() => { setShowModal(false); setResultadoEvento(''); }}
                    />
                </Modal.Header>
                <Modal.Body>
                    <h4>&iquest;Cu&aacute;l fue el resultado del evento que est&aacute;s marcando como terminado?</h4>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Control
                            as="textarea"
                            rows={3}
                            onChange={(event) => setResultadoEvento(event.target.value)}
                            value={resultadoEvento}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger' onClick={() => { setShowModal(false); setResultadoEvento(''); } }>Cancelar</Button>

                    {
                        habilitarBtnTerminarEvento ?
                            <>
                                <Button variant='success' onClick={() => { TerminarEvento(idLeadEvento, resultadoEvento) }}>Terminar evento</Button>
                            </>
                            :
                            <>
                                <Button disabled  variant='success' onClick={() => { TerminarEvento(idLeadEvento, resultadoEvento) }}>Terminar evento</Button>
                            </>
                    }
                    
                </Modal.Footer>
            </Modal>
        </>
    );
};

Event.propTypes = {
    details: PropTypes.shape({
        vTituloEvento: PropTypes.string.isRequired,
        dFechaEvento: PropTypes.string.isRequired,
        vDescripcionEvento: PropTypes.string,
        idLeadEvento: PropTypes.number
    }),
    TerminarEvento: PropTypes.func,
    habilitarBtnTerminarEvento: PropTypes.bool,
    editarEventos: PropTypes.func
};

export default Event;
