import IconButton from 'components/common/IconButton';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const HistorialMovimientosCliente = ({ clienteActivityLogs }) => {
    console.log('Logs:' + clienteActivityLogs);
    return (
        <Card className="mb-3">
            <Card.Header>
                <h5 className="mb-0">Historial de movimientos / Logs</h5>
            </Card.Header>
            <Card.Body className="border-top p-0 fs--1">
                {clienteActivityLogs.length > 0 && 
                
                    clienteActivityLogs.map((log, index) => (
                        <Row
                            key={index}
                            className="g-0 align-items-center border-bottom py-2 px-3"
                        >
                            <Col lg={1} className="pe-3">
                                <span
                                    className='badge rounded-pill badge-soft-success'>
                                    {log.vIP}
                                </span>
                            </Col>
                            <Col lg={6} className="mt-1 mt-md-0 fs--2">
                                <code className="fs--1">
                                    {log.vMovimiento}
                                </code>
                            </Col>
                            <Col lg={3} className="mt-1 mt-md-0">
                                <code className="fs--1">
                                    {log.vNombre}
                                </code>
                            </Col>
                            <Col lg={2}>

                                {log.dCreatedDate}

                            </Col>
                        </Row>
                    ))
                }    
                
            </Card.Body>
            <Card.Footer className="bg-light p-0">
                <IconButton
                    variant="link"
                    iconClassName="fs--2 ms-1"
                    icon="chevron-right"
                    className="d-block w-100"
                    iconAlign="right"
                    as={Link}
                    to="#!"
                >
                    View more logs
                </IconButton>
            </Card.Footer>
        </Card>
    );
};

HistorialMovimientosCliente.propTypes = {
    clienteActivityLogs: PropTypes.array
};

export default HistorialMovimientosCliente;
