import FalconCloseButton from 'components/common/FalconCloseButton';
import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';

const PurchaseCard = () => {
    const [show, setShow] = useState(true);
    return (
        show && (
            <div className="settings my-3">
                <Card className="p-0 rounded-2 position-relative">
                    <div
                        className="position-absolute"
                        style={{ right: '6px', top: '3px' }}
                    >
                        <FalconCloseButton
                            size="sm"
                            className="fs--2"
                            noOutline
                            onClick={() => setShow(false)}
                        />
                    </div>
                    <Card.Body className="text-center">
                        <img src="/img/libro96.png" alt="" width={80} />
                        <p className="fs--2 mt-2">
                            &iquest;Necesitas asesor&iacute;a para vender m&aacute;s o solucionar problemas de soporte?

                        </p>
                        <div className="d-grid gap-2">
                            <Button
                                as={'a'}
                                href="#"
                                target="_blank"
                                size="sm"
                                className="btn-purchase"
                            >
                                Banco de informaci&oacute;n Akasia
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        )
    );
};

export default PurchaseCard;
