import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useToken from 'hooks/useToken';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { Modal, Button, Col, Row } from 'react-bootstrap';
import FalconCloseButton from '../../../../../components/common/FalconCloseButton';
import Title from './Title';


const ModalDesgloceVentas = (
    {
        idUsuario,
        setMensajeError,
        setShowMensajeError,
        setShowModal,
        showModal,
        dateQuarter
    }
) => {

    const { token, setToken } = useToken();

    const [cargando, setCargando] = useState(true);
    const [dataStatistics, setDataStatistics] = useState([]);

    /**
     * Carga las Estadisticas Mensuales de la venta
     * 
     */
    const ObtenerEstadisticasVentasMensual = async () => {

        setCargando(true);

        var jDataSend = {};

        var newDate_ = new Date(dateQuarter).toLocaleDateString('es-MX', {
            year: 'numeric', month: '2-digit', day: '2-digit'
        });

        jDataSend.idUsuario = idUsuario;
        jDataSend.dDate = newDate_;

        console.log("DataSend -> ", jDataSend);

        const response = await fetch("/api/home/ObtenerEstadisticasVentasMensual", {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jDataSend)
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch: ' + error);
                console.log('Hubo un problema con la peticion Fetch: ' + error.message);

            });

        if (response.ok) {
            console.log("==== MODAL ESTADISTICAS VENTAS ====");
            console.log(response);
            const data = await response.json();
            console.log("Modal Estadisticas Ventas -> ", data);

            var jsonData = JSON.parse(data.detalles);
            console.log("Modal Estadisticas Ventas JSONData -> ", jsonData);
            setDataStatistics(jsonData);

            setCargando(false);
        }
        else {

            setCargando(false);

            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }


    };


    /**
     * Se ejecuta por primera vez
     * 
     */
    useEffect(
        () => {
            ObtenerEstadisticasVentasMensual();
        }
        , []);


    return (
        <>
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                onShow={() => { }}
                size="lg"
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
            >

                <Modal.Header>

                    <Modal.Title>

                        <Title />

                    </Modal.Title>

                    <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setShowModal(false); }} />

                </Modal.Header>
                <Modal.Body>

                    <Row>

                        <Col>

                            <Row className="border-bottom fs--1">

                                <Col className="col-7 fw-bold ">
                                    KPI (Key Performance Indicator)
                                </Col>

                                <Col className="col-5 fw-bold text-center">
                                    {
                                        new Date(dateQuarter).toLocaleDateString('es-MX', {
                                            year: 'numeric', month: 'short'
                                        }).toUpperCase()
                                    }
                                </Col>

                            </Row>

                            {/*TOTALES GENERALES*/}
                            <Row className="mt-4 fs--1">
                                <Col className="fw-bold"> General totals </Col>
                            </Row>

                            <Row className=" border-bottom ps-2 mt-1">

                                <Col className="col-7">
                                    Total sales
                                </Col>

                                <Col className="col-5 text-end">

                                    {
                                        cargando ?
                                            <>
                                                <Skeleton />
                                            </>
                                            :
                                            <>
                                                {
                                                    parseFloat(dataStatistics.table2[0].dTotalVentas).toLocaleString("es-MX", {
                                                        maximumFractionDigits: 2,
                                                        minimumFractionDigits: 2,
                                                        style: 'currency',
                                                        currency: 'MXN'
                                                    }
                                                    )

                                                }
                                            </>

                                    }
                                </Col>

                            </Row>

                            <Row className=" border-bottom ps-2">

                                <Col className="col-7">
                                    AVG Sales by Customer
                                </Col>

                                <Col className="col-5 text-end">

                                    {
                                        cargando ?
                                            <>
                                                <Skeleton />
                                            </>
                                            :
                                            <>
                                                {
                                                    parseFloat(dataStatistics.table2[0].dPromedioVentasPorCliente).toLocaleString("es-MX", {
                                                        maximumFractionDigits: 2,
                                                        minimumFractionDigits: 2,
                                                        style: 'currency',
                                                        currency: 'MXN'
                                                    }
                                                    )

                                                }
                                            </>

                                    }
                                </Col>

                            </Row>

                            {/*TOTALES POR TIPO DE PRODUCTO*/}
                            <Row className="mt-4 fs--1">
                                <Col className="fw-bold col-4"> Total by Product </Col>
                                <Col className="fw-bold text-center"> Quantity </Col>
                                <Col className="fw-bold text-end"> Total </Col>
                                <Col className="fw-bold text-end"> AVG </Col>
                                <Col className="fw-bold text-end"> Percent </Col>
                            </Row>

                            {dataStatistics.table3 && dataStatistics.table3.map((item, index) => (
                                <Row key={index} className="border-bottom ps-2 mt-1">
                                    <Col className="col-4">
                                        Sales [ {item.vCodigoBarras} ]
                                    </Col>
                                    <Col className="">
                                        <Row>
                                            <Col className="text-center">
                                                {cargando ? (
                                                    <Skeleton />
                                                ) : (
                                                    <>
                                                        {parseInt(item.dCantidad).toLocaleString("es-MX", {
                                                            maximumFractionDigits: 0,
                                                            minimumFractionDigits: 0,
                                                        })}
                                                    </>
                                                )}
                                            </Col>
                                            <Col className="text-end">
                                                {cargando ? (
                                                    <Skeleton />
                                                ) : (
                                                    <>
                                                        {parseFloat(item.dImporte).toLocaleString("es-MX", {
                                                            maximumFractionDigits: 2,
                                                            minimumFractionDigits: 2,
                                                            style: 'currency',
                                                            currency: 'MXN',
                                                        })}
                                                    </>
                                                )}
                                            </Col>
                                            <Col className="text-end">
                                                {cargando ? (
                                                    <Skeleton />
                                                ) : (
                                                    <>
                                                        {parseFloat(item.dPromVenta).toLocaleString("es-MX", {
                                                            maximumFractionDigits: 2,
                                                            minimumFractionDigits: 2,
                                                            style: 'currency',
                                                            currency: 'MXN',
                                                        })}
                                                    </>
                                                )}
                                            </Col>
                                            <Col className="text-end">
                                                {cargando ? (
                                                    <Skeleton />
                                                ) : (
                                                    <>
                                                        {(parseFloat(item.dPorcentaje) / 100).toLocaleString("es-MX", {
                                                            maximumFractionDigits: 2,
                                                            minimumFractionDigits: 2,
                                                            style: 'percent',
                                                        })}
                                                    </>
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            ))}


                            {/*TOTALES POR TIPO [RECOMOMPRA - DISTIBUIDOR - PROPIAS]*/}
                            <Row className="mt-4 fs--1">
                                <Col className="fw-bold col-6"> Total Classification </Col>
                                <Col className="fw-bold text-start"> CO </Col>
                                <Col className="fw-bold text-center"> Total </Col>
                                <Col className="fw-bold text-center"> AVG </Col>
                                <Col className="fw-bold text-end"> Percent </Col>
                            </Row>

                            <Row className=" border-bottom ps-2 mt-1">

                                <Col className="col-6">
                                    Total repurchase (Dist + Aks)
                                </Col>


                                <Col className="">

                                    <Row>

                                        <Col className="text-start">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            parseInt(dataStatistics.table4[0].dClientesRecompra).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 0,
                                                                minimumFractionDigits: 0
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>

                                        <Col className="text-end">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            parseFloat(dataStatistics.table4[0].dTotalbIsRecompra).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                                style: 'currency',
                                                                currency: 'MXN'
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>

                                        <Col className="text-end">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            parseFloat(dataStatistics.table4[0].dAvgSalesRecompra).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                                style: 'currency',
                                                                currency: 'MXN'
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>

                                        <Col className="text-end">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            (parseFloat(dataStatistics.table4[0].dPorcenbIsRecompra) / 100).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                                style: 'percent'
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>

                                    </Row>

                                    

                                </Col>

                            </Row>

                            <Row className=" border-bottom ps-2">

                                <Col className="col-6">
                                    Total distributors (No repurchase)
                                </Col>

                                <Col className="">
                                    <Row>

                                        <Col className="text-start">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            parseInt(dataStatistics.table4[0].dClientesDistribuidor).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 0,
                                                                minimumFractionDigits: 0
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>

                                        <Col className="text-end">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            parseFloat(dataStatistics.table4[0].dTotalbIsDistribucion).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                                style: 'currency',
                                                                currency: 'MXN'
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>

                                        <Col className="text-end">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            parseFloat(dataStatistics.table4[0].dAvgSalesDistribution).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                                style: 'currency',
                                                                currency: 'MXN'
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>

                                        <Col className="text-end">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            (parseFloat(dataStatistics.table4[0].dPorcenbIsDistribucion) / 100).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                                style: 'percent'
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>

                                    </Row>
                                </Col>

                            </Row>

                            <Row className=" border-bottom ps-2">

                                <Col className="col-6">
                                    Total Akasia (own) (No repurchase)
                                </Col>

                                <Col className=" text-end">

                                    <Row>



                                        <Col className="text-start">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            parseInt(dataStatistics.table4[0].dClientesAkasia).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 0,
                                                                minimumFractionDigits: 0
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>

                                        <Col className="text-end">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            parseFloat(dataStatistics.table4[0].dTotalIsAkasia).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                                style: 'currency',
                                                                currency: 'MXN'
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>

                                        <Col className="text-end">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            parseFloat(dataStatistics.table4[0].dAvgSalesAkasia).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                                style: 'currency',
                                                                currency: 'MXN'
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>

                                        <Col className="text-end">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            (parseFloat(dataStatistics.table4[0].dPorcenIsAkasia) / 100).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                                style: 'percent'
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>

                                    </Row>
                                </Col>

                            </Row>

                            <Row className=" border-bottom ps-2">

                                <Col className="col-6">
                                    Total Repurchase Akasia (own)
                                </Col>

                                <Col className=" text-end">

                                    <Row>



                                        <Col className="text-start">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            parseInt(dataStatistics.table4[0].iClientesRecompraAkasia).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 0,
                                                                minimumFractionDigits: 0
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>

                                        <Col className="text-end">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            parseFloat(dataStatistics.table4[0].dTotalAkasiaRecompra).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                                style: 'currency',
                                                                currency: 'MXN'
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>

                                        <Col className="text-end">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            parseFloat(dataStatistics.table4[0].dAvgSalesAkasiaRecompra).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                                style: 'currency',
                                                                currency: 'MXN'
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>

                                        <Col className="text-end">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            (parseFloat(dataStatistics.table4[0].dPercentAkasiaRecompra) / 100).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                                style: 'percent'
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>

                                    </Row>
                                </Col>

                            </Row>

                            <Row className=" border-bottom ps-2">

                                <Col className="col-6">
                                    Total Repurchase Distribuitor
                                </Col>

                                <Col className=" text-end">

                                    <Row>



                                        <Col className="text-start">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            parseInt(dataStatistics.table4[0].iClientesRecompraDistribuidor).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 0,
                                                                minimumFractionDigits: 0
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>

                                        <Col className="text-end">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            parseFloat(dataStatistics.table4[0].dTotalDistribucionRecompra).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                                style: 'currency',
                                                                currency: 'MXN'
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>

                                        <Col className="text-end">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            parseFloat(dataStatistics.table4[0].dAvgSalesDistribucionRecompra).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                                style: 'currency',
                                                                currency: 'MXN'
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>

                                        <Col className="text-end">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            (parseFloat(dataStatistics.table4[0].dPercentDistribucionRecompra) / 100).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                                style: 'percent'
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>

                                    </Row>
                                </Col>

                            </Row>

                            {/*Detalles de  Clasificados*/}
                            <Row className="mt-4 fs--1">
                                <Col className="fw-bold col-8"> Detailed Classification </Col>
                                <Col className="fw-bold text-center"> Total </Col>
                                <Col className="fw-bold text-end"> Percent </Col>
                            </Row>


                            {/*TOTALES POR RECOMPRA, NUEVOS, AKASIA Y DISTRIBUCION */}
                            <Row className="ps-2">

                                <Col className="col-8">
                                    Total Akasia (own)
                                </Col>

                                <Col className=" text-end">

                                    <Row>

                                        <Col className="text-end">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            parseFloat(dataStatistics.table6[0].dTotalAkasia).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                                style: 'currency',
                                                                currency: 'MXN'
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>
                                        <Col className="text-end">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            (parseFloat(dataStatistics.table6[0].dTotalAkasia_Percent) / 100).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                                style: 'percent'
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>

                                    </Row>
                                </Col>

                            </Row>

                            <Row className=" ps-4 mt-1" style={{ fontSize: '0.85rem' }}>

                                <Col className="col-8">
                                    Total New Akasia
                                </Col>


                                <Col className="">

                                    <Row>

                                        <Col className="text-end">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            parseFloat(dataStatistics.table6[0].dNuevoAkasia).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                                style: 'currency',
                                                                currency: 'MXN'
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>
                                        <Col className="text-end">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            (parseFloat(dataStatistics.table6[0].dNuevoAkasia_Percent) / 100).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                                style: 'percent'
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>

                                    </Row>

                                </Col>

                            </Row>

                            <Row className=" border-bottom ps-4 mt-1" style={{ fontSize: '0.85rem' }}>

                                <Col className="col-8">
                                    Total repurchase Akasia
                                </Col>


                                <Col className="">

                                    <Row>

                                      
                                        <Col className="text-end">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            parseFloat(dataStatistics.table6[0].dRecompraAkasia).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                                style: 'currency',
                                                                currency: 'MXN'
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>
                                        <Col className="text-end">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            (parseFloat(dataStatistics.table6[0].dRecompraAkasia_Percent) / 100).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                                style: 'percent'
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>

                                    </Row>

                                </Col>

                            </Row>

                            <Row className="ps-2">

                                <Col className="col-8">
                                    Total Distribuitor
                                </Col>

                                <Col className=" text-end">

                                    <Row>

                                        <Col className="text-end">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            parseFloat(dataStatistics.table5[0].dTotalDistribuidor).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                                style: 'currency',
                                                                currency: 'MXN'
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>
                                        <Col className="text-end">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            (parseFloat(dataStatistics.table5[0].dTotalDistribuidor_Percent) / 100).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                                style: 'percent'
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>

                                    </Row>
                                </Col>

                            </Row>

                            <Row className="ps-4 mt-2" style={{ fontSize: '0.85rem' }}>

                                <Col className="col-8">
                                    Total New Distribuitor
                                </Col>


                                <Col className="">

                                    <Row>

                                        <Col className="text-end">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            parseFloat(dataStatistics.table5[0].dNuevoDistribuidor).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                                style: 'currency',
                                                                currency: 'MXN'
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>
                                        <Col className="text-end">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            (parseFloat(dataStatistics.table5[0].dNuevoDistribuidor_Percent) / 100).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                                style: 'percent'
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>

                                    </Row>

                                </Col>

                            </Row>

                            <Row className="ps-4 mt-1" style={{ fontSize: '0.85rem' }}>

                                <Col className="col-8">
                                    Total repurchase Distribuitor
                                </Col>


                                <Col className="">

                                    <Row>

                               
                                        <Col className="text-end">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            parseFloat(dataStatistics.table5[0].dRecompraDistribuidor).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                                style: 'currency',
                                                                currency: 'MXN'
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>
                                        <Col className="text-end">
                                            {
                                                cargando ?
                                                    <>
                                                        <Skeleton />
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            (parseFloat(dataStatistics.table5[0].dRecompraDistribuidor_Percent) / 100).toLocaleString("es-MX", {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                                style: 'percent'
                                                            }
                                                            )

                                                        }
                                                    </>
                                            }
                                        </Col>

                                    </Row>

                                </Col>

                            </Row>



                        </Col>

                    </Row>


                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { setShowModal(false); }} variant='primary' >Cerrar</Button>
                </Modal.Footer>

            </Modal>

        </>

    );
};

ModalDesgloceVentas.propTypes = {
    idUsuario: PropTypes.number,
    setMensajeError: PropTypes.func,
    setShowMensajeError: PropTypes.func,
    setShowModal: PropTypes.func,
    showModal: PropTypes.bool,
    dateQuarter: PropTypes.instanceOf(Date)
};

export default ModalDesgloceVentas;
