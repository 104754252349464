import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons'; 

const IconButton = ({
    icon,
    iconAlign = 'left',
    iconClassName,
    transform,
    children,
    ...rest
}) => {

    /*si no se pasa un icono,significa que se esta usando una imagen directamente, pero la propiedad icon 
    es required en <FontAwesomeIcon/> , entonces para evitar errores, cuando llegue un icono vacio se va 
    usar el icono faCircle y se activaran los estilos con una opacidad, alto y ancho de cero, lo que resulta 
    en un icono "invisible"
    */
    const estilosIcono = !icon ? { opacity: 0, width: 0, height: 0 } : {};
    const icono = icon ? icon : faCircle;

    return (
        <Button {...rest}>
            {iconAlign === 'right' && children}
            <FontAwesomeIcon
                icon={icono}
                className={classNames(iconClassName, {
                    'me-1': children && iconAlign === 'left',
                    'ms-1': children && iconAlign === 'right'
                })}
                transform={transform}
                style={estilosIcono}

            />
            {iconAlign === 'left' || iconAlign === 'middle' ? children : false}
        </Button>
    )
};

IconButton.propTypes = {
  ...Button.propTypes,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  children: PropTypes.any,
  iconAlign: PropTypes.oneOf(['left', 'right', 'middle']),
  iconClassName: PropTypes.string,
  transform: PropTypes.string
};

export default IconButton;
