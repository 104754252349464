import React from 'react';
import Flex from 'components/common/Flex';
import { Col, Row } from 'react-bootstrap';
import barChart from 'assets/img/illustrations/reports-greeting.png';
import lineChart from 'assets/img/illustrations/crm-line-chart.png';

const Title = () => {
    return (
        <Row >
            <Col sm="auto">
                <Flex alignItems="center">
                    <img src={barChart} width={90} alt="..."  />
                    <div>
                        <h6 className="text-primary fs--1 mb-0">Estadísticas  </h6>
                        <h4 className="text-primary fw-bold mb-0">
                            <span className="text-info fw-medium"> de ventas</span>
                        </h4>
                    </div>
                    <img
                        src={lineChart}
                        width={140}
                        alt="..."
                        className="ms-n4 d-md-none d-lg-block"
                    />
                </Flex>
            </Col>

        </Row>
    );
};

export default Title;
