
import { LineChart } from 'echarts/charts';
import {
    GridComponent,
    TitleComponent,
    ToolboxComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import BasicECharts from 'components/common/BasicEChart';

//import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import PropTypes from 'prop-types';
import React from 'react';



echarts.use([
    GridComponent,
    ToolboxComponent,
    TitleComponent,
    LineChart,
    CanvasRenderer
]);

const TotalAnualByMonthChart = ({
    data_
}) => {


    const Options = {
        tooltip: {
            trigger: 'axis',
            //formatter: "{b0} : {c0}"
            formatter: (a) => {
                //console.log(a);
                return a[0].axisValue + " <div><strong>" + a[0].data.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }) + "</strong></div>";

            }
        },
        xAxis: {
            data: data_.monthNames
        },
        series: [
            {
                type: 'line',
                data: data_.monthSales,
                color: '#2c7be5',
                smooth: true,
                lineStyle: {
                    width: 2
                },
                areaStyle: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            {
                                offset: 0,
                                color: 'rgba(44, 123, 229, .25)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(44, 123, 229, 0)'
                            }
                        ]
                    }
                }
            }
        ],
        grid: {
            bottom: '2%',
            top: '2%',
            right: '0',
            left: '0px'
        }
    };

  

    return (
        <BasicECharts
            //ref={ref}
            echarts={echarts}
            options={Options}
            className="w-100 h-50"
        />
    );
};

TotalAnualByMonthChart.propTypes = {
    data_: PropTypes.array
};

export default TotalAnualByMonthChart;
