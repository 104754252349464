import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

const MensajeErrror = ({ setShowMensajeError, showMensajeError, mensajeError }) => {

    if (showMensajeError) {
        toast.error(mensajeError, {
            theme: 'colored',
            position: "bottom-right"
        });

        
        setShowMensajeError(false);
    }

    return (
        //<ToastContainer/>
        <></>
    );
};

MensajeErrror.propTypes = {

    setShowMensajeError: PropTypes.func,
    showMensajeError: PropTypes.bool,
    mensajeError: PropTypes.string
};


export default MensajeErrror;