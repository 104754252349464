import classNames from 'classnames';
import FalconCloseButton from 'components/common/FalconCloseButton';
import IconButton from 'components/common/IconButton';
import MensajeErrror from 'components/common/MensajeError';
import MensajeInformativo from 'components/common/MensajeInformativo';
import { ToastContainer } from 'react-toastify';
import PageHeader from 'components/common/PageHeader';
import SoftBadge from 'components/common/SoftBadge';
import useToken from 'hooks/useToken';
import React, { useEffect, useState } from 'react';
import { Alert, Badge, Button, Card, Col, Form, Modal, Row, Spinner, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';


const DetallesPoliza = () => {
    const { id } = useParams();
    const { register, handleSubmit, formState: { errors } } = useForm();

    //DATA
    const [poliza, setPoliza] = useState([]);
    const [categoriaPolizas, setCategoriaPolizas] = useState([]);
    const [logs, setLogs] = useState([]);
    const [licencias, setLicencias] = useState([]);

    //MENSAJES Y AVISOS
    const [cargando, setCargando] = useState(true);
    const [mensajeError, setMensajeError] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);
    const [mensajeInformativo, setMensajeInformativo] = useState('');
    const [showMensajeInformativo, setShowMensajeInformativo] = useState(false);
    const [showModalRenovar, setShowModalRenovar] = useState(false);
    const [procesandoModal, setProcesandoModal] = useState(false);

    //AUTENTICACION
    const { token, setToken } = useToken();

    //FUNCIONES
    //Obtiene la poliza por id
    const getInformacionDetalladaPolizaByID = async () => {
        setCargando(true);
        const response = await fetch("/api/poliza/GetInformacionDetalladaPolizaByID/?id=" + id, {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('' + error.message);
                console.log('Hubo un problema con la peticion Fetch:' + error.message);
            });

        if (response.ok) {
            console.log(response);
            const data = await response.json();
            //detalle Poliza
            console.log(data['table1'][0]);
            setPoliza(data['table1'][0]);

            //detalle logs
            console.log(data['table2']);
            setLogs(data['table2']);

            console.log(data['table3']);
            setLicencias(data['table3']);

            //se cambia estado de cargando
            setCargando(false);

        }
        else {
            setShowMensajeError(true);
            setCargando(false);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
    };

    //Renovar Poliza
    const renovarPoliza = async (idCatPoliza) => {

        setProcesandoModal(true);
        const response = await fetch("/api/poliza/renovarpoliza/?idPoliza=" + id + "&idCatPoliza=" + idCatPoliza, {
            method: 'PUT',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            getInformacionDetalladaPolizaByID();
            setShowMensajeInformativo(true);
            setMensajeInformativo(data[0].vMensaje);
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setShowModalRenovar(false);
        setProcesandoModal(false);
    };

    //Desactivar Poliza
    const ObtenerListaCategoriasPolizas = async () => {
        const response = await fetch("/api/poliza/ObtenerListaCategoriasPolizas/?idPoliza=" + id, {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            setCategoriaPolizas(data);

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }

    };

    //ejecutar solo 1 vez despues del DOM
    useEffect(() => {
        getInformacionDetalladaPolizaByID();
    }, []);

    const onSubmitRenovar = data => {
        renovarPoliza(data.idCatPoliza);
    };

    //Se ha recargado 
    const navigate = useNavigate();

    return (
        <>
            <PageHeader
                title={"P\u00F3liza " + id + " |  " + poliza.vNombreTipoPoliza}
                className="mb-1"
            >
            </PageHeader>
            {cargando && (<h3><Spinner className="text-inline" animation="border" variant="primary" /> Cargando...</h3>)}
            <Card className="mb-3">
                <Card.Header className="d-flex flex-between-center">
                    <IconButton
                        onClick={() => navigate(-1)}
                        variant="falcon-default"
                        size="sm"
                        icon="arrow-left"
                    />
                </Card.Header>
            </Card>
            <Row className="g-3 mb-3">
                <Col lg={6} md={12} xs={12}>
                    <Card>
                        <Card.Header>
                            <Row className="align-items-center">
                                <Col md={2}><img src="/img/poliza48.gif"></img></Col>
                                <Col>
                                    <h5 className="mb-0">Datos de p&oacute;liza</h5>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body className="border-top">
                            <Table responsive striped hover size="sm" >
                                <tbody>
                                    <tr>
                                        <td>Fecha Activacion</td>
                                        <td>{poliza.dFechaActivacion}</td>
                                    </tr>
                                    <tr>
                                        <td>Fecha vencimiento</td>
                                        <td>{poliza.dFechaVencimiento}</td>
                                    </tr>
                                    <tr>
                                        <td>D&iacute;as restantes</td>
                                        <td>{poliza.dDiasFaltantes} d&iacute;as</td>
                                    </tr>
                                    <tr>
                                        <td>Estado</td>
                                        <td>{poliza.dDiasFaltantes > 0 && <Badge bg="success" className="me-2 dark__bg-dark"> A C T I V A  </Badge>}
                                            {poliza.dDiasFaltantes <= 0 && <Badge bg="danger" className="me-2 dark__bg-dark"> V E N C I D A </Badge>}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <div className="text-end">

                                <Button
                                    onClick={() => { ObtenerListaCategoriasPolizas(); setShowModalRenovar(true); }}
                                    variant='falcon-success'
                                    className='me-2 mb-1 mt-1'>Renovar</Button>
                            </div>
                        </Card.Body>
                        <Card.Footer className="border-top text-end">
                        </Card.Footer>
                    </Card>
                </Col>
                <Col lg={6} md={12} xs={12}>
                    <Card>
                        <Card.Header>
                            <Row className="align-items-center">
                                <Col>
                                    <h5 className="mb-0">Cobertura de la p&oacute;liza</h5>
                                </Col>
                            </Row>
                        </Card.Header>

                        <Card.Body className="border-top">
                            <Table responsive striped hover size="sm" >
                                <p>La <strong>P&oacute;liza de Actualizaciones</strong> tiene la siguiente cobertura:</p>
                                <ul>
                                    <li>La licencia tipo Servidor (LIC-SERV) o Caja Adicional (LIC-CAJ) podr&aacute; descargar actualizaciones de manera gratuita y autom&aacute;ticamente.</li>
                                </ul>
                                <p>La <strong>P&oacute;liza de Soporte T&eacute;nico B&aacute;sico</strong> tiene la siguiente cobertura:</p>
                                <ul>
                                    <li>Atenci&oacute;n t&eacute;cnica personalizada al cliente con cualquier inconveniente que inhabilite abrir o ejecutar el programa Akasia Punto de Venta.</li>
                                    <li>La atenci&oacute;n de esta p&oacute;liza solo permite la comunicaci&oacute;n por los siguientes canales: WhatsApp, Mensaje de texto, chat, correo electr&oacute;nico. Si desea atenci&oacute;n por llamada, tiene costo adicional.</li>
                                </ul>

                            </Table>
                            <center>
                                <a href="https://akasia.com.mx/?terminos" target="_blank" rel="noreferrer">
                                    <IconButton
                                        variant="falcon-default"
                                        size="sm"
                                        icon="file"
                                        transform="shrink-2"
                                        iconAlign="middle"
                                        className="d-none d-sm-block me-2"
                                    >
                                        <span className="d-none d-xl-inline-block ms-1">Ver t&eacute;rminos y condiciones</span>
                                    </IconButton>
                                </a>
                            </center>
                        </Card.Body>
                        <Card.Footer className="border-top text-end">

                        </Card.Footer>
                    </Card>
                </Col>

            </Row>

            <Row className="g-3 mb-3">
                <Col lg={12}>
                    <Card className="mt-3">
                        <Card.Header className="border-bottom border-200">
                            <h5 className="mb-0">Licencias que cubre la p&oacute;liza</h5>
                        </Card.Header>
                        <Card.Body>
                            <Row className="gx-3">
                                {licencias.map((detalle, i) => {
                                    return (
                                        <Col key={i} md={6} xxl={4} className="border-200 border-md-bottom-0 border-end pt-1 pb-md-0 ps-md-3">
                                            <div className="hover-bg-100 py-x1 text-center rounded-3 position-relative">
                                                {detalle.idCatTipoLicencia == 1 &&
                                                    <img src="/img/servidor48.png"></img>}
                                                {detalle.idCatTipoLicencia == 2 &&
                                                    <img src="/img/caja48.png"></img>}
                                                {detalle.idCatTipoLicencia == 3 &&
                                                    <img src="/img/nube48.png"></img>}

                                                <h5 className="mt-3 mb-1">
                                                    <Link to={"/licencias/detalle/" + detalle.idLicencia}>
                                                        {detalle.vDescripcionTipo}
                                                    </Link>
                                                </h5>
                                                <h5 className="w-75 mx-auto text-600">{detalle.idLicencia}</h5>
                                                {/*<Badge bg='primary' className='mb-2'>{detalle.vNombreVendedor}</Badge>*/}

                                                <Table responsive size="sm"  >
                                                    <tbody>
                                                        <tr>
                                                            <td className="col-lg-4 col-sm-4 col-md-4"><h6 className="mt-1 text-600">Fecha Inicio:</h6></td>
                                                            <td><h6 className="mt-1 text-600">{detalle.dInicioLicenciaUso} <SoftBadge bg='primary' className='me-2'>hace {detalle.dDiasTranscurridos} d&iacute;as</SoftBadge></h6></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="col-lg-4 col-sm-4 col-md-4"><h6 className="mt-1 text-600">&Uacute;ltima Conexi&oacute;n:</h6></td>
                                                            <td><h6 className="mt-1 text-600">{detalle.dUltimaConexion} <SoftBadge bg='warning' className='me-2'>hace {detalle.dDiasTransConexion} d&iacute;as</SoftBadge></h6></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="col-lg-4 col-sm-4 col-md-4"><h6 className="mt-1 text-600">Licencia:</h6></td>
                                                            <td><h6 className="mt-1 text-600">{detalle.vUUIDLicencia} </h6></td>
                                                        </tr>
                                                        {!detalle.bCancelado &&
                                                            <>
                                                                <tr>
                                                                    <td className="col-lg-4 col-sm-4 col-md-4"><h6 className="mt-1 text-600">Estado:</h6></td>
                                                                    <td>
                                                                        <h6 className="mt-1 text-600">
                                                                            {!detalle.bIsPeriodoPrueba ?
                                                                                (
                                                                                    <>
                                                                                        <SoftBadge bg='success' className='me-2'>A C T I V A</SoftBadge>
                                                                                        <img src="/img/activo.png"></img>
                                                                                    </>
                                                                                )
                                                                                :
                                                                                (
                                                                                    <>
                                                                                        <SoftBadge bg='danger' className='me-2'>P R U E B A</SoftBadge>
                                                                                        <img src="/img/noactivo.png"></img>
                                                                                    </>
                                                                                )

                                                                            }

                                                                        </h6>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="col-lg-4 col-sm-4 col-md-4"><h6 className="mt-1 text-600">D&iacute;as restantes:</h6></td>
                                                                    <td>
                                                                        <h6 className="mt-1 text-600">
                                                                            {detalle.dDiasRestantes > 0 ?
                                                                                detalle.dDiasRestantes > 1500 ?
                                                                                    (
                                                                                        <>
                                                                                            <SoftBadge bg='success' className='me-2'> P E R M A N E N T E </SoftBadge>
                                                                                        </>
                                                                                    )
                                                                                    :
                                                                                    (
                                                                                        <>
                                                                                            <SoftBadge bg='success' className='me-2'>Restan {detalle.dDiasRestantes} d&iacute;as</SoftBadge>
                                                                                        </>
                                                                                    )
                                                                                :
                                                                                (
                                                                                    <>
                                                                                        <SoftBadge bg='danger' className='me-2'>V E N C I D A </SoftBadge>
                                                                                    </>
                                                                                )

                                                                            }

                                                                        </h6>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        }
                                                    </tbody>
                                                </Table>
                                                {detalle.bCancelado &&
                                                    <Row className="align-items-center">
                                                        <Col>
                                                            <SoftBadge bg='danger' className='me-2'>C A N C E L A D A </SoftBadge>
                                                            <Alert variant="danger">
                                                                <p className="fs--1"><b>MOTIVO:</b> {detalle.vMotivoCancelacion}</p>
                                                                <p className="fs--1"><b>CANCEL&Oacute;:</b> {detalle.vUsuarioCancelador}</p>
                                                            </Alert>
                                                        </Col>
                                                    </Row>
                                                }
                                                {!detalle.bCancelado &&
                                                    <>
                                                        <IconButton
                                                            iconClassName="fs--2 me-1"
                                                            variant="falcon-default"
                                                            size="sm"
                                                            icon="file"
                                                            className="m-2"
                                                        >
                                                            Descargar licencia
                                                        </IconButton>
                                                        <Link to={"/licencias/detalle/" + detalle.idLicencia}>
                                                            <IconButton
                                                                iconClassName="fs--2 me-1"
                                                                variant="falcon-default"
                                                                size="sm"
                                                                icon="eye"
                                                                className="m-2"
                                                            >
                                                                Ir a detalles
                                                            </IconButton>
                                                        </Link>
                                                    </>
                                                }
                                            </div>
                                        </Col>
                                    )
                                })}

                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="g-3 mb-3">
                <Col lg={12}>
                    <Card className="mb-3">
                        <Card.Header>
                            <h5 className="mb-0">Historial de movimientos / Logs</h5>
                        </Card.Header>
                        <Card.Body className="border-top p-0">
                            {cargando ? (<h3><Spinner className="text-inline" animation="border" variant="primary" /> Cargando...</h3>) : (
                                logs.map((log, index) => (
                                    <Row
                                        key={index}
                                        className="g-0 align-items-center border-bottom px-3" >
                                        <Col md="auto" className="pe-3">
                                            <span className={classNames('badge rounded-pill', 'badge-soft-success')}   >
                                                {log.vIP}
                                            </span>
                                        </Col>
                                        <Col >
                                            {log.vMovimiento}
                                        </Col>
                                        <Col md >
                                            <code>
                                                {log.vCreatedUser}
                                            </code>
                                        </Col>
                                        <Col md="auto">
                                            {log.dCreatedDate}

                                        </Col>
                                    </Row>
                                ))
                            )}
                        </Card.Body>
                        <Card.Footer className="bg-light p-0">
                            <IconButton
                                variant="link"
                                iconClassName="fs--2 ms-1"
                                icon="chevron-right"
                                className="d-block w-100"
                                iconAlign="right"
                                to="#!"
                            >
                                Ver m&aacute;s movimientos
                            </IconButton>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>

            <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>
            <MensajeInformativo setShowMensajeInformativo={setShowMensajeInformativo} showMensajeInformativo={showMensajeInformativo} mensajeInformativo={mensajeInformativo}></MensajeInformativo>
            <ToastContainer/>


            {showModalRenovar &&
                <Modal
                    show={showModalRenovar}
                    onHide={() => setShowModalRenovar(false)}
                    size="md"
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Form noValidate onSubmit={handleSubmit(onSubmitRenovar)}>
                        <Modal.Header>
                            <Modal.Title>Renovar P&oacute;liza {poliza.idPoliza}</Modal.Title>
                            <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => setShowModalRenovar(false)} />

                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col md={12}>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Selecciona P&oacute;liza</Form.Label>
                                        <Form.Select
                                            aria-label="Default select"
                                            isInvalid={!!errors.idCatPoliza}
                                            {...register("idCatPoliza",
                                                {
                                                    required: true
                                                })
                                            }
                                        >
                                            <option value="">Selecciona el periodo de renovaci&oacute;n</option>
                                            {categoriaPolizas.map((categoria, index) => {
                                                return (<option key={index} value={categoria.idCatPoliza}>{categoria.vNombrePoliza} - {categoria.iNumeroDias} d&iacute;as</option>)
                                            })}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.idCatPoliza && errors.idCatPoliza.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => { setShowModalRenovar(false) }} variant='primary' >Cerrar</Button>
                            {procesandoModal ? <Button variant="primary" disabled>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                Procesando...
                            </Button> : <Button type="submit" variant='success' >R E N O V A R</Button>}

                        </Modal.Footer>
                    </Form>
                </Modal>
            }


        </>
    );

};

export default DetallesPoliza;
