import IconButton from 'components/common/IconButton';
import PageHeader from 'components/common/PageHeader';
import useToken from 'hooks/useToken';
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import MensajeErrror from 'components/common/MensajeError';
import MensajeInformativo from 'components/common/MensajeInformativo';
import { ToastContainer } from 'react-toastify';

const NuevoUsuarioNubeAkasia = () => {
    const { register, setValue, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();
    //DATA
    const [clientes, setClientes] = useState([]);
    const [UUIDNubes, setUUIDNubes] = useState([]);

    /*SPRINT_18*/
    const [Sucursales, setSucursales] = useState([]);


    //MENSAJES Y AVISOS

    const [mensajeError, setMensajeError] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);
    const [cargando, setCargando] = useState(true);
    const [mensajeInformativo, setMensajeInformativo] = useState('');
    const [showMensajeInformativo, setShowMensajeInformativo] = useState(false);

    //AUTENTICACION
    const { token, setToken } = useToken();

    //Obtener datos Licencia Nueva
    const obtenerDatosLicenciaNueva = async () => {
        const response = await fetch("/api/licencia/ObtenerDatosLicenciaNueva", {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data["table1"]);
            setClientes(data["table1"]);

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setCargando(false);
    };


    //Obtener UUID Empresas De Un Cliente
    const obtenerUUIDNubesDeCliente = async (idClienteAkasia) => {
        setUUIDNubes([]);
        const response = await fetch("/api/licencia/ObtenerLicenciasNubeCliente?idClienteAkasia=" + idClienteAkasia, {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            setUUIDNubes(data);

            setValue("idLicencia", data[0].idLicencia);

            /**
             * SPRINT_18
             * */
            if (data.length > 0) {
                obtenerSucursalesDeEmpresa(data[0].idLicencia);
            }
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
    };

    //Obtener UUID
    const ObtenerCadenaRandom = async (action) => {
        const response = await fetch("/api/licencia/ObtenerCadenaRandom", {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'text/plain'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.text();
            console.log(data);

            if (action == 'Usuario')
                setValue("vUsuario", data);
            else if (action == 'Contrasena')
                setValue("vContrasena", data);

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
    };

    //SPRINT_18
    //Obtener Sucursales De la empresa
    const obtenerSucursalesDeEmpresa = async (idLicencia) => {
        // alert("hola mundo " + idLicencia);
        setSucursales([]);
        const response = await fetch("/api/licencia/ObtenerSucursalesEmpresa?idLicencia=" + idLicencia, {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            setSucursales(data);

            setValue("idSucursal", data[0].idSucursal);
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
    };

    //Registrar Usuario Nube Akasia
    const registrarUsuarioNubeAkasia = async (values) => {

        console.log(values);
        const response = await fetch("/api/usuarionubeakasia/RegistrarUsuarioNubeAkasia/?values=" + values, {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {

            const jsonResponse = await response.json();
            console.log(jsonResponse);

            if (jsonResponse.iResponseType == 1) {
                setShowMensajeInformativo(true);
                setMensajeInformativo(jsonResponse.vMessage);

                navigate("/servicios/usuarios-nube-akasia", {jsonResponse});
            } else {
                setShowMensajeError(true);
                setMensajeError(jsonResponse.vMessage);
            }


        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
    };


    const onSubmit = data => {
        registrarUsuarioNubeAkasia(JSON.stringify(data));
    };

    //ejecutar solo 1 vez despues del DOM
    useEffect(() => {
        obtenerDatosLicenciaNueva();
    }, []);

    return (
        <>

            <PageHeader
                title={"Registrar Usuario Nube Akasia"}
                className="mb-1"
            >

            </PageHeader>
            <Card>
                <Card.Header className="d-flex flex-between-center">
                    <IconButton
                        onClick={() => navigate(-1)}
                        variant="falcon-default"
                        size="sm"
                        icon="arrow-left"
                    />

                </Card.Header>
            </Card>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Row className="g-3 mb-3">
                    {cargando ? (<h3><Spinner className="text-inline" animation="border" variant="primary" /> Cargando...</h3>)
                        :
                        (
                            <>
                                <Col lg={6}>
                                    <Card className="mt-3">
                                        <Card.Header className="border-bottom border-200">
                                            <Row>
                                                <Col xs={2}>
                                                    <img src="/img/usuarioNubeAkasia.png"></img>
                                                </Col>
                                                <Col>
                                                    <h4 className="mb-0">Usuario Nube</h4>
                                                    <p>Cada usuario de la Nube Akasia puede entrar de forma independiente a los datos de la empresa:</p>
                                                    <ul>
                                                        <li>Es necesario que el cliente haya adquirido una licencia tipo Servidor.</li>
                                                    </ul>
                                                </Col>
                                            </Row>

                                        </Card.Header>
                                        <Card.Body>
                                            <>
                                                <Form.Group className="mb-3">
                                                    <Row>
                                                        <Col>
                                                            <Form.Label>Usuario</Form.Label>
                                                        </Col>
                                                        <Col xs="auto">
                                                            <IconButton
                                                                className="ms-2"
                                                                iconClassName="fs--2 me-1"
                                                                variant="falcon-primary"
                                                                size="sm"
                                                                icon="circle"
                                                                onClick={() => { ObtenerCadenaRandom('Usuario') }}
                                                            >
                                                                Generar
                                                            </IconButton>
                                                        </Col>
                                                    </Row>
                                                    <Form.Control
                                                        type="text"
                                                        autoComplete="off" 
                                                        onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                        isInvalid={!!errors.vUsuario}
                                                        {...register("vUsuario",
                                                            {
                                                                required: 'El vUsuario es requerido',
                                                                maxLength: 40
                                                            })
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.vUsuario && errors.vUsuario.message}
                                                    </Form.Control.Feedback>

                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Row>
                                                        <Col>
                                                            <Form.Label>Contrase&ntilde;a</Form.Label>
                                                        </Col>
                                                        <Col xs="auto">
                                                            <IconButton
                                                                className="ms-2"
                                                                iconClassName="fs--2 me-1"
                                                                variant="falcon-primary"
                                                                size="sm"
                                                                icon="circle"
                                                                onClick={() => { ObtenerCadenaRandom('Contrasena') }}
                                                            >
                                                                Generar
                                                            </IconButton>
                                                        </Col>
                                                    </Row>


                                                    <Form.Control
                                                        type="text"
                                                        autoComplete="off" 
                                                        onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                        isInvalid={!!errors.vContrasena}
                                                        {...register("vContrasena",
                                                            {
                                                                required: 'El vContrasena es requerido',
                                                                maxLength: 40
                                                            })
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.vContrasena && errors.vContrasena.message}
                                                    </Form.Control.Feedback>

                                                </Form.Group>
                                            </>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card className="mt-3">
                                        <Card.Header className="border-bottom border-200">

                                        </Card.Header>
                                        <Card.Body>
                                            <>
                                                <Form.Group className="mb-3" >
                                                    <Form.Label>Nombre del cliente</Form.Label>
                                                    <Select

                                                        isInvalid={!!errors.idClienteAkasia}
                                                        {...register("idClienteAkasia",
                                                            {
                                                                required: true
                                                            })
                                                        }
                                                        closeMenuOnSelect={true}
                                                        options={clientes}
                                                        placeholder='Selecciona...'
                                                        classNamePrefix="react-select"
                                                        onChange={target => { setValue("idClienteAkasia", target.value); obtenerUUIDNubesDeCliente(target.value) }}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.idClienteAkasia && errors.idClienteAkasia.message}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                {/*<Form.Group className="mb-3">
                                                    <Form.Label>Nombre del Cliente</Form.Label>
                                                    <Form.Select
                                                        aria-label="Default select"
                                                        isInvalid={!!errors.idClienteAkasia}
                                                        {...register("idClienteAkasia",
                                                            {
                                                                required: true
                                                            })
                                                        }
                                                        onChange={(event) => { obtenerUUIDNubesDeCliente(event.target.value) }}
                                                    >
                                                        <option value="">Selecciona un cliente</option>
                                                        {clientes.map((cl, index) => {
                                                            return (<option key={index} value={cl.value}>{cl.label}</option>)
                                                        })}
                                                    </Form.Select>
                                                </Form.Group>*/}

                                                <Form.Group className="mb-3" >
                                                    <Form.Label>Licencia Nube</Form.Label>
                                                    <Form.Select
                                                        aria-label="Default select"
                                                        isInvalid={!!errors.idLicencia}
                                                        {...register("idLicencia",
                                                            {
                                                                required: true

                                                            })
                                                        }
                                                        //SPRINT_18
                                                        onChange={(event) => { setValue("idLicencia", event.target.value); obtenerSucursalesDeEmpresa(event.target.value) }}

                                                    >
                                                        {UUIDNubes.map((nube, index) => {
                                                            return (<option key={index} value={nube.idLicencia}>{nube.idLicencia} - {nube.vUUIDLicencia}</option>)
                                                        })}

                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.idLicencia && errors.idLicencia.message}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                {/*SPRINT_18*/}
                                                <Form.Group className="mb-3" >
                                                    <Form.Label>Sucursal Asignada</Form.Label>
                                                    <Form.Select
                                                        aria-label="Default select"
                                                        isInvalid={!!errors.idSucursal}
                                                        {...register("idSucursal",
                                                            {
                                                                required: true
                                                            })
                                                        }
                                                    >
                                                        {Sucursales.map((sucursal, index) => {
                                                            return (<option key={index} value={sucursal.idSucursal}>{sucursal.idSucursal} - {sucursal.vNombreSucursal}</option>)
                                                        })}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.idSucursal && errors.idSucursal.message}
                                                    </Form.Control.Feedback>
                                                </Form.Group>


                                                <Form.Group className="mb-3" >
                                                    <Form.Label>Nombre y apellidos del usuario</Form.Label>
                                                    <Form.Control
                                                        autoComplete="off" 
                                                        onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                                        type="text"
                                                        placeholder="vNombre"
                                                        isInvalid={!!errors.vNombre}
                                                        {...register("vNombre",
                                                            {
                                                                required: 'El nombre del cliente es requerido',
                                                                maxLength: 200,
                                                                minLength: 1
                                                            })
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.vNombre && errors.vNombre.message}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Row>
                                                    <Col>
                                                        &nbsp;
                                                    </Col>
                                                    <Col xs="auto">
                                                        <IconButton
                                                            className="ms-2"
                                                            iconClassName="fs--2 me-1"
                                                            variant="falcon-default"
                                                            size="sm"
                                                            icon="check"
                                                            type="submit"
                                                        >
                                                            Guardar Cambios
                                                        </IconButton>
                                                    </Col>
                                                </Row>

                                            </>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </>
                        )}
                </Row>
            </Form>
            <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>
            <MensajeInformativo setShowMensajeInformativo={setShowMensajeInformativo} showMensajeInformativo={showMensajeInformativo} mensajeInformativo={mensajeInformativo}></MensajeInformativo>
            <ToastContainer/>
        </>
    );
};

export default NuevoUsuarioNubeAkasia;