import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import useCopyToClipboard from "hooks/useCopyToClipboard";

const CopyLabel = ({ code, placement,padding }) => {
    const [isCopied, handleCopy] = useCopyToClipboard(2000);

    return (
        <OverlayTrigger
            placement={placement}
            overlay={
                <Tooltip id="tooltip-copy">
                    <span>Copiar</span>
                </Tooltip>
            }
        >
            <span
                onClick={() => {
                    handleCopy(code);
                    toast.success('Copiado en el portapapeles: ' + code, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "colored"
                    });
                }}
                style={{
                    cursor: 'pointer',
                    display: 'inline-flex',
                    alignItems: 'center',
                    padding: padding ,
                    //backgroundColor: '#f0f0f0',
                    borderRadius: '4px',
                    //fontSize: '14px',
                    color: isCopied ? '#748194' : '#007bff',
                    userSelect: 'none'
                }}
            >
                <>{isCopied ? "¡Copiado!" : code}</>
            </span>
        </OverlayTrigger>
    );
};

CopyLabel.propTypes = {
    code: PropTypes.string,
    placement: PropTypes.string,
    padding: PropTypes.string
};

export default CopyLabel;
