import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

//import FalconCardHeader from 'components/common/FalconCardHeader';
//import CardDropdown from 'components/common/CardDropdown';
import { Card, Spinner, Col, Row, Form } from 'react-bootstrap';
//import DatePicker from 'react-datepicker';
import VentasPorVendedorChart from './VentasPorVendedorChart';

//import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import useToken from 'hooks/useToken';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//import holdTicket from 'assets/img/support-desk/hold-tickets.png';


const VentasPorVendedor = ({
    setMensajeError,
    setShowMensajeError,
    items_Money

}) => {

    const { token, setToken } = useToken();
    const [cargando, setCargando] = useState(true);

    const [chartData, setChartData] = useState([]);

    //const date = new Date();
    //const [startDate, setStartDate] = useState(date.setDate(date.getDate() - 30));
    //const [endDate, setEndDate] = useState(new Date());

    //const chartRef = useRef(null);

    useEffect(
        () => {
            //setCargando(true)
            //getDailyleads(startDate, endDate);
            //console.log("12_pastDates", getPastDates(12));
            if (items_Money == 'ITEMS') {
                obtenerVentasTotalesByVendedor_ITEMS();
            }

            if (items_Money == 'MONEY') {
                obtenerVentasTotalesByVendedor();
            }

            
        }
        , []);

    //const onChange = dates => {
    //    const [start, end] = dates;


    //    setStartDate(start);
    //    setEndDate(end);

    //    if (start != null && end != null) {
    //        //getDailyleads(start, end);
    //    }

    //    console.log(dates);
    //};



    /**
     * Obtiene las ventas por vendedor
     * 
     */
    const obtenerVentasTotalesByVendedor = async () => {

        setCargando(true);

        const response = await fetch("/api/Administradores/ventasTotalesByVendedor",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);

                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            const responseType = await response.json();
            console.log(responseType);

            if (responseType.iResponseType == 1) {

                const data = JSON.parse(responseType.detalles);
                console.log("============= VENTAS POR VENDEDORES =================");
                console.log(data);

                var chartData_ = [];
                chartData_.VentasPorVendedor = data[0];
                chartData_.Vendedores = data[1];

                setChartData(chartData_);

                setCargando(false);

            } else {
                setMensajeError(responseType.vMessage);
                setShowMensajeError(true);
            }

        }
        else {
            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }

        setCargando(false);
    }


    const obtenerVentasTotalesByVendedor_ITEMS = async () => {

        setCargando(true);
        const response = await fetch("/api/home/ITEMSVendidos",
            {
                method: 'GET',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);

                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            const responseType = await response.json();
            console.log(responseType);

            if (responseType.iResponseType == 1) {

                const data = JSON.parse(responseType.detalles);
                console.log("============= ITEMS POR VENDEDORES =================");
                console.log("data -> ", data);
                console.log("data 0 -> ", data[0]);
                console.log("data 1 -> ", data[1]);
                var chartData_ = [];
                chartData_.VentasPorVendedor = data[0];
                chartData_.Vendedores = data[1];

                setChartData(chartData_);

                setCargando(false);
            } else {
                setMensajeError(responseType.vMessage);
                setShowMensajeError(true);
                setCargando(false);
            }

        }
        else {
            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
            setCargando(false);
        }
    }


    const GreetingsDateInput = forwardRef(({ value, onClick }, ref) => (
        <div className="position-relative">
            <Form.Control
                size="sm"
                ref={ref}
                onClick={onClick}
                value={value}
                className="ps-4"
                onChange={e => {
                    console.log({ e });
                }}
            />
            <FontAwesomeIcon
                icon="calendar-alt"
                className="text-primary position-absolute top-50 translate-middle-y ms-2"
            />
        </div>
    ));
    GreetingsDateInput.propTypes = {
        value: PropTypes.string,
        onClick: PropTypes.func
    };

    return (
        <>
            {
                cargando ?
                    <>
                        <Card >
                            <Card.Header>
                                <Row>
                                    <Col>
                                        <h5><Spinner className='text-inline mt-3 ms-2' animation='border' variant='primary' /> Cargando... Ventas por vendedor </h5>
                                    </Col>
                                    {/*<Col md="auto" className="mb-3 mb-md-0">*/}
                                    {/*    <Row className="g-3 gy-md-0 h-100 align-items-center">*/}
                                    {/*        <Col xs="auto">*/}
                                    {/*            <h6 className="text-700 mb-0 text-nowrap">Mostrando datos entre:</h6>*/}
                                    {/*        </Col>*/}
                                    {/*        <Col md="auto">*/}
                                    {/*            <DatePicker*/}
                                    {/*                selected={startDate}*/}
                                    {/*                onChange={onChange}*/}
                                    {/*                startDate={startDate}*/}
                                    {/*                formatWeekDay={day => day.slice(0, 3)}*/}
                                    {/*                endDate={endDate}*/}
                                    {/*                selectsRange*/}
                                    {/*                dateFormat="MMM dd"*/}
                                    {/*                customInput={<GreetingsDateInput />}*/}
                                    {/*            />*/}
                                    {/*        </Col>*/}
                                    {/*    </Row>*/}
                                    {/*</Col>*/}
                                </Row>


                            </Card.Header>

                        </Card>
                        <Skeleton height={400} style={{ "zIndex": "-1" }} />
                    </>
                    :
                    <>
                        <Row>

                            <Col className="col-12">

                                <Card>
                                    <Card.Header>
                                        <Row className="flex-between-center g-0">
                                            <Col xs="auto">
                                                <h6 className="mb-0">Ventas por vendedor</h6>
                                            </Col>


                                            {/*<Col md="auto" className="mb-3 mb-md-0">*/}
                                            {/*    <Row className="g-3 gy-md-0 h-100 align-items-center">*/}
                                            {/*        <Col xs="auto">*/}
                                            {/*            <h6 className="text-700 mb-0 text-nowrap">Mostrando datos entre:</h6>*/}
                                            {/*        </Col>*/}
                                            {/*        <Col md="auto">*/}
                                            {/*            <DatePicker*/}
                                            {/*                selected={startDate}*/}
                                            {/*                onChange={onChange}*/}
                                            {/*                startDate={startDate}*/}
                                            {/*                formatWeekDay={day => day.slice(0, 3)}*/}
                                            {/*                endDate={endDate}*/}
                                            {/*                selectsRange*/}
                                            {/*                dateFormat="MMM dd"*/}
                                            {/*                customInput={<GreetingsDateInput />}*/}
                                            {/*            />*/}
                                            {/*        </Col>*/}
                                            {/*    </Row>*/}
                                            {/*</Col>*/}


                                        </Row>

                                    </Card.Header>
                                    <Card.Body className="pe-xxl-0">
                                        <Row className="p-1">
                                            <Col className="col-12">
                                                <VentasPorVendedorChart
                                                    chartData={chartData}
                                                    items_Money={items_Money}
                                                />
                                            </Col>

                                        </Row>
                                        
                                    </Card.Body>
                                </Card>

                            </Col>
                        </Row>

                    </>
            }
        </>

    );
};

VentasPorVendedor.propTypes = {
    setMensajeError: PropTypes.func,
    setShowMensajeError: PropTypes.func,
    items_Money: PropTypes.string,
};

export default VentasPorVendedor;
