import classNames from 'classnames';
import Background from 'components/common/Background';
import SoftBadge from 'components/common/SoftBadge';
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import CountUp from 'react-countup';

const StatisticsCard = ({ stat, ...rest }) => {
    const {
        title,
        titleClassName,
        value,
        decimal,
        suffix,
        prefix,
        valueClassName,
        badgeText,
        badgeBg,
        image,
        className,
        imgLic,
    } = stat;
    return (
        <Card className={classNames(className, 'overflow-hidden')} {...rest}>
            <Background image={image} className="bg-card" />
            <Card.Body className="position-relative">
                <h6>
                    <span className={titleClassName}>{title}</span>
                    {badgeText && (
                        <SoftBadge bg={badgeBg} pill className="ms-2">
                            {badgeText}
                        </SoftBadge>
                    )}
                </h6>
                <div
                    className={classNames(
                        valueClassName,
                        'display-4 fs-1 mb-0 fw-normal font-sans-serif'
                    )}
                >
                    <img src={imgLic} height="35"></img> &nbsp;&nbsp;
                    <CountUp
                        start={0}
                        end={value}
                        duration={2.75}
                        suffix={suffix}
                        prefix={prefix}
                        separator=","
                        decimals={decimal ? 2 : 0}
                        decimal="."
                    />
                </div>
            </Card.Body>
        </Card>
    );
};

StatisticsCard.propTypes = {
    stat: PropTypes.shape({
        title: PropTypes.string.isRequired,
        titleClassName: PropTypes.string,
        value: PropTypes.number.isRequired,
        decimal: PropTypes.bool,
        suffix: PropTypes.string.isRequired,
        prefix: PropTypes.string.isRequired,
        valueClassName: PropTypes.string,
        linkText: PropTypes.string,
        link: PropTypes.string,
        badgeText: PropTypes.string,
        badgeBg: PropTypes.string,
        image: PropTypes.string,
        className: PropTypes.string,
        imgLic: PropTypes.string
    })
};

export default StatisticsCard;
